import HButton from 'components/Bottom'
import React, { useState, useEffect } from 'react'
import { FormControl, FormGroup } from 'react-bootstrap'

const CompleteQuote = () => {
  const [massage, setMassage] = useState()

  const [dimension, setDimension] = useState({
    height: window.innerHeight - 757
  })
  const { height } = dimension
  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 757
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <>
      <div className="title-quotation">Quotation Request Submitted</div>
      <div className="text-complete">
        Thank you for submitting your request for a quotation. Our team will be
        in touch with you as soon as possible through the email address you
        entered below
      </div>

      <div className="text-complete">
        <div className="textBold">alvianto.w@onebyone.io</div>
      </div>
      <div className="text-complete mt-5 pt-5">
        If you have any further questions or comments, please enter them below
      </div>
      <FormGroup className="pt-2">
        <FormControl
          className="form-control-area"
          placeholder="Enter your Note Here"
          value={massage}
          name="message"
          onChange={e => setMassage(e.target.value)}
          as="textarea"
          maxLength={200}
          rows={3}
        />
      </FormGroup>
      <HButton
        className="mt-2"
        icon="ri-add-fill"
        text={'SEND NOTES'}
        disabledStatus
      />
      <div style={{ height: height }} />
      <div className="my-3 btn-request-quotation">
        <HButton icon="ri-add-fill" text={'YOUR QOUTE'} />
        <HButton
          icon="ri-add-fill"
          // onClick={() => validationRequest()}
          // disabledStatus
          text={'REQUEST ANOTHER QOUTES'}
        />
      </div>
    </>
  )
}

export default CompleteQuote

import React, { useEffect, useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import {
  resetSchema,
  saveSchema,
  changeOptionsValue,
  hiddenColumn
} from './service'
import HButton from 'components/Bottom'
import Dropdown from 'components/Dropdown'
import CToast from 'components/Toast'

const ModalEditColumn = ({
  show,
  setModalTable,
  setEditColumnTemp,
  fields,
  columnHidden,
  module,
  dataListDropdown,
  t
}) => {
  const dispatch = useDispatch()
  const [resetColumn, setResetColumn] = useState(false)
  const [optionsValue, setOptionsValue] = useState({})
  const [optionsDropdown, setOptionsDropdown] = useState()
  const currentOrderColumn = localStorage.getItem(`tables__${module}`)
  const [state, setState] = React.useState({
    editColumn: [],
    disableBtn: false,
    newName: ''
  })

  const templateColumn = []
  fields.forEach(fieldsData => {
    templateColumn.push(fieldsData.accessor)
  })

  // dimension
  const [dimension, setDimension] = useState({
    height: window.innerHeight - 230
  })
  const { height } = dimension

  useEffect(() => {
    const newState = { ...state }
    if (newState.editColumn.length === 0) newState.disableBtn = true
    else newState.disableBtn = false
    setState(newState)
  }, [columnHidden])

  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 230
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    const newState = { ...state }
    newState.editColumn = columnHidden || []
    if (
      JSON.stringify(currentOrderColumn) === JSON.stringify(templateColumn) ||
      currentOrderColumn === null
    ) {
      newState.disableBtn = true
    }
    setState(newState)
  }, [columnHidden])

  useEffect(() => {
    const newOptionsDropdown = { ...optionsDropdown }
    if (dataListDropdown) {
      fields?.forEach(item => {
        if (dataListDropdown[item.list]) {
          const filterDuplicate = dataListDropdown[item.list].filter(function (
            value,
            index,
            array
          ) {
            return array.indexOf(value) === index
          })
          filterDuplicate.forEach(data => {
            if (newOptionsDropdown[item.list]) {
              newOptionsDropdown[item.list].push({
                value: data,
                label: data
              })
            } else {
              newOptionsDropdown[item.list] = [
                {
                  value: data,
                  label: data
                }
              ]
            }
          })
        }
      })
      setOptionsDropdown(newOptionsDropdown)
    }
  }, [dataListDropdown])

  return (
    <>
      <Modal
        show={show}
        onHide={() => setModalTable(false)}
        dialogClassName="shipment-filter fade-down"
        centered={false}
        fullscreen={true}
        className="d-flex flex-row-reverse modal right"
      >
        <Modal.Header className="filter-column-header-shipment py-2">
          <div className="filter-column-headerPort-title-shipment">
            {t('Edit Table Column')}
          </div>
          <i
            className="ri-arrow-right-s-line filter-column-headerPort-icon-shipment cursor-pointe"
            onClick={() => setModalTable(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-column justify-content-start h-100">
            <Row
              className="p-3 content-filter-summary"
              style={{ height: height }}
            >
              {fields &&
                fields.map((item, index) => {
                  const columnsFilter =
                    item.list?.slice(0, 1).toUpperCase() + item.list?.substr(1)
                  return (
                    <Col lg={12} key={index} className="edit-column-button">
                      <>
                        <span> {t(item.Column)} </span>
                        <div className="d-flex">
                          <div className="w-100 pe-3">
                            <Dropdown
                              placeholder={`All ${item.Column}`}
                              options={
                                optionsDropdown
                                  ? optionsDropdown[item.list]
                                  : ''
                              }
                              onChangeDropdown={selected =>
                                changeOptionsValue({
                                  column: columnsFilter,
                                  value: selected,
                                  optionsValue,
                                  setOptionsValue
                                })
                              }
                              selectedValue={optionsValue[columnsFilter]}
                              className="create-input"
                            />
                          </div>
                          <div
                            className={`${
                              !state.editColumn?.includes(item.accessor)
                                ? 'switch-filter-active'
                                : 'switch-filter'
                            } px-1`}
                            onClick={() =>
                              hiddenColumn({
                                header: item.accessor,
                                length: fields.length,
                                setState,
                                state
                              })
                            }
                          >
                            <div className="ri-check-line px-1 checkFilter " />
                            <div className="ri-close-line px-1 closeFilter" />
                          </div>
                        </div>
                      </>
                    </Col>
                  )
                })}
            </Row>
            <Row className="p-3 footer-filter-summary">
              <CToast
                showToast={resetColumn}
                setShowToast={setResetColumn}
                className="toast-resetColumn"
                caption="Are you sure to reset column order?"
                moduleComponent="ResetModalEditColumn"
                resetSchema={resetSchema}
                state={state}
                module={module}
              />
              <HButton
                size="sm"
                text={t ? t('RESET COLUMN ORDER') : ''}
                className="m-0 mt-2 w-100 py-2"
                onClick={() => setResetColumn(!resetColumn)}
                textRight
                disabledStatus={state.disableBtn}
              />
              <HButton
                size="sm"
                text={t('APPLY')}
                className="m-0 mt-2 w-100 py-2"
                textRight
                onClick={() => {
                  saveSchema({
                    module,
                    dispatch,
                    state,
                    setEditColumnTemp
                  })
                  setModalTable(false)
                }}
              />
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalEditColumn

export const CheckInputName = ({ statusList, inputName, initialName }) => {
  let check = ''
  switch (true) {
    case !inputName:
      check = 'emptyName'
      break
    case inputName === initialName:
      check = ''
      break
    case inputName.length > 18:
      check = 'tooLong'
      break
    case statusList.findIndex(
      e => e.name.toLowerCase() === inputName.toLowerCase()
    ) > -1:
      check = 'nameExist'
      break
    default:
      check = ''
      break
  }
  return check
}

export const CheckInputColor = ({ statusList, inputColor, initialColor }) => {
  let check = ''
  switch (true) {
    case !inputColor:
      check = 'emptyColor'
      break
    case inputColor === initialColor:
      check = ''
      break
    case statusList.findIndex(e => e.hexcode === inputColor) > -1:
      check = 'colorExist'
      break
    default:
      check = ''
      break
  }
  return check
}

export const EditStatus = async ({
  editName,
  editColor,
  editId,
  initialName,
  initialColor,
  checkEditName,
  checkEditColor,
  dispatch,
  notify,
  editFlagStatus
}) => {
  const trimmedEditName = editName.trim()
  switch (true) {
    case trimmedEditName === initialName && editColor === initialColor:
      notify({ text: 'Status edited', status: 'success' })
      break
    case checkEditName === 'emptyName' && checkEditColor === 'emptyColor':
      notify({ text: 'Status label & color cannot be blank', status: 'error' })
      break
    case checkEditName === 'emptyName':
      notify({ text: 'Status label cannot be blank', status: 'error' })
      break
    case checkEditName === 'tooLong':
      notify({ text: 'Too long! 18 Characters max', status: 'error' })
      break
    case checkEditName === 'nameExist':
      notify({ text: 'Status label already exist', status: 'error' })
      break
    case checkEditColor === 'emptyColor':
      notify({ text: 'Status color cannot be blank', status: 'error' })
      break
    case checkEditColor === 'colorExist':
      notify({ text: 'Status color already exist', status: 'error' })
      break
    default:
      await editFlagStatus({
        dispatch: dispatch,
        name: trimmedEditName,
        code: editColor,
        id: editId,
        notify: notify
      })
      break
  }
}

export const CreateNewStatus = async ({
  newName,
  newColor,
  checkNewName,
  checkNewColor,
  dispatch,
  notify,
  createFlagStatus
}) => {
  let isSuccess = false
  switch (true) {
    case checkNewName === 'emptyName' && checkNewColor === 'emptyColor':
      notify({ text: 'Status label & color cannot be blank', status: 'error' })
      break
    case checkNewName === 'emptyName':
      notify({ text: 'Status label cannot be blank', status: 'error' })
      break
    case checkNewColor === 'emptyColor':
      notify({ text: 'Status color cannot be blank', status: 'error' })
      break
    case checkNewName === 'tooLong':
      notify({ text: 'Too long! 18 Characters max', status: 'error' })
      break
    case checkNewName === 'nameExist' && checkNewColor === 'colorExist':
      notify({ text: 'Label and color already exist', status: 'error' })
      break
    case checkNewName === 'nameExist':
      notify({ text: 'Status label already exist', status: 'error' })
      break
    case checkNewColor === 'colorExist':
      notify({ text: 'Status color already exist', status: 'error' })
      break
    default:
      {
        const successCreate = await createFlagStatus({
          dispatch: dispatch,
          name: newName,
          code: newColor,
          notify: notify
        })
        if (successCreate) {
          isSuccess = true
        }
      }
      break
  }
  return isSuccess
}

export const colorList = [
  '#f38588',
  '#fac2c5',
  '#faa385',
  '#d89a8d',
  '#f2ec7c',
  '#dfe581',
  '#add49b',
  '#89bcab',
  '#83d9dc',
  '#b0efef',
  '#ccaed4',
  '#9999cd'
]

import React from 'react'
import moment from 'moment'

export const setExportValue = ({ dataSummary, setExported }) => {
  let exported = []
  if (dataSummary) {
    const mapData = JSON.stringify([...dataSummary])
    const datas = JSON.parse(mapData)
    if (datas.length > 0) {
      for (let i = 0; i < datas.length; i++) {
        const newData = datas[i]
        newData.homeBranch = newData?.nativeStaffData?.homeBranch?.code ?? null
        newData.city = newData?.nativeStaffData?.city ?? null
        exported = [...exported, newData]
      }
    }
    setExported(exported)
  }
}

export const getTrProps = () => {
  return {
    style: {
      backgroundColor: '#F6F7FC'
    }
  }
}

export const SchemaHeader = t => [
  {
    Header: t('Username'),
    placeholder: 'Name',
    accessor: 'portalLogin',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 200,
    Cell: props => {
      return (
        <div>
          {props.original?.portalLogin == '' ||
          props.original?.portalLogin == null ||
          props.original?.portalLogin == undefined
            ? '-'
            : props.original?.portalLogin.toUpperCase()}
        </div>
      )
    }
  },
  {
    Header: t('Job Title'),
    placeholder: 'Job Title',
    accessor: 'jobTitle',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 150,
    Cell: props => {
      return (
        <div>
          {props.original?.staffPortalData?.jobTitle == '' ||
          props.original?.staffPortalData?.jobTitle == null ||
          props.original?.staffPortalData?.jobTitle == undefined
            ? '-'
            : props.original?.staffPortalData?.jobTitle}
        </div>
      )
    }
  },
  {
    Header: t('Last Login'),
    placeholder: 'Last Login',
    accessor: 'lastLogin',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    Cell: props => {
      return (
        <div>
          {props.original?.lastLogin == '' ||
          props.original?.lastLogin == null ||
          props.original?.lastLogin == undefined
            ? '-'
            : getDateVal(props.original?.lastLogin)}
        </div>
      )
    }
  },
  {
    Header: t('Role'),
    placeholder: 'Role',
    accessor: 'glbGroup',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props?.original?.staffPortalRole?.description == '' ||
          props?.original?.staffPortalRole?.description == null ||
          props?.original?.staffPortalRole?.description == undefined
            ? '-'
            : props?.original?.staffPortalRole?.description ?? '-'}
        </div>
      )
    }
  },
  {
    Header: t('Active'),
    placeholder: 'Active',
    accessor: 'isActive',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 150,
    Cell: () => {
      return (
        <div>
          <i className="ri-check-fill status-active-check" />
        </div>
      )
    }
  }
]

export const dataUser = [
  {
    name: 'Aaron Evans',
    emailAddress: 'aaron@controlgl.com',
    code: 'AE',
    workingStatus: 'Working',
    admin: 'Admin',
    active: true,
    branch: 'SYD',
    city: 'BOTANY'
  }
]

export const getDateVal = dateVal => {
  if (dateVal != null && dateVal != undefined) {
    if (dateVal === '') {
      return '-'
    } else {
      const date = moment(dateVal ?? '-')
        .format('L')
        .substring(3, 5)
      const month = moment(dateVal ?? '-')
        .format('L')
        .substring(0, 2)
      const year = moment(dateVal ?? '-')
        .format('L')
        .substring(6, 10)
      return year + '/' + month + '/' + date
    }
  } else {
    return '-'
  }
}

// import library
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table-v6'
import withDraggableColumns from 'react-table-hoc-draggable-columns'

// import style
import { setDraggableColumn, saveSchemaToLocal, renewColumn } from './service'
import 'react-table-v6/react-table.css'
import 'react-table-hoc-draggable-columns/dist/styles.css'
import ModalEditColumn from 'components/Modal/ModalEditColumn'

// translation
import { useTranslation } from 'react-i18next'
import LoadingAnimation from 'components/LoadingAnimation'

const Table = ({
  schemaColumn,
  onClick,
  data,
  style,
  module,
  className,
  title,
  columnHidden,
  loadingStatus,
  trProps,
  thead,
  defaultSorted,
  onSortedChange,
  defaultSortMethod,
  modalFilter,
  setModalFilter,
  dataListDropdown
}) => {
  // translation
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [editColumnTemp, setEditColumnTemp] = useState({})
  const [fields] = useState(schemaColumn)
  const [newSchema, setNewSchema] = useState(schemaColumn)
  const markedRow = useSelector(state => state.markedRow)
  const ReactTableDraggableColumns = withDraggableColumns(ReactTable)
  const noDataMessage = (
    <div className="icon-alert-triangle text-center">
      <i className="ri-error-warning-line ri-4x" style={{ color: '#B0B3D6' }} />
      <div className="text-purple-600" style={{ color: '#B0B3D6' }}>
        {t ? t('No Data Available') : ''}
      </div>
    </div>
  )
  // List Header: to enable function draggable
  const draggableColumn = setDraggableColumn({ fields })

  useEffect(() => {
    renewColumn({
      setNewSchema,
      data,
      fields,
      module,
      columnHidden,
      dispatch
    })
  }, [data, fields, columnHidden])

  return (
    <div className={`${className}`}>
      <ReactTableDraggableColumns
        draggableColumns={{
          mode: 'reorder',
          draggable: draggableColumn,
          onDropSuccess: (draggedColumn, targetColumn, oldIndex, newIndex) => {
            saveSchemaToLocal({
              setNewSchema,
              schemaColumn: fields,
              module,
              draggedColumn,
              targetColumn,
              oldIndex,
              newIndex,
              dispatch
            })
            renewColumn({
              setNewSchema,
              data,
              fields,
              module,
              editColumnTemp,
              columnHidden,
              dispatch
            })
          }
        }}
        columns={newSchema}
        defaultSorted={defaultSorted}
        data={data}
        showPagination={false}
        defaultPageSize={50}
        style={style}
        noDataText={noDataMessage}
        loading={loadingStatus ?? false}
        loadingText={<LoadingAnimation />}
        minRows="0"
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              !!onClick && onClick(rowInfo.original, state, column, e, instance)
            },
            style: {
              backgroundColor: markedRow.includes(rowInfo?.index)
                ? 'aliceblue'
                : 'none'
            }
          }
        }}
        TheadComponent={thead}
        getTrProps={trProps}
        onSortedChange={onSortedChange}
        show
        sortable
        defaultSortMethod={defaultSortMethod}
      />
      <ModalEditColumn
        dataListDropdown={dataListDropdown}
        show={modalFilter}
        setModalTable={setModalFilter}
        icon="ri-draft-line"
        schemaColumn={schemaColumn}
        setEditColumnTemp={setEditColumnTemp}
        editColumnTemp={editColumnTemp}
        title={title}
        fields={fields}
        columnHidden={columnHidden}
        module={module}
        data={data}
        t={t}
      />
    </div>
  )
}

export default Table

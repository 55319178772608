import React, { useState } from 'react'
import Input from 'components/Input'
import Checkbox from 'components/CheckBox'
import Dropdown from 'components/Dropdown'
import TextAreaInput from 'components/TextAreaInput'
import { changeCreateFromNew } from '../service'
import DatePicker from 'components/DatePicker'
import useFormContext from 'hooks/useFormContext'

const InputTypesHandler = ({ header, module, t }) => {
  const { accessor, placeholder, title, type, optionList } = header
  const { formData, setFormData } = useFormContext()

  // --- useState for Input Type --- //
  const [selectedDropdown, setSelectedDropdown] = useState()
  const [selectedCheckBox, setSelectedCheckBox] = useState(false)
  const [selectedDate, setSelectedDate] = useState(undefined || '')

  const chooseInput = () => {
    switch (type) {
      case 'input':
        return (
          <>
            <Input
              title={t(title)}
              placeholder={t(placeholder)}
              parentDivClassName="textTitle"
              titleClassName="fieldTitle"
              className="create-input"
              onChange={e =>
                changeCreateFromNew({
                  module: module,
                  column: accessor,
                  value: e.target.value,
                  formData,
                  setFormData
                })
              }
            />
          </>
        )
      case 'dropdown':
        return (
          <>
            <label className="mb-0 fieldTitle">{t(title)}</label>
            <Dropdown
              placeholder={t(placeholder)}
              options={optionList}
              onChangeDropdown={selected => {
                changeCreateFromNew({
                  module: module,
                  column: accessor,
                  value: selected,
                  formData,
                  setFormData
                })
                setSelectedDropdown(selected)
              }}
              selectedValue={selectedDropdown}
              className="create-input"
            />
          </>
        )
      case 'textarea':
        return (
          <TextAreaInput
            title={t(title)}
            placeholder={t(placeholder)}
            parentDivClassName="textTitle"
            titleClassName="fieldTitle"
            className="create-input form-control-address"
            onChange={e =>
              changeCreateFromNew({
                module: module,
                column: accessor,
                value: e.target.value,
                formData,
                setFormData
              })
            }
          />
        )
      case 'time':
        return (
          <Input
            type="time"
            className="fieldTime"
            onChange={e =>
              changeCreateFromNew({
                module: module,
                column: accessor,
                value: e.target.value,
                formData,
                setFormData
              })
            }
          />
        )
      case 'date':
        return (
          <DatePicker
            title={title}
            titleClassName="fieldTitle"
            defaultValues={selectedDate}
            onChange={e => {
              console.log(e)
              changeCreateFromNew({
                module: module,
                column: accessor,
                value: e,
                formData,
                setFormData
              })
              setSelectedDate(e)
            }}
          />
        )
      case 'checkbox':
        return (
          <>
            <Checkbox
              name="Save Pickup Address"
              className="pt-2"
              classNameText="textTitle"
              value={selectedCheckBox}
              onClick={() => {
                setSelectedCheckBox(!selectedCheckBox)
                changeCreateFromNew({
                  module: module,
                  column: accessor,
                  value: selectedCheckBox.toString(),
                  formData,
                  setFormData
                })
              }}
            />
          </>
        )
    }
  }

  return <>{chooseInput()}</>
}

export default InputTypesHandler

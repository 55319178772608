import axios from 'axios'

export const initialState = [
  {
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    f: false,
    g: false,
    h: false,
    i: false,
    j: false,
    k: false
  }
]

export const goToStripe = async id => {
  const endpoints = 'api/Payments/create-checkout-session'
  const formData = {
    priceId: id,
    successUrl: 'https://localhost/success',
    failureUrl: 'https://localhost/failed'
  }
  try {
    const response = await axios.post(endpoints, formData)
    window.open(`${response.data.checkoutUrl}`, '_blank')
    return true
  } catch (error) {
    return false
  }
}

import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import ExportBtn from 'components/ExportBtn'
import { SchemaHeader, setExportValue } from './service'
import { getUserDetail, getSummaryData } from 'ApiService'
import HPagination from 'components/Pagination'
import { useMemo } from 'react'
import CToast from 'components/Toast'
import CreateNewUser from 'components/CreateNewUser'
import AccountSetting from 'components/UserAccess/AccountSetting'
import { dataUserAccessDemo } from 'helpers/dataDummyDemo'

// translation
import { useTranslation } from 'react-i18next'

const PageSize = 50

const UserManagement = ({ navigate }) => {
  const user = useSelector(state => state.user)
  const currentUserId = user.all.id
  const userAccess = user.all.role
  const [showCreateNewUser, setShowCreateNewUser] = useState(false)
  const currentPage = 1
  // const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const dataSummary = useSelector(state => state.spUserSummary)
  const summaryTableStatus = useSelector(state => state.spUserSummaryStatus)
  const dataPagination = useSelector(state => state.paginationUser)
  const stateChangeHeader = useSelector(state => state.changeHeader)
  const getColumn = useSelector(state => state.getColumn)
  const [columnHidden, setColumnHidden] = useState(null)
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    if (dataSummary) {
      return dataSummary.slice(firstPageIndex, lastPageIndex)
    }
  }, [currentPage, dataSummary])
  const dataSummaryUserAccess =
    userAccess === 'User Demo' ? dataUserAccessDemo : currentTableData
  const module = 'User Access'
  const [Export, setExport] = useState(false)
  const [state2, setState2] = useState(null)
  const [dataToExport, setDataToExport] = useState()
  const [showToastExport, setShowToastExport] = useState(false)
  const [showToastNoColumn, setShowToastNoColumn] = useState(false)
  const [confirmExport, setConfirmExport] = useState(false)
  const [exportAll, setExportAll] = useState(false)

  const [showAccountSetting, setShowAccountSetting] = useState(false)
  const userDetail = useSelector(state => state.spUserDetails)
  const userDetailLoading = useSelector(state => state.spUserDetailsStatus)

  const showDetails = async item => {
    const getData = await getUserDetail({ dispatch: dispatch, id: item.id })
    if (getData) {
      setShowAccountSetting(true)
    }
  }

  // translation
  const { t } = useTranslation()

  if (!columnHidden) {
    setColumnHidden(
      localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
    )
    setState2(true)
  }

  const height = 'calc(100vh - 150px)'
  const width = 'window.innerWidth'

  useEffect(() => {
    getSummaryData({
      dispatch,
      PageNumber: 1,
      module: module
    })
  }, [])

  useEffect(() => {
    setExportValue({
      dataSummary,
      setExported: setDataToExport
    })
  }, [dataSummary])

  useEffect(() => {
    if (stateChangeHeader) {
      dispatch({ type: 'CHANGE_HEADER', data: false })
    }
  }, [stateChangeHeader])

  useEffect(() => {
    if (state2) {
      const columnHidden = localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
      let tmp = null
      if (columnHidden) {
        columnHidden.map(data => {
          if (data.title === 'User Access') {
            tmp = data.columns
          }
        })
      }
      if (tmp) {
        setColumnHidden(tmp)
      } else {
        setColumnHidden([])
      }
      setState2(false)
      dispatch({ type: 'CHANGE_HEADER', data: false })
    }
  }, [state2])

  useEffect(() => {
    if (Export === true) {
      setExport(false)
    }
  }, [Export])

  useEffect(() => {
    // toast no column
    if (getColumn) {
      setShowToastNoColumn(true)
    } else {
      setShowToastNoColumn(false)
    }
  }, [getColumn])

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      moduleName: t(module),
      actionsButton: true,
      actionsMenuList: [
        {
          title: t('Export Excel'),
          function: () => {
            setConfirmExport(true)
          }
        },
        { title: t('Download Edocs'), function: () => {} }
      ]
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  return (
    <>
      <Row>
        <Col lg={12} className="custom-table-padding">
          <div className="ps-0 ps-xl-1">
            <div className="page-table">
              <CToast
                showToast={showToastNoColumn}
                setShowToast={setShowToastNoColumn}
                className="toast-EditColumn"
                caption="Table column is empty, please adjust or reset it to show data"
              />
              <Table
                onClick={showDetails}
                schemaColumn={SchemaHeader(t)}
                data={dataSummaryUserAccess ?? []}
                style={{
                  minHeight: height,
                  maxHeight: height,
                  maxWidth: width
                }}
                module={module}
                noDataText
                title="User Access"
                columnHidden={columnHidden}
                editColumn
                className="clearShadow"
                loadingStatus={summaryTableStatus}
                t={t}
              />
              <div className="p-2 card-paging">
                <CToast
                  showToast={showToastExport}
                  setShowToast={setShowToastExport}
                  className="toast-ExportDataSummary"
                  caption="Export existing visible data only?"
                  moduleComponent={module}
                  setExportAll={setExportAll}
                  confirmExport={confirmExport}
                  setConfirmExport={setConfirmExport}
                />
                <Row className="p-1 align-items-center d-flex justify-content-between">
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={5}
                    className="d-flex justify-content-start"
                  >
                    <HPagination
                      data={dataPagination}
                      module={module}
                      navigate={navigate}
                    />
                    <p className="mt-3 text-descPaging">
                      {dataPagination?.totalItems} {t('Bookings')}{' '}
                      <span className="text-softGray">
                        {t('in_last_3_months')}
                      </span>
                    </p>
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={5}
                    className="d-flex justify-content-center text-copy-right cursor-default"
                  />
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={2}
                    className="d-flex justify-content-end"
                  >
                    <ExportBtn
                      filename="User."
                      getExportData={async () => {
                        setExport(true)
                      }}
                      exportData={dataToExport}
                      schemaColumn={SchemaHeader(t)}
                      modules={module}
                      t={t}
                      showToast={showToastExport}
                      setShowToast={setShowToastExport}
                      exportAll={exportAll}
                      confirmExport={confirmExport}
                      setConfirmExport={setConfirmExport}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <AccountSetting
        showAccountSetting={showAccountSetting}
        setShowAccountSetting={setShowAccountSetting}
        currentUserId={currentUserId}
        userDetail={userDetail}
        userDetailLoading={userDetailLoading}
      />
      <CreateNewUser
        showCreateNewUser={showCreateNewUser}
        setShowCreateNewUser={setShowCreateNewUser}
      />
    </>
  )
}

export default UserManagement

import HeaderCard from 'components/HeaderCard'
import React from 'react'
import DashboardInfoBox from './DashboardInfoBox'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const DashBoardShipments = ({ headerText, data, boxColor }) => {
  const { t } = useTranslation()
  const maxValue = Math.max(...data.map(o => o.total))
  return (
    <div className="bg-white p-3 border dashboard-col h-100 ms-lg-2 mt-2 mt-md-0 h-90">
      <HeaderCard title={headerText} className="h-10" />
      <Col>
        <div className="dashboard-shipments-subtitle mt-2">
          {t('Organisation')}
        </div>
        <Col className="custom-height fill-height-or-more">
          {data.map((item, index) => (
            <DashboardInfoBox
              key={index}
              borderWidth="1px"
              borderColor="#EEEEF9"
              color1={boxColor}
              colorWidth1={`${(item.total / maxValue) * 100}%`}
              height="100%"
            >
              <div className="d-flex align-items-center justify-content-between dashboard-shipments-completed-value">
                <span>{t(item.company)}</span>
                <span className="ps-2">{item.total.toLocaleString()}</span>
              </div>
            </DashboardInfoBox>
          ))}
        </Col>
      </Col>
    </div>
  )
}

export default DashBoardShipments

export const appRoutes = {
  label: 'app',
  labelDisable: true,
  to: '/',
  children: [
    {
      name: 'Dashboard',
      icon: 'ri-dashboard-line',
      to: '/dashboard',
      visible: true
    },
    {
      name: 'Forwarding',
      icon: 'ri-book-mark-line',
      visible: true,
      children: [
        {
          name: 'Shipments',
          to: '/shipments',
          visible: true
        },
        {
          name: 'Shipments Detail',
          to: '/shipments-detail',
          visible: false
        },
        {
          name: 'Bookings',
          to: '/bookings',
          visible: true
        },
        {
          name: 'Bookings Detail',
          to: '/bookings-detail',
          visible: false
        }
      ]
    },
    {
      name: 'User Access',
      icon: 'ri-group-line',
      to: '/users-access',
      visible: true
    },
    {
      name: 'Organisation',
      icon: 'ri-building-line',
      to: '/organisation',
      visible: false
    },

    {
      name: 'Demo Payment',
      icon: 'ri-wallet-2-line',
      to: '/payment-demo',
      visible: true
    }
  ]
}

export default [appRoutes]

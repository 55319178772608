import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const ProgressBarCard = ({ booking, active, completed }) => {
  return (
    <ProgressBar className="progress-bar-container">
      <ProgressBar
        className="progress-bar-item progress-bar-item__gray"
        now={booking}
        key={1}
        style={{ height: 12 }}
      />
      <ProgressBar
        className="mx-2 progress-bar-item progress-bar-item__yellow"
        now={active}
        key={2}
        style={{ height: 12 }}
      />
      <ProgressBar
        className="progress-bar-item progress-bar-item__teal"
        now={completed}
        key={3}
        style={{ height: 12 }}
      />
    </ProgressBar>
  )
}

export default ProgressBarCard

import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Table from 'components/Table'
import OrgDetail from 'components/OrgDetail'
import CreateNewOrg from 'components/CreateNewOrg'
import Pagination2 from 'components/PaginationNonApi'
import ExportBtn from 'components/ExportBtn'
import dummyOrgList from 'data/organisation/organisationList'
import { SchemaHeader } from 'page/Organisation/service'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const PageSize = 50

const Organisation = () => {
  const orgData = dummyOrgList
  const dispatch = useDispatch()
  const [showCreateNewOrg, setShowCreateNewOrg] = useState(false)
  const [showOrgDetail, setShowOrgDetail] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [orgDetailLoading, setOrgDetailLoading] = useState()
  const [orgDetail, setOrgDetail] = useState()
  const [sortedCol, setSortedCol] = useState()
  const [modalFilter, setModalFilter] = useState(false)
  const [columnHidden, setColumnHidden] = useState(null)
  const [state2, setState2] = useState(null)
  const [confirmExport, setConfirmExport] = useState(false)
  const [setShowToastExport] = useState(false)

  const { t } = useTranslation()
  const module = 'Organisation'

  // dimension
  const [dimension, setDimension] = useState({
    height: window.innerHeight - 218,
    width: window.innerWidth
  })
  const { width, height } = dimension

  const handleShowOrg = e => {
    setOrgDetail(() => {
      return orgData.find(data => data.id === e.id)
    })
    setShowOrgDetail(true)
  }

  if (!columnHidden) {
    setColumnHidden(
      localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
    )
    setState2(true)
  }

  useEffect(() => {
    if (state2) {
      const columnHidden = localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
      let tmp = null
      columnHidden?.map(data => {
        if (data.title === 'Bookings') {
          tmp = data.columns
        }
      })
      if (tmp) {
        setColumnHidden(tmp)
      } else {
        setColumnHidden([])
      }
      setState2(false)
      dispatch({ type: 'CHANGE_HEADER', data: false })
    }
  }, [state2])

  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 218,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    setOrgDetailLoading(false)
  }, [])

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      moduleName: t(module),
      filterButton: true,
      filterHandling: () => {
        setModalFilter(true)
      },
      layoutButton: true,
      layoutHandling: () => {},
      actionsButton: true,
      actionsMenuList: [
        {
          title: t('Export Excel'),
          function: () => {
            setConfirmExport(true)
          }
        },
        { title: t('Download Edocs'), function: () => {} }
      ]
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  return (
    <>
      <Row>
        <Col className="custom-table-padding">
          <div className="ps-0 ps-xl-1">
            <div className="page-table">
              <Table
                id={'tableOrganisation'}
                onClick={handleShowOrg}
                schemaColumn={SchemaHeader(t)}
                defaultSorted={sortedCol}
                onSortedChange={e => {
                  setSortedCol(e)
                }}
                data={orgData}
                style={{
                  minHeight: height,
                  maxHeight: height,
                  maxWidth: width
                }}
                module={module}
                modalFilter={modalFilter}
                setModalFilter={setModalFilter}
                columnHidden={columnHidden}
                className="clearShadow"
                tableName="tableOrganisation"
                title="Organisation Summary"
                noDataText
                t={t}
              />
              <div className="p-2 card-paging">
                <Row className="p-1 align-items-center d-flex justify-content-between">
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={5}
                    className="d-flex justify-content-start"
                  >
                    <Pagination2
                      currentPage={currentPage}
                      totalCount={orgData?.length ?? 0}
                      pageSize={PageSize}
                      onPageChange={page => setCurrentPage(page)}
                    />
                    <p className="mt-3 text-descPaging">
                      {PageSize?.totalItems} {t('Organisation')}{' '}
                      <span className="text-softGray">
                        {t('in_last_3_months')}
                      </span>
                    </p>
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={5}
                    className="d-flex justify-content-center text-copy-right cursor-default"
                  />
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={2}
                    className="d-flex justify-content-end"
                  >
                    <ExportBtn
                      filename="User."
                      t={t}
                      confirmExport={confirmExport}
                      setShowToast={setShowToastExport}
                      setConfirmExport={setConfirmExport}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CreateNewOrg
        showCreateNewOrg={showCreateNewOrg}
        setShowCreateNewOrg={setShowCreateNewOrg}
      />
      <OrgDetail
        showOrgDetail={showOrgDetail}
        setShowOrgDetail={setShowOrgDetail}
        orgDetail={orgDetail}
        orgDetailLoading={orgDetailLoading}
      />
    </>
  )
}
export default Organisation

import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { defaultSortMethod, SchemaHeader, SchemaHeaderExport } from './service'
import { dataBookingSummary } from 'helpers/dataDummyDemo'
import { getStatusData, getSummaryData } from 'ApiService'
import Table from 'components/Table'
import ExportBtn from 'components/ExportBtn'
import HPagination from 'components/Pagination'
import CToast from 'components/Toast'

// translation
import { useTranslation } from 'react-i18next'

const Bookings = ({ navigate }) => {
  const dispatch = useDispatch()
  const [modalFilter, setModalFilter] = useState(false)
  const [sortedCol, setSortedCol] = useState()
  const [sortedColPrev, setSortedColPrev] = useState()
  const [showToastExport, setShowToastExport] = useState(false)
  const [showToastNoColumn, setShowToastNoColumn] = useState(false)
  const [exportAll, setExportAll] = useState()
  const [confirmExport, setConfirmExport] = useState(false)
  const user = useSelector(state => state.user)
  const userAccess = user?.role
  const module = 'Bookings'
  const dataSummary = useSelector(state => state.boSummaryData)
  const summaryTableStatus = useSelector(state => state.spSummaryDataStatus)
  const dataPagination = useSelector(state => state.paginationBo)
  const stateChangeHeader = useSelector(state => state.changeHeader)
  const getColumn = useSelector(state => state.getColumn)
  const filteredData = useSelector(state => state.spSummaryDataFiltered)
  const displayFilterData = useSelector(state => state.spSummaryFilterDisplay)
  const keyword = useSelector(state => state.spSummaryKeyword)
  const showDetails = item => {
    navigate(`/bookings-detail/${item.jobNo}`)
  }

  const height = 'calc(100vh - 150px)'
  const width = 'window.innerWidth'

  // translation
  const { t } = useTranslation()
  const [columnHidden, setColumnHidden] = useState(null)
  const [state2, setState2] = useState(null)

  const tableData = displayFilterData
    ? filteredData
    : userAccess === 'User Demo'
    ? dataBookingSummary
    : dataSummary

  if (!columnHidden) {
    setColumnHidden(
      localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
    )
    setState2(true)
  }

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      moduleName: t(module),
      filterButton: true,
      filterHandling: () => {
        setModalFilter(true)
      },
      layoutButton: true,
      layoutHandling: () => {},
      actionsButton: true,
      actionsMenuList: [
        {
          title: t('Create New'),
          function: () => {
            navigate('/bookings/create')
          }
        },
        {
          title: t('Export Excel'),
          function: () => {
            setConfirmExport(true)
          }
        },
        { title: t('Download Edocs'), function: () => {} }
      ]
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  useEffect(() => {
    if (stateChangeHeader) {
      const columnHidden = localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
      columnHidden?.map(data => {
        if (data.title === 'Bookings') {
          setColumnHidden(data.columns)
        }
      })
      dispatch({ type: 'CHANGE_HEADER', data: false })
    }
  }, [stateChangeHeader])

  useEffect(() => {
    if (state2) {
      const columnHidden = localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
      let tmp = null
      columnHidden?.map(data => {
        if (data.title === 'Bookings') {
          tmp = data.columns
        }
      })
      if (tmp) {
        setColumnHidden(tmp)
      } else {
        setColumnHidden([])
      }
      setState2(false)
      dispatch({ type: 'CHANGE_HEADER', data: false })
    }
  }, [state2])

  useEffect(() => {
    if (displayFilterData == false) {
      if (sortedCol != null) {
        if (sortedCol[0]?.id == 'etd' || sortedCol[0]?.id == 'eta') {
          dispatch({ type: 'SP_SUMMARY_SORTING_BY', data: sortedCol[0]?.id })
          dispatch({
            type: 'SP_SUMMARY_SORTING_TYPE',
            data: sortedCol[0]?.desc == true ? 'desc' : 'asc'
          })
          getSummaryData({
            dispatch,
            PageNumber: dataPagination.active ?? '1',
            module,
            searchVal: keyword,
            sortBy: sortedCol[0]?.id,
            desc: sortedCol[0]?.desc == true ? 'desc' : 'asc'
          })
        } else {
          if (
            sortedColPrev != null &&
            (sortedColPrev[0]?.id == 'eta' || sortedColPrev[0]?.id == 'etd')
          ) {
            dispatch({ type: 'SP_SUMMARY_SORTING_BY', data: null })
            dispatch({
              type: 'SP_SUMMARY_SORTING_TYPE',
              data: null
            })
            getSummaryData({
              dispatch,
              PageNumber: dataPagination.active ?? '1',
              module: 'Bookings',
              searchVal: keyword
            })
          }
        }
      }
    }
  }, [sortedCol])

  useEffect(() => {
    dispatch({ type: 'GET_SP_SUMMARY_KEYWORD', data: null })
    dispatch({ type: 'DISPLAY_SP_SUMMARY_FILTERED', data: false })
    getStatusData({ dispatch, module })
    if (!tableData) {
      getSummaryData({ dispatch, module, navigate })
    }
  }, [])

  useEffect(() => {
    if (getColumn) {
      setShowToastNoColumn(true)
    } else {
      setShowToastNoColumn(false)
    }
  }, [getColumn])

  return (
    <>
      <Row>
        <Col lg={12} className="custom-table-padding">
          <div className="ps-0 ps-xl-1">
            <div className="page-table">
              <CToast
                showToast={showToastNoColumn}
                setShowToast={setShowToastNoColumn}
                className="toast-EditColumn"
                caption="Table column is empty, please adjust or reset it to show data"
              />
              <Table
                id={'tableBookings'}
                onClick={showDetails}
                schemaColumn={SchemaHeader(t)}
                defaultSorted={sortedCol}
                onSortedChange={e => {
                  setSortedColPrev(sortedCol)
                  setSortedCol(e)
                }}
                defaultSortMethod={defaultSortMethod}
                data={tableData}
                style={{
                  minHeight: height,
                  maxHeight: height,
                  maxWidth: width
                }}
                module={module}
                loadingStatus={summaryTableStatus}
                noDataText
                modalFilter={modalFilter}
                setModalFilter={setModalFilter}
                title="Bookings Summary"
                columnHidden={columnHidden}
                editColumn
                className="clearShadow"
                tableName="tableBookings"
                t={t}
              />
              <div className="card-paging p-2">
                <CToast
                  showToast={showToastExport}
                  setShowToast={setShowToastExport}
                  className="toast-ExportDataSummary"
                  caption="Export existing visible data only?"
                  moduleComponent={module}
                  setExportAll={setExportAll}
                  setConfirmExport={setConfirmExport}
                />
                <Row className="p-1 align-items-center d-flex justify-content-between">
                  <Col
                    xl={5}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="d-flex justify-content-start text-nowrap"
                  >
                    <HPagination
                      data={dataPagination}
                      module={module}
                      navigate={navigate}
                    />
                    <p className="mt-3 text-descPaging">
                      {dataPagination?.totalItems} {t('Bookings')}{' '}
                      <span className="text-softGray">
                        {t('in_last_3_months')}
                      </span>
                    </p>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    className="d-flex justify-content-center text-copy-right cursor-default"
                  />
                  <Col
                    xl={4}
                    lg={3}
                    md={3}
                    sm={2}
                    xs={1}
                    className="d-flex justify-content-end"
                  >
                    <ExportBtn
                      filename="Booking."
                      exportData={tableData}
                      schemaColumn={SchemaHeaderExport}
                      modules={module}
                      t={t}
                      showToast={showToastExport}
                      setShowToast={setShowToastExport}
                      exportAll={exportAll}
                      confirmExport={confirmExport}
                      setConfirmExport={setConfirmExport}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Bookings

import moment from 'moment'
import React from 'react'
import FlagShipment from 'components/FlagShipments'

export const defaultSortMethod = (a, b, desc) => {
  // method for future (be return data after sort is selected)
  if (
    a?.split('.').pop() == 'etd' ||
    b?.split('.').pop() == 'etd' ||
    a?.split('.').pop() == 'eta' ||
    b?.split('.').pop() == 'eta'
  ) {
    if (desc == false) {
      return 1
    } else {
      return -1
    }
  }

  let type = 'string'
  a = a !== '-' ? a : ''
  b = b !== '-' ? b : ''
  a = a ? a.replaceAll(',', '') : ''
  b = b ? b.replaceAll(',', '') : ''
  // check format if date
  if (moment(a).isValid()) {
    a = moment(a)
    type = 'date'
  }
  if (moment(b).isValid()) {
    b = moment(b)
    type = 'date'
  }
  // end date

  // check format if number
  const regex = /^\d*(\.\d+)?$/
  let typeA = 'string'
  let typeB = 'string'
  if (type == 'string' && a.match(regex)) {
    const tmp = parseFloat(a)
    if (!isNaN(tmp)) {
      a = tmp
      typeA = 'number'
    }
  } else if (type == 'string') {
    a = a.toLowerCase()
  }
  if (type == 'string' && b.match(regex)) {
    const tmp = parseFloat(b)
    if (!isNaN(tmp)) {
      b = tmp
      typeB = 'number'
    }
  } else if (type == 'string') {
    b = b.toLowerCase()
  }
  type = typeA == 'number' && typeB == 'number' ? 'number' : 'string'
  // end check number

  // force null and undefined to the bottom
  a = a === '' ? (type === 'string' ? '' : -999999999999) : a
  b = b === '' ? (type === 'string' ? '' : -999999999999) : b
  if (a === '') {
    if (b === '') {
      return 0 // Both empty/null
    }
    if (desc) {
      return -1
    }
    return 1 // Sort a to an index lower than b
  }

  if (b === '') {
    if (a === '') {
      return 0 // Both empty/null
    }
    if (desc) {
      return 1
    }
    return -1 // Sort b to an index lower than a
  }

  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or
  // the index as a tiebreaker
  return 0
}

export const getDateVal = dateVal => {
  if (dateVal != null && dateVal != undefined) {
    if (dateVal === '') {
      return '-'
    } else {
      const date = moment(dateVal ?? '-')
        .format('L')
        .substring(3, 5)
      const month = moment(dateVal ?? '-')
        .format('L')
        .substring(0, 2)
      const year = moment(dateVal ?? '-')
        .format('L')
        .substring(6, 10)
      return year + '/' + month + '/' + date
    }
  } else {
    return '-'
  }
}

export const SchemaHeader = t => [
  {
    Column: 'Status',
    Header: () => t('Status'),
    placeholder: () => t('Status'),
    accessor: 'flag',
    list: 'status',
    style: {
      textAlign: 'left',
      padding: '0 0 0 15px'
    },
    sortable: false,
    width: 150,
    Cell: props => {
      return (
        <div className="align-items-center">
          <FlagShipment
            statusId={props?.original?.statusId ?? null}
            jobId={props?.original?.jobNo ?? null}
            module="shipment"
            cellIndex={props.index}
          />
        </div>
      )
    }
  },
  {
    Column: 'Shipment No.',
    Header: () => t('Shipment No.'),
    placeholder: () => t('Shipment No.'),
    accessor: 'jobNo',
    list: 'shipmentNo',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 130,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Shipper',
    Header: () => t('Shipper'),
    placeholder: () => t('Shipper'),
    accessor: 'shipperName',
    list: 'shipper',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Bill',
    Header: () => t('Bill'),
    placeholder: () => t('Bill'),
    accessor: 'houseBill',
    list: 'bill',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Consignee',
    Header: () => t('Consignee'),
    placeholder: () => t('Consignee'),
    accessor: 'consignee',
    list: 'consignee',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Last Milestone',
    Header: () => t('Last Milestone'),
    placeholder: () => t('Last Milestone'),
    accessor: 'lastMilestoneDesc',
    list: 'lastMilestone',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Declaration Country',
    Header: () => t('Declaration Country'),
    placeholder: () => t('Declaration Country'),
    accessor: 'declarationCountry',
    list: 'declarationCountry',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 180,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Origin',
    Header: () => t('Origin'),
    placeholder: () => t('Origin'),
    accessor: 'origin',
    list: 'origin',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'ETD',
    Header: () => t('ETD'),
    placeholder: () => t('ETD'),
    accessor: 'etd',
    list: 'etd',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value?.replace('.etd', '') == '' ||
          props.value?.replace('.etd', '') == 'null' ||
          props.value?.replace('.etd', '') == null ||
          props.value?.replace('.etd', '') == undefined
            ? '-'
            : getDateVal(
                moment(props.value?.replace('.etd', '')).format('DD-MMM-YYYY')
              )}
        </div>
      )
    }
  },
  {
    Column: 'Destination',
    Header: () => t('Destination'),
    placeholder: () => t('Destination'),
    accessor: 'destination',
    list: 'destination',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 120,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'ETA',
    Header: () => t('ETA'),
    placeholder: () => t('ETA'),
    accessor: 'eta',
    list: 'eta',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value?.replace('.eta', '') == '' ||
          props.value?.replace('.eta', '') == null ||
          props.value?.replace('.eta', '') == 'null' ||
          props.value?.replace('.eta', '') == undefined
            ? '-'
            : getDateVal(
                moment(props.value?.replace('.eta', '')).format('DD-MMM-YYYY')
              )}
        </div>
      )
    }
  }
]

export const SchemaHeaderExport = [
  {
    Header: 'Shipment No.',
    placeholder: 'Shipment No.',
    accessor: 'jobNo',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'Shipper ',
    placeholder: 'Shipper ',
    accessor: 'shipperName',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 135,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'Bill.',
    placeholder: 'Bill',
    accessor: 'houseBill',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'Origin',
    placeholder: 'Origin',
    accessor: 'origin',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'ETD',
    placeholder: 'ETD',
    accessor: 'etd',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : getDateVal(moment(props.value).format('DD-MMM-YYYY'))}
        </div>
      )
    }
  },
  {
    Header: 'Destination',
    placeholder: 'Destination',
    accessor: 'destination',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 110,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'ETA',
    placeholder: 'ETA',
    accessor: 'eta',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : getDateVal(moment(props.value).format('DD-MMM-YYYY'))}
        </div>
      )
    }
  },
  {
    Header: 'Q.P',
    placeholder: 'Q.P',
    accessor: 'qp',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 100
  },
  {
    Header: 'Product',
    placeholder: 'Product',
    accessor: 'product',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 180,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'Consignee',
    placeholder: 'Consignee',
    accessor: 'consignee',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 150,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'Last Milestone',
    placeholder: 'Last Milestone',
    accessor: 'lastMilestoneDesc',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 150,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Header: 'Declaration Country',
    placeholder: 'Declaration Country',
    accessor: 'DeclarationCountry',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 150,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  }
]

export const dataShipmentDummy = [
  {
    vessel: 'Nordpacific V. 0446',
    shipmentNo: 'S00008951',
    shipperName: 'VIDRALA S.A.',
    shipper: {
      shipperName: 'VIDRALA S.A.',
      shipperPickupFrom:
        'VIDRALA S.A. BARRIO MUNEGAZO, 22 01400 LLODIO ALAVA SPAIN'
    },
    billNo: 'NAM9253900',
    containerNo: 'TCLU1251230 TTNU8657920',
    containerType: '2x40Rf/-18',
    shipperRef: 'Frutex-33779',
    jobNo: 'F10692',
    origin: 'Valencia',
    etd: '26-Dec-20',
    destination: 'Melbourne',
    eta: '6-Jan-2021',
    product: 'IQF Large Blueberries 13.61kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '4-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO 100788',
    orderRef: 'TBC',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '30-Dec-20 22:07 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'SABRANDS AUSTRALIA MANAGEMENT',
      consigneeDeliverTo:
        'STATE TRANSPORT 170-172 ATLANTIC DRIVE KEYSBOROUGH VIC 3173',
      consigneeEstimatedDelivery: '20-Jan-21 0:00'
    },
    goodDescription: 'IQF Large Blueberries 13.61kg',
    milestones: [
      {
        info: {
          name: 'S00008951',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '6-Jan-21',
          time: '16:37'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008160',
        transportBill: 'EFPA211028',
        transportVoyage: '035E',
        transportLoad: 'Valencia',
        transportDischarge: 'Melbourne',
        transportDeparture: '26-Dec-20',
        transportArrival: '18-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MEDSHI_AU'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '13.61',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'IQF Large Blueberries'
      }
    ],
    orders: [
      {
        orderSupplier: 'Sunmark Foods',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '13.61kg'
      }
    ],
    container: [
      {
        containerName: 'S00008951',
        containerSeal: '159632',
        containerContainerType: '2x40Rf/-18',
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00008951/1',
        customEntry: 'AEMHPH4RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Kota Laris V.048S',
    shipmentNo: 'S00009186',
    shipperName: 'VIDRALA S.A.',
    shipper: {
      shipperName: 'VIDRALA S.A.',

      shipperPickupFrom:
        'VIDRALA S.A. BARRIO MUNEGAZO, 22 01400 LLODIO ALAVA SPAIN'
    },
    billNo: 'OOLU2651389122',
    containerNo: 'OOLU0653300',
    containerType: "1x20'",
    shipperRef: 'Frutex-33821',
    jobNo: 'S4150',
    origin: 'Valencia',
    etd: '26-Dec-20',
    destination: 'Melbourne',
    eta: '6-Jan-2021',
    product: 'Grd Ginger SS Nigerian #35 25kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '2-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO 100809',
    orderRef: 'PO, 100789',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes:
      '30-Dec-20 12:05 - Detailed Goods Description\nGrd Ginger SS Nigerian #35 25kg',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'SABRANDS AUSTRALIA MANAGEMENT',
      consigneeDeliverTo:
        'STATE TRANSPORT 170-172 ATLANTIC DRIVE KEYSBOROUGH VIC 3173',
      consigneeEstimatedDelivery: '20-Jan-21 0:00'
    },
    goodDescription: 'Grd Ginger SS Nigerian #35 25kg',
    milestones: [
      {
        info: {
          name: 'S00009186',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '6-Jan-21',
          time: '15:58'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008161',
        transportBill: '00/12452',
        transportVoyage: 'XA144A',
        transportLoad: 'Valencia',
        transportDischarge: 'Melbourne',
        transportDeparture: '26-Dec-20',
        transportArrival: '18-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MEDSHI_AU'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '25',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Grd Ginger SS Nigerian #35'
      }
    ],
    orders: [
      {
        orderSupplier: 'Olam Vietnam',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '25kg'
      }
    ],
    container: [
      {
        containerName: 'S00009186',
        containerSeal: '159631',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription:
          'RE NEW BOOKING -LCL Ex door DE - 88316 Isny im Allgäu Germany - Sydney - LCL -S C Drive DeVilbiss Healthcare SE1521110215',
        referenceType: 'Quotation'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009186/1',
        customEntry: 'AEMEXEF3G',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Kota Laris V.048S',
    shipmentNo: 'S00009214',
    shipperName: 'DRIVE MEDICAL GMBH & CO. G',
    shipper: {
      shipperName: 'DRIVE MEDICAL GMBH & CO. G',
      shipperPickupFrom:
        'DRIVE MEDICAL GMBH & CO. G LEUTKIRCHER STRASSE 44 88316 ISNY IM ALLGAEU GERMANY'
    },
    billNo: 'COSU6283874570',
    containerNo: 'MAGU2133075',
    containerType: '1x20"',
    shipperRef: 'Frutex-33950',
    jobNo: 'F10740',
    origin: 'Hamburg',
    etd: '26-Dec-20',
    destination: 'Sydney',
    eta: '6-Jan-2021',
    product: 'Pineapple Crushed Aseptic in Juice 23kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '5-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: '53664ILS',
    orderRef: '100808',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '31-Dec-20 21:03 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'DRIVE DEVILBISS AUSTRALIA PTY LTD',
      consigneeDeliverTo:
        'ILS EASTERN CREEK CONTACT: ANTHONY POWELL EX DRIVE DEVILBISS 51 EASTERN CREEK DRIVE EASTERN CREEK NSW 2766',
      consigneeEstimatedDelivery: '20-Jan-21 0:00',
      goodDescription: 'Pineapple Crushed Aseptic in Juice 23kg'
    },
    milestones: [
      {
        info: {
          name: 'S00009214',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '6-Jan-21',
          time: '13:58'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008162',
        transportBill: 'SE1521110215',
        transportVoyage: 'MA',
        transportLoad: 'Hamburg',
        transportDischarge: 'Melbourne',
        transportDeparture: '26-Dec-20',
        transportArrival: '18-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MEDSHI_AU'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '23',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Pineapple Crushed Aseptic in Juice'
      }
    ],
    orders: [
      {
        orderSupplier: 'Sue Yin Asia',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '23kg'
      }
    ],
    container: [
      {
        containerName: 'S00009214',
        containerSeal: '159630',
        containerContainerType: '1x20"',
        containerContainerMode: 'LCL',
        containerTareWeight: '3730.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009214/1',
        customEntry: 'AEMT7R9WL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'APL Savannah V.MA046A',
    shipmentNo: 'S00009007',
    shipperName: 'EQO FLUIDS SL',
    shipper: {
      shipperName: 'EQO FLUIDS SL',
      shipperPickupFrom:
        'VIDRALA S.A. BARRIO MUNEGAZO, 22 01400 LLODIO ALAVA SPAIN'
    },
    billNo: '0.10702',
    containerNo: 'MEDU5762803 FCIU5929020 FCIU5866997 CAIU6907285 MSDU1208832',
    containerType: "5x20'",
    shipperRef: 'Frutex-33691',
    jobNo: 'F10638',
    origin: 'Valencia',
    etd: '26-Dec-20',
    destination: 'Sydney',
    eta: '6-Jan-2021',
    product: 'Sultanas Turkish No 9 RTU 12.5kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '6-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO211414',
    orderRef: '53664ILS',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '31-Dec-20 19:14 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'DRIVE DEVILBISS AUSTRALIA PTY LTD',
      consigneeDeliverTo:
        'ILS EASTERN CREEK CONTACT: ANTHONY POWELL EX DRIVE DEVILBISS 51 EASTERN CREEK DRIVE EASTERN CREEK NSW 2766',
      consigneeEstimatedDelivery: '20-Jan-21 0:00'
    },
    goodDescription: 'Sultanas Turkish No 9 RTU 12.5kg',
    milestones: [
      {
        info: {
          name: 'S00009007',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '6-Jan-21',
          time: '12:24'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008163',
        transportBill: '1X40OT-HC',
        transportVoyage: 'TBA00',
        transportLoad: 'Valencia',
        transportDischarge: 'Sydney',
        transportDeparture: '26-Dec-20',
        transportArrival: '18-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MEDSHI_AU'
      }
    ],
    goods: [
      {
        goodsPieces: '14',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '12.5',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Sultanas Turkish No 9 RTU'
      }
    ],
    orders: [
      {
        orderSupplier: 'Erturk Uzum Ve Tarim',
        orderStatus: 'Pending',
        orderPacks: '14 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '12.5kg'
      }
    ],
    container: [
      {
        containerName: 'S00009007',
        containerSeal: '159629',
        containerContainerType: "5x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009007/1',
        customEntry: 'AEMFPH6RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'APL Savannah V.MA046A',
    shipmentNo: 'S00009189',
    shipperName: 'MILAT HALI SANAYI VE TICARET A.S.',
    shipper: {
      shipperName: 'MILAT HALI SANAYI VE TICARET A.S.',
      shipperPickupFrom:
        'MILAT HALI SANAYI VE TICARET A.S. 5. ORGANIZE SANAYI BOLGESI NOLU CADDE 27 83539 TURKEY'
    },
    billNo: '0.10748',
    containerNo:
      'TGBU3112355 MSDU1132721 FBIU0415068 GESU1165273 TCKU1165942 FCIU5946578 TGBU3194348 FTAU1410967 TEMU1885649 MEDU5484045',
    containerType: "10x20'",
    shipperRef: 'Frutex-33694',
    jobNo: 'F10659',
    origin: 'Mersin',
    etd: '25-Dec-20',
    destination: 'Sydney',
    eta: '5-Jan-2021',
    product: 'Sultanas Turkish No 9 RTU 12.5kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '5-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: '53666ILS',
    orderRef: 'PO211414',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Time Draft',
    chargesApply: 'Show Collect Charges',
    notes: '31-Dec-20 13:07 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'SARAY RUGS',
      consigneeDeliverTo:
        'SARAY RUGS UNIT 2 264-272 HOXTON PARK ROAD PRESTONS NSW 2170',
      consigneeEstimatedDelivery: '20-Jan-21 0:00'
    },
    goodDescription: 'Sultanas Turkish No 9 RTU 12.5kg',
    milestones: [
      {
        info: {
          name: 'S00009189',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '5-Jan-21',
          time: '12:17'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008164',
        transportBill: 'SE1521100182',
        transportVoyage: '014E',
        transportLoad: 'Mersin',
        transportDischarge: 'Sydney',
        transportDeparture: '25-Dec-20',
        transportArrival: '17-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MEDSHI_AU'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '12.5',
        goodsUQ: 'KG',
        goodsVolume: '50.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Sultanas Turkish No 9 RTU'
      }
    ],
    orders: [
      {
        orderSupplier: 'Taris Sinirli',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '50.000 M3',
        orderWeight: '12.5kg'
      }
    ],
    container: [
      {
        containerName: 'S00009189',
        containerSeal: '159628',
        containerContainerType: "10x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009189/1',
        customEntry: 'AEMNPH4RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'APL Savannah V.MA046A',
    shipmentNo: 'S00009123',
    shipperName: 'BOSSAN TEKSTIL IHT. IHR. SAN. VE TIC LTD. STI',
    shipper: {
      shipperName: 'BOSSAN TEKSTIL IHT. IHR. SAN. VE TIC LTD. STI',
      shipperPickupFrom:
        'VIMOTER SPA VIA CROCE ROSSA ITALIANA 12 20054 NOVA MILANESE MONZA E DELLA BRIANZA ITALY'
    },
    billNo: '0.10749',
    containerNo: 'MEDU5376375 FBIU0224138 FBIU0177885 MSDU1279899 TEMU5324939',
    containerType: "5x20'",
    shipperRef: 'Frutex-33693',
    jobNo: 'F10656',
    origin: 'La Spezia',
    etd: '24-Dec-20',
    destination: 'Sydney',
    eta: '4-Jan-2021',
    product: 'Sultanas Turkish No 9 RTU 12.5kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '5-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO211415',
    orderRef: 'TBC',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '01-Jan-21 20:14 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'ALLMACH SUPERIOR PIPING SOLUTIONS',
      consigneeDeliverTo:
        'C/O ALLMACH PNEUMATIC & FLUID 2 GARVAN ROAD HEATHCOTE NSW 2233',
      consigneeEstimatedDelivery: '20-Jan-21 0:00'
    },
    goodDescription: 'Sultanas Turkish No 9 RTU 12.5kg',
    milestones: [
      {
        info: {
          name: 'S00009123',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '4-Jan-21',
          time: '11:00'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008165',
        transportBill: 'S00009125',
        transportVoyage: 'MA146A',
        transportLoad: 'La Spezia',
        transportDischarge: 'Singapore',
        transportDeparture: '24-Dec-20',
        transportArrival: '16-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Piece',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '12.5',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Sultanas Turkish No 9 RTU'
      }
    ],
    orders: [
      {
        orderSupplier: 'Erturk Uzum Ve Tarim',
        orderStatus: 'Pending',
        orderPacks: '0 PCE',
        orderVolume: '0.000 M3',
        orderWeight: '12.5kg'
      }
    ],
    container: [
      {
        containerName: 'S00009123',
        containerSeal: '159627',
        containerContainerType: "5x20'",
        containerContainerMode: 'LCL',
        containerTareWeight: '3730.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009123/1',
        customEntry: 'AEMEXEF2G',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'APL Savannah V.MA046A',
    shipmentNo: 'S00009220',
    shipperName: 'SINIRLI SORUMLU TARIS UZUM',
    shipper: {
      shipperName: 'SINIRLI SORUMLU TARIS UZUM',
      shipperPickupFrom:
        'APN COMPOUNDING 8 VICARS PLACE WETHERILL PARK NSW 2164'
    },
    billNo: 'MEDUV2619223',
    containerNo: 'MEDU5321731',
    containerType: "1x20'",
    shipperRef: 'Frutex-33658',
    jobNo: 'S4121',
    origin: 'Sydney',
    etd: '24-Dec-20',
    destination: 'Hamburg',
    eta: '4-Jan-2021',
    product: 'Paprika Asta 100 HT SS 15kg',
    qp: 'Y',
    ifip: 'Y',
    eidoCarrier: '6-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'MBE0364418',
    orderRef: 'TBC',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Letter of Credit',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'KNUDSEN PLAST S.R.O',
      consigneeDeliverTo:
        "KNUDSEN PLAST S.R.O HERLANSKA 547 09301 VRANOV NAD TOP'LOU PV SLOVAKIA",
      consigneeEstimatedDelivery: '20-Jan-21 0:00'
    },
    goodDescription: 'Paprika Asta 100 HT SS 15kg',
    milestones: [
      {
        info: {
          name: 'S00009220',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '4-Jan-21',
          time: '21:00'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008166',
        transportBill: 'S00009221',
        transportVoyage: 'MA146R',
        transportLoad: 'Sydney',
        transportDischarge: 'Sydney',
        transportDeparture: '24-Dec-20',
        transportArrival: '16-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '6',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '15',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Paprika Asta 100 HT SS'
      }
    ],
    orders: [
      {
        orderSupplier: 'Omega Spices',
        orderStatus: 'Pending',
        orderPacks: '6 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '15kg'
      }
    ],
    container: [
      {
        containerName: 'S00009220',
        containerSeal: '159626',
        containerContainerType: "1x20'",
        containerContainerMode: 'LCL',
        containerTareWeight: '3980.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '12-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009220/1',
        customEntry: 'AEMT1R9WL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'GSL Christel Elis. V.047S',
    shipmentNo: 'S00009209',
    shipperName: 'ZWEIGART & SAWITZKI GMBH & CO',
    shipper: {
      shipperName: 'ZWEIGART & SAWITZKI GMBH & CO',
      shipperPickupFrom:
        'LINCOLN INDUSTRIES 600 W E ST LINCOLN NE 68522 UNITED STATES'
    },
    billNo: 'ACB0118316',
    containerNo: 'CMAU0555908',
    containerType: "1x20'",
    shipperRef: 'Frutex-33741',
    jobNo: 'S4138',
    origin: 'Omaha',
    etd: '23-Dec-20',
    destination: 'Sydney',
    eta: '3-Jan-2021',
    product: 'Whole Cumin Dried 25kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '6-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'LC61 DRP 30/11 P/UP 03/12',
    orderRef: '04840-1',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Letter of Credit',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'CSC GROUP AUSTRALIA PTY LTD',
      consigneeDeliverTo:
        'CSC GROUP AUSTRALIA PTY LTD 13 WINDSOR STREET RICHMOND NSW 2753',
      consigneeEstimatedDelivery: '21-Jan-21 0:00'
    },
    goodDescription: 'Whole Cumin Dried 25kg',
    milestones: [
      {
        info: {
          name: 'S00009209',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '3-Jan-21',
          time: '10:12'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008167',
        transportBill: 'S00009231',
        transportVoyage: '148S',
        transportLoad: 'Omaha',
        transportDischarge: 'Hamburg',
        transportDeparture: '23-Dec-20',
        transportArrival: '15-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '25',
        goodsUQ: 'KG',
        goodsVolume: '10.644',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Whole Cumin Dried'
      }
    ],
    orders: [
      {
        orderSupplier: 'Grover Sons',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '10.644 M3',
        orderWeight: '25kg'
      }
    ],
    container: [
      {
        containerName: 'S00009209',
        containerSeal: '159625',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '13-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009209/1',
        customEntry: 'AEMFPH6RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'GSL Christel Elis. V.047S',
    shipmentNo: 'S00009153',
    shipperName: 'DANPARK PTY LTD',
    shipper: {
      shipperName: 'DANPARK PTY LTD',
      shipperPickupFrom:
        'SYDNEY DECORATIVE CONCRETORS WAREHOUSE PTY LTD UNIT 3 31 ELIZABETH STREET WETHERILL PARK NSW 2164'
    },
    billNo: 'ACB0118315',
    containerNo: 'TLLU2727700',
    containerType: "1x20'",
    shipperRef: 'Frutex-33743',
    jobNo: 'S4140',
    origin: 'Sydney',
    etd: '23-Dec-20',
    destination: 'Kobe',
    eta: '3-Jan-2021',
    product: 'Whole Cumin Dried 25kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '5-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: '38855',
    orderRef: 'LC61',
    serviceLevel: 'STD',
    incoterm: 'Cost And Freight',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'KOBE GARDEN HOUSE CO.LTD',
      consigneeDeliverTo:
        'KOBE GARDEN HOUSE CO.LTD 5-7-31 KITAGOYO KITA-KU KOBE-SHI, HYOGO 651-1131 JAPAN',
      consigneeEstimatedDelivery: '21-Jan-21 0:00'
    },
    goodDescription: 'Whole Cumin Dried 25kg',
    milestones: [
      {
        info: {
          name: 'S00009153',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '3-Jan-21',
          time: '14:15'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008168',
        transportBill: 'AEL1181553',
        transportVoyage: '121N',
        transportLoad: 'Sydney',
        transportDischarge: 'Sydney',
        transportDeparture: '23-Dec-20',
        transportArrival: '15-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '581',
        goodsPackType: 'Piece',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '25',
        goodsUQ: 'KG',
        goodsVolume: '10.644',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Whole Cumin Dried'
      }
    ],
    orders: [
      {
        orderSupplier: 'Grover Sons',
        orderStatus: 'Pending',
        orderPacks: '581 PCE',
        orderVolume: '0.000 M3',
        orderWeight: '25kg'
      }
    ],
    container: [
      {
        containerName: 'S00009153',
        containerSeal: '159624',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '13-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009153/1',
        customEntry: 'AEMNPL3RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Seatrade Red T/s on CMA CGM Puccini V.048S',
    shipmentNo: 'S00009162',
    shipperName: 'MG CHEMICALS LTD',
    shipper: {
      shipperName: 'MG CHEMICALS LTD',
      shipperPickupFrom:
        'VINH DUC PLASTICS-RUBBER PRODUCTION CO., LTD D5/3 HAMLET 4, LE MINH XUAN COMMUNE BINH CHANH DISTRICT . VIET NAM'
    },
    billNo: 'NAM9253898',
    containerNo: 'CGMU5235839 CRSU6109422',
    containerType: '2x40Rf/-18',
    shipperRef: 'Frutex-33675',
    jobNo: 'F10655',
    origin: 'Qingdao',
    etd: '23-Dec-20',
    destination: 'Sydney',
    eta: '3-Jan-2021',
    product: 'Iqf Large Blueberries 13.61kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '30-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: '53668ILS',
    orderRef: 'DP',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Time Draft',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'THE RUSSELL COLLECTION',
      consigneeDeliverTo:
        'THE RUSSELL COLLECTION PTY LTD 104 ADDERLEY STREET AUBURN NSW 2144',
      consigneeEstimatedDelivery: '22-Jan-21 0:00'
    },
    goodDescription: 'Iqf Large Blueberries 13.61kg',
    milestones: [
      {
        info: {
          name: 'S00009162',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '3-Jan-21',
          time: '12:12'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008169',
        transportBill: 'CGLSZE21110349',
        transportVoyage: '2102S',
        transportLoad: 'Qingdao',
        transportDischarge: 'Osaka',
        transportDeparture: '23-Dec-20',
        transportArrival: '15-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '468',
        goodsPackType: 'Carton',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '13.61',
        goodsUQ: 'KG',
        goodsVolume: '21.950',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Iqf Large Blueberries'
      }
    ],
    orders: [
      {
        orderSupplier: 'Sunmark Foods',
        orderStatus: 'Pending',
        orderPacks: '468 CTN',
        orderVolume: '21.950 M3',
        orderWeight: '13.61kg'
      }
    ],
    container: [
      {
        containerName: 'S00009162',
        containerSeal: '159623',
        containerContainerType: '2x40Rf/-18',
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '14-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009162/1',
        customEntry: 'AEMEXDF2H',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'CMA Cgm Puccini V.048S',
    shipmentNo: 'S00009215',
    shipperName: 'PETROUTSOS BROS LTD',
    shipper: {
      shipperName: 'PETROUTSOS BROS LTD',
      shipperPickupFrom:
        'PETROUTSOS BROS LTD 5 -7 IN. PARK ANO LIOSION 19 10440 ATHENS ATTIKI GREECE'
    },
    billNo: 'AHA01930902',
    containerNo: 'SZLU3804200',
    containerType: '1x20Rf/-18',
    shipperRef: 'Frutex-33872',
    jobNo: 'F10714',
    origin: 'Ho Chi Minh City',
    etd: '23-Dec-20',
    destination: 'Melbourne',
    eta: '3-Jan-2021',
    product: 'IQF Pineapple Tidbits',
    qp: 'N',
    ifip: 'Y',
    eidoCarrier: '6-Jan-21',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO 100812',
    orderRef: 'PO#HDE10',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'CRP INDUSTRIES PTY. LTD.',
      consigneeDeliverTo:
        'CRP INDUSTRIES PTY. LTD. 6 MICKLE STREET DANDENONG SOUTH VIC 3175',
      consigneeEstimatedDelivery: '22-Jan-21 0:00'
    },
    goodDescription: 'IQF Pineapple Tidbits',
    milestones: [
      {
        info: {
          name: 'S00009215',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '3-Jan-21',
          time: '12:13'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008170',
        transportBill: 'S00009245',
        transportVoyage: '0196S',
        transportLoad: 'Ho Chi Minh City',
        transportDischarge: 'Sydney',
        transportDeparture: '23-Dec-20',
        transportArrival: '15-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '15',
        goodsUQ: 'KG',
        goodsVolume: '30.050',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'IQF Pineapple Tidbits'
      }
    ],
    orders: [
      {
        orderSupplier: 'Exoriens Co',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '30.050 M3',
        orderWeight: '15kg'
      }
    ],
    container: [
      {
        containerName: 'S00009215',
        containerSeal: '159622',
        containerContainerType: '1x20Rf/-18',
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '14-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009215/1',
        customEntry: 'AEMH7R9WL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'CMA Cgm Puccini V.048S',
    shipmentNo: 'S00009008',
    shipperName: 'PETROUTSOS BROS LTD',
    shipper: {
      shipperName: 'PETROUTSOS BROS LTD',
      shipperPickupFrom:
        'WERA WERKZEUGE GMBH KORZERTER STRASSE 21-25 42349 WUPPERTAL GERMANY'
    },
    billNo: 'AHA0191770',
    containerNo: 'TRIU8662182',
    containerType: '1x40Rf/-18',
    shipperRef: 'Frutex-33869',
    jobNo: 'F10713',
    origin: 'Piraeus',
    etd: '22-Dec-20',
    destination: 'Sydney',
    eta: '2-Jan-2021',
    product: 'IQF Mango Chunks Natures Grocer 8x1.5kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '29-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO TBA - 1X20GP',
    orderRef: 'TBC',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'ECO D.P.B. PTY LIMITED',
      consigneeDeliverTo:
        'ECO D.P.B. PTY LIMITED UNIT 8 168-180 VICTORIA ROAD MARRICKVILLE NSW 2204',
      consigneeEstimatedDelivery: '22-Jan-21 0:00'
    },
    goodDescription: 'IQF Mango Chunks Natures Grocer 8x1.5kg',
    milestones: [
      {
        info: {
          name: 'S00009008',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '2-Jan-21',
          time: '13:13'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008171',
        transportBill: '1X20GP LOAD 12/11',
        transportVoyage: '063E',
        transportLoad: 'Piraeus',
        transportDischarge: 'Singapore',
        transportDeparture: '22-Dec-20',
        transportArrival: '14-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '12',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'IQF Mango Chunks Natures Grocer'
      }
    ],
    orders: [
      {
        orderSupplier: 'Exoriens Co',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '12kg'
      }
    ],
    container: [
      {
        containerName: 'S00009008',
        containerSeal: '159621',
        containerContainerType: '1x40Rf/-18',
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '14-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009008/1',
        customEntry: 'AEMFPH4RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Maersk Seoul V.050S',
    shipmentNo: 'S00009077',
    shipperName: 'WERA WERKZEUGE GMBH',
    shipper: {
      shipperName: 'WERA WERKZEUGE GMBH',
      shipperPickupFrom: 'DANPARK PTY LTD 23-29 BOOLA AVENUE YENNORA NSW 2161'
    },
    billNo: 'MEDUQO717019',
    containerNo: 'TCKU3858479',
    containerType: "1x20'",
    shipperRef: 'Frutex-33988',
    jobNo: 'F10640',
    origin: 'Hamburg',
    etd: '22-Dec-20',
    destination: 'Sydney',
    eta: '2-Jan-2021',
    product: 'Apple Sliced Solid Pack 2x5Kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '30-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO 100816',
    orderRef: 'PO, TBA, -, 1X20GP',
    serviceLevel: 'STD',
    incoterm: 'Ex Works',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'THE TOOL GROUP PTY LTD',
      consigneeDeliverTo:
        'THE TOOL GROUP PTY LTD 5 PRINCE WILLIAM DRIVE SEVEN HILLS NSW 2147',
      consigneeEstimatedDelivery: '22-Jan-21 0:00'
    },
    goodDescription: 'Apple Sliced Solid Pack 2x5Kg',
    milestones: [
      {
        info: {
          name: 'S00009077',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '2-Jan-21',
          time: '14:33'
        },
        status: 'Completed'
      }
    ],
    milestoneJob: 'S00009077',
    milestoneDescription: 'Delivery Cartage Complete/Finalised',
    mileStoneTime: '2-Jan-21 14:33',
    milestoneStatus: 'Completed',
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008172',
        transportBill: 'SE1521110014',
        transportVoyage: 'TBC',
        transportLoad: 'Rotterdam',
        transportDischarge: 'Sydney',
        transportDeparture: '22-Dec-20',
        transportArrival: '14-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '8',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '10',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Apple Sliced Solid Pack'
      }
    ],
    orders: [
      {
        orderSupplier: 'Weihai Junlide',
        orderStatus: 'Pending',
        orderPacks: '8 PLT',
        orderVolume: '0.000 M3',
        orderWeight: '10Kg'
      }
    ],
    container: [
      {
        containerName: 'S00009077',
        containerSeal: '159620',
        containerContainerType: "1x20'",
        containerContainerMode: 'LCL',
        containerTareWeight: '3730.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '14-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009077/1',
        customEntry: 'AEMHPR4RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'INT YM Wonderland',
    shipmentNo: 'S00009170',
    shipperName: 'DANPARK PTY LTD',
    shipper: {
      shipperName: 'DANPARK PTY LTD',
      shipperPickupFrom:
        'PACK LEADER MACHINERY INC. 60 32ND ROAD TAICHUNG INDUSTRIAL PARK TAICHUNG CITY 40768 TAIWAN'
    },
    billNo: '618-86485840',
    containerNo: 'OOLU1281104',
    containerType: "1x20'",
    shipperRef: 'Frutex-34016',
    jobNo: 'S4143',
    origin: 'Mersin',
    etd: '21-Dec-20',
    destination: 'Busan',
    eta: '1-Jan-2021',
    product: 'Saffron Filaments 0.5g',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '31-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: '38857',
    orderRef: 'PO-TBA',
    serviceLevel: 'STD',
    incoterm: 'Cost And Freight',
    releaseType: 'Letter of Credit',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'SAMYANG CORPORATION',
      consigneeDeliverTo:
        'SAMYANG CORPORATION 295, PANGYO-RO, BUNDANG-GU SEONGNAM-SI, GYEONGGI-DO KOREA, REPUBLIC OF KOREA',
      consigneeEstimatedDelivery: '23-Jan-21 0:00'
    },
    goodDescription: 'Saffron Filaments 0.5g',
    milestones: [
      {
        info: {
          name: 'S00008951',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '16:17'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Pre-Carriage Vessel\t',
        transportParent: 'C00008173',
        transportBill: 'S00009171',
        transportVoyage: '0079N',
        transportLoad: 'Sydney',
        transportDischarge: 'Sydney',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '3540',
        goodsPackType: 'Carton',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '0.5',
        goodsUQ: 'KG',
        goodsVolume: '0.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Saffron Filaments'
      }
    ],
    orders: [
      {
        orderSupplier: 'Verdu-Canto Saffron',
        orderStatus: 'Pending',
        orderPacks: '3540 CTN',
        orderVolume: '0.000 M3',
        orderWeight: '0.5g'
      }
    ],
    container: [
      {
        containerName: 'S00009170',
        containerSeal: '159619',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '15-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009170/1',
        customEntry: 'AEMEEXF2G',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Mattina V.KY049A',
    shipmentNo: 'S00009210',
    shipperName: 'UPC CHINA',
    shipper: {
      shipperName: 'UPC CHINA',
      shipperPickupFrom:
        'SINIRLI SORUMLU TARIS UZUM 14/914 KEY PLAZA ALSANCAK KONAK 35 35030 TURKEY'
    },
    billNo: 'MEDUJ3079335',
    containerNo: 'MEDU1226700',
    containerType: "1x20'",
    shipperRef: 'Frutex-33780',
    jobNo: 'S4142',
    origin: 'Aliaga',
    etd: '21-Dec-20',
    destination: 'Melbourne',
    eta: '1-Jan-2021',
    product: 'Grd Nutmeg/Mace SS 25kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '31-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'SLP265A',
    orderRef: 'DP',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'THE EVERLEIGH BOTTLING CO',
      consigneeDeliverTo:
        'THE EVERLEIGH BOTTLING CO / WAEHOUSE STORAGE 5 BROWN STREET COLLINGWOOD VIC 3066',
      consigneeEstimatedDelivery: '23-Jan-21 0:00'
    },
    goodDescription: 'Grd Nutmeg/Mace SS 25kg',
    milestones: [
      {
        info: {
          name: 'S00008951',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '15:23'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008174',
        transportBill: 'ATMGXE2021110016',
        transportVoyage: '2110N',
        transportLoad: 'Qingdao',
        transportDischarge: 'Busan',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '0',
        goodsPackType: 'Pallet',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '25',
        goodsUQ: 'KG',
        goodsVolume: '250.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Grd Nutmeg/Mace SS'
      }
    ],
    orders: [
      {
        orderSupplier: 'PT Indoharvest',
        orderStatus: 'Pending',
        orderPacks: '0 PLT',
        orderVolume: '250.000 M3',
        orderWeight: '25kg'
      }
    ],
    container: [
      {
        containerName: 'S00009210',
        containerSeal: '159618',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '15-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009210/1',
        customEntry: 'AEMT9R7WL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Loraine V.026S',
    shipmentNo: 'S00009015',
    shipperName: 'ASHMIC STEEL GROUP',
    shipper: {
      shipperName: 'ASHMIC STEEL GROUP',
      shipperPickupFrom:
        'DRIVE MEDICAL GMBH & CO. G LEUTKIRCHER STRASSE 44 88316 ISNY IM ALLGAEU GERMANY'
    },
    billNo: 'SGNSYD0237201',
    containerNo: 'HDMU2487986',
    containerType: "1x20'",
    shipperRef: 'Frutex-34010',
    jobNo: 'S4179',
    origin: 'Hamburg',
    etd: '21-Dec-20',
    destination: 'Brisbane',
    eta: '1-Jan-2021',
    product: 'Black Cracked Pepper 16/24 SS 25kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '31-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO211416',
    orderRef: 'TBC',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'ATF SERVICES',
      consigneeDeliverTo: 'ATF SERVICES 9 HARBORD STREET CLYDE NSW 2142',
      consigneeEstimatedDelivery: '24-Jan-21 0:00'
    },
    goodDescription: 'Black Cracked Pepper 16/24 SS 25kg',
    milestones: [
      {
        info: {
          name: 'S00009015',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '14:55'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008175',
        transportBill: 'ATMGXE2021100011',
        transportVoyage: 'TBC',
        transportLoad: 'Xingang',
        transportDischarge: 'Melbourne',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '1334',
        goodsPackType: 'Package',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '25',
        goodsUQ: 'KG',
        goodsVolume: '100.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Black Cracked Pepper 16/24 SS'
      }
    ],
    orders: [
      {
        orderSupplier: 'Olam Vietnam',
        orderStatus: 'Pending',
        orderPacks: '1334 PKG',
        orderVolume: '100.000 M3',
        orderWeight: '25kg'
      }
    ],
    container: [
      {
        containerName: 'S00009015',
        containerSeal: '159617',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3730.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '16-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009015/1',
        customEntry: 'AEMTPH6RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Seamax Stradford V.104S',
    shipmentNo: 'S00008779',
    shipperName: 'DRAPER TOOLS LTD',
    shipper: {
      shipperName: 'DRAPER TOOLS LTD',
      shipperPickupFrom:
        'SYDNEY DECORATIVE CONCRETORS WAREHOUSE PTY LTD UNIT 3 31 ELIZABETH STREET WETHERILL PARK NSW 2164'
    },
    billNo: 'AHA0190923',
    containerNo: 'TEMU9416490',
    containerType: '1x40Rf/-18',
    shipperRef: 'Frutex-33758',
    jobNo: 'F10681',
    origin: 'Sydney',
    etd: '21-Dec-20',
    destination: 'Busan',
    eta: '1-Jan-2021',
    product: 'IQF Mango Chunks Natures Grocer 8x1.5kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '30-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: '38854',
    orderRef: 'TBC',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'SAMYANG CORPORATION',
      consigneeDeliverTo:
        'SAMYANG CORPORATION 295, PANGYO-RO, BUNDANG-GU SEONGNAM-SI, GYEONGGI-DO KOREA, REPUBLIC OF KOREA',
      consigneeEstimatedDelivery: '25-Jan-21 0:00'
    },
    goodDescription: 'IQF Mango Chunks Natures Grocer 8x1.5kg',
    milestones: [
      {
        info: {
          name: 'S00008779',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '16:00'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008176',
        transportBill: '17305',
        transportVoyage: 'QG136E',
        transportLoad: 'London',
        transportDischarge: 'Brisbane',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '55',
        goodsPackType: 'Package',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '12',
        goodsUQ: 'KG',
        goodsVolume: '50.000',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'IQF Mango Chunks Natures Grocer'
      }
    ],
    orders: [
      {
        orderSupplier: 'BST Produce',
        orderStatus: 'Pending',
        orderPacks: '55 PKG',
        orderVolume: '50.000 M3',
        orderWeight: '12kg'
      }
    ],
    container: [
      {
        containerName: 'S00008779',
        containerSeal: '159616',
        containerContainerType: '1x40Rf/-18',
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '17-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00008779/1',
        customEntry: 'AEMHJH4RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Kota Lambai V.135S',
    shipmentNo: 'S00008937',
    shipperName: 'MAXIM LOGISTICA INTERNATIONAL EIRELLI',
    shipper: {
      shipperName: 'MAXIM LOGISTICA INTERNATIONAL EIRELLI',
      shipperPickupFrom:
        'MG CHEMICALS LTD 1210 CORPORATE DR BURLINGTON ON L7L 5R6 CANADA'
    },
    billNo: 'OOLU2654140440',
    containerNo: 'OOLU0451470',
    containerType: "1x20'",
    shipperRef: 'Frutex-33843',
    jobNo: 'F10704',
    origin: 'Philadelphia',
    etd: '21-Dec-20',
    destination: 'Melbourne',
    eta: '1-Jan-2021',
    product: 'Coconut Cream Aseptic 20kg',
    qp: 'N',
    ifip: 'N',
    eidoCarrier: '30-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'SLP263A',
    orderRef: 'SLP263A',
    serviceLevel: 'STD',
    incoterm: 'Free On Board',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'STOCKLOTS WHOLESALERS PTY LTD',
      consigneeDeliverTo:
        'TIGERS BUILDING J 77 MILLERS ROAD CONTACT: SILVIO / 03 9315 1700 BROOKLYN VIC 3012',
      consigneeEstimatedDelivery: '25-Jan-21 0:00'
    },
    goodDescription: 'Coconut Cream Aseptic 20kg',
    milestones: [
      {
        info: {
          name: 'S00008937',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '17:01'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008177',
        transportBill: '21-01190CF',
        transportVoyage: 'FI140R',
        transportLoad: 'Santos',
        transportDischarge: 'Singapore',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '2658',
        goodsPackType: 'Carton',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '20',
        goodsUQ: 'KG',
        goodsVolume: '1.551',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Coconut Cream Aseptic'
      }
    ],
    orders: [
      {
        orderSupplier: 'Silvermill Holds',
        orderStatus: 'Pending',
        orderPacks: '2658 CTN',
        orderVolume: '1.551 M3',
        orderWeight: '20kg'
      }
    ],
    container: [
      {
        containerName: 'S00008937',
        containerSeal: '159615',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3980.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '17-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00008937/1',
        customEntry: 'AEMEZEF3G',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Kota Lambai V.135S',
    shipmentNo: 'S00009225',
    shipperName: 'SINIRLI SORUMLU TARIS UZUM',
    shipper: {
      shipperName: 'SINIRLI SORUMLU TARIS UZUM',
      shipperPickupFrom:
        'SINIRLI SORUMLU TARIS UZUM 14/914 KEY PLAZA ALSANCAK KONAK 35 35030 TURKEY'
    },
    billNo: 'OOLU2654141530',
    containerNo: 'OOLU1171094',
    containerType: "1x20'",
    shipperRef: 'Frutex-33854',
    jobNo: 'F10706',
    origin: 'Aliaga',
    etd: '21-Dec-20',
    destination: 'Sydney',
    eta: '1-Jan-2021',
    product: 'Med Toasted Coconut 20kg',
    qp: 'Y',
    ifip: 'N',
    eidoCarrier: '29-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'PO211417',
    orderRef: 'PO-TBA',
    serviceLevel: 'STD',
    incoterm: 'Cost And Freight',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'FRUTEX AUSTRALIA PTY LTD',
      consigneeDeliverTo:
        'FRUTEX AUSTRALIA PTY LTD 18 ST ALBANS ROAD KINGSGROVE NSW 2208',
      consigneeEstimatedDelivery: '25-Jan-21 0:00'
    },
    goodDescription: 'Med Toasted Coconut 20kg',
    milestones: [
      {
        info: {
          name: 'S00009225',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '18:01'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008178',
        transportBill: '0.12491',
        transportVoyage: 'MA144A',
        transportLoad: 'Aliaga',
        transportDischarge: 'Singapore',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '8000',
        goodsPackType: 'Carton',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '20',
        goodsUQ: 'KG',
        goodsVolume: '21.950',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Med Toasted Coconut'
      }
    ],
    orders: [
      {
        orderSupplier: 'Silvermill Holds',
        orderStatus: 'Pending',
        orderPacks: '8000 CTN',
        orderVolume: '21.950 M3',
        orderWeight: '20kg'
      }
    ],
    container: [
      {
        containerName: 'S00009225',
        containerSeal: '159614',
        containerContainerType: "1x20'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3690.000',
        containerWeight: '14388.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '17-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009225/1',
        customEntry: 'AEMY7R9WL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  },
  {
    vessel: 'Kota Lambai V.135S',
    shipmentNo: 'S00009226',
    shipperName: 'SINIRLI SORUMLU TARIS UZUM',
    shipper: {
      shipperName: 'SINIRLI SORUMLU TARIS UZUM',
      shipperPickupFrom:
        'SINIRLI SORUMLU TARIS UZUM 14/914 KEY PLAZA ALSANCAK KONAK 35 35030 TURKEY'
    },
    billNo: 'COSU6279770900',
    containerNo: 'BSIU9479355',
    containerType: "1x40'",
    shipperRef: 'Frutex-33591',
    jobNo: 'S4104',
    origin: 'Aliaga',
    etd: '21-Dec-20',
    destination: 'Sydney',
    eta: '1-Jan-2021',
    product: 'Parsley Flat 4mm Serbian SS 7kg',
    qp: 'Y',
    ifip: 'Y',
    eidoCarrier: '29-Dec-20',
    onboard: 'Shipped',
    lastUpdated: '1-Dec-2021',
    ownerRef: 'LCL - PO - TBA',
    orderRef: 'DP',
    serviceLevel: 'STD',
    incoterm: 'Cost And Freight',
    releaseType: 'Express Bill of Lading',
    chargesApply: 'Show Collect Charges',
    notes: '02-Jan-21 21:09 - Marks & Numbers\nCRT31B09I787',
    agents: null,
    references: [],
    consignee: {
      consigneeName: 'FRUTEX AUSTRALIA PTY LTD',
      consigneeDeliverTo:
        'FRUTEX AUSTRALIA PTY LTD 18 ST ALBANS ROAD KINGSGROVE NSW 2208',
      consigneeEstimatedDelivery: '25-Jan-21 0:00'
    },
    goodDescription: 'Parsley Flat 4mm Serbian SS 7kg',
    milestones: [
      {
        info: {
          name: 'S00009226',
          description: 'Delivery Cartage Complete/Finalised'
        },
        date: {
          date: '1-Jan-21',
          time: '18:02'
        },
        status: 'Completed'
      }
    ],
    transports: [
      {
        transportLeg: '1',
        transportMode: 'SEA',
        transportType: 'Main Vessel',
        transportParent: 'C00008179',
        transportBill: '0.12497',
        transportVoyage: 'MA144A',
        transportLoad: 'Aliaga',
        transportDischarge: 'Sydney',
        transportDeparture: '21-Dec-20',
        transportArrival: '13-Jan-21',
        transportStatus: 'Arrived',
        transportCarrier: 'MAELINSYD'
      }
    ],
    goods: [
      {
        goodsPieces: '16000',
        goodsPackType: 'Carton',
        goodsLength: '0.000',
        goodsWidth: '0.000',
        goodsHeight: '0.000',
        goodsUD: 'M',
        goodsWeight: '7',
        goodsUQ: 'KG',
        goodsVolume: '1.920',
        goodsLinePrice: '0.00',
        goodsCurrency: 'USD',
        goodsDescription: 'Parsley Flat 4mm Serbian SS'
      }
    ],
    orders: [
      {
        orderSupplier: 'Geneza Ltd',
        orderStatus: 'Pending',
        orderPacks: '16000 CTN',
        orderVolume: '1.920 M3',
        orderWeight: '7kg'
      }
    ],
    container: [
      {
        containerName: 'S00009226',
        containerSeal: '159613',
        containerContainerType: "1x40'",
        containerContainerMode: 'FCL',
        containerTareWeight: '3730.000',
        containerWeight: '13734.000',
        containerDeliveryMode: 'CY/CY'
      }
    ],
    documents: [
      {
        referenceDate: '17-Nov-21',
        referenceDescription: 'House Waybill/Bill of Lading',
        referenceType: 'House Waybill/Bill of Lading'
      }
    ],
    related: [
      {
        relatedCurrency: 'USD'
      }
    ],
    customs: [
      {
        customReference: 'S00009226/1',
        customEntry: 'AEMFPH9RL',
        customMessageStatus: 'CPY',
        customEntryAdvice: 'Authority to Deal'
      }
    ]
  }
]

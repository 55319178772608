import React, { useContext, useEffect, useState } from 'react'
import AppContext from 'context/Context'
import Select from 'react-select'
import momentTZ from 'moment-timezone'

const DropdownTimeZones = ({ countryCodes, t }) => {
  const { setConfig } = useContext(AppContext)
  const [selectedCountry, setSelectedCountry] = useState()
  const [timeZoneList, setTimeZoneList] = useState([])

  const styles = {
    placeholder: provided => ({
      ...provided,
      color: '#efeef9',
      fontSize: '12px'
    }),
    option: provided => ({
      ...provided,
      textAlign: 'left'
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      display: 'flex'
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      pointerEvents: 'auto',
      border: 0,
      borderColor: '#D5D9E8 !important',
      height: 48,
      minHeight: 34,
      boxShadow: 'none',
      opacity: state.isDisabled ? '0.5' : '1'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: 14
    })
  }
  const handleSelectCountry = selected => {
    setSelectedCountry(selected)
  }

  const handleChangeTimeZone = selected => {
    setConfig('currentTimeZone', selected.name)
  }

  useEffect(() => {
    if (selectedCountry) {
      setTimeZoneList(momentTZ.tz.zonesForCountry(selectedCountry.code, true))
    }
  }, [selectedCountry])

  return (
    <>
      <div className="d-block w-100">
        <div className="d-flex w-100 mb-1">
          <div className="dropdown-timezones-label pe-2">
            <span>{t('Region')}</span>
          </div>
          <div className="w-100">
            <Select
              options={countryCodes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.code}
              onChange={selected => handleSelectCountry(selected)}
              placeholder={t('Select region')}
              isSearchable={false}
              className="create-input"
              maxMenuHeight={200}
              styles={styles}
              theme={theme => ({
                ...theme,
                borderRadius: 4
              })}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
        </div>
        <div className="d-flex w-100">
          <div className="dropdown-timezones-label pe-2">
            <span>{t('Time Zone')}</span>
          </div>
          <div className="w-100">
            <Select
              options={timeZoneList}
              getOptionLabel={option => timeZoneLabel(option)}
              getOptionValue={option => option.code}
              onChange={selected => handleChangeTimeZone(selected)}
              placeholder={t('Select time zone')}
              isSearchable={false}
              isDisabled={selectedCountry ? false : true}
              className="create-input"
              maxMenuHeight={200}
              styles={styles}
              theme={theme => ({
                ...theme,
                borderRadius: 4
              })}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DropdownTimeZones

const convertOffsetToTime = minutes => {
  let h = Math.floor(Math.abs(minutes) / 60)
  h = h < 10 ? '0' + String(h) : String(h)
  let m = Math.abs(minutes % 60)
  m = String(m).padStart(2, '0')
  if (minutes < 0) {
    return '-' + h + ':' + m
  }
  return '+' + h + ':' + m
}

const timeZoneLabel = option => {
  const convertedTime = convertOffsetToTime(option.offset)
  const removedRegion =
    option.name.split('/').length > 2
      ? option.name.split('/')[1] + ' - ' + option.name.split('/')[2]
      : option.name.split('/')[1]
  const replacedLine = removedRegion.replaceAll('_', ' ')
  return replacedLine + ' (' + convertedTime + ')'
}

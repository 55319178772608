export const changePagination = ({
  data,
  dispatch,
  getSummaryData,
  getFilteredSummary,
  funct,
  module,
  keyword,
  filterValue,
  displayFilterData,
  navigate,
  sortBy,
  desc,
  onChangeValue
}) => {
  let pageNumber
  let _navigate = null
  const canFirst = data?.active > 1
  const canNext = data?.active < data?.totalPage
  const canPrev = data?.active > 1

  if (funct == 'First') {
    pageNumber = 1
    _navigate = navigate
  } else if (funct == 'Next') {
    if (data.active < data.lastPage) {
      pageNumber = data?.active + 1
    }
  } else if (funct == 'Previously') {
    if (data.active !== 1) {
      pageNumber = data?.active - 1
    }
  } else if (funct == 'onChange') {
    pageNumber = onChangeValue
    _navigate = navigate
  } else if (funct == 'Last') {
    pageNumber = data?.totalPage
    _navigate = navigate
  }

  if (
    funct == 'onChange' ||
    data?.active > 1 ||
    canFirst ||
    canNext ||
    canPrev
  ) {
    if (displayFilterData) {
      getFilteredSummary(dispatch, filterValue, pageNumber)
    } else {
      getSummaryData({
        dispatch,
        PageNumber: pageNumber,
        module,
        searchVal: keyword,
        navigate: _navigate,
        sortBy,
        desc
      })
    }
  }
}

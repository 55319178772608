import React, { useState } from 'react'
import Select from 'react-select'

const DropdownUserType = ({ userTypeList, userType, setUserType, t }) => {
  const [isOpen, setIsOpen] = useState(false)
  const styles = {
    placeholder: provided => ({
      ...provided,
      color: '#efeef9',
      fontSize: '12px'
    }),
    option: provided => ({
      ...provided,
      textAlign: 'left'
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      display: 'flex'
    }),
    control: provided => ({
      ...provided,
      backgroundColor: 'white',
      pointerEvents: 'auto',
      border: 0,
      borderColor: '#D5D9E8 !important',
      height: 34,
      minHeight: 34,
      boxShadow: 'none'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: 14
    })
  }

  return (
    <>
      <Select
        options={userTypeList}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        onChange={selected => setUserType(selected.value)}
        selectedValue={userType}
        placeholder={t('Select user type')}
        isSearchable={false}
        className="create-input w-100"
        maxMenuHeight={200}
        styles={styles}
        theme={theme => ({
          ...theme,
          borderRadius: 4
        })}
        components={{
          IndicatorSeparator: () => null
        }}
      />
    </>
  )
}

export default DropdownUserType

import React, { useEffect, useState, useRef } from 'react'
import { TwitterPicker } from 'react-color'
import { toast } from 'react-toastify'
import {
  CheckInputColor,
  CheckInputName,
  colorList,
  CreateNewStatus,
  EditStatus
} from './service'
import { createFlagStatus, deleteFlagStatus, editFlagStatus } from 'ApiService'
import { Placeholder } from 'react-bootstrap'

const EditStatusItem = ({ dataColors, item, dispatch }) => {
  const inputRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [toggleEditName, setToggleEditName] = useState(false)
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [editName, setEditName] = useState(item.name)
  const [editColor, setEditColor] = useState(item.hexcode)
  const [prevColor, setPrevColor] = useState()
  const [colorPicked, setColorPicked] = useState(0)
  const [checkEditName, setCheckEditName] = useState()
  const [checkEditColor, setCheckEditColor] = useState()
  const [colorError, setColorError] = useState(false)

  const handleToggleInput = () => {
    setToggleEditName(prev => !prev)
  }

  const handleOpenColorPicker = () => {
    setOpenColorPicker(prev => !prev)
  }

  const handleSetColor = e => {
    setPrevColor(editColor)
    setColorError(false)
    setEditColor(e.hex)
    setColorPicked(prev => prev + 1)
    handleOpenColorPicker()
  }

  const handleEditStatus = async () => {
    setIsLoading(true)
    if (editName) {
      setEditName(editName.trim())
    }
    await EditStatus({
      editName: editName,
      editColor: editColor,
      editId: item.id,
      initialName: item.name,
      initialColor: item.hexcode,
      checkEditName: checkEditName,
      checkEditColor: checkEditColor,
      dispatch: dispatch,
      notify: notify,
      editFlagStatus: editFlagStatus
    })
    if (checkEditColor) {
      setEditColor(prevColor)
      setColorError(true)
    }
    setIsLoading(false)
  }

  const handleDeleteStatus = async () => {
    setIsLoading(true)
    await deleteFlagStatus({
      dispatch: dispatch,
      id: item.id,
      notify: notify
    })
    setIsLoading(false)
  }

  useEffect(() => {
    setCheckEditName(
      CheckInputName({
        statusList: dataColors,
        inputName: editName,
        initialName: item.name
      })
    )
  }, [editName])

  useEffect(() => {
    setCheckEditColor(
      CheckInputColor({
        statusList: dataColors,
        inputColor: editColor,
        initialColor: item.hexcode
      })
    )
  }, [editColor])

  useEffect(() => {
    if (colorPicked > 0) {
      handleEditStatus()
    }
  }, [colorPicked])

  useEffect(() => {
    const handleResize = e => {
      if (
        inputRef.current &&
        toggleEditName &&
        !inputRef.current.contains(e.target)
      ) {
        handleToggleInput()
      }
    }
    window.addEventListener('mousedown', handleResize)
    return () => {
      window.removeEventListener('mousedown', handleResize)
    }
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="position-relative d-flex mb-1">
      <div className="w-100 d-flex justify-content-between">
        {toggleEditName ? (
          <div ref={inputRef} className="position-relative me-2 w-75 d-flex">
            <input
              autoFocus
              className={`setting-input ${
                checkEditName && 'input-highlight__red'
              }`}
              placeholder="Edit status label"
              type="text"
              name="editStatus"
              onChange={e => setEditName(e.target.value)}
              value={editName}
            />
            <i
              type="submit"
              onClick={() => handleEditStatus()}
              className="ri-check-line setting-input-button"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => handleToggleInput()}
            className="setting-input-toggle me-2"
          >
            {item.name}
          </div>
        )}
        {openColorPicker && (
          <ColorPicker
            handleSetColor={handleSetColor}
            handleOpenColorPicker={handleOpenColorPicker}
          />
        )}
        <div
          className={`setting-color-button me-2 w-25 ${
            openColorPicker && 'color-button-highlight__blue'
          } ${colorError && 'color-button-highlight__red'} `}
          onClick={() => handleOpenColorPicker()}
          style={{
            backgroundColor: editColor ?? '#cfd2fc'
          }}
        />
      </div>
      <i
        className="ri-close-line status-edit-icon"
        onClick={() => handleDeleteStatus()}
      ></i>
    </div>
  )
}

const ShipmentStatusEdit = ({ dispatch, dataColors }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [newName, setNewName] = useState()
  const [newColor, setNewColor] = useState()
  const [checkNewName, setCheckNewName] = useState()
  const [checkNewColor, setCheckNewColor] = useState()

  const handleOpenColorPicker = () => {
    setOpenColorPicker(prev => !prev)
  }

  const handleSetColor = e => {
    setNewColor(e.hex)
    setOpenColorPicker(prev => !prev)
  }

  const handleCreateNewStatus = async () => {
    setIsLoading(true)
    if (newName) {
      setNewName(newName.trim())
    }
    const successCreateNew = await CreateNewStatus({
      newName: newName,
      newColor: newColor,
      checkNewName: checkNewName,
      checkNewColor: checkNewColor,
      dispatch: dispatch,
      notify: notify,
      createFlagStatus: createFlagStatus
    })
    if (successCreateNew) {
      setNewName(null)
      setNewColor(null)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setCheckNewName(
      CheckInputName({
        statusList: dataColors,
        inputName: newName,
        initialName: null
      })
    )
  }, [newName])

  useEffect(() => {
    setCheckNewColor(
      CheckInputColor({
        statusList: dataColors,
        inputColor: newColor,
        initialName: null
      })
    )
  }, [newColor])

  return (
    <div className="setting-edit-status">
      {dataColors ? (
        dataColors.map(item => (
          <EditStatusItem
            key={item.id}
            dataColors={dataColors}
            item={item}
            dispatch={dispatch}
          />
        ))
      ) : (
        <div>no status available, please create new status.</div>
      )}
      <div className="mt-3">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="position-relative mb-2 d-flex">
            <div className="w-100 d-flex">
              <div className="me-2 d-flex w-75">
                <input
                  className={`setting-input ${
                    checkNewName === 'nameExist' && 'input-highlight__red'
                  }`}
                  placeholder="Add new status"
                  type="text"
                  name="newStatus"
                  onChange={e => setNewName(e.target.value)}
                  value={newName ?? ''}
                />
              </div>
              {openColorPicker && (
                <ColorPicker
                  handleSetColor={handleSetColor}
                  handleOpenColorPicker={handleOpenColorPicker}
                />
              )}
              <div
                className={`setting-color-button me-2 w-25 ${
                  openColorPicker && 'color-button-highlight__blue'
                } ${
                  checkNewColor === 'colorExist' &&
                  'color-button-highlight__red'
                } `}
                onClick={() => handleOpenColorPicker()}
                style={{
                  backgroundColor: newColor ?? '#cfd2fc'
                }}
              />
            </div>
            <i
              className="ri-check-line status-edit-icon"
              onClick={() => handleCreateNewStatus()}
            ></i>
          </div>
        )}
      </div>
    </div>
  )
}

export default ShipmentStatusEdit

const ColorPicker = ({ handleSetColor, handleOpenColorPicker }) => {
  const pickerRef = useRef()
  const pickerWidth = '175px'
  const styles = {
    default: {
      input: {
        display: 'none'
      },
      hash: {
        display: 'none'
      }
    }
  }

  useEffect(() => {
    const handleResize = e => {
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        handleOpenColorPicker()
      }
    }
    window.addEventListener('mousedown', handleResize)
    return () => {
      window.removeEventListener('mousedown', handleResize)
    }
  })

  return (
    <div ref={pickerRef} className="setting-color-picker">
      <TwitterPicker
        width={pickerWidth}
        colors={colorList}
        styles={styles}
        triangle="top-right"
        onChangeComplete={e => handleSetColor(e)}
      />
    </div>
  )
}

const notify = ({ text, status }) => {
  if (status === 'warn') {
    toast.warn(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
  if (status === 'success') {
    toast.success(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
  if (status === 'error') {
    toast.error(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
}

const Loading = () => {
  return (
    <Placeholder
      className="d-flex mb-1"
      animation="glow"
      onClick={e => e.stopPropagation()}
    >
      <div className="w-100 d-flex">
        <div className="w-75 me-2">
          <Placeholder className="setting-input-loading w-100" />
        </div>
        <Placeholder className="setting-input-loading w-25 me-2" />
      </div>
      <div className="px-1 py-1">
        <Placeholder className="setting-icon-loading" />
      </div>
    </Placeholder>
  )
}

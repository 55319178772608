import React from 'react'
import { Button } from 'react-bootstrap'

// translation
import { useTranslation } from 'react-i18next'

const HButton = ({
  text,
  icon,
  onClick,
  className,
  size,
  classNameIcon,
  module,
  disabledStatus,
  textRight,
  textLeft,
  joinIcon
}) => {
  // translation
  const { t } = useTranslation()
  const textStyle = textRight
    ? 'justify-content-start'
    : textLeft
    ? 'justify-content-end'
    : 'justify-content-center'
  return (
    <Button
      variant={disabledStatus ? 'outline-secondary' : 'outline-primary'}
      size={size}
      className={`btn-class btn-hover d-flex ${textStyle} ${
        module ? '' : 'custom-button'
      } ${disabledStatus ? 'disable-custom-button' : ''} ${className} `}
      onClick={onClick}
      disabled={disabledStatus}
    >
      {text ? (
        joinIcon ? (
          <span className="d-lg-flex d-none">
            <i className={` ${icon} ${classNameIcon} me-1`} /> {t(text)}
          </span>
        ) : icon ? (
          <>
            <div className={`${classNameIcon}`}>
              <i className={`${icon} d-lg-none d-block`} />
            </div>
            <span className="d-lg-block d-none">{t(text)}</span>
          </>
        ) : (
          <>
            <span> {t(text)} </span>
          </>
        )
      ) : (
        <>
          <i className={` ${icon} ${classNameIcon}`} />
        </>
      )}
    </Button>
  )
}

export default HButton

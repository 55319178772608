import AppContext from 'context/Context'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { appRoutes } from 'routes/routes'
import NavbarSidebarGroupItem from './NavbarSidebarGroupItem'
import NavbarSidebarItem from './NavbarSidebarItem'

const NavbarSidebar = ({
  isNavCollapsed,
  navHandling,
  expandNavHandling,
  shrinkNavHandling
}) => {
  const { t } = useTranslation()
  const routes = appRoutes.children

  // if on mobile, after click menu then close menu again
  const mobileNavHandling = () => {
    const isMobile =
      window.matchMedia && window.matchMedia('(max-width: 480px)').matches
    if (isMobile) {
      navHandling()
    }
  }

  // Settings sidepanel
  const { setConfig } = useContext(AppContext)
  const ToggleSettingSidePanel = () => {
    setConfig('showSettingPanel', true)
  }

  // component for item
  return (
    <div className={`sidebar ${!isNavCollapsed ? 'open' : ''}`}>
      <ul className="nav-list">
        {routes.map((item, index) => {
          const isHaveChild = item.children ? true : false
          return isHaveChild ? (
            <NavbarSidebarGroupItem
              key={'navGroup' + index}
              nav={item}
              expandNavHandling={() => {
                expandNavHandling()
              }}
              shrinkNavHandling={() => {
                shrinkNavHandling()
              }}
              isNavCollapsed={isNavCollapsed}
              mobileNavHandling={mobileNavHandling}
            >
              {item.children}
            </NavbarSidebarGroupItem>
          ) : (
            <NavbarSidebarItem
              key={'navItems' + index}
              navItem={item}
              mobileNavHandling={mobileNavHandling}
            />
          )
        })}

        {/* Custom Item for settings */}
        <li>
          <a
            href="#"
            onClick={() => {
              ToggleSettingSidePanel()
              mobileNavHandling()
            }}
          >
            <i className={`ri-settings-line`} />
            <span className="links_name">{t('Settings')}</span>
          </a>
          <span className="tooltip">{t('Settings')}</span>
        </li>
        {/* End Custom Item for Settings */}
      </ul>
    </div>
  )
}

export default NavbarSidebar

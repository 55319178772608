import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-datetime/css/react-datetime.css'
import Layout from './layouts/Layout'

const App = () => {
  return (
    <Router>
      <Layout />
    </Router>
  )
}

export default App

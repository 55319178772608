// import library
import React from 'react'

const Pagination2 = props => {
  const { onPageChange, totalCount, currentPage, pageSize } = props

  const lastPage = Math.ceil(totalCount / pageSize)
  const classPrevious =
    lastPage >= 1
      ? currentPage - 1 === 0
        ? 'paging-arrow-left-disable'
        : 'paging-arrow'
      : 'aging-arrow-left-disable'
  const classNext =
    lastPage >= 1
      ? lastPage == currentPage
        ? 'paging-arrow-right-disable'
        : 'paging-arrow'
      : 'paging-arrow-right-disable'

  const onNext = () => {
    if (currentPage != lastPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage != 1) {
      onPageChange(currentPage - 1)
    }
  }

  const onFirstPage = () => {
    onPageChange(1)
  }

  const onLastPage = () => {
    onPageChange(lastPage)
  }
  return (
    <div style={{ width: '312px' }}>
      <ul className="paging-box d-flex m-0 p-2">
        <li className={`${classPrevious} paging-arrow`} onClick={onFirstPage}>
          <i className="ri-skip-back-line" />
        </li>
        <li className={`${classPrevious} paging-arrow`} onClick={onPrevious}>
          <i className="ri-arrow-left-s-line" />
        </li>
        <li className="paging-number-total px-3">
          <div className="paging-number-active">{currentPage}</div>
          <div className="paging-number-of ">of&nbsp;</div>
          <div className="paging-number-total-placeholder"> {lastPage}</div>
        </li>
        <li className={`${classNext} paging-arrow`} onClick={onNext}>
          <i className="ri-arrow-right-s-line" />
        </li>
        <li className={`${classNext} paging-arrow`} onClick={onLastPage}>
          <i className="ri-skip-forward-line" />
        </li>
      </ul>
    </div>
  )
}

export default Pagination2

export const recentMilestone = [
  { number: 'S0001000', activity: 'Send XML', date: '2022-06-28' },
  {
    number: 'S0003031',
    activity: 'All Container Release from All',
    date: '2022-06-22'
  },
  { number: 'S0002476', activity: 'Send XML', date: '2022-06-21' },
  { number: 'S0001000', activity: 'Custom Cleared', date: '2022-06-21' },
  {
    number: 'S0001000',
    activity: 'Departure from Last Port',
    date: '2022-06-21'
  }
]

export const recentActivity = [
  { number: 'S0001000', activity: 'Shipment', date: '2022-06-28' },
  { number: 'S0003021', activity: 'Shipment Detail', date: '2022-06-22' },
  { number: 'S0001343', activity: 'Booking Detail', date: '2022-06-21' },
  { number: 'Ethan Michael', activity: 'Create User', date: '2022-06-21' },
  { number: 'S0002332', activity: 'Create Booking', date: '2022-06-21' }
]

export const orderStatus = [
  { number: 'S0002434', activity: 'Shipment Complete', date: '2022-06-28' },
  { number: 'S0003661', activity: 'Booking Complete', date: '2022-06-22' },
  { number: 'S0007375', activity: 'Booking Complete', date: '2022-06-21' },
  { number: 'S0002445', activity: 'Shipment Complete', date: '2022-06-21' },
  { number: 'S0007285', activity: 'Booking Complete', date: '2022-06-21' }
]

export const optionsBar = {
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: {
      right: 80
    }
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 20
        }
      },
      grid: {
        display: false,
        drawBorder: false
      }
    },
    y: {
      ticks: {
        color: 'rgb(177, 180, 214)',
        font: {
          size: 16
        }
      },
      grid: {
        display: false,
        drawBorder: false
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
}

export const shipments = {
  labels: [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13'
  ],
  datasets: [
    {
      id: 1,
      label: 'ETD',
      data: [37, 34, 90, 15, 75, 25, 37, 28, 37, 78, 75, 25, 25],
      backgroundColor: '#828daf'
    },
    {
      id: 2,
      label: 'ETA',
      data: [50, 78, 50, 18, 80, 20, 90, 20, 10, 37, 92, 20, 20],
      backgroundColor: '#259ca7'
    }
  ]
}

export const totalShipmentsPlugins = {
  id: 'total_shipments_background_color',
  beforeDraw: chart => {
    const { ctx, chartArea } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#f6f5fc'
    ctx.fillRect(
      chartArea.left,
      0,
      chartArea.width + 80,
      chartArea.height + chartArea.top
    )
    ctx.restore()
  }
}

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      }
    },
    y: {
      grid: {
        display: false,
        drawBorder: false
      }
    }
  }
}

const labels = [
  'ONELOGMEL',
  'AERLGOISIC',
  'MICROGAMA',
  'TOYOMARK',
  'HUDOKORP',
  'GARUDALIN',
  'OPERHARD'
]

export const consigneeShipments = {
  labels,
  datasets: [
    {
      label: 'Dataset 2',
      data: [20, 30, 50, 15, 27, 38, 5],
      borderColor: 'transparent',
      backgroundColor: '#259ca7',
      borderRadius: 50,
      barThickness: 13
    }
  ]
}

export const completedShipmentsDashboard = [
  {
    company: 'ONELOGMEL',
    total: 7724
  },
  {
    company: 'AEROLOGILS',
    total: 4032
  },
  {
    company: 'NIKECORD',
    total: 3703
  },
  {
    company: 'HUDOKORPO',
    total: 3053
  },
  {
    company: 'GIANTHORDE',
    total: 2932
  },
  {
    company: 'TOKOPARTS',
    total: 2403
  },
  {
    company: 'ISKANMART',
    total: 1453
  },
  {
    company: 'MARTAINSE',
    total: 1453
  }
]

export const activeShipmentsDashboard = [
  {
    company: 'TOKYO',
    total: 7724
  },
  {
    company: 'OSAKA',
    total: 4032
  },
  {
    company: 'MELBOURNE',
    total: 3703
  },
  {
    company: 'SINGAPORE',
    total: 3053
  },
  {
    company: 'JAKARTA',
    total: 2932
  },
  {
    company: 'BANDUNG',
    total: 2403
  },
  {
    company: 'BANGKOK',
    total: 1453
  },
  {
    company: 'KALIMANTAN',
    total: 1453
  }
]

export const upcomingShipment = [
  {
    name: 'S00001032: Sydney (AUS) - Singapore (SIN)',
    days: 3
  },
  {
    name: 'S00001092: Tokyo (JPN) - Jakarta (IND)',
    days: 4
  },
  {
    name: 'S00001532: Melbourne (AUS) - Bangkok (THA)',
    days: 10
  },
  {
    name: 'S00001062: Sydney (AUS) - Melbourne (AUS)',
    days: 14
  },
  {
    name: 'S00001656: Singapore (SIN) - Indonesia (IND)',
    days: 20
  },
  {
    name: 'S00001092: London (UK) - Singapore (SIN)',
    days: 20
  },
  {
    name: 'S00001082: Melbourne (AUS) - Surabaya (IND)',
    days: 28
  },
  {
    name: 'S00001082: Melbourne (AUS) - Semarang (IND)',
    days: 30
  }
]

export const delayedShipment = [
  {
    name: 'S00001042: Osaka (JPN) - Germany (GEM)',
    days: 29
  },
  {
    name: 'S00001045: Tokyo (JPN) - Jakarta (IND)',
    days: 20
  },
  {
    name: 'S00001076: Singapore (SIN) - Indonesia (IND)',
    days: 16
  },
  {
    name: 'S00001534: Osaka (JPN) - Germany (GEM)',
    days: 10
  },
  {
    name: 'S00001574: London (UK) - Singapore (SIN)',
    days: 5
  },
  {
    name: 'S00001072: Melbourne (AUS) - Surabaya (IND)',
    days: 5
  },
  {
    name: 'S00001040: Melbourne (AUS) - Jakarta (IND)',
    days: 2
  },
  {
    name: 'S00001040: Melbourne (AUS) - Semarang (IND)',
    days: 1
  }
]

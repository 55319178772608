import React, { useState, Fragment, useEffect } from 'react'
import { Col, Row, FormGroup } from 'react-bootstrap'
import Checkbox from 'components/CheckBox'
import HButton from 'components/Bottom'
import Input from 'components/Input'
import {
  handleFieldChange,
  addRowTable,
  changeTableForm,
  deleteLineTable,
  validationAddRowTable
} from './service'

const RequestQuotation = ({
  quotationPage,
  isValidation,
  packageDatails,
  setPackageDatails,
  formData,
  setFormData,
  switchCategory,
  setSwitchCategory,
  validationRequest,
  setIsValidation,
  setContent
}) => {
  const [dimension, setDimension] = useState({
    height: window.innerHeight - 577
  })

  const { height } = dimension
  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 577
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    if (packageDatails?.length < 1) {
      addRowTable({
        state: packageDatails,
        setState: setPackageDatails
      })
    }
  }, [])

  return (
    <>
      <div className="title-quotation">Request Quotation</div>
      <Row>
        <Col md={6}>
          <FormGroup className="pe-3">
            <span>Email Address</span>
            <Input
              className={`form-control`}
              placeholder="Enter an Email Address"
              value={formData?.email}
              type="text"
              name="email"
              onChange={e =>
                handleFieldChange({
                  e: e,
                  column: 'email',
                  formData,
                  setFormData
                })
              }
              parentDivClassName={
                isValidation && !formData?.email ? 'input-danger' : ''
              }
            />
          </FormGroup>
          <FormGroup className="pe-3">
            <span>Origin Address</span>
            <Input
              className={`form-control`}
              placeholder="Enter an Origin Address"
              value={formData?.originAddress}
              type="text"
              name="originAddress"
              onChange={e =>
                handleFieldChange({
                  e: e,
                  column: 'originAddress',
                  formData,
                  setFormData
                })
              }
              parentDivClassName={
                isValidation && !formData?.originAddress ? 'input-danger' : ''
              }
            />
          </FormGroup>
          <div className="checkBox-request">
            <Checkbox
              name="Include Pick-Up"
              value={formData.checkboxPickup}
              onClick={() => {
                const newFormData = { ...formData }
                newFormData.checkboxPickup = !formData.checkboxPickup
                setFormData(newFormData)
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <FormGroup className="pe-3">
            <span>Contact Name</span>
            <Input
              className={`form-control`}
              placeholder="Enter an Email Address"
              value={formData?.contant}
              type="text"
              name="contant"
              onChange={e =>
                handleFieldChange({
                  e: e,
                  column: 'contant',
                  formData,
                  setFormData
                })
              }
              parentDivClassName={
                isValidation && !formData?.contant ? 'input-danger' : ''
              }
            />
          </FormGroup>
          <FormGroup className="pe-3">
            <span>Destination Address</span>
            <Input
              className={`form-control`}
              placeholder="Enter an Destination Address"
              value={formData?.destinationAddress}
              type="text"
              name="destinationAddress"
              onChange={e =>
                handleFieldChange({
                  e: e,
                  column: 'destinationAddress',
                  formData,
                  setFormData
                })
              }
              parentDivClassName={
                isValidation && !formData?.destinationAddress
                  ? 'input-danger'
                  : ''
              }
            />
          </FormGroup>
          <div className="checkBox-request">
            <Checkbox
              name="Include Delivery"
              value={formData.checkboxDelivery}
              onClick={() => {
                const newFormData = { ...formData }
                newFormData.checkboxDelivery = !formData.checkboxDelivery
                setFormData(newFormData)
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="quotation-detail" style={{ height: height + 130 }}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="title-quotation">Package Details</div>
          <div
            className="switch-category"
            onClick={() => setSwitchCategory(!switchCategory)}
          >
            <span className={switchCategory ? 'active' : ''}>Kg/cm</span>
            <span className={!switchCategory ? 'active' : ''}>lb/inch</span>
          </div>
        </div>
        <hr />
        <div className="overflow-auto" style={{ maxHeight: height }}>
          <table className={`table-${quotationPage}`}>
            <thead>
              <tr>
                <td>
                  <div className="c-50">No</div>
                </td>
                <td>
                  <div className="c-100">Quantity</div>
                </td>
                <td>
                  <div className="c-150">Length</div>
                </td>
                <td>
                  <div className="c-30" />
                </td>
                <td>
                  <div className="c-150">Width</div>
                </td>
                <td>
                  <div className="c-30" />
                </td>
                <td>
                  <div className="c-150">Height</div>
                </td>
                <td>
                  <div className="c-150">Weight Per Piece</div>
                </td>
              </tr>
            </thead>
            <tbody>
              {packageDatails.map((item, index) => {
                return (
                  <Fragment key={`container-${index}`}>
                    <tr key={`container-key-${index}`}>
                      <td>
                        <div className="c-50">
                          <div
                            className="icon-delete"
                            onClick={() => {
                              if (packageDatails.length > 1) {
                                deleteLineTable({
                                  state: packageDatails,
                                  setState: setPackageDatails,
                                  index
                                })
                              }
                            }}
                          >
                            <span>{index + 1}</span>
                            <i className="ri-close-line" />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="c-100">
                          <Input
                            type="number"
                            min={0}
                            placeholder="Enter Quantity"
                            value={item?.quantity}
                            onChange={e =>
                              changeTableForm({
                                switchCategory,
                                item: item,
                                val: e.target.value,
                                column: 'quantity',
                                index,
                                state: packageDatails,
                                setState: setPackageDatails
                              })
                            }
                            parentDivClassName={
                              isValidation && !item?.quantity
                                ? 'input-danger'
                                : ''
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="c-150 ">
                          <Input
                            type="number"
                            rightText={switchCategory ? 'cm' : 'inch'}
                            min={0}
                            placeholder="Enter Lenght"
                            value={item.lenght}
                            onChange={e =>
                              changeTableForm({
                                switchCategory,
                                item: item,
                                val: e.target.value,
                                column: 'lenght',
                                index,
                                state: packageDatails,
                                setState: setPackageDatails
                              })
                            }
                            className={
                              isValidation && !item?.lenght
                                ? 'input-danger-text'
                                : ''
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="c-30 d-flex justify-content-center">
                          <i className="ri-close-line" />
                        </div>
                      </td>
                      <td>
                        <div className="c-150">
                          <Input
                            type="number"
                            rightText={switchCategory ? 'cm' : 'inch'}
                            min={0}
                            placeholder="Enter Widht"
                            value={item.widht}
                            onChange={e =>
                              changeTableForm({
                                switchCategory,
                                item: item,
                                val: e.target.value,
                                column: 'widht',
                                index,
                                state: packageDatails,
                                setState: setPackageDatails
                              })
                            }
                            className={
                              isValidation && !item?.widht
                                ? 'input-danger-text'
                                : ''
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="c-30 d-flex justify-content-center">
                          <i className="ri-close-line" />
                        </div>
                      </td>
                      <td>
                        <div className="c-150">
                          <Input
                            type="number"
                            rightText={switchCategory ? 'cm' : 'inch'}
                            min={0}
                            placeholder="Enter Height"
                            value={item.height}
                            onChange={e =>
                              changeTableForm({
                                switchCategory,
                                item: item,
                                val: e.target.value,
                                column: 'height',
                                index,
                                state: packageDatails,
                                setState: setPackageDatails
                              })
                            }
                            className={
                              isValidation && !item?.height
                                ? 'input-danger-text'
                                : ''
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="c-198">
                          <Input
                            type="number"
                            rightText={switchCategory ? 'kg' : 'lb'}
                            min={0}
                            readOnly
                            placeholder="Enter Weight"
                            value={item.weight}
                            onChange={e =>
                              changeTableForm({
                                switchCategory,
                                item: item,
                                val: e.target.value,
                                column: 'weight',
                                index,
                                state: packageDatails,
                                setState: setPackageDatails
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
        <HButton
          icon="ri-add-fill"
          text={'Add another package'}
          joinIcon
          onClick={async () => {
            setIsValidation(true)
            const validate = await validationAddRowTable({
              packageDatails,
              setPackageDatails
            })
            if (validate) {
              addRowTable({
                state: packageDatails,
                setState: setPackageDatails
              })
            }
          }}
          className="w-100 mt-2"
        />
      </div>
      <div className="my-3 btn-request-quotation">
        <HButton
          icon="ri-add-fill"
          text={'Back'}
          onClick={() => setContent('login')}
        />
        <HButton
          icon="ri-add-fill"
          onClick={() => validationRequest()}
          // disabledStatus
          text={'GET QUOTES'}
        />
      </div>
    </>
  )
}

export default RequestQuotation

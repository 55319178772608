import React, { createContext, useState } from 'react'
import formJSON from '../../src/page/Bookings/Create/formBooking.json'

const FormContext = createContext({})

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(formJSON)

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  )
}

export default FormContext

import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import TemplateLocation from './TemplateLocation'
import Dropdown from './Dropdown'
import { dataSettingDashboard, arrLocation } from '../../service'

const MappingDashboard = ({ t }) => {
  const [activeLocation, setActiveLocation] = useState(0) // -1 : none of location selected
  const [arrTemplate, setArrTemplate] = useState(arrLocation)

  const handleClick = number => {
    setActiveLocation(number)
  }
  const updateLocationTemplate = (number, acc, val) => {
    const index = arrTemplate.findIndex(object => {
      if (object.id === number) {
        return object.id === number
      }
      return object
    })
    if (index !== -1) {
      arrTemplate[index].accessor = acc
      arrTemplate[index].label = val
    }
    setArrTemplate(arrTemplate)
  }

  return (
    <>
      <div className="mapping-dashboard">
        <Row>
          <Col>
            <TemplateLocation
              number={arrTemplate[0]?.id}
              type={arrTemplate[0]?.type}
              onSelect={handleClick}
              activeIdx={activeLocation}
            />
          </Col>
          <Col>
            <TemplateLocation
              number={arrTemplate[2].id}
              type={arrTemplate[2].type}
              onSelect={handleClick}
              activeIdx={activeLocation}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TemplateLocation
              number={arrTemplate[1].id}
              type={arrTemplate[1].type}
              onSelect={handleClick}
              activeIdx={activeLocation}
            />
          </Col>
          <Col>
            <TemplateLocation
              number={arrTemplate[3].id}
              type={arrTemplate[3].type}
              onSelect={handleClick}
              activeIdx={activeLocation}
            />
          </Col>
        </Row>
      </div>
      {arrTemplate.map((value, idx) => {
        return (
          <div className="mb-2" key={value.id}>
            <span className="title-gray mb-0 text-capitalize">
              {`${t('Dashboard')} ${idx + 1}`}
            </span>
            <Dropdown
              number={value.id}
              dataDropdown={dataSettingDashboard}
              t={t}
              defaultVal={value.label}
              activeIdx={activeLocation}
              onSelect={handleClick}
              onChangeTemplate={updateLocationTemplate}
            />
          </div>
        )
      })}
    </>
  )
}

export default MappingDashboard

import React from 'react'
import PickUpAndDeliverForm from '../PickUpAndDeliverForm'
import PickupAddressAndAdditionalInformation from '../PickupAddressAndAdditionalInformation'
import ModeAndMessageForm from '../ModeAndMessageForm'
import FormTable from 'components/FormTable'
import {
  goodsPackHeader,
  containerHeader,
  attachedHeader,
  referenceHeader
} from '../HeadersAndOptions/headers'

// ----- CHOOSE FORM ------- //
export const FormsHandler = ({ accessor, title, t }) => {
  // accessor is a key from the tab

  switch (accessor) {
    case 'company':
      return <PickUpAndDeliverForm t={t} />
    case 'details':
      return (
        <>
          <ModeAndMessageForm t={t} />
          <PickupAddressAndAdditionalInformation t={t} />
        </>
      )
    case 'goodsPacksList':
      return (
        <FormTable
          header={goodsPackHeader}
          module={accessor}
          title="Goods/Packs"
          t={t}
        />
      )
    case 'attachedOrderList':
      return (
        <FormTable
          header={attachedHeader}
          module={accessor}
          title={title}
          t={t}
        />
      )
    case 'containerList':
      return (
        <FormTable
          header={containerHeader}
          module={accessor}
          title={title}
          t={t}
        />
      )
    case 'referenceList':
      return (
        <FormTable
          header={referenceHeader}
          module={accessor}
          title={title}
          t={t}
        />
      )
  }
}

export default FormsHandler

import React from 'react'
import ConfirmMailContent from 'components/authentication/ConfirmMailContent'

const ConfirmMail = () => (
  <div className="text-center">
    <ConfirmMailContent email="xyz@abc.com" />
  </div>
)

export default ConfirmMail

import React from 'react'
import loadingRipple3 from 'assets/gif/Loading_Logistical_LightGray.gif'

const LoadingAnimation = ({ type }) => {
  return (
    <>
      <div
        role="status"
        className={`${
          type === 'user-detail' ? 'text-center' : ''
        }loading-container`}
      >
        <img
          className={`${
            type === 'user-detail' ? 'loading-animation-2' : 'loading-animation'
          }`}
          src={loadingRipple3}
          alt="loading-ripple"
        />
      </div>
    </>
  )
}

export default LoadingAnimation

import moment from 'moment'

export const checkUserDetail = userDetail => {
  return userDetail ?? null
}

export const getStringVal2 = (preview, value1, value2) => {
  const stringval = preview ? value1 : value2
  if (stringval != null && stringval != undefined) {
    if (stringval === '') {
      return '-'
    } else {
      return stringval
    }
  } else {
    return '-'
  }
}

export const getStringVal = stringval => {
  if (stringval != null && stringval != undefined) {
    if (stringval === '') {
      return '-'
    } else {
      return stringval
    }
  } else {
    return '-'
  }
}

export const getDateVal2 = (preview, value1, value2) => {
  const dateVal = preview ? value1 : value2
  if (dateVal != null && dateVal != undefined) {
    if (dateVal === '') {
      return '-'
    } else {
      const date = moment(dateVal ?? '-')
        .format('L')
        .substring(3, 5)
      const month = moment(dateVal ?? '-')
        .format('L')
        .substring(0, 2)
      const year = moment(dateVal ?? '-')
        .format('L')
        .substring(6, 10)
      return year + '/' + month + '/' + date
    }
  } else {
    return '-'
  }
}

export const getDateVal = dateVal => {
  if (dateVal != null && dateVal != undefined) {
    if (dateVal === '') {
      return '-'
    } else {
      const date = moment(dateVal ?? '-')
        .format('L')
        .substring(3, 5)
      const month = moment(dateVal ?? '-')
        .format('L')
        .substring(0, 2)
      const year = moment(dateVal ?? '-')
        .format('L')
        .substring(6, 10)
      return year + '/' + month + '/' + date
    }
  } else {
    return '-'
  }
}

export const getHeightDimension = (preview, innerWidth, innerHeight) => {
  if (preview === true) {
    if (innerWidth < 768) {
      return innerHeight - 350
    } else {
      return innerHeight - 250
    }
  } else {
    if (innerWidth < 768) {
      return innerHeight - 310
    } else {
      return innerHeight - 200
    }
  }
}

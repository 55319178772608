import React, { useState } from 'react'
import LoginForm from './LoginForm'
import AuthCardLayout from 'layouts/AuthCardLayout'
import Logo from 'assets/img/logos/ControlGlobal.png'

const Login = ({ navigate }) => {
  const [content, setContent] = useState('login')
  return (
    <AuthCardLayout content={content} setContent={setContent}>
      <img
        className="icon-login"
        onClick={() => {
          setContent('login')
        }}
        src={Logo}
        alt="Logo Logistical"
      />
      <LoginForm
        navigate={navigate}
        content={content}
        setContent={setContent}
      />
    </AuthCardLayout>
  )
}

export default Login

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const NavbarSidebarItem = ({ navItem, mobileNavHandling }) => {
  const { t } = useTranslation()
  const { name, to, icon, visible } = navItem
  const isNoLink = !!to
  const classIcon = icon ?? null

  if (!visible) return <></>
  return (
    <li>
      <NavLink
        className={({ isActive }) => {
          if (!isNoLink) return ''
          return isActive ? 'active' : ''
        }}
        to={to}
        onClick={() => {
          mobileNavHandling()
        }}
      >
        {!classIcon ? <></> : <i className={`${classIcon}`} />}
        <span className="links_name">{t(name)}</span>
      </NavLink>
      <span className="tooltip">{t(name)}</span>
    </li>
  )
}

export default NavbarSidebarItem

import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import NavbarSidebarItem from './NavbarSidebarItem'

const NavbarSidebarGroupItem = ({
  nav,
  children,
  expandNavHandling,
  isNavCollapsed,
  mobileNavHandling
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { name, icon, visible } = nav
  const [open, setOpen] = useState(false)
  const [isChildrenActive, setIsChildrenActive] = useState(false)
  const classGroup = open ? 'active' : ''

  useEffect(() => {
    if (isNavCollapsed) {
      setOpen(false)
    } else {
      if (isChildrenActive) {
        setOpen(true)
      }
    }
  }, [isNavCollapsed])

  useEffect(() => {
    setIsChildrenActive(false)
    const activeChildren = children.find(value => {
      if (value.to == location.pathname) {
        setIsChildrenActive(true)
        setOpen(true)
        return value
      }
    })
    if (!activeChildren) setOpen(false)
  }, [location.pathname])

  if (!visible) return <></>
  return (
    <div className={`sidebar-group ${classGroup}`}>
      <li className={open ? 'divider' : ''}>
        <div
          className={isNavCollapsed && isChildrenActive ? 'active' : ''}
          onClick={() => {
            const stats = !open
            const isOpen = stats == true
            if (isOpen) expandNavHandling()
            setOpen(!open)
          }}
        >
          <i className={`${icon}`} />
          <span className="links_name">{t(name)}</span>
        </div>
        <span className="tooltip">{t(name)}</span>
      </li>
      <Collapse in={open}>
        <div>
          {children.map((item, index) => {
            return (
              <NavbarSidebarItem
                key={'nav-item' + index}
                navItem={item}
                index={index}
                mobileNavHandling={mobileNavHandling}
              />
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}

export default NavbarSidebarGroupItem

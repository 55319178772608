import HButton from 'components/Bottom'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

const QuotationResult = ({
  quotationPage,
  formData,
  switchCategory,
  changeContent
}) => {
  const [dimension, setDimension] = useState({
    height: window.innerHeight - 395
  })

  const { height } = dimension
  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 395
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <>
      <div className="title-quotation">Quotation Result</div>
      <div className="top-content">
        <Row>
          <Col md={8}>
            <Row>
              <Col md={5}>
                <div className="title-quotation-item">Origin</div>
                <span>{formData.originAddress}</span>
                {formData.checkboxPickup ? <p>With pickup</p> : ''}
              </Col>
              <Col md={4}>
                <div className="title-quotation-item">Total Volume</div>
                <span>{formData.totalVolume}</span>
                <p>m3</p>
              </Col>
              <Col md={5}>
                <div className="title-quotation-item">Destination</div>
                <span>{formData.destinationAddress}</span>
                {formData.checkboxDelivery ? <p>With delivery</p> : ''}
              </Col>
              <Col md={4}>
                <div className="title-quotation-item">Total Weight</div>
                <span>{formData.totalWeight}</span>
                <p>{switchCategory ? 'kg' : 'lb'}</p>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="icon-content">
              <i className="ri-share-box-line" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="title-quotation">Choose Product</div>
      <div className="overflow-auto" style={{ height: height }}>
        <Row className={`title-table-${quotationPage}`}>
          <Col md={1}>Mode</Col>
          <Col md={4}>Product</Col>
          <Col md={3}>Estimated Transit Time</Col>
          <Col md={2}>CO2e Emission</Col>
          <Col md={2}>Transit Cost</Col>
        </Row>
        <Row
          className={`content-table-${quotationPage}`}
          onClick={() => changeContent('review')}
        >
          <Col className="content-item" md={1}>
            <div className="icon-content-table">
              <i className="ri-ship-2-fill" />
            </div>
          </Col>
          <Col className="content-item" md={4}>
            <div className="vertical-line-header" />
            <div className="product-item">
              <span>KN LCL</span>
              <p>Sea Freight transportation in a consolidation container</p>
            </div>
          </Col>
          <Col className="content-item" md={3}>
            <div className="vertical-line-header" />
            <span>45 Days</span>
          </Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <span>0.092t</span>
          </Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <span>1,157,35EUR</span>
          </Col>
        </Row>
        <Row
          className={`content-table-${quotationPage}`}
          onClick={() => changeContent('review')}
        >
          <Col className="content-item" md={1}>
            <div className="icon-content-table">
              <i className="ri-ship-2-fill" />
            </div>
          </Col>
          <Col className="content-item" md={4}>
            <div className="vertical-line-header" />
            <div className="product-item">
              <span>KN LCL</span>
              <p>Sea Freight transportation in a consolidation container</p>
            </div>
          </Col>
          <Col className="content-item" md={3}>
            <div className="vertical-line-header" />
            <span>20 Days</span>
          </Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <span>0.092t</span>
          </Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <span>1,157,35EUR</span>
          </Col>
        </Row>
        <Row
          className={`content-table-${quotationPage}`}
          onClick={() => changeContent('review')}
        >
          <Col className="content-item" md={1}>
            <div className="icon-content-table">
              <i className="ri-flight-takeoff-line" />
            </div>
          </Col>
          <Col className="content-item" md={7}>
            <div className="vertical-line-header" />
            <div className="product-solo-item">
              Sea Freight transportation in a consolidation container
            </div>
          </Col>
          <Col className="content-item" md={1}></Col>
          <Col className="content-item" md={1}></Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <HButton
              icon="ri-add-fill"
              // disabledStatus
              text={'Contact us'}
            />
          </Col>
        </Row>
        <Row
          className={`content-table-${quotationPage}`}
          onClick={() => changeContent('review')}
        >
          <Col className="content-item" md={1}>
            <div className="icon-content-table">
              <i className="ri-train-fill" />
            </div>
          </Col>
          <Col className="content-item" md={7}>
            <div className="vertical-line-header" />
            <div className="product-solo-item">
              Sea Freight transportation in a consolidation container
            </div>
          </Col>
          <Col className="content-item" md={1}></Col>
          <Col className="content-item" md={1}></Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <HButton
              icon="ri-add-fill"
              // disabledStatus
              text={'Contact us'}
            />
          </Col>
        </Row>
        <Row
          className={`content-table-${quotationPage}`}
          onClick={() => changeContent('review')}
        >
          <Col className="content-item" md={1}>
            <div className="icon-content-table">
              <i className="ri-truck-fill" />
            </div>
          </Col>
          <Col className="content-item" md={7}>
            <div className="vertical-line-header" />
            <div className="product-solo-item">
              KN LCLSea Freight transportation in a consolidation container
            </div>
          </Col>
          <Col className="content-item" md={1}></Col>
          <Col className="content-item" md={1}></Col>
          <Col className="content-item" md={2}>
            <div className="vertical-line-header" />
            <HButton
              icon="ri-add-fill"
              // disabledStatus
              text={'Contact us'}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default QuotationResult

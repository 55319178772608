import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { updateShipmentStatus } from 'ApiService'
import { Button } from 'reactstrap'
import { Placeholder } from 'react-bootstrap'

const FlagShipment = ({ statusId, jobId, module }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentColor, setCurrentColor] = useState(null)
  const [currentName, setCurrentName] = useState(null)
  const [currentId, setCurrentId] = useState(statusId)
  const dataColors = useSelector(state => state.spStatusData)

  useEffect(() => {
    if (dataColors != null && dataColors?.length > 0) {
      const statusIdList = dataColors.map(items => items.id)
      const currentIndex = statusIdList.indexOf(currentId)
      if (currentIndex !== -1) {
        setCurrentId(dataColors[currentIndex]?.id)
        setCurrentName(dataColors[currentIndex]?.name)
        setCurrentColor(dataColors[currentIndex]?.hexcode)
      } else {
        setCurrentId(null)
        setCurrentName(null)
        setCurrentColor(null)
      }
    }
  }, [dataColors, currentId])

  const HandleClickChangeStatus = async () => {
    setIsLoading(true)
    let statusIndex = 0
    if (dataColors) {
      const statusIdList = dataColors.map(item => item.id)
      const currentIndex = statusIdList.indexOf(currentId)
      switch (true) {
        case currentIndex === -1:
          statusIndex = 0
          break
        case currentIndex + 1 < statusIdList.length:
          statusIndex = currentIndex + 1
          break
        default:
          statusIndex = -1
          break
      }
    }
    if (statusIndex > -1) {
      const changed = await updateShipmentStatus({
        jobNo: jobId,
        id: dataColors[statusIndex].id,
        module: module
      })
      if (changed === true) {
        setCurrentId(dataColors[statusIndex].id)
        setCurrentColor(dataColors[statusIndex].hexcode)
        setCurrentName(dataColors[statusIndex].name)
      }
      setIsLoading(false)
      return
    }
    const changed = await updateShipmentStatus({
      jobNo: jobId,
      id: null,
      module: module
    })
    if (changed === true) {
      setCurrentId(null)
      setCurrentColor(null)
      setCurrentName(null)
    }
    setIsLoading(false)
    return
  }

  if (isLoading) {
    return (
      <Placeholder animation="glow" onClick={e => e.stopPropagation()}>
        <Placeholder className="flag-shipment-placeholder" />
      </Placeholder>
    )
  }

  return (
    <div key={statusId} className="d-flex " onClick={e => e.stopPropagation()}>
      <Button
        id="caret"
        onClick={HandleClickChangeStatus}
        className="flag-button"
        style={{
          backgroundColor: currentColor ?? '#cfd2fc'
        }}
      >
        {currentName ? (
          currentName.length < 11 ? (
            currentName
          ) : (
            <>{currentName.slice(0, 9)}..</>
          )
        ) : (
          'No status'
        )}
      </Button>
    </div>
  )
}

export default FlagShipment

import React from 'react'
import { Row } from 'react-bootstrap'

const DashboardInfoBox = ({
  children,
  height,
  color1,
  color2,
  colorWidth1,
  colorWidth2,
  radius,
  borderColor,
  borderWidth,
  marginBottom
}) => {
  const dataStyle = {
    height: height,
    border: `solid ${borderColor} ${borderWidth}`,
    backgroundImage: `linear-gradient(90deg, ${color1} ${
      colorWidth1 ?? '100%'
    }, ${color2 ?? '#fff'} ${colorWidth2 ?? '0%'})`,
    borderRadius: radius ?? '4px',
    marginBottom: marginBottom ?? '4px'
  }
  return <Row style={dataStyle}>{children}</Row>
}

export default DashboardInfoBox

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const OrgDetail = ({
  showOrgDetail,
  setShowOrgDetail,
  orgDetail,
  orgDetailLoading
}) => {
  const [companyName, setCompanyName] = useState()
  const [companyAddress, setCompanyAddress] = useState()
  const [companyCity, setCompanyCity] = useState()
  const [companyState, setCompanyState] = useState()
  const [companyCountry, setCompanyCountry] = useState()
  const [companyPhone, setCompanyPhone] = useState()
  const [companyPostCode, setCompanyPostCode] = useState()
  const [companyEmail, setCompanyEmail] = useState()
  const [companyWebsite, setCompanyWebsite] = useState()

  const { t } = useTranslation()

  return (
    <>
      <Modal
        show={showOrgDetail}
        onHide={() => setShowOrgDetail(false)}
        dialogClassName="shipment-filter fade-down"
        centered={false}
        fullscreen={true}
        className="d-flex flex-row-reverse modal right"
      >
        <Modal.Header className="filter-column-header-shipment">
          <div className="filter-column-headerPort-title-shipment text-capitalize">
            {t('organisation detail')}
          </div>
          <i
            className="ri-arrow-right-s-line filter-column-headerPort-icon-shipment cursor-pointer"
            onClick={() => setShowOrgDetail(false)}
          />
        </Modal.Header>
        <Modal.Body className="font-weight-light">
          {orgDetailLoading && <div>loading</div>}
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('organisation code')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Organisation code unavailable')}
                value={orgDetail?.id}
                type="text"
                disabled
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('company name')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company name')}
                value={orgDetail?.name ?? companyName}
                onChange={e => setCompanyName(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('address')}
            </span>
            <div className="w-100 d-flex">
              <textarea
                className="setting-input mb-1"
                placeholder={t('Enter company address')}
                value={orgDetail?.address ?? companyAddress}
                onChange={e => setCompanyAddress(e.target.value)}
                type="text/area"
                style={{ minHeight: '5rem', overflowY: 'auto', resize: 'none' }}
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('country')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company country')}
                value={companyCountry}
                onChange={e => setCompanyCountry(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('state')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company state')}
                value={companyState}
                onChange={e => setCompanyState(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">{t('city')}</span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company city')}
                value={companyCity}
                onChange={e => setCompanyCity(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('post code')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company post code')}
                value={companyPostCode}
                onChange={e => setCompanyPostCode(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('phone')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company phone number')}
                value={companyPhone}
                onChange={e => setCompanyPhone(e.target.value)}
                type="tel"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('email')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter company email address')}
                value={companyEmail}
                onChange={e => setCompanyEmail(e.target.value)}
                type="email"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('website')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter website address')}
                value={companyWebsite}
                onChange={e => setCompanyWebsite(e.target.value)}
                type="text"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="footer-button btn-hover">
            {t('Update Details')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OrgDetail

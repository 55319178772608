export const clickCheckIcon = ({
  moduleComponent,
  setExportAll,
  setShowToast,
  setConfirmExport,
  resetSchema,
  state,
  setModalTable,
  action,
  createUser,
  createForm,
  navigate,
  updateUser,
  id,
  saveSchema,
  module,
  newSchema,
  dispatch,
  schemaChanged,
  setReload
}) => {
  switch (moduleComponent) {
    case 'Shipments':
    case 'User Management':
      setExportAll(false)
      setShowToast(true)
      setConfirmExport(true)
      break
    default:
      switch (moduleComponent) {
        case 'ResetModalEditColumn':
          resetSchema({
            module,
            state
          })

          break
        case 'ExitModalEditColumn':
          setModalTable(false)
          break
        case 'SaveModalEditColumn':
          saveSchema({
            module,
            newSchema,
            dispatch,
            schemaChanged,
            setReload
          })
          // setModalTable(false);
          break
        case 'CreateEditUser':
          if (action === 'create') {
            createUser({
              formData: createForm,
              navigate
            })
          } else {
            updateUser({
              formData: createForm,
              navigate,
              id
            })
          }
          break
        case 'Logout':
          localStorage.removeItem('persist:root')
          navigate('/login')
          break
      }
      setShowToast(false)
  }
}

export const clickCloseIcon = ({
  moduleComponent,
  setExportAll,
  setConfirmExport,
  setShowToast
}) => {
  if (moduleComponent == 'Shipments' || moduleComponent == 'User Management') {
    setExportAll(true)
    setConfirmExport(true)
  } else {
    setShowToast(false)
  }
}

import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const Dropdown = ({
  dataDropdown,
  t,
  defaultVal,
  activeIdx,
  number,
  onSelect,
  onChangeTemplate
  // onSelect
}) => {
  const [isOpen, setIsOpen] = useState()
  const [selectedAcc, setSelectedAcc] = useState('')
  const [selectedLabel, setSelectedLabel] = useState('')
  const dataDropdownTranslate = dataDropdown.map(data => {
    return { label: t(data.label), accessor: data.accessor }
  })
  useEffect(() => {
    setIsOpen(activeIdx === number ? true : false)
  }, [activeIdx])
  useEffect(() => {
    onChangeTemplate(number, selectedAcc, selectedLabel)
  }, [selectedAcc])

  const handleSelected = selectedOption => {
    setSelectedAcc(selectedOption.accessor)
    setSelectedLabel(selectedOption.label)
  }

  const styles = {
    option: provided => ({
      ...provided,
      textAlign: 'left'
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      display: 'flex'
    }),
    control: provided => ({
      ...provided,
      backgroundColor: 'white',
      pointerEvents: 'auto',
      border: '1px solid #EEEEF9',
      borderRadius: '5px',
      height: 40,
      minHeight: 40,
      boxShadow: 'none',
      fontSize: '12px'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: 14
    })
  }

  return (
    <>
      <Select
        options={dataDropdownTranslate}
        menuIsOpen={isOpen}
        onMenuOpen={() => {
          setIsOpen(true)
          onSelect(number)
        }}
        onMenuClose={() => {
          setIsOpen(false)
          onSelect(0)
        }}
        placeholder={t(`${defaultVal}`)}
        className="create-input"
        maxMenuHeight={200}
        styles={styles}
        theme={theme => ({
          ...theme,
          borderRadius: 4
        })}
        components={{
          IndicatorSeparator: () => null
        }}
        onChange={handleSelected}
      />
    </>
  )
}

export default Dropdown

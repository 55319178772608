import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import Lottie from 'react-lottie'
import animationData from './lottie/celebration.json'
import { AuthWizardContext } from 'context/Context'

const Success = ({ reset }) => {
  const { setStep, setUser } = useContext(AuthWizardContext)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const emptyData = () => {
    setStep(1)
    setUser({})
    reset()
  }

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-0">Now you can access to your account</p>
          <Button color="primary" className="px-5 my-3" onClick={emptyData}>
            Start Over
          </Button>
        </Col>
      </Row>
    </>
  )
}

Success.propTypes = {
  reset: PropTypes.func.isRequired
}

export default Success

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AppContext from 'context/Context'
import HButton from 'components/Bottom'
import { headersTab } from './HeadersAndOptions/headers'
// import { useNavigate } from 'react-router-dom'
import { createBooking } from 'ApiService'

// translation
import { useTranslation } from 'react-i18next'
import FormsHandler from './Handler/FormsHandler'
import useFormContext from 'hooks/useFormContext'

const BookingsCreate = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    config: { isNavbarVerticalCollapsed }
  } = useContext(AppContext)

  const { formData } = useFormContext()

  const [activeTab, setActiveTab] = useState('company')

  const handleSubmit = e => {
    e.preventDefault()
  }

  const onCreateBookings = async () => {
    const isCreate = await createBooking({ formData: formData })
    if (isCreate === true) {
      // navigate('/bookings')
    }
  }

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      moduleName: t('Create Booking')
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  return (
    <div
      className={`${isNavbarVerticalCollapsed == true ? 'search-burger' : ''}`}
    >
      <form className="create-booking detail-tab" onSubmit={handleSubmit}>
        <ul className="nav nav-tabs d-flex justify-content-between">
          <div className="d-flex flex-row header-tabs">
            {headersTab.map((item, index) => {
              const { accessor, title } = item
              return (
                <li className="nav-item" key={index} title={title}>
                  <a
                    className={`nav-link ${
                      accessor === activeTab && 'active'
                    } `}
                    aria-current="page"
                    href={`#${accessor}`}
                    onClick={() => setActiveTab(accessor)}
                  >
                    {title}
                  </a>
                </li>
              )
            })}
          </div>
          <div className="d-flex gap-2 header-actions">
            <HButton
              key={`button_cancel`}
              text="Cancel"
              useDefaultStyle={false}
              className={`custom-button custom-button2 right-btn`}
              size="md"
              onClick={() => {}}
              module="create_booking_cancel"
            />
            <HButton
              key={`button_save`}
              text="Save"
              useDefaultStyle={false}
              className={`custom-button custom-button2 right-btn`}
              size="md"
              onClick={() => {
                onCreateBookings()
              }}
              module="create_booking_save"
            />
          </div>
        </ul>

        {/* Render Component */}
        <div className="tab-content clearfix">
          {headersTab.map((header, index) => {
            const { accessor, title } = header
            return (
              <div
                key={index}
                className={`tab-pane ${accessor === activeTab && 'active'}`}
              >
                <FormsHandler
                  accessor={accessor}
                  t={t}
                  title={title}
                  id={accessor}
                />
              </div>
            )
          })}
        </div>
      </form>
    </div>
  )
}

export default BookingsCreate

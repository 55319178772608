import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { renderValue } from './service'
import LoadingAnimation from 'components/LoadingAnimation'
import { useTranslation } from 'react-i18next'

const ListOfInformation = ({ header, data, loading, list }) => {
  const { t } = useTranslation()

  return (
    <div className={'list-information'}>
      {loading ? (
        <LoadingAnimation />
      ) : data?.length === 0 ? (
        <div className="noData">{t('No Record Found')}</div>
      ) : (
        header?.map((id, index1) => {
          return (
            <Col key={index1} className={`information ${list}`}>
              {id.map((item, index2) => {
                const { accessor, header } = item
                return (
                  <Row key={`${index1}-${index2}`}>
                    <p className="header">{t(header)}</p>
                    <p className="value">
                      {renderValue(accessor, data?.[accessor])}
                    </p>
                  </Row>
                )
              })}
            </Col>
          )
        })
      )}
    </div>
  )
}

export default ListOfInformation

export const ExportName = filename => {
  const arrmonth = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const date = new Date()
  const date1 = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const Seconds = date.getSeconds()
  const Minutes = date.getMinutes()
  const Hours = date.getHours()
  return `${filename + date1}-${
    arrmonth[month]
  }-${year}.${Hours}-${Minutes}-${Seconds}`
}

export const checkSchema = ({ schemaColumn, module, setNewSchema }) => {
  const key = `tables__${module}`
  const schema = localStorage.getItem(key) || null
  const newSchema = JSON.parse(schema)
  let schemaExported = []
  if (newSchema != null) {
    for (let i = 0; i < newSchema.length; ++i) {
      const idx = schemaColumn.findIndex(e => e.accessor === newSchema[i])
      if (idx != -1) {
        schemaExported = [...schemaExported, schemaColumn[idx]]
      }
    }
  }
  setNewSchema(schemaExported)
}

import HButton from 'components/Bottom'
import Checkbox from 'components/CheckBox'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

const ReviewQuote = ({
  quotationPage,
  formData,
  switchCategory,
  changeContent
}) => {
  const [dimension, setDimension] = useState({
    height: window.innerHeight - 462
  })

  const [checkBox, setCheckBox] = useState({
    option1: false,
    option2: false,
    special1: false,
    special2: false
  })

  const { height } = dimension
  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 462
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <>
      <div className="title-quotation">Review Quote</div>
      <div className="top-content">
        <Row>
          <Col md={8}>
            <Row>
              <Col md={5}>
                <div className="title-quotation-item">Origin</div>
                <span>{formData.originAddress}</span>
                {formData.checkboxPickup ? <p>With pickup</p> : ''}
              </Col>
              <Col md={4}>
                <div className="title-quotation-item">Total Volume</div>
                <span>{formData.totalVolume}</span>
                <p>m3</p>
              </Col>
              <Col md={3}>
                <div className="title-quotation-item">To</div>
                <span>62374, US</span>
                <p>with consignee delivery</p>
              </Col>
              <Col md={5}>
                <div className="title-quotation-item">Destination</div>
                <span>{formData.destinationAddress}</span>
                {formData.checkboxDelivery ? <p>With delivery</p> : ''}
              </Col>
              <Col md={4}>
                <div className="title-quotation-item">Total Weight</div>
                <span>{formData.totalWeight}</span>
                <p>{switchCategory ? 'kg' : 'lb'}</p>
              </Col>
              <Col md={3}>
                <div className="title-quotation-item">Incognito</div>
                <span>DAP</span>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="icon-content">
              <i className="ri-share-box-line" />
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{ height: height }}
        className={`content-table-${quotationPage} my-3`}
      >
        <div className="p-1">
          <Row>
            <Col className="content-item" md={1}>
              <div className="icon-content-table">
                <i className="ri-ship-2-fill" />
              </div>
            </Col>
            <Col className="content-item" md={3}>
              <div className="vertical-line-header" />
              <div className="product-item">
                <span>KN LCL</span>
                <p>Sea Freight transportation in a consolidation container</p>
              </div>
            </Col>
            <Col className="content-item" md={2}>
              <div className="item-data">
                <div className="title-item">Estimated Transit Time</div>
                <span>45 Days</span>
              </div>
            </Col>
            <Col className="content-item" md={2}>
              <div className="vertical-line-header" />
              <div className="item-data">
                <div className="title-item">Departure frequency</div>
                <span>Weekly</span>
              </div>
            </Col>
            <Col className="content-item" md={2}>
              <div className="vertical-line-header" />
              <div className="item-data">
                <div className="title-item">Carbon Netural</div>
                <i className="ri-check-line" />
              </div>
            </Col>
            <Col className="content-item" md={2}>
              <div className="vertical-line-header" />
              <div className="item-data">
                <div className="title-item">Your Rate</div>
                <span>1,157,35 EUR</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`content-option-${quotationPage}`}>
          <span className={`title-option-${quotationPage}`}>
            Choose optional
          </span>
          <Row>
            <Col lg={8}>
              <Checkbox
                className={`checkbox-${quotationPage}`}
                name="Customs Clearance Charge Import (122.12 EUR)"
                value={checkBox.option1}
                onClick={() => {
                  console.log('halo')
                  const newCheckBox = { ...checkBox }
                  newCheckBox.option1 = !checkBox.option1
                  setCheckBox(newCheckBox)
                }}
              />
            </Col>
            <Col lg={4} className={`currency-${quotationPage}`}>
              122,12 EUR
            </Col>
            <Col lg={8}>
              <Checkbox
                className={`checkbox-${quotationPage}`}
                name="Customs Clearance Charge Export (37.58 EUR)"
                value={checkBox.option2}
                onClick={() => {
                  const newCheckBox = { ...checkBox }
                  newCheckBox.option2 = !checkBox.option2
                  setCheckBox(newCheckBox)
                }}
              />
            </Col>
            <Col lg={4} className={`currency-${quotationPage}`}>
              37,58 EUR
            </Col>
          </Row>
          <span className={`title-option-${quotationPage}`}>
            Special handling requirements
          </span>
          <Row>
            <Col lg={8}>
              <Checkbox
                className={`checkbox-${quotationPage}`}
                name="Customs Clearance Charge Import (122.12 EUR)"
                value={checkBox.special1}
                onClick={() => {
                  const newCheckBox = { ...checkBox }
                  newCheckBox.special1 = !checkBox.special1
                  setCheckBox(newCheckBox)
                }}
              />
            </Col>
            <Col lg={4} className={`currency-${quotationPage}`}>
              <span>Manual calculation</span>
            </Col>
            <Col lg={8}>
              <Checkbox
                className={`checkbox-${quotationPage}`}
                name="Customs Clearance Charge Export (37.58 EUR)"
                value={checkBox.special2}
                onClick={() => {
                  const newCheckBox = { ...checkBox }
                  newCheckBox.special2 = !checkBox.special2
                  setCheckBox(newCheckBox)
                }}
              />
            </Col>
            <Col lg={4} className={`currency-${quotationPage}`}>
              <span>Manual calculation</span>
            </Col>
          </Row>
        </div>
        <div className={`total-amount-${quotationPage}`}>
          <span>Total Amount</span>
          <div>1,317.05 EUR</div>
        </div>
      </div>
      <div className="my-3 btn-request-quotation">
        <HButton icon="ri-add-fill" text={'SAVE QUOTE FOR 30 DAYS'} />
        <HButton
          icon="ri-add-fill"
          onClick={() => changeContent('complete')}
          // disabledStatus
          text={'CONTINUE WITH BOOKING'}
        />
      </div>
    </>
  )
}

export default ReviewQuote

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const RequiredMessage = ({
  messageShow,
  column,
  columnText,
  value,
  customMessage,
  style,
  checkDateTo
}) => {
  const [message, setMessage] = useState(null)
  useEffect(() => {
    setMessage('')
    // if empty
    if (!value) setMessage(`${columnText} must be entered`)

    if (column === 'validDates') {
      if (!value && !checkDateTo) {
        setMessage(`${columnText} must be entered`)
      }
      if (checkDateTo !== undefined && value) {
        setMessage('Please select a valid date')
      }
    }
  }, [columnText, value, customMessage])
  return (
    <div className={messageShow ? 'text-error' : null}>
      {messageShow ? (
        <p className={`pl-0 font-12 ${column === 'validDates' ? style : null}`}>
          {message}
        </p>
      ) : (
        <p
          style={{ color: 'transparent !important' }}
          className={`pl-0 text-tranparent font-12 ${
            column === 'validDates' ? style : null
          }`}
        />
      )}
    </div>
  )
}

RequiredMessage.propTypes = {
  messageShow: PropTypes.bool,
  column: PropTypes.string,
  columnText: PropTypes.string,
  value: PropTypes.string,
  customMessage: PropTypes.string,
  style: PropTypes.string,
  checkDateTo: PropTypes.func
}

export default RequiredMessage

import axios from 'axios'
import NavbarContent from 'components/NavbarNew/NavbarContent'
import NavbarSidebar from 'components/NavbarNew/NavbarSidebar'
import NavbarTop from 'components/NavbarNew/NavbarTop'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const MainLayout = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { t } = useTranslation()
  const isMobile =
    window.matchMedia && window.matchMedia('(max-width: 480px)').matches

  // Navigation - get last state
  const navCollapsed = useSelector(state => state.navCollapsed)
  const [isNavCollapsed, setIsNavCollapsed] = useState(
    isMobile ? true : navCollapsed
  )

  // Navigation - nav handling
  const handleNav = () => {
    const newState = !isNavCollapsed
    setIsNavCollapsed(newState)
    dispatch({ type: 'NAV_COLLAPSED', data: newState })
  }

  const expandNavHandling = () => {
    setIsNavCollapsed(false)
    dispatch({ type: 'NAV_COLLAPSED', data: false })
  }

  const shrinkNavHandling = () => {
    setIsNavCollapsed(true)
    dispatch({ type: 'NAV_COLLAPSED', data: true })
  }

  // End nav handling

  if (user) {
    const { token } = user
    axios.defaults.baseURL = process.env.REACT_APP_API_URL
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
    axios.interceptors.request.use(request => {
      return request
    })
    axios.interceptors.response.use(
      response => {
        return response
      },
      function (error) {
        if (error?.response?.status === 401) {
          // if unauthorized
          dispatch({ type: 'EXPIRED' })
        }
        return Promise.reject(error)
      }
    )
  }

  if (user && user.token) {
    // nothing here
  } else {
    // if session not found
    return <Navigate to={'/login'} />
  }

  return (
    <>
      <NavbarTop t={t} navHandling={() => handleNav()} />
      <NavbarSidebar
        t={t}
        isNavCollapsed={isNavCollapsed}
        navHandling={() => handleNav()}
        expandNavHandling={() => {
          expandNavHandling()
        }}
        shrinkNavHandling={() => {
          shrinkNavHandling()
        }}
      />
      <NavbarContent />
    </>
  )
}

export default MainLayout

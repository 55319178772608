import React, { useRef, useState } from 'react'
import { checkSchema, ExportName } from './services'
import ReactExport from 'react-export-excel'
import 'jspdf-autotable'
import { useDispatch, useSelector } from 'react-redux'
import { getExport } from 'ApiService'
import { useEffect } from 'react'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const ExportBtn = ({
  schemaColumn,
  filename,
  classNameIcon,
  className,
  modules,
  t,
  showToast,
  setShowToast,
  exportAll,
  confirmExport,
  setConfirmExport
}) => {
  const dispatch = useDispatch()
  const keyword = useSelector(state => state.spSummaryKeyword)
  const filterValue = useSelector(state => state.spSummaryFilterValue)
  const displayFilterData = useSelector(state => state.spSummaryFilterDisplay)
  const exportedData = useSelector(state => state.spExportData)
  const dataPagination = useSelector(state => state.paginationSp)
  const ref = useRef(null)
  const ref2 = useRef(null)
  const [schemaExport, setSchemaExport] = useState([])
  const schemaChanged = useSelector(state => state.schemaChanged)

  useEffect(() => {
    checkSchema({
      schemaColumn,
      module: modules,
      setNewSchema: setSchemaExport
    })
  }, [])

  useEffect(async () => {
    checkSchema({
      schemaColumn,
      module: modules,
      setNewSchema: setSchemaExport
    })
    if (confirmExport == true) {
      await getExport({
        dispatch,
        modules,
        searchVal: keyword,
        filterValue,
        displayFilterData,
        pageNumber: dataPagination?.current_page,
        exportAll
      })
      setShowToast(false)
      setConfirmExport(false)
      ref2.current.click()
    }
  }, [schemaChanged, confirmExport])
  return (
    <div>
      <button
        ref={ref}
        onClick={() => setShowToast(!showToast)}
        className={`d-none btn-class btn btn-hover d-flex justify-content-center custom-button ${className}`}
      >
        <div className={`${classNameIcon}`}>
          <i className="ri-file-excel-2-line d-lg-none d-block" />
        </div>
        <span className="d-lg-block d-none  title-btn-excel">
          {t('export_to_excel')}
        </span>
      </button>
      <div>
        <ExcelFile
          element={
            // dont change id and style please
            <button
              ref={ref2}
              id="download-template-xls"
              className={` d-none btn-class btn btn-hover d-flex justify-content-center ${className}`}
            >
              <div className={`${classNameIcon}`}>
                <i className="ri-file-excel-2-line d-lg-none d-block" />
              </div>
              <span className="d-lg-block d-none title-btn-excel">
                {t('export_to_excel')}
              </span>
            </button>
          }
          // file name without types
          filename={ExportName(filename)}
        >
          <ExcelSheet data={exportedData} name="template">
            {schemaExport?.map((data, idx) => {
              return (
                <ExcelColumn
                  key={idx}
                  label={data.Header}
                  value={data.accessor}
                />
              )
            })}
          </ExcelSheet>
        </ExcelFile>
      </div>
    </div>
  )
}

export default ExportBtn

export const cleanReference = {
  quantity: '',
  lenght: '',
  widht: '',
  height: '',
  weight: ''
}
export const numberCheck = e => {
  if (!/D+$/.test(e.key)) e.preventDefault()
}

export const deleteLineTable = ({ state, setState, index }) => {
  const newState = [...state]
  newState.splice(index, 1)
  setState(newState)
}

export const changeTableForm = ({
  val,
  column,
  index,
  state,
  setState,
  item,
  switchCategory
}) => {
  const newState = [...state]
  newState[index][`validation_${column}`] = !!val
  newState[index][column] = val

  if (switchCategory) {
    const dataQty = parseInt(item.quantity)
    const dataLenght = parseInt(item.lenght) / 100
    const dataWidht = parseInt(item.widht) / 100
    const dataHeight = parseInt(item.height) / 100
    const volumeCubicM = dataLenght * dataWidht * dataHeight
    const dataWeight = volumeCubicM * 1000
    const totalWeight = dataQty * dataWeight
    if (volumeCubicM) {
      newState[index].volume = volumeCubicM
    }
    if (totalWeight) {
      newState[index].weight = totalWeight
    }
  } else {
    const dataQty = parseInt(item.quantity)
    const dataLenght = parseInt(item.lenght) / 12
    const dataWidht = parseInt(item.widht) / 12
    const dataHeight = parseInt(item.height)
    const volumeCubicFt = dataLenght * dataWidht * dataHeight
    const volumeCubicIn = volumeCubicFt * 1728
    const dataWeight = volumeCubicIn / 16
    const totalWeight = dataQty * dataWeight
    if (volumeCubicFt) {
      newState[index].volume = volumeCubicFt
    }
    if (totalWeight) {
      newState[index].weight = totalWeight
    }
  }

  setState(newState)
}

export const addRowTable = ({ state, setState }) => {
  let targetTable = cleanReference

  const newDataLines = Object.assign(JSON.parse(JSON.stringify(targetTable)))
  const newState = [...state]
  newState.push(newDataLines)
  setState(newState)
}

export const handleFieldChange = ({ e, column, formData, setFormData }) => {
  const newFormData = { ...formData }
  const Value = e.target.value
  newFormData[column] = Value
  newFormData[`validation_${column}`] = !!Value
  setFormData(newFormData)
}

export const validationAddRowTable = async ({
  packageDatails,
  setPackageDatails
}) => {
  // initial
  let statusValidate = true
  const checkValidation = [
    'validation_height',
    'validation_widht',
    'validation_lenght',
    'validation_quantity'
  ]

  // validasi orderLines
  packageDatails.forEach(data => {
    data.validation = true
    checkValidation.forEach(key => {
      if (data[key] !== true) statusValidate = false
    })
  })

  // set
  const newOrderLines = [...packageDatails]
  setPackageDatails(newOrderLines)
  return statusValidate
}

import React from 'react'
import { clickCheckIcon, clickCloseIcon } from './service'

export default function Toast({
  // default
  showToast,
  setShowToast,
  moduleComponent,
  className,
  caption,

  //   modalEditColumn
  setModalTable,
  resetSchema,
  state,
  saveSchema,
  module,
  newSchema,
  dispatch,
  schemaChanged,
  setReload,

  //   create/edituser
  action,
  createUser,
  updateUser,
  createForm,
  navigate,
  id,

  // shipment export
  setExportAll,
  setConfirmExport
}) {
  const clickIcon = value => {
    if (value == 'check-icon') {
      clickCheckIcon({
        moduleComponent,
        setExportAll,
        setShowToast,
        setConfirmExport,
        resetSchema,
        state,
        setModalTable,
        action,
        createUser,
        createForm,
        navigate,
        updateUser,
        id,
        saveSchema,
        module,
        newSchema,
        dispatch,
        schemaChanged,
        setReload
      })
    } else {
      clickCloseIcon({
        moduleComponent,
        setExportAll,
        setConfirmExport,
        setShowToast
      })
    }
  }

  return (
    <div className={`position-relative ${showToast ? '' : 'd-none'} `}>
      <div className={`position-absolute ${className}`}>
        <div className="mt-3 bg-primary text-white d-flex p-2 rounded-3 ps-3 pe-3">
          <div
            className={`d-flex ${className == 'toast-EditColumn' && 'ms-2'}`}
            style={{ marginTop: '0.188rem' }}
          >
            {caption}
          </div>
          {className !== 'toast-EditColumn' && (
            <div className="d-flex ms-2" style={{ height: 0 }}>
              <i
                className="ri-check-fill cursor-pointer fw-bold"
                style={{ fontSize: '1.5rem', marginTop: '-0.125rem' }}
                onClick={() => clickIcon('check-icon')}
              />
            </div>
          )}
          <span
            className="ms-2 me-2"
            style={{ borderRight: '1px solid white' }}
          />
          <div className="d-flex" style={{ height: 0 }}>
            <i
              className="ri-close-fill cursor-pointer fw-bold"
              style={{ fontSize: '1.5rem', marginTop: '-0.125rem' }}
              onClick={() => clickIcon('close-icon')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default [
  {
    id: 1,
    code: 'ONELOGIND',
    name: 'OneByOne Logistical Indonesia Pty. Ltd.',
    mainAdminUser: 'Aldi Trifaldi',
    address:
      'Gedung Graha Pena Batam Lantai 3A Ruang 3A-08 JL. Raya Batam Centre Kecamatan'
  },
  {
    id: 2,
    code: 'ONELOGMEL',
    name: 'OneByOne Logistical Indonesia Pty. Ltd.',
    mainAdminUser: 'Dimas Hassan',
    address: 'Level 2, 525 Collins Street, Melbourne Viictoria, 3000'
  },
  {
    id: 3,
    code: 'ONELOGSIN',
    name: 'OneByOne Logistical Indonesia Pty. Ltd.',
    mainAdminUser: 'John Leon',
    address: 'One George Street 1 George St # 10-01, Singapore 049145'
  },
  {
    id: 4,
    code: 'NIMPERIND',
    name: 'Nimperal Perlindo Indonesia',
    mainAdminUser: 'Janice Kho',
    address: 'Cimacan, Cipanas, Cianjur Regency, West Java 43253'
  },
  {
    id: 5,
    code: 'SAILAUIND',
    name: 'Saint Laurent Indonesia',
    mainAdminUser: 'Kristine Sew',
    address:
      'Jl. Gn. Putri, RT.06/RW.07, Sukatani, Kec. Pacet, Kabupaten Cianjur, Jawa Barat 43253'
  },
  {
    id: 6,
    code: 'MCDRESMEL',
    name: 'McDonalds Restaurant Melbourne',
    mainAdminUser: 'Potter Mike',
    address: '2 Booker St, Spotswood VIC 3015, Australia'
  },
  {
    id: 7,
    code: 'SUMWARBUS',
    name: 'Sumise Warehouse Busan',
    mainAdminUser: 'Leonardo Lim',
    address: ''
  },
  {
    id: 8,
    code: 'GLORESSYD',
    name: 'Gloria Restorant Sydney',
    mainAdminUser: 'Rachel West',
    address: ''
  },
  {
    id: 9,
    code: 'ACEHARSIN',
    name: 'Ace Hardware Singapore',
    mainAdminUser: 'Alvin Tim',
    address: 'Ace Hardware Melbourne'
  },
  {
    id: 10,
    code: 'ACEHARMEL',
    name: 'Ace Hardware Melbourne',
    mainAdminUser: 'Katherine Wu',
    address:
      'Werribee River Linear Reserve, 25 Muirhead Cres, Werribee VIC 3030, Australia'
  }
]

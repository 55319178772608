import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Row, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Flex from 'components/common/Flex'
import Section from 'components/common/Section'
import Logo from 'assets/img/logos/ControlGlobal.png'
import loginBgVideo from 'assets/video/wms.mp4'
import RequestQuotation from 'page/Login/RequestQuotation'
import QuotationResult from 'page/Login/QuotationResult'
import ReviewQuote from 'page/Login/ReviewQuote'
import { validationRequest } from './service'
import CompleteQuote from 'page/Login/CompleteQuote'

const AuthCardLayout = ({ children, content, setContent }) => {
  const user = useSelector(state => state.user)
  const [quotationProgress, setQuotationProgress] = useState(0)
  const [quotationPage, setQuotationPage] = useState('request')
  const [formData, setFormData] = useState({
    checkboxDelivery: false,
    checkboxPickup: false
  })
  const [isValidation, setIsValidation] = useState(false)
  const [packageDatails, setPackageDatails] = useState([])
  const [switchCategory, setSwitchCategory] = useState(false)
  const videoRef = useRef()
  const playbackRate = 0.7

  useEffect(() => {
    videoRef.current.playbackRate = playbackRate
  }, [])

  useEffect(() => {
    if (user) {
      window.location.href = '/'
    }
  })

  const rows = []
  for (let i = 0; i < 10; i++) {
    rows.push(<i className="ri-checkbox-blank-circle-fill" key={i} />)
  }

  const changeContent = contentName => {
    if (contentName === 'request') {
      setQuotationProgress(0)
      setQuotationPage(contentName)
    } else if (contentName === 'result') {
      setQuotationProgress(34)
      setQuotationPage(contentName)
    } else if (contentName === 'review') {
      setQuotationProgress(66)
      setQuotationPage(contentName)
    } else if (contentName === 'complete') {
      setQuotationProgress(100)
      setQuotationPage(contentName)
    }
  }

  useEffect(() => {
    let totalWeight = 0
    let totalVolume = 0
    const newFormData = { ...formData }
    packageDatails.forEach(item => {
      totalWeight = totalWeight + item.weight
      totalVolume = totalVolume + item.volume
    })
    newFormData.totalVolume = totalVolume
    newFormData.totalWeight = totalWeight
    setFormData(newFormData)
  }, [packageDatails])

  const contentLogin = () => {
    return (
      <Col lg={8} xxl={5} className="py-3 position-relative">
        <Card className="overflow-hidden z-index-1">
          <Card.Body className="p-0">
            <Row className="h-100 g-0">
              <Col
                md={content === 'createDemo' ? 12 : 6}
                as={Flex}
                alignItems="center"
                justifyContent="center"
              >
                <div className="box-login">{children}</div>
              </Col>
              {content === 'createDemo' ? (
                ''
              ) : (
                <Col
                  md={6}
                  className={`box-login-second-content${
                    content === 'contact' ? '-' + content : ''
                  }`}
                >
                  {content === 'contact' ? (
                    <div className="description-contact">
                      <h5>Request have created successfully</h5>
                      <span className="pb-3">
                        check your email for validation
                      </span>
                      <div>
                        <span className="mt-2">
                          Please wait around 24 hours if the email have not been
                          received, contact us if there is anything that we can
                          assist.
                        </span>
                      </div>
                      <div className="icon-contact">
                        <i className="ri-hotel-line" />
                        {rows}
                        <i className="ri-mail-send-line" />
                      </div>
                      <Button
                        type="submit"
                        size="lg"
                        onClick={() => setContent('login')}
                      >
                        BACK TO LOGIN PAGE
                      </Button>
                    </div>
                  ) : (
                    <div className="box-login-second">
                      <div className="btn-request-quotation">
                        <Button
                          type="submit"
                          size="md"
                          className="w-100 mb-2"
                          onClick={() => setContent('quotation')}
                        >
                          REQUEST QUOTATION
                        </Button>
                      </div>
                      <div className="login-box-footer">
                        <span>Don't have an account?</span>
                        <Button
                          type="submit"
                          size="lg"
                          className="w-100 mb-2"
                          onClick={() => setContent('createDemo')}
                        >
                          ACCESS DEMO ACCOUNT
                        </Button>
                        <div className="login-text">
                          <p>
                            Please contact our administration person to help you
                            on this issue in the url link shown below.
                          </p>
                          <a href="https://www.logistical.one/">
                            www.logistical.one
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        <p className="mt-2 mb-0 text-white">
          ©OneByOne Logistical Pty Ltd 2024. Connect and control with digital
          precision™
        </p>
      </Col>
    )
  }

  const contentTopQuotation = () => {
    return (
      <Row className="">
        <Col md={6}>
          <div>
            <img
              className="icon-login"
              onClick={() => {
                setContent('login')
              }}
              src={Logo}
              alt="Logo Logistical"
            />
          </div>
        </Col>
        <Col md={6} className="top-content-quotation">
          <div className="top-nav-quotation">
            <div
              className={`item-nav ${
                quotationProgress === 0
                  ? 'active-nav'
                  : quotationProgress > 0
                  ? 'active-nav-done '
                  : ''
              }`}
              onClick={() => changeContent('request')}
            >
              <span>Quote Request</span>
              <hr />
              <i className="ri-checkbox-blank-circle-fill" />
            </div>
            <div
              className={`item-nav ${
                quotationProgress === 34
                  ? 'active-nav'
                  : quotationProgress > 34
                  ? 'active-nav-done '
                  : ''
              }`}
              onClick={() =>
                validationRequest({
                  formData,
                  packageDatails,
                  setPackageDatails,
                  changeContent,
                  setIsValidation
                })
              }
            >
              <span>Quote Result</span>
              <hr />
              <i className="ri-checkbox-blank-circle-fill" />
            </div>
            <div
              className={`item-nav ${
                quotationProgress === 66
                  ? 'active-nav'
                  : quotationProgress > 66
                  ? 'active-nav-done '
                  : ''
              }`}
            >
              <span>Quote Review</span>
              <hr />
              <i className="ri-checkbox-blank-circle-fill" />
            </div>
            <div
              className={`item-nav ${
                quotationProgress === 100 ? 'active-nav' : ''
              }`}
            >
              <span>Quote Complete</span>
              <hr />
              <i className="ri-checkbox-blank-circle-fill" />
            </div>
          </div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: quotationProgress + '%' }}
            ></div>
          </div>
        </Col>
      </Row>
    )
  }

  const contentQuotation = () => {
    return (
      <Col lg={8} xxl={6} className="py-3 position-relative">
        <Card className="overflow-hidden z-index-1">
          <Card.Body className="p-0">
            <div className="box-quotation">
              {contentTopQuotation()}
              <div className={`box-quotation-${quotationPage}`}>
                {quotationPage === 'request' ? (
                  <RequestQuotation
                    formData={formData}
                    setContent={setContent}
                    setFormData={setFormData}
                    setIsValidation={setIsValidation}
                    isValidation={isValidation}
                    switchCategory={switchCategory}
                    setSwitchCategory={setSwitchCategory}
                    packageDatails={packageDatails}
                    setPackageDatails={setPackageDatails}
                    quotationPage={quotationPage}
                    validationRequest={() =>
                      validationRequest({
                        formData,
                        packageDatails,
                        setPackageDatails,
                        changeContent,
                        setIsValidation
                      })
                    }
                  />
                ) : quotationPage === 'result' ? (
                  <QuotationResult
                    formData={formData}
                    switchCategory={switchCategory}
                    quotationPage={quotationPage}
                    changeContent={changeContent}
                  />
                ) : quotationPage === 'review' ? (
                  <ReviewQuote
                    formData={formData}
                    switchCategory={switchCategory}
                    quotationPage={quotationPage}
                    changeContent={changeContent}
                  />
                ) : (
                  <CompleteQuote changeContent={changeContent} />
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
        <p className="mt-2 mb-0 text-white">
          ©OneByOne Logistical Pty Ltd 2024. Connect and control with digital
          precision™
        </p>
      </Col>
    )
  }

  return (
    <Section fluid className="py-0">
      <div className="login-bg-video">
        <video
          ref={videoRef}
          id="login-bg-video"
          preload="true"
          autoPlay
          loop
          muted
        >
          <source type="video/mp4" src={loginBgVideo} alt="video" />
        </video>
      </div>
      <Row className="box-content-login">
        {content === 'quotation' ? contentQuotation() : contentLogin()}
      </Row>
    </Section>
  )
}
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
}

export default AuthCardLayout

import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Input from 'components/Input'
import AppContext from 'context/Context'
import HButton from 'components/Bottom'
import { toast } from 'react-toastify'
import {
  assignDetail,
  changeCreateFrom,
  changeCreateFromRole,
  genderList,
  orgList
} from './service'
import Dropdown from 'components/Dropdown'
import { useParams } from 'react-router-dom'
import TextAreaInput from 'components/TextAreaInput'
import { createUser, getRoles, getUserDetail, updateUser } from 'ApiService'
import { useDispatch, useSelector } from 'react-redux'
import UserDetail from '../detail'
import CToast from 'components/Toast'

// translation
import { useTranslation } from 'react-i18next'
const actions = {
  create: 'Create User',
  edit: 'Edit User'
}

const UserCreate = ({ navigate }) => {
  const { action } = useParams()
  const { id } = useParams()
  const dispatch = useDispatch()
  const {
    config: { isNavbarVerticalCollapsed }
  } = useContext(AppContext)
  const [createForm, SetCreateFrom] = useState({})
  const [genderSelected, SetGenderSelected] = useState()
  const [orgSelected, SetOrgSelected] = useState()
  const userDetail = useSelector(state => state.spUserDetails)
  const [roles, setRole] = useState([])
  const [preview, setPreview] = useState(false)
  const [checkbox1, setCheckbox1] = useState(false)
  const [showToast, setShowToast] = useState(false)

  // translation
  const { t } = useTranslation()

  // dimension Table
  const [dimension, setDimension] = useState({
    height:
      window.innerWidth < 768
        ? window.innerHeight - 340
        : window.innerHeight - 310,
    width: window.innerWidth
  })
  const { width, height } = dimension

  const notify = ({ text, status }) => {
    if (status == 'warn') {
      toast.warn(text, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    if (status == 'success') {
      toast.success(text, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  }

  useEffect(async () => {
    await getRoles({
      dispatch,
      setRole
    })
    if (id != null) {
      await getUserDetail({ dispatch, id })
    }
  }, [])
  useEffect(async () => {
    assignDetail({
      userDetail,
      createFrom: createForm,
      SetCreateFrom
    })
  }, [userDetail])

  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height:
          window.innerWidth < 768
            ? window.innerHeight - 340
            : window.innerHeight - 270,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return (
    <>
      <Row className={`g-3 mb-3 ${preview ? 'd-none' : ''}`}>
        <Col lg={12} className="sticky-header" style={{ position: 'sticky' }}>
          <div
            className={`${
              isNavbarVerticalCollapsed == true ? 'search-burger' : ''
            }`}
          >
            <Row style={{ height: '30px' }}>
              <Col
                lg={3}
                className="d-flex align-items-center"
                style={{ fontWeight: 'bold' }}
              >
                {/* <i className="ri-edit-2-fill pe-2 headerBar" /> */}
                {action == 'create' ? t(actions.create) : t(actions.edit)}
              </Col>
              <Col lg={8} className="d-flex align-items-center" />
              <Col
                lg={3}
                className="d-flex  align-items-center justify-content-end"
              />
            </Row>
          </div>
        </Col>
        <div className="cardLayout" style={{ marginTop: '3.75rem' }}>
          <div>
            <div className="createHeader">
              {t('Fill in the required details provided to create user')}
            </div>
            <div
              className="formLayout p-3"
              style={{ minHeight: height, maxHeight: height, maxWidth: width }}
            >
              <Row className="d-flex">
                <Col className="d-flex" sm={12}>
                  <Col sm={3} className="d-flex">
                    <div className="w-100">
                      <div className="d-flex flex-column">
                        <Input
                          title={t('Login Name') + ' *'}
                          placeholder=""
                          parentDivClassName="textTitle"
                          titleClassName="fieldTitle"
                          className="create-input"
                          value={createForm?.portalLogin}
                          onChange={e =>
                            changeCreateFrom({
                              column: 'portalLogin',
                              value: e.target.value,
                              createFrom: createForm,
                              SetCreateFrom
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="ps-2" style={{ maxWidth: '60px' }}>
                      <Input
                        title={`${t('Code')} *`}
                        placeholder=""
                        parentDivClassName="textTitle"
                        titleClassName="fieldTitle"
                        className="create-input px-2"
                        value={createForm?.code}
                        onChange={e =>
                          changeCreateFrom({
                            column: 'code',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col sm={2} className="ps-2">
                    <Input
                      title={t('Password') + ' *'}
                      placeholder=""
                      parentDivClassName="textTitle"
                      titleClassName="fieldTitle"
                      className="create-input"
                      type="password"
                      onChange={e =>
                        changeCreateFrom({
                          column: 'password',
                          value: e.target.value,
                          createFrom: createForm,
                          SetCreateFrom
                        })
                      }
                    />
                  </Col>
                  <Col
                    sm={2}
                    className={`ps-2 ${action == 'edit' ? 'd-none' : ''}`}
                  >
                    <Input
                      title={t('Confirm password') + ' *'}
                      placeholder=""
                      parentDivClassName="textTitle"
                      titleClassName="fieldTitle"
                      className="create-input"
                      type="password"
                      onChange={e =>
                        changeCreateFrom({
                          column: 'confirmPassword',
                          value: e.target.value,
                          createFrom: createForm,
                          SetCreateFrom
                        })
                      }
                    />
                  </Col>
                  <Col sm={3} className="ps-2">
                    <label className="mb-0 fieldTitle">
                      {t('Organization') + ' *'}
                    </label>
                    <Dropdown
                      placeholder=""
                      options={orgList}
                      onChangeDropdown={selected => {
                        changeCreateFrom({
                          column: 'organization',
                          value: selected.value,
                          createFrom: createForm,
                          SetCreateFrom
                        })
                        SetOrgSelected(selected)
                      }}
                      selectedValue={orgSelected}
                      className="create-input"
                    />
                    {/* <Input
                      title={'Organization'}
                      placeholder={''}
                      parentDivClassName={'textTitle'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e =>
                        changeCreateFrom({
                          column: 'organization',
                          value: e.target.value,
                          createFrom: createForm,
                          SetCreateFrom
                        })
                      }
                    /> */}
                  </Col>
                  <Col className="ps-2 pt-2">
                    <div className="pt-4 ps-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        defaultChecked={checkbox1}
                        onChange={() => {
                          setCheckbox1(!checkbox1)
                          changeCreateFromRole({
                            column: 'roleid',
                            values: roles,
                            checked: false,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }}
                      />
                      <div className="textTitle ps-2">{t('Admin User')}</div>
                    </div>
                    {/* old role */}
                    {/* <label className={'mb-0 fieldTitle'}>Role *</label>
                    <Dropdown
                      placeholder={''}
                      options={roles}
                      onChangeDropdown={selected => {
                        changeCreateFrom({
                          column: 'roleid',
                          value: selected.value,
                          createFrom: createForm,
                          SetCreateFrom
                        });
                        setRoleSelected(selected);
                      }}
                      selectedValue={roleSelected}
                      className={'create-input'}
                    /> */}
                    {/* <Input
                      title={'Organization'}
                      placeholder={''}
                      parentDivClassName={'textTitle'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e =>
                        changeCreateFrom({
                          column: 'organization',
                          value: e.target.value,
                          createFrom: createForm,
                          SetCreateFrom
                        })
                      }
                    /> */}
                  </Col>
                </Col>
              </Row>
              <Row className="d-flex pt-3">
                <Col xs={5}>
                  <div className="formTitle">{t('Employee Details')}</div>
                  <Input
                    title={t('Full Name')}
                    placeholder=""
                    parentDivClassName="textTitle pe-5"
                    titleClassName="fieldTitle"
                    className="create-input"
                    value={createForm?.fullName}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'fullName',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  />
                  <Input
                    title={t('Preferred Name')}
                    placeholder=""
                    parentDivClassName="textTitle pe-5"
                    titleClassName="fieldTitle"
                    className="create-input"
                    onChange={e =>
                      changeCreateFrom({
                        column: 'preferredName',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  />
                  <TextAreaInput
                    title={t('Address')}
                    placeholder=""
                    parentDivClassName="textTitle pe-5"
                    titleClassName="fieldTitle"
                    className="create-input"
                    onChange={e =>
                      changeCreateFrom({
                        column: 'address',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  />
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={t('Country')}
                        placeholder=""
                        parentDivClassName="textTitle pe-2"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'country',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title={t('City')}
                        placeholder=""
                        parentDivClassName="textTitle"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'city',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                  </div>
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={t('Postcode')}
                        placeholder=""
                        parentDivClassName="textTitle pe-2"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'postCode',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title={t('State')}
                        placeholder=""
                        parentDivClassName="textTitle"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'state',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                  </div>
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={t('Date of Birth')}
                        placeholder="select date"
                        parentDivClassName="textTitle pe-2"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'dateOfBirth',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                        type="date"
                      />
                    </Col>
                    <Col xs={6}>
                      <label className="mb-0 fieldTitle">{t('Gender')}</label>
                      <Dropdown
                        placeholder=""
                        options={genderList}
                        onChangeDropdown={selected => {
                          changeCreateFrom({
                            column: 'gender',
                            value: selected.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                          SetGenderSelected(selected)
                        }}
                        selectedValue={genderSelected}
                        className="create-input"
                      />
                    </Col>
                  </div>
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={t('Nationality')}
                        placeholder=""
                        parentDivClassName="textTitle pe-2"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'nationality',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title={t('Employed')}
                        placeholder="select date"
                        parentDivClassName="textTitle pe-2"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'employed',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                        type="date"
                      />
                    </Col>
                  </div>
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={t('language')}
                        placeholder=""
                        parentDivClassName="textTitle pe-2"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'language',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title={t('JobTitle')}
                        placeholder=""
                        parentDivClassName="textTitle"
                        titleClassName="fieldTitle"
                        className="create-input"
                        onChange={e =>
                          changeCreateFrom({
                            column: 'jobTitle',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                  </div>
                  {/* <div className={`formTitle pt-3`}>Bank Details/ Wages</div>
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={'Account Number'}
                        placeholder={''}
                        parentDivClassName={'textTitle pe-2'}
                        titleClassName={'fieldTitle'}
                        className={'create-input'}
                        onChange={e =>
                          changeCreateFrom({
                            column: 'accountNo',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title={'Bank Name'}
                        placeholder={''}
                        parentDivClassName={'textTitle'}
                        titleClassName={'fieldTitle'}
                        className={'create-input'}
                        onChange={e =>
                          changeCreateFrom({
                            column: 'bankName',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                  </div>
                  <div className="d-flex pe-5">
                    <Col xs={6}>
                      <Input
                        title={'Banks BSB'}
                        placeholder={''}
                        parentDivClassName={'textTitle pe-2'}
                        titleClassName={'fieldTitle'}
                        className={'create-input'}
                        onChange={e =>
                          changeCreateFrom({
                            column: 'bankBSB',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title={'Bank SWIFT'}
                        placeholder={''}
                        parentDivClassName={'textTitle'}
                        titleClassName={'fieldTitle'}
                        className={'create-input'}
                        onChange={e =>
                          changeCreateFrom({
                            column: 'bankSwift',
                            value: e.target.value,
                            createFrom: createForm,
                            SetCreateFrom
                          })
                        }
                      />
                    </Col>
                  </div> */}
                </Col>
                <Col xs={6} className="ps-2">
                  <div className="formTitle">{t('Emails')}</div>
                  <Input
                    title={t('Login Email') + ' *'}
                    placeholder=""
                    parentDivClassName="textTitle w-75"
                    titleClassName="fieldTitle"
                    className="create-input"
                    value={createForm?.portalEmail}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'portalEmail',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  />
                  {/* <div className={`formTitle pt-3`}>Employee Status</div>
                  <Input
                    title={'Current Status'}
                    placeholder={''}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'employeStatus',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  />
                  <Input
                    title={'Out On Task'}
                    placeholder={''}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e => {
                      changeCreateFrom({
                        column: 'outOnTask',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      });
                    }}
                  />
                  <Input
                    title={'Due Back'}
                    placeholder={'select date'}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'dueBack',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                    type={'date'}
                  /> */}
                  <Input
                    title={t('Additional Email')}
                    placeholder=""
                    parentDivClassName="textTitle w-75"
                    titleClassName="fieldTitle"
                    className="create-input"
                    // disabled={true}
                    // onChange={e =>
                    //   changeCreateFrom({
                    //     column: 'email2',
                    //     value: e.target.value,
                    //     createFrom: createForm,
                    //     SetCreateFrom
                    //   })
                    // }
                  />
                  <Input
                    title={t('Additional Email')}
                    placeholder=""
                    parentDivClassName="textTitle w-75"
                    titleClassName="fieldTitle"
                    className="create-input"
                    // disabled={true}
                    // onChange={e =>
                    //   changeCreateFrom({
                    //     column: 'email2',
                    //     value: e.target.value,
                    //     createFrom: createForm,
                    //     SetCreateFrom
                    //   })
                    // }
                  />
                  <Input
                    title={t('Additional Email')}
                    placeholder=""
                    parentDivClassName="textTitle w-75"
                    titleClassName="fieldTitle"
                    className="create-input"
                    // disabled={true}
                    // onChange={e =>
                    //   changeCreateFrom({
                    //     column: 'email2',
                    //     value: e.target.value,
                    //     createFrom: createForm,
                    //     SetCreateFrom
                    //   })
                    // }
                  />
                  <Input
                    title={t('Additional Email')}
                    placeholder=""
                    parentDivClassName="textTitle w-75"
                    titleClassName="fieldTitle"
                    className="create-input"
                    // disabled={true}
                    // onChange={e =>
                    //   changeCreateFrom({
                    //     column: 'email2',
                    //     value: e.target.value,
                    //     createFrom: createForm,
                    //     SetCreateFrom
                    //   })
                    // }
                  />
                  <Input
                    title={t('Additional Email')}
                    placeholder=""
                    parentDivClassName="textTitle w-75"
                    titleClassName="fieldTitle"
                    className="create-input"
                    // disabled={true}
                    // onChange={e =>
                    //   changeCreateFrom({
                    //     column: 'email2',
                    //     value: e.target.value,
                    //     createFrom: createForm,
                    //     SetCreateFrom
                    //   })
                    // }
                  />
                  {/* <div className={`formTitle pt-3`}>Contact Numbers</div>
                  <div className="d-flex align-items-center">
                    <Input
                      title={'Work Phone'}
                      placeholder={''}
                      parentDivClassName={'textTitle w-75'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e => {
                        changeCreateFromPhone({
                          column: 'workPhone',
                          value: e.target.value,
                          createFrom: createForm,
                          checkBox: 'isWorkPhone',
                          checkBoxValue: checkbox1,
                          SetCreateFrom
                        });
                      }}
                    />
                    <div className="pt-4 ps-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        defaultChecked={checkbox1}
                        onChange={() => {
                          setCheckbox1(!checkbox1);
                          changeCreateFrom({
                            column: 'isWorkPhone',
                            value: !checkbox1,
                            createFrom: createForm,
                            SetCreateFrom
                          });
                        }}
                      />
                      <div className="textTitle ps-2">Display</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Input
                      title={'Work Extensions'}
                      placeholder={''}
                      parentDivClassName={'textTitle w-75'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e => {
                        changeCreateFromPhone({
                          column: 'workExtension',
                          value: e.target.value,
                          createFrom: createForm,
                          checkBox: 'isWorkExtension',
                          checkBoxValue: checkbox2,
                          SetCreateFrom
                        });
                      }}
                    />
                    <div className="pt-4 ps-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        defaultChecked={checkbox2}
                        onChange={() => {
                          setCheckbox2(!checkbox2);
                          changeCreateFrom({
                            column: 'isWorkExtension',
                            value: !checkbox2,
                            createFrom: createForm,
                            SetCreateFrom
                          });
                        }}
                      />
                      <div className="textTitle ps-2">Display</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Input
                      title={'Fax Number'}
                      placeholder={''}
                      parentDivClassName={'textTitle w-75'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e => {
                        changeCreateFromPhone({
                          column: 'faxNumber',
                          value: e.target.value,
                          createFrom: createForm,
                          checkBox: 'isFaxNumber',
                          checkBoxValue: checkbox3,
                          SetCreateFrom
                        });
                      }}
                    />
                    <div className="pt-4 ps-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        defaultChecked={checkbox3}
                        onChange={() => {
                          setCheckbox3(!checkbox3);
                          changeCreateFrom({
                            column: 'isFaxNumber',
                            value: !checkbox3,
                            createFrom: createForm,
                            SetCreateFrom
                          });
                        }}
                      />
                      <div className="textTitle ps-2">Display</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Input
                      title={'Home Phone'}
                      placeholder={''}
                      parentDivClassName={'textTitle w-75'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e => {
                        changeCreateFromPhone({
                          column: 'homePhone',
                          value: e.target.value,
                          createFrom: createForm,
                          checkBox: 'isHomePhone',
                          checkBoxValue: checkbox4,
                          SetCreateFrom
                        });
                      }}
                    />
                    <div className="pt-4 ps-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        defaultChecked={checkbox4}
                        onChange={() => {
                          setCheckbox4(!checkbox4);
                          changeCreateFrom({
                            column: 'isHomePhone',
                            value: !checkbox4,
                            createFrom: createForm,
                            SetCreateFrom
                          });
                        }}
                      />
                      <div className="textTitle ps-2">Display</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Input
                      title={'Mobile Phone'}
                      placeholder={''}
                      parentDivClassName={'textTitle w-75'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e => {
                        changeCreateFromPhone({
                          column: 'mobilePhone',
                          value: e.target.value,
                          createFrom: createForm,
                          checkBox: 'isMobilePhone',
                          checkBoxValue: checkbox5,
                          SetCreateFrom
                        });
                      }}
                    />
                    <div className="pt-4 ps-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        defaultChecked={checkbox5}
                        onChange={() => {
                          setCheckbox5(!checkbox5);
                          changeCreateFrom({
                            column: 'isMobilePhone',
                            value: !checkbox5,
                            createFrom: createForm,
                            SetCreateFrom
                          });
                        }}
                      />
                      <div className="textTitle ps-2">Display</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Input
                      title={'Other Reference'}
                      placeholder={''}
                      parentDivClassName={'textTitle w-75'}
                      titleClassName={'fieldTitle'}
                      className={'create-input'}
                      onChange={e =>
                        changeCreateFrom({
                          column: 'otherReference',
                          value: e.target.value,
                          createFrom: createForm,
                          SetCreateFrom
                        })
                      }
                    />
                  </div>
                  <div className={`formTitle pt-3`}>Emergency Contact</div>
                  <Input
                    title={'Emergency Contact Name'}
                    placeholder={''}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'emergencyContactName',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  />
                  <Input
                    title={'Emergency Home Phone'}
                    placeholder={''}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'emergencyContactPhone',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  /> */}
                  {/* <div className={`formTitle pt-3`}>Branch</div>
                  <Input
                    title={'Home Branch'}
                    placeholder={''}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'branch',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  /> */}
                  {/* <Input
                    title={'Other Branch'}
                    placeholder={''}
                    parentDivClassName={'textTitle w-75'}
                    titleClassName={'fieldTitle'}
                    className={'create-input'}
                    onChange={e =>
                      changeCreateFrom({
                        column: 'branch2',
                        value: e.target.value,
                        createFrom: createForm,
                        SetCreateFrom
                      })
                    }
                  /> */}
                </Col>
              </Row>
            </div>
            <CToast
              showToast={showToast}
              setShowToast={setShowToast}
              className="toast-CreateEditUser"
              caption={`Are you sure to ${action} this user?`}
              moduleComponent="CreateEditUser"
              action={action}
              createUser={createUser}
              updateUser={updateUser}
              createForm={createForm}
              navigate={navigate}
              id={id}
            />
            <div className="createBottom d-flex">
              <Col
                xs={6}
                className="d-flex  align-items-center justify-content-start"
              >
                <HButton
                  onClick={() => {
                    navigate(-1)
                  }}
                  icon="ri-arrow-left-line"
                  text={t('Back')}
                  className="btn-class me-2"
                />
              </Col>
              <Col
                xs={6}
                className="d-flex  align-items-center justify-content-end"
              >
                <HButton
                  onClick={() => {
                    setPreview(true)
                  }}
                  icon="ri-filter-3-line"
                  text={t('PREVIEW')}
                  className="btn-class me-2 button-custom"
                />
                <HButton
                  icon="ri-add-line"
                  text={action == 'create' ? t('create') : t('SAVE')}
                  onClick={() => {
                    if (
                      createForm?.portalLogin === null ||
                      createForm?.portalLogin === ''
                    ) {
                      notify({
                        text: "Login name can't be empty",
                        status: 'warn'
                      })
                      return
                    }
                    if (createForm?.code === null || createForm?.code === '') {
                      notify({
                        text: "User code can't be empty",
                        status: 'warn'
                      })
                      return
                    }
                    if (
                      createForm?.password === null ||
                      createForm?.password === ''
                    ) {
                      notify({
                        text: "Password can't be empty",
                        status: 'warn'
                      })
                      return
                    }
                    if (
                      createForm?.organization === null ||
                      createForm?.organization === ''
                    ) {
                      notify({
                        text: 'No organization selected',
                        status: 'warn'
                      })
                      return
                    }
                    if (
                      createForm?.roleid === null ||
                      createForm?.roleid === ''
                    ) {
                      notify({
                        text: 'No role selected',
                        status: 'warn'
                      })
                      return
                    }
                    if (
                      createForm?.portalEmail === null ||
                      createForm?.portalEmail === ''
                    ) {
                      notify({
                        text: "Email can't be empty",
                        status: 'warn'
                      })
                      return
                    }
                    if (
                      action == 'create' &&
                      createForm?.password != createForm?.confirmPassword
                    ) {
                      notify({
                        text: 'Password confirmation is invalid',
                        status: 'warn'
                      })
                      return
                    }
                    setShowToast(!showToast)
                  }}
                  className="btn-class button-custom"
                />
              </Col>
            </div>
          </div>
        </div>
      </Row>
      <div className={`${preview ? '' : 'd-none'}`}>
        <UserDetail preview user={createForm} />
        <div className="d-flex align-items-end justify-content-end">
          <HButton
            onClick={() => setPreview(false)}
            icon="ri-arrow-left-line"
            text={t('BACK')}
            className="btn-class custom-button"
          />
        </div>
      </div>
    </>
  )
}

export default UserCreate

import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  Chart,
  registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { useDispatch } from 'react-redux'
import {
  completedShipmentsDashboard,
  activeShipmentsDashboard,
  delayedShipment,
  upcomingShipment
} from '../../static/dashboardStaticData'
import OrderStatus from './OrderStatus'
import DashBoardShipments from './DashboardShipments'
import moment from 'moment'
import DashBoardShipmentTracking from './DashboardShimpentTracking'
import AppContext from 'context/Context'

Chart.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const Dashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const date = Date.now()
  const lastMonth = subtractMonths(1, new Date(date))
  const { setConfig } = useContext(AppContext)
  const nowMonth = moment(date).format('MMMM')
  const nowYear = moment(date).format('YYYY')

  // dimension
  const [dimension, setDimension] = useState({
    height: window.innerHeight - 160,
    width: window.innerWidth
  })
  const { width, height } = dimension

  // open setting
  const ToggleSettings = () => {
    setConfig('showSettingPanel', true)
  }

  // subtractMonths
  function subtractMonths(numOfMonths, newDate) {
    newDate.setMonth(newDate.getMonth() - numOfMonths)
    return newDate
  }

  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: window.innerHeight - 160,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useLayoutEffect(() => {
    // document.body.classList.add('hide-scroll-body')
    // document
    //   .getElementById('main-footer')
    //   .classList.add('custom-margin-dashboard')
  }, [])

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      layoutButton: true,
      layoutHandling: () => {
        ToggleSettings()
      }
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  return (
    <div>
      <div
        className="custom-table-padding scrollable-dashboard"
        style={{
          minHeight: height,
          maxHeight: height,
          maxWidth: width
          // height: '100vh'
        }}
      >
        <Row className="h-50">
          <OrderStatus date={date} lastDate={lastMonth} />
          <Col lg={4} sm={12}>
            {/*height-fill-rest*/}
            <DashBoardShipments
              headerText={`${t('Completed shipments')}: ${t(
                nowMonth
              )} ${nowYear}`}
              data={completedShipmentsDashboard}
              boxColor="#CEFFE6"
            />
          </Col>
        </Row>
        <Row className="mt-2 h-50">
          <Col lg={8} sm={12} className="bg-white p-3 border dashboard-col ">
            {/*height-fill-rest*/}
            <DashBoardShipmentTracking
              delayedData={delayedShipment}
              upcomingData={upcomingShipment}
            ></DashBoardShipmentTracking>
          </Col>
          <Col lg={4} sm={12}>
            {/*height-fill-rest*/}
            <DashBoardShipments
              headerText={`${t('Active shipment country')}:  ${t(
                nowMonth
              )} ${nowYear}`}
              data={activeShipmentsDashboard}
              boxColor="#FFEDCE"
            ></DashBoardShipments>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Dashboard

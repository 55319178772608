import React from 'react'

const StatusLabel = ({ width, bg, label, margin }) => {
  return (
    <div
      className={`${margin} status-label-container d-flex align-items-center`}
      style={{
        width: width
      }}
    >
      <div
        className="status-label-dot"
        style={{
          backgroundColor: bg
        }}
      />
      {label}
    </div>
  )
}

export default StatusLabel

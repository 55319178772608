import React from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'

// translation
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { rootReducer } from './store'
import App from './App'
import Main from './Main'
import 'helpers/initFA'
import './web.config'

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: [
      'user',
      'siteData',
      'clientData',
      'moduleAccess',
      'loadClient',
      'loadSite',
      'dragStatus',
      'navCollapsed'
    ]
  },
  rootReducer
)

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr', 'ja', 'zh', 'id'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['localStorage', 'path', 'cookie', 'htmlTag'],
      caches: ['cookie']
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json'
    }
  })

const store = createStore(persistedReducer)
const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Main>
        <App />
      </Main>
    </PersistGate>
  </Provider>,

  document.getElementById('main')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

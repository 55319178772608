export const checkSchema = async ({ setNewSchema, getHeader }) => {
  setNewSchema(getHeader)
  // countEnableColumns({
  //   module,
  //   setNumOfEnableColumns,
  //   setTmpOfEnableColumns
  // })
}

export const resetSchema = ({ module, state }) => {
  const savedTableColumns = localStorage.getItem('tableColumns')
    ? JSON.parse(localStorage.getItem('tableColumns'))
    : []
  if (savedTableColumns.length > 0) {
    savedTableColumns.forEach((data, index) => {
      if (data.title === module) {
        savedTableColumns.splice(index, 1)
        state.editColumn = []
      }
    })
  }
}

export const saveSchema = async ({
  module,
  dispatch,
  state,
  setEditColumnTemp
}) => {
  const savedTableColumns = localStorage.getItem('tableColumns')
    ? JSON.parse(localStorage.getItem('tableColumns'))
    : []
  const hiddenColumn = Object.values(state.editColumn)
  if (savedTableColumns.length > 0) {
    savedTableColumns.forEach((data, index) => {
      if (data.title === module) {
        savedTableColumns.splice(index, 1)
      }
    })
    savedTableColumns.push({
      title: module,
      columns: hiddenColumn
    })
  } else {
    savedTableColumns.push({
      title: module,
      columns: hiddenColumn
    })
  }

  localStorage.setItem('tableColumns', JSON.stringify(savedTableColumns))

  setEditColumnTemp(state.editColumn)
  try {
    dispatch({ type: 'CHANGE_HEADER', data: true })
  } catch (e) {
    console.log(e)
  }
}

export const resetOrder = ({ schemaColumn, setNewSchema, newSchema }) => {
  let schema = []
  const defaultSchema = schemaColumn.map(data => {
    return data.accessor
  })
  for (let i = 0; i < defaultSchema.length; i++) {
    if (newSchema?.includes(defaultSchema[i])) {
      schema = [...schema, defaultSchema[i]]
    }
  }

  setNewSchema(schema)
}

// export const countEnableColumns = ({
//   module,
//   setNumOfEnableColumns,
//   setTmpOfEnableColumns
// }) => {
//   const key = `tables__${module}`
//   const keyColumnNum = `tablesColumn__${module}`
//   var arrayFromStorage = JSON.parse(localStorage.getItem(key))
//   var arrayLength = arrayFromStorage?.length
//   localStorage.setItem(keyColumnNum, arrayLength)
//   setNumOfEnableColumns(arrayLength)
//   setTmpOfEnableColumns(arrayLength)
// }

export const changeOptionsValue = ({
  column,
  value,
  optionsValue,
  setOptionsValue
}) => {
  const od = { ...optionsValue }
  od[column] = value
  od[`validation_${column}`] = !!value
  setOptionsValue(od)
}

export const hiddenColumn = ({ header, length, setState, state }) => {
  const max = length - Object.keys(state.editColumn).length > 1
  const hide = !state.editColumn.includes(header) // true || false
  const newState = { ...state }
  if (hide && max) {
    state.editColumn.push(header)
    newState.editColumn = state.editColumn
    setState(newState)
  } else if ((!hide && max) || (!max && !hide)) {
    const tmpIndex = state.editColumn.indexOf(header)
    state.editColumn.splice(tmpIndex, 1)
    newState.editColumn = state.editColumn
    setState(newState)
  }
}

import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AppContext from 'context/Context'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getStatusData } from 'ApiService'
import DropdownLanguage from './subComponents/DropdownLanguage'
import DropdownTimeZones from './subComponents/DropdownTimeZones'
import ShipmentStatusEdit from './subComponents/StatusEdit/StatusEdit'
import { dataLanguages, countryCodes } from './service'

// translation
import { useTranslation } from 'react-i18next'
import MappingDashboard from './subComponents/DashboardEdit/MappingDashboard'

const SettingPanel = () => {
  const dispatch = useDispatch()
  const dataColors = useSelector(state => state.spStatusData)
  const location = useLocation()
  const {
    config: { showSettingPanel },
    setConfig
  } = useContext(AppContext)

  // translation
  const { t } = useTranslation()

  const LogoutUser = () => {
    setConfig('showSettingPanel', false)
    localStorage.removeItem('persist:root')
    window.location.reload()
  }

  useEffect(() => {
    getStatusData({ dispatch })
  }, [])

  return (
    <>
      <Modal
        show={showSettingPanel}
        onHide={() => setConfig('showSettingPanel', false)}
        dialogClassName="shipment-filter fade-down"
        centered={false}
        fullscreen={true}
        className="d-flex flex-row-reverse modal right"
      >
        <Modal.Header className="filter-column-header-shipment">
          <div className="filter-column-headerPort-title-shipment text-capitalize">
            {t('Portal Settings')}
          </div>
          <i
            className="ri-arrow-right-s-line filter-column-headerPort-icon-shipment cursor-pointer"
            onClick={() => setConfig('showSettingPanel', false)}
          />
        </Modal.Header>
        <Modal.Body className="font-weight-light">
          <div className="mb-2">
            <span className="title-gray mb-0 text-capitalize">
              {t('Change Language')}
            </span>
            <DropdownLanguage dataLanguages={dataLanguages} t={t} />
          </div>
          <div className="mb-2">
            <span className="title-gray mb-0 text-capitalize">
              {t('Change Time Zone')}
            </span>
            <DropdownTimeZones countryCodes={countryCodes} t={t} />
          </div>
          {(location.pathname === '/dashboard' ||
            location.pathname === '/') && <MappingDashboard t={t} />}
          {(location.pathname === '/shipments' ||
            location.pathname === '/bookings') && (
            <div className="mb-2">
              <span className="title-gray mb-1 text-capitalize">
                {t('edit status')}
              </span>
              <ShipmentStatusEdit dispatch={dispatch} dataColors={dataColors} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="footer-button btn-hover">
            {t('RESET SETTINGS')}
          </button>
          <button
            type="button"
            className="footer-button btn-hover"
            onClick={LogoutUser}
          >
            {t('log_out')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SettingPanel

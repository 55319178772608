import moment from 'moment'

export const dateValueProcess = ({ e, setDefaultValue, state, setState }) => {
  const newState = { ...state }
  setDefaultValue(moment(e.target.value.format('MM/DD/YYYY')))
  const dateNumber = 3
  const dateNumber2 = 4
  const monthNumber = 0
  const monthNumber2 = 1

  if (e.target.value.length >= 10) {
    const value = e.target.value.split('')
    const year = value[6] + value[7] + value[8] + value[9]
    const month = value[monthNumber] + value[monthNumber2]
    const date = value[dateNumber] + value[dateNumber2]
    if (month <= 12 && date <= 31) {
      newState.selectedDay = new Date(`${year}-${month}-${date}`)
      newState.month = new Date(`${year}-${month}-${date}`)
      setState(newState)
    }
  }
}

export const openDatePicker = ({ state, setState }) => {
  const newState = { ...state }
  newState.showDatePicker = true
  setState(newState)
}
export const closeDatePicker = ({ state, setState }) => {
  const newState = { ...state }
  newState.showDatePicker = false
  setState(newState)
}

export const dateValueFormat = e => {
  if (/^[0-9]+$/.test(e.key)) {
    if (e.target.value.length === 2) {
      e.target.value += '/'
    } else if (e.target.value.length === 5) {
      e.target.value += '/'
    }
  }
}

export const disabledAlpha = e => {
  const dateNumber = 2
  const dateNumber2 = 1
  const monthNumber = 3

  if (
    !/[0-9]/g.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }

  // limit spesific character to slash only
  if (e.target.selectionStart == 2 && e.key != '/' && e.key !== 'Backspace') {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 5 &&
    e.key != '/' &&
    e.key !== 'Backspace'
  ) {
    e.preventDefault()
  }

  // limit date
  if (
    e.target.selectionStart == 0 &&
    e.key !== 'Backspace' &&
    e.key > dateNumber2
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 1 &&
    e.key !== 'Backspace' &&
    e.key == 0 &&
    e.target.value[0] == 0
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 1 &&
    e.key !== 'Backspace' &&
    e.key > 1 &&
    e.target.value[0] == dateNumber
  ) {
    e.preventDefault()
  }

  // limit month
  else if (
    e.target.selectionStart == 3 &&
    e.key !== 'Backspace' &&
    e.key > monthNumber
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 4 &&
    e.key !== 'Backspace' &&
    e.key == 0 &&
    e.target.value[3] == 0
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 4 &&
    e.key !== 'Backspace' &&
    e.key > 2 &&
    e.target.value[3] == monthNumber
  ) {
    e.preventDefault()
  }

  // validate month

  // enable month that have more than 29 days if date is 29
  else if (
    e.target.selectionStart == 4 &&
    e.key !== 'Backspace' &&
    e.target.value[0] == 2 &&
    e.target.value[1] == 9 &&
    e.target.value[3] == 0 &&
    /[2]/g.test(e.key)
  ) {
    e.preventDefault()
  }

  // enable month that have more than 30 days if date is 30
  else if (
    e.target.selectionStart == 4 &&
    e.key !== 'Backspace' &&
    e.target.value[0] == 3 &&
    e.target.value[1] == 0 &&
    e.target.value[3] == 0 &&
    /[2]/g.test(e.key)
  ) {
    e.preventDefault()
  }

  // enable month that have 31 days if date is 31
  else if (
    e.target.selectionStart == 4 &&
    e.key !== 'Backspace' &&
    e.target.value[0] == 3 &&
    e.target.value[1] == 1 &&
    e.target.value[3] == 0 &&
    !/[1|3|5|7|8]/g.test(e.key)
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 4 &&
    e.key !== 'Backspace' &&
    e.target.value[0] == 3 &&
    e.target.value[1] == 1 &&
    e.target.value[3] == 1 &&
    !/[0|2]/g.test(e.key)
  ) {
    e.preventDefault()
  }

  // limit year
  else if (
    e.target.selectionStart == 6 &&
    e.key !== 'Backspace' &&
    (e.key == 0 || e.key > 2)
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 7 &&
    e.key !== 'Backspace' &&
    e.key != 9 &&
    e.target.value[6] == 1
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 7 &&
    e.key !== 'Backspace' &&
    e.key > 1 &&
    e.target.value[6] == 2
  ) {
    e.preventDefault()
  } else if (
    e.target.selectionStart == 8 &&
    e.key > 3 &&
    e.key !== 'Backspace' &&
    e.key < 10 &&
    e.target.value[5] == 1
  ) {
    e.preventDefault()
  }
}

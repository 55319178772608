import React from 'react'
import RequiredMessage from '../RequiredMessage'
// translation
import { useTranslation } from 'react-i18next'

const TextAreaInput = ({
  id,
  title,
  autoComplete = 'off',
  onChange,
  placeholder,
  maxLength = 99,
  className,
  name,
  style = null,
  value,
  required,
  readOnly,
  onKeyUp,
  messageRequired,
  parentDivClassName,
  titleClassName,
  messageParam = { messageShow: false, messageData: {}, customMessage: {} }
}) => {
  // translation
  const { t } = useTranslation()

  return (
    <div className={parentDivClassName}>
      {!title ? null : (
        <label
          className={`title-gray mb-0 ${titleClassName} ${
            required ? 'required' : ''
          }`}
        >
          {t(title)}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        autoComplete={autoComplete}
        onChange={e => onChange(e)}
        onKeyUp={e => (onKeyUp ? onKeyUp(e) : null)}
        className={`form-control ${className}`}
        placeholder={placeholder}
        maxLength={maxLength}
        readOnly={readOnly}
        value={value}
        style={style}
        multiple
      />
      {!messageRequired ? null : (
        <RequiredMessage
          column={name}
          messageShow={messageParam?.messageShow}
          columnText={title || placeholder}
          value={messageParam?.value}
          customMessage={messageParam?.customMessage}
        />
      )}
    </div>
  )
}

export default TextAreaInput

import React, { useContext, useState } from 'react'
import HButton from 'components/Bottom'
import AppContext from 'context/Context'
import { Row, Col } from 'react-bootstrap'
import { goToStripe, initialState } from './service'

export default function AccountUpgrade() {
  const {
    config: { isNavbarVerticalCollapsed }
  } = useContext(AppContext)

  const [isChecked, setIsChecked] = useState(initialState)

  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={12} className="sticky-header" style={{ position: 'sticky' }}>
          <div
            className={`${
              isNavbarVerticalCollapsed == true ? 'search-burger' : ''
            }`}
          >
            <Row style={{ height: '30px' }}>
              <Col
                lg={3}
                className="d-flex align-items-center"
                style={{ fontWeight: 'bold' }}
              >
                <p className="module-title">Account Upgrade</p>
              </Col>
              <Col lg={8} className="d-flex align-items-center" />
              <Col
                lg={3}
                className="d-flex  align-items-center justify-content-end"
              />
            </Row>
          </div>
        </Col>
        <Col lg={12} className="">
          <div className="ps-1 ps-xl-1">
            <div className="p-2 mt-4">
              <Row>
                <Col className="m-1 align-items-center card-paging rounded">
                  <div className="p-2">
                    <p style={{ color: 'black' }}>Standard Plan</p>
                    <hr />
                    <div>
                      <p className="m-0 tes">UPGRADE INCLUDE:</p>
                      <label>
                        <ol>
                          <li>Shipment Module</li>
                          <li>User Access Module</li>
                          <li>Accounting Module</li>
                          <li>Platform and Support</li>
                          <li>Iventory Module</li>
                          <li>Access to Dashboard Module</li>
                          <li>Portal Customizable Default Theme</li>
                          <li>Additional UM, Shipments, Accounting Details</li>
                          <li>Shipment Tracking Through Mapping</li>
                          <li>All Feature on Standard Plan</li>
                          <li>Mobile App</li>
                        </ol>
                      </label>
                    </div>
                    <div>
                      <p className="m-0">UPGRADE LIMIT:</p>
                      <label>
                        <ol>
                          <li>3 Admin User</li>
                          <li>3 Months Shipments Data Storage</li>
                          <li>Only 50 user creation</li>
                          <li>Only 2 device accessible for 1 user account</li>
                        </ol>
                      </label>
                    </div>
                    <div>
                      <h3 className="d-flex justify-content-end">
                        880 USD/month
                      </h3>
                      <HButton
                        icon="ri-filter-3-line"
                        text="GET STARTED"
                        className="w-100"
                        module
                        onClick={() =>
                          goToStripe('price_1L15fIEoRSW9li2KzgIGhs9U')
                        }
                      />
                    </div>
                  </div>
                </Col>

                <Col className="m-1 align-items-center card-paging position-relative rounded">
                  <div className="p-2">
                    <p style={{ color: 'black' }}>Premium Plan</p>
                    <hr />
                    <div>
                      <p className="m-0">UPGRADE INCLUDE:</p>
                      <label>
                        <ol>
                          <li>8 Admin Users</li>
                          <li>200+ User Creation</li>
                          <li>1 Year Shipment Data Storage</li>
                          <li>Multiple Devices Access for One Account</li>
                          <li>Customizable Portal Template</li>
                          <li>Customizable Invoice Template</li>
                          <li>Customizable Dashboard</li>
                          <li>Customizable Data Fields</li>
                          <li>Additional Request Module</li>
                          <li>Unlimited Language Options</li>
                          <li>All Feature on Standard Plan</li>
                        </ol>
                      </label>
                    </div>
                    <div className="card-upgrade-custom p-2">
                      <h3 className="d-flex justify-content-end">
                        2,500 USD/month
                      </h3>
                      <HButton
                        icon="ri-filter-3-line"
                        text="GET STARTED"
                        className="w-100"
                        module
                        onClick={() =>
                          goToStripe('price_1L15gKEoRSW9li2KddUvdUvS')
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col className="m-1 align-items-center card-paging position-relative rounded">
                  <div className="p-2">
                    <p style={{ color: 'black' }}>Custom Plan</p>
                    <hr />
                    <label className="ps-2">
                      <Row>
                        <Col xl={5}>
                          <p className="me-4">No. of Admin User</p>
                          <p className="me-4">No. of Regular User</p>
                          <p className="me-4">data storage duration</p>
                        </Col>
                        <Col className="d-grid">
                          {Array.from({ length: 3 }).map(() => (
                            <>
                              <input
                                type="number"
                                style={{ width: '35px', height: '30px' }}
                              />
                            </>
                          ))}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xl={5}>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.a ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, a: !isChecked.a })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Shipment Module
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.b ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, b: !isChecked.b })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Booking Module
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.c ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, c: !isChecked.c })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Accounting Module
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.d ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, d: !isChecked.d })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              User Module
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.e ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, e: !isChecked.e })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Dashboard Module
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.f ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, f: !isChecked.f })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Setting Module
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.g ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, g: !isChecked.g })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Customizable Portal
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.h ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, h: !isChecked.h })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Customizable Invoice
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.i ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, i: !isChecked.i })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Customizable Dashboard
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.j ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, j: !isChecked.j })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Customizable Data Field
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <button
                              className={`btn checkDropdown ${
                                isChecked.k ? 'ActivecheckDropdown' : ''
                              }`}
                              onClick={() =>
                                setIsChecked({ ...isChecked, k: !isChecked.k })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Multiple Device Access for 1 Reguler Account
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </label>
                    <div className="card-upgrade-custom p-2">
                      <HButton
                        icon="ri-filter-3-line"
                        text="SEND REQUEST"
                        className="w-100"
                        module
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

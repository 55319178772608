import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { detailInformationHeader } from './HeadersAndOptions/headers'
import InputTypesHandler from './Handler/InputTypesHandler'

const ModeAndMessageForm = ({ t }) => {
  const lastHeader = detailInformationHeader[detailInformationHeader.length - 1]
  return (
    <Row className="p-3 gap-3 modeMessageForm">
      <h3 className="formTitle">Detail Information</h3>
      <Col>
        {detailInformationHeader.map((header, index) => {
          return (
            index !== detailInformationHeader.length - 1 && (
              <div key={index}>
                <InputTypesHandler
                  header={header}
                  t={t}
                  module="detail_information"
                />
              </div>
            )
          )
        })}
      </Col>
      <Col className="textArea">
        <InputTypesHandler
          className="full-height"
          header={lastHeader}
          t={t}
          module="detail_information"
        />
      </Col>
    </Row>
  )
}

export default ModeAndMessageForm

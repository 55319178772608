import HeaderCard from 'components/HeaderCard'
import moment from 'moment'
import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DashboardInfoBox from './DashboardInfoBox'
import ProgressBarCard from './ProgressBarCard'

const OrderStatus = ({ date, lastDate }) => {
  const { t } = useTranslation()
  const totalOrders = 254545
  const increasedOrders = 23032
  const bookings = 20000
  const activeShipments = 34545
  const completedShipments = 200000
  const nowMonth = moment(date).format('MMMM')
  const nowYear = moment(date).format('YYYY')
  const lastMonth = moment(lastDate).format('MMMM')
  const lastYear = moment(lastDate).format('YYYY')

  return (
    <Col
      lg={8}
      sm={12}
      className="bg-white p-3 border dashboard-col " //height-fill-rest
    >
      <HeaderCard
        title={`${t('Order status')}: ${t(nowMonth)} ${nowYear}`}
        titleSize={20}
      />
      <div className="d-flex align-items-center dashboard-border total-order-dashboard">
        <Col xs={5}>
          <span className="dashboard-total-orders">{t('Total Orders')}</span>
          <div className="d-flex column align-items-center">
            <span className="dashboard-total-orders-number">
              {totalOrders.toLocaleString()}
            </span>
            <div className="d-flex column align-items-center">
              <div className="px-2">
                <div className="triangle-dashboard-up" />
              </div>
              <div className="d-flex row align-items-center">
                <span className="dashboard-order-increase">
                  {t('order increased')}
                </span>
                <span className="dashboard-order-increase-number">
                  {increasedOrders.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={7} className="align-items-center">
          <DashboardInfoBox
            borderWidth="1px"
            borderColor="#EEEEF9"
            color1="#D5D9E873"
            colorWidth1={`${
              ((totalOrders - increasedOrders) / totalOrders) * 100
            }%`}
            height="33px"
            marginBottom="8px"
          >
            <span className="dashboard-order-status-month status-month-1">
              {t(nowMonth)} {nowYear}
            </span>
          </DashboardInfoBox>
          <DashboardInfoBox
            borderWidth="1px"
            borderColor="#EEEEF9"
            color1="#D5D9E8"
            colorWidth1="100%"
            height="33px"
          >
            <span className="dashboard-order-status-month status-month-2">
              {t(lastMonth)} {lastYear}
            </span>
          </DashboardInfoBox>
        </Col>
      </div>
      <div className="mt-1 dashboard-border total-order-info-dashboard">
        <ProgressBarCard
          active={(activeShipments / totalOrders) * 100}
          completed={(completedShipments / totalOrders) * 100}
          booking={(bookings / totalOrders) * 100}
        />
        <div className="d-flex column justify-content-start mt-2">
          <div className="d-flex row">
            <span className="title-info booking-color">{t('Bookings')}</span>
            <span className="number-info booking-color">
              {bookings.toLocaleString()}
            </span>
          </div>
          <div className="d-flex row">
            <span className="title-info active-shipments-color">
              {t('Active Shipments')}
            </span>
            <span className="number-info active-shipments-color">
              {activeShipments.toLocaleString()}
            </span>
          </div>
          <div className="d-flex row">
            <span className="title-info completed-shipments-color">
              {t('Completed Shipments')}
            </span>
            <span className="number-info completed-shipments-color">
              {completedShipments.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default OrderStatus

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { changeUserPassword } from 'ApiService'
import { toast } from 'react-toastify'
import { Placeholder } from 'react-bootstrap'

const UserPasswordSection = ({
  showChangeUserPassword,
  setShowChangeUserPassword,
  t
}) => {
  const dispatch = useDispatch()
  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const changePasswordLoading = useSelector(
    state => state.spUserChangePasswordStatus
  )

  const handleChangePassword = async () => {
    const changePassword = await changeUserPassword({
      dispatch: dispatch,
      currentPassword: currentPassword,
      newPassword: newPassword,
      newPasswordConfirmation: confirmPassword
    })
    if (changePassword === true) {
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
      notify({ text: 'Password changed!', status: 'success' })
    } else {
      notify({ text: 'Error changing password!', status: 'error' })
    }
  }

  return (
    <Modal
      show={showChangeUserPassword}
      onHide={() => {
        setShowChangeUserPassword(false)
      }}
      dialogClassName="shipment-filter fade-down"
      backdropClassName="d-none"
      centered={false}
      fullscreen={true}
      className="d-flex flex-row-reverse modal right"
    >
      <Modal.Header className="filter-column-header-shipment">
        <div className="filter-column-headerPort-title-shipment text-capitalize">
          {t('Change Password')}
        </div>
        <i
          className="ri-arrow-right-s-line filter-column-headerPort-icon-shipment cursor-pointer"
          onClick={() => setShowChangeUserPassword(false)}
        />
      </Modal.Header>
      <Modal.Body className="font-weight-light">
        <div className="position-relative mb-1">
          <div className="w-100 d-block mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('old password')}
            </span>
            {changePasswordLoading ? (
              <Loading />
            ) : (
              <input
                className="setting-input mb-1"
                placeholder={t('Enter current password')}
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
                type="password"
              />
            )}
            <span className="fieldTitle mb-0 text-capitalize">
              {t('new password')}
            </span>
            {changePasswordLoading ? (
              <Loading />
            ) : (
              <input
                className="setting-input mb-1"
                placeholder={t('Enter new password')}
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                type="password"
              />
            )}
            <span className="fieldTitle mb-0 text-capitalize">
              {t('confirm new password')}
            </span>
            {changePasswordLoading ? (
              <Loading />
            ) : (
              <input
                className="setting-input mb-1"
                placeholder={t('Confirm new password')}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                type="password"
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleChangePassword}
          className="footer-button btn-hover"
        >
          {t('change password')}
        </button>
        <button
          onClick={() => setShowChangeUserPassword(false)}
          type="button"
          className="footer-button btn-hover"
        >
          {t('cancel')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserPasswordSection

const notify = ({ text, status }) => {
  if (status === 'warn') {
    toast.warn(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
  if (status === 'success') {
    toast.success(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
  if (status === 'error') {
    toast.error(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
}

const Loading = () => {
  return (
    <Placeholder
      className="d-flex mb-1"
      animation="glow"
      onClick={e => e.stopPropagation()}
    >
      <div className="d-flex w-100">
        <Placeholder className="input-loading w-100" />
      </div>
    </Placeholder>
  )
}

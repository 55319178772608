import React, { useState, useEffect } from 'react'
import RequiredMessage from '../RequiredMessage'
// import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import moment from 'moment'
import ReactResizeDetector from 'react-resize-detector'
import {
  dateValueProcess,
  openDatePicker,
  dateValueFormat,
  closeDatePicker,
  disabledAlpha
} from './service'

const dateFormate = 'MM/DD/YYYY'

export default function DatePicker({
  title,
  placeholder,
  name,
  required,
  messageRequired,
  className,
  parentDivClassName,
  titleClassName,
  firstDate,
  formStyle,
  style,
  right,
  fixedTop,
  top,
  isDisable,
  tabIndex,
  arrowStyle,
  fromMonth,
  onChange,
  defaultValues,
  messageParam = { messageShow: false, messageData: {}, customMessage: {} }
}) {
  const [defaultValue, setDefaultValue] = useState('')

  const [state, setState] = useState({
    selectedDay: defaultValue ? defaultValue : new Date(),
    showDatePicker: false,
    month: defaultValue ? defaultValue : new Date(),
    monthChange: false,
    top: null,
    left: null
  })

  useEffect(() => {
    const newState = { ...state }
    if (defaultValues) {
      newState.defaultValue = defaultValues
      setState(newState)
    }
  }, [])

  const firstDateData = new Date(firstDate)
  firstDateData.setDate(firstDateData.getDate() + 1)
  const no = Math.floor(Math.random() * 100000) + 1

  const handleDayClick = (day, modifiers = {}) => {
    if (!modifiers.disabled) {
      const newState = { ...state }
      newState.selectedDay = day
      newState.defaultValue = moment(day).format(dateFormate)
      newState.showDatePicker = false
      setState(newState)
      if (onChange) {
        onChange(day)
      }
    }
  }

  const valueDefault = moment(defaultValues).format(dateFormate)

  return (
    <div
      className={`datePicker select_date pt-1 ${parentDivClassName}`}
      style={style}
      tabIndex={tabIndex ? tabIndex : null}
    >
      {title && (
        <span
          className={`title-gray ${titleClassName} ${
            required ? 'required' : ''
          }`}
        >
          {title}
        </span>
      )}

      <div className={`form-datePicker ${className}`}>
        <input
          type="text"
          disabled={isDisable}
          placeholder={placeholder}
          value={
            valueDefault && !valueDefault === 'Invalid date'
              ? valueDefault
              : state.defaultValue
          }
          style={formStyle}
          onChange={e =>
            dateValueProcess({ e, setDefaultValue, state, setState })
          }
          onFocus={() => {
            if (!isDisable) {
              openDatePicker({ state, setState })
            }
          }}
          onKeyDown={e => disabledAlpha(e)}
          onKeyUp={e => dateValueFormat(e)}
        />
        <i
          className="ri-calendar-todo-line"
          onClick={() => openDatePicker({ state, setState })}
        />
      </div>

      <input
        className={`select_date_expand ${
          arrowStyle ? 'select_arrow_expand' : 'select_calendar_expand'
        }`}
        type="checkbox"
        name={`select${placeholder}${no}`}
        checked={state.showDatePicker}
        id={`select-opener${placeholder}`}
        onChange={() => {}}
      />
      <label
        className="select_date_closeLabel"
        htmlFor={`select-opener${placeholder}${no}`}
        onClick={() => closeDatePicker({ state, setState })}
        aria-hidden="true"
      />

      <ReactResizeDetector handleWidth handleHeight refreshRate={2000}>
        {({ width, height }) => (
          <div
            className={`select_date_options ${
              (top && fixedTop) || fixedTop ? 'fixed-top-position' : ''
            }`}
            style={
              (top && !fixedTop
                ? { marginTop: `-${height}px`, marginLeft: `-${width + 6}px` }
                : null) ||
              (right && !fixedTop
                ? { marginTop: '-50px', marginLeft: `${width + 24}px` }
                : null)
            }
          >
            <DayPicker
              className="datepicker-content"
              tabIndex="-1"
              selectedDays={state.selectedDay ? state.selectedDay : new Date()}
              onDayClick={handleDayClick}
              fromMonth={
                fromMonth
                  ? new Date(fromMonth)
                  : new Date(new Date().getFullYear() - 5, 0)
              }
            />
          </div>
        )}
      </ReactResizeDetector>

      {!messageRequired ? null : (
        <RequiredMessage
          column={name}
          messageShow={messageParam?.messageShow}
          columnText={title || placeholder}
          value={messageParam?.value}
          customMessage={messageParam?.customMessage}
        />
      )}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getCurrentUserDetail } from 'ApiService'
import { useTranslation } from 'react-i18next'
import DropdownOrganizationCode from './subComponents/DropdownOrganizationCode'
import DropdownUserType from './subComponents/DropdownUserType'

const CreateNewUser = ({ showCreateNewUser, setShowCreateNewUser }) => {
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [organizationCode, setOrganizationCode] = useState()
  const [organizationCodeList, setOrganizationCodeList] = useState([])
  const [userEmail, setUserEmail] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [userType, setUserType] = useState()
  const [userTypeList, setUserTypeList] = useState([])

  const { t } = useTranslation()

  useEffect(() => {
    getCurrentUserDetail({ dispatch })
    setOrganizationCodeList([
      {
        value: 'obo',
        label: 'OneByOne'
      },
      {
        value: 'sumitomoTokyo',
        label: 'Sumitomo Soko Tokyo'
      },
      {
        value: 'sumitomoOsaka',
        label: 'Sumitomo Soko OSAKA'
      }
    ])
    setUserTypeList([
      {
        value: 'admin',
        label: 'Admin'
      },
      {
        value: 'regular',
        label: 'Regular'
      }
    ])
  }, [])

  return (
    <>
      <Modal
        show={showCreateNewUser}
        onHide={() => setShowCreateNewUser(false)}
        dialogClassName="shipment-filter fade-down"
        centered={false}
        fullscreen={true}
        className="d-flex flex-row-reverse modal right"
      >
        <Modal.Header className="filter-column-header-shipment">
          <div className="filter-column-headerPort-title-shipment text-capitalize">
            {t('create new user')}
          </div>
          <i
            className="ri-arrow-right-s-line filter-column-headerPort-icon-shipment cursor-pointer"
            onClick={() => setShowCreateNewUser(false)}
          />
        </Modal.Header>
        <Modal.Body className="font-weight-light">
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('organisation code')}
            </span>
            <div className="w-100 d-flex">
              <DropdownOrganizationCode
                organizationCodeList={organizationCodeList}
                organizationCode={organizationCode}
                setOrganizationCode={setOrganizationCode}
                t={t}
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('user type')}
            </span>
            <div className="w-100 d-flex">
              <DropdownUserType
                userTypeList={userTypeList}
                userType={userType}
                setUserType={setUserType}
                t={t}
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('first name')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter first name')}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('last name')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter last name')}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('email')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter email address')}
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('password')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter password')}
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
              />
            </div>
          </div>
          <div className="position-relative mb-1">
            <span className="fieldTitle mb-0 text-capitalize">
              {t('confirm password')}
            </span>
            <div className="w-100 d-flex">
              <input
                className="setting-input mb-1"
                placeholder={t('Enter confirm password')}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                type="password"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div type="button" className="footer-button btn-hover">
            {t('create user')}
          </div>
          <button type="button" className="footer-button btn-hover">
            {t('reset')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateNewUser

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { defaultSortMethod, SchemaHeader } from './service'
import { getStatusData, getSummaryData, getAllInvoice } from 'ApiService'
import Table from 'components/Table'
import CToast from 'components/Toast'
import {
  dataShipmentSummary,
  dataShipmentListDemo
} from 'helpers/dataDummyDemo'

// translation
import { useTranslation } from 'react-i18next'

const Payment = ({ navigate }) => {
  const user = useSelector(state => state.user)
  const userAccess = user?.role
  const dispatch = useDispatch()
  const dataSummary = useSelector(state => state.spInvoiceSummaryData)
  const summaryTableStatus = useSelector(state => state.spSummaryDataStatus)
  const dataPagination = useSelector(state => state.paginationSp)
  const stateChangeHeader = useSelector(state => state.changeHeader)
  const getColumn = useSelector(state => state.getColumn)
  const filteredData = useSelector(state => state.spSummaryDataFiltered)
  const displayFilterData = useSelector(state => state.spSummaryFilterDisplay)
  const keyword = useSelector(state => state.spSummaryKeyword)
  const module = 'Demo Payment'
  const [sortedCol, setSortedCol] = useState([])
  const [sortedColPrev, setSortedColPrev] = useState()
  const [showToastNoColumn, setShowToastNoColumn] = useState(false)
  const [modalFilter, setModalFilter] = useState()
  const fieldDropdownFilter = dataShipmentListDemo
  const tableData = dataSummary

  // translation
  const { t } = useTranslation()
  const [columnHidden, setColumnHidden] = useState(null)
  const [state2, setState2] = useState(null)

  const height = 'calc(100vh - 150px)'
  const width = 'window.innerWidth'

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      moduleName: t(module),
      actionsButton: true,
      actionsMenuList: [
        {
          title: t('Create Payment'),
          function: () => {
            window.open(
              'https://buy.stripe.com/test_3cs02k2lL8Bt39S006', //dev
              // 'https://buy.stripe.com/test_8wM9Eq5EecIRecwdQQ',
              '_blank'
            )
          }
        }
      ]
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  useEffect(() => {
    if (state2) {
      const columnHidden = localStorage.getItem('tableColumns')
        ? JSON.parse(localStorage.getItem('tableColumns'))
        : []
      let tmp = null
      columnHidden?.map(data => {
        if (data.title === 'Shipments') {
          tmp = data.columns
        }
      })
      if (tmp) {
        setColumnHidden(tmp)
      } else {
        setColumnHidden([])
      }
      setState2(false)
      dispatch({ type: 'CHANGE_HEADER', data: false })
    }
  }, [state2])

  useEffect(() => {
    getSummaryData({
      dispatch,
      // PageNumber: dataPagination?.active ?? '1',
      module: 'Payments'
      // searchVal: keyword
      // sortBy: 'id',
      // desc: sortedCol[0].desc == true ? 'desc' : 'asc'
    })
  }, [sortedCol])

  return (
    <>
      <Row>
        <Col lg={12} className="custom-table-padding">
          <div className="ps-0 ps-xl-1">
            <div className="page-table">
              <CToast
                showToast={showToastNoColumn}
                setShowToast={setShowToastNoColumn}
                className="toast-EditColumn"
                caption="Table column is empty, please adjust or reset it to show data"
              />
              <Table
                id={'tablePayments'}
                onClick={event => {
                  if (event.hosted_invoice_url)
                    window.open(event.hosted_invoice_url, '_blank')
                  // if (event.invoice_pdf)
                  // window.open(event.invoice_pdf, '_blank')
                }}
                schemaColumn={SchemaHeader(t)}
                defaultSorted={sortedCol}
                onSortedChange={e => {
                  setSortedColPrev(sortedCol)
                  setSortedCol(e)
                }}
                defaultSortMethod={defaultSortMethod}
                data={tableData}
                style={{
                  minHeight: height,
                  maxHeight: height,
                  maxWidth: width
                }}
                module={module}
                loadingStatus={summaryTableStatus}
                noDataText
                modalFilter={modalFilter}
                setModalFilter={setModalFilter}
                dataListDropdown={fieldDropdownFilter}
                title="Payments Summary"
                columnHidden={columnHidden}
                className="clearShadow"
                tableName="tablePayments"
                t={t}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Payment

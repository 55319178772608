export const validationRequest = async ({
  formData,
  packageDatails,
  setPackageDatails,
  changeContent,
  setIsValidation
}) => {
  setIsValidation(true)
  // initial
  let statusValidate = true
  const formDataValidation = [
    'validation_email',
    'validation_originAddress',
    'validation_destinationAddress',
    'validation_contant'
  ]
  const linessValidation = [
    'validation_height',
    'validation_lenght',
    'validation_widht',
    'validation_quantity'
  ]

  // validasi order details
  formDataValidation.forEach(key => {
    if (formData[key] !== true) statusValidate = false
  })

  // validasi orderLines
  packageDatails.map(data => {
    data.validation = true
    linessValidation.forEach(key => {
      if (data[key] !== true) statusValidate = false
    })
    return false
  })
  const newLines = [...packageDatails]
  setPackageDatails(newLines)

  if (packageDatails.length < 1) {
    statusValidate = false
  }

  if (statusValidate) {
    changeContent('result')
  }
}

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import UserDetailSection from './subComponents/UserDetailSection'
import UserPasswordSection from './subComponents/UserPasswordSection'

const AccountSetting = ({
  showAccountSetting,
  setShowAccountSetting,
  currentUserId,
  userDetail,
  userDetailLoading
}) => {
  const { t } = useTranslation()
  const [showChangeUserPassword, setShowChangeUserPassword] = useState(false)

  return (
    <>
      <Modal
        show={showAccountSetting}
        onHide={() => setShowAccountSetting(false)}
        dialogClassName="shipment-filter fade-down"
        centered={false}
        fullscreen={true}
        className="d-flex flex-row-reverse modal right"
      >
        <Modal.Header className="filter-column-header-shipment">
          <div className="filter-column-headerPort-title-shipment text-capitalize">
            {t('user access')}
          </div>
          <i
            className="ri-arrow-right-s-line filter-column-headerPort-icon-shipment cursor-pointer"
            onClick={() => setShowAccountSetting(false)}
          />
        </Modal.Header>
        <Modal.Body className="font-weight-light">
          <UserDetailSection
            userDetail={userDetail}
            userDetailLoading={userDetailLoading}
            t={t}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="footer-button btn-hover">
            {t('update details')}
          </button>
          {currentUserId === userDetail?.id && (
            <div
              type="button"
              onClick={() => {
                setShowChangeUserPassword(true)
              }}
              className="footer-button btn-hover"
            >
              {t('Change password')}
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <UserPasswordSection
        showChangeUserPassword={showChangeUserPassword}
        setShowChangeUserPassword={setShowChangeUserPassword}
        t={t}
      />
    </>
  )
}

export default AccountSetting

import React, { useEffect, useState } from 'react'
import { getSummaryData, getFilteredSummary } from 'ApiService'
import { useDispatch, useSelector } from 'react-redux'
import { changePagination } from './service'
import { Form } from 'react-bootstrap'

const HPagination = ({ data, module, navigate }) => {
  const dispatch = useDispatch()
  const keyword = useSelector(state => state.spSummaryKeyword)
  const filterValue = useSelector(state => state.spSummaryFilterValue)
  const displayFilterData = useSelector(state => state.spSummaryFilterDisplay)
  const spOrderBy = useSelector(state => state.spOrderBy)
  const spOrderType = useSelector(state => state.spOrderType)
  const [active, setActive] = useState('0')
  const [classPrevious, setClassPrevious] = useState('')
  const [classNext, setClassNext] = useState('')
  const [timer, setTimer] = useState(null)

  const getClassPrevious = async _data => {
    if (_data?.totalPage >= 1) {
      if (_data?.active - 1 > 0) {
        setClassPrevious('paging-arrow-left')
        return
      }
    }
    setClassPrevious('paging-arrow-left-disable')
  }

  const getClassNext = async _data => {
    if (_data?.totalPage >= 1) {
      if (_data?.totalPage !== _data?.active) {
        setClassNext('paging-arrow-right')
        return
      }
    }
    setClassNext('paging-arrow-right-disable')
  }

  useEffect(() => {
    if (data) {
      setActive(data.active)
      getClassNext(data)
      getClassPrevious(data)
    }
  }, [data])

  return (
    <ul className="paging-box d-flex m-0 p-2">
      <li
        className={`${classPrevious} paging-arrow paging-arrow-left`}
        onClick={() => {
          if (data?.active === 1) console.log('already on the first page')
          else {
            changePagination({
              data,
              dispatch,
              getSummaryData,
              getFilteredSummary,
              funct: 'First',
              module,
              keyword,
              filterValue,
              displayFilterData,
              navigate,
              sortBy: spOrderBy,
              desc: spOrderType
            })
          }
        }}
      >
        <i className="ri-skip-back-line" />
      </li>
      <li
        className={`${classPrevious} paging-arrow paging-arrow-left`}
        onClick={() => {
          if (data?.active === 1) console.log('already on the first page')
          else {
            changePagination({
              data,
              dispatch,
              getSummaryData,
              getFilteredSummary,
              funct: 'Previously',
              module,
              keyword,
              filterValue,
              displayFilterData,
              navigate,
              sortBy: spOrderBy,
              desc: spOrderType
            })
          }
        }}
      >
        <i className="ri-arrow-left-s-line" />
      </li>

      <li className="paging-number-total">
        <Form.Control
          type="text"
          value={active}
          className="paging-number-active"
          maxLength={4}
          onFocus={e => {
            setActive(e.target.value)
          }}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              changePagination({
                data,
                dispatch,
                getSummaryData,
                getFilteredSummary,
                funct: 'onChange',
                module,
                keyword,
                filterValue,
                displayFilterData,
                navigate,
                sortBy: spOrderBy,
                desc: spOrderType,
                onChangeValue: active
              })
            }
          }}
          onBlur={() =>
            changePagination({
              data,
              dispatch,
              getSummaryData,
              getFilteredSummary,
              funct: 'onChange',
              module,
              keyword,
              filterValue,
              displayFilterData,
              navigate,
              sortBy: spOrderBy,
              desc: spOrderType,
              onChangeValue: active
            })
          }
          onChange={e => {
            setActive(e.target.value)

            clearTimeout(timer)
            const newTimer = setTimeout(() => {
              changePagination({
                data,
                dispatch,
                getSummaryData,
                getFilteredSummary,
                funct: 'onChange',
                module,
                keyword,
                filterValue,
                displayFilterData,
                navigate,
                sortBy: spOrderBy,
                desc: spOrderType,
                onChangeValue: e.target.value
              })
            }, 1500)
            setTimer(newTimer)
          }}
        />
        <div className="paging-number-of">of</div>
        <div className="paging-total-result">
          {data ? data?.totalPage ?? 1 : 0}
        </div>
      </li>
      <li
        className={`${classNext} paging-arrow`}
        onClick={() => {
          if (data.active < data.lastPage) {
            changePagination({
              data,
              dispatch,
              getSummaryData,
              getFilteredSummary,
              funct: 'Next',
              module,
              keyword,
              filterValue,
              displayFilterData,
              navigate,
              sortBy: spOrderBy,
              desc: spOrderType
            })
          }
        }}
      >
        <i className="ri-arrow-right-s-line" />
      </li>
      <li
        className={`${classNext} paging-arrow`}
        onClick={() => {
          if (data?.active === data?.totalPage)
            console.log('already on the last page')
          else {
            changePagination({
              data,
              dispatch,
              getSummaryData,
              getFilteredSummary,
              funct: 'Last',
              module,
              keyword,
              filterValue,
              displayFilterData,
              navigate,
              sortBy: spOrderBy,
              desc: spOrderType
            })
          }
        }}
      >
        <i className="ri-skip-forward-line" />
      </li>
    </ul>
  )
}

export default HPagination

import React from 'react'
import { Row, Col } from 'react-bootstrap'

import { pickupFromHeader, deliveryToHeader } from './HeadersAndOptions/headers'
import InputTypesHandler from './Handler/InputTypesHandler'

const PickUpAndDeliverForm = ({ t }) => {
  return (
    <Row>
      <Col className="p-3 gap-3 pickUpAddress" lg={6} sm={12}>
        <h3 className="formTitle">{t('Pick Up From')}</h3>
        <Row>
          {pickupFromHeader.map((header, index) => {
            const { lg, accessor } = header
            return (
              <Col lg={lg} key={index} className={`${accessor}`}>
                <InputTypesHandler header={header} t={t} module="pickup_from" />
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col className="p-3 deliveryAddress" lg={6} sm={12}>
        <h3 className="formTitle">{t('Delivery To')}</h3>
        <Row>
          {deliveryToHeader.map((header, index) => {
            const { lg, accessor } = header
            return (
              <Col lg={lg} key={index} className={`${accessor}`}>
                <InputTypesHandler header={header} t={t} module="delivery_to" />
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}

export default PickUpAndDeliverForm

import React from 'react'
import { Col } from 'react-bootstrap'

const TemplateLocation = ({ number, type, onSelect, activeIdx }) => {
  return (
    <div className="mapping-dashboard">
      <Col>
        <div
          className={`rectangle ${type} ${
            activeIdx === number ? 'selected' : ''
          }`}
          onClick={() => {
            onSelect(number)
          }}
        >
          {number}
        </div>
      </Col>
    </div>
  )
}

export default TemplateLocation

export const changeCreateFrom = ({
  column,
  value,
  createFrom,
  SetCreateFrom
}) => {
  const od = { ...createFrom }
  od[column] = value

  SetCreateFrom(od)
}

export const changeCreateFromPhone = ({
  column,
  value,
  checkBox,
  checkBoxValue,
  createFrom,
  SetCreateFrom
}) => {
  const od = { ...createFrom }
  od[column] = value
  od[checkBox] = checkBoxValue

  SetCreateFrom(od)
}

export const changeCreateFromRole = ({
  column,
  values,
  createFrom,
  SetCreateFrom
}) => {
  const od = { ...createFrom }
  od[column] = values
  od['validation_' + column] = values ? true : false

  SetCreateFrom(od)
}

export const checkPage = ({ dispatch, datas, pages }) => {
  if (datas?.length > 0) {
    const index = datas.findIndex(x => x.label === 'More')
    if (index != -1) {
      datas.splice(index, 1)
    }
    if (pages?.current_page != pages?.last_page) {
      datas = [
        ...datas,
        {
          value: 'More',
          label: 'More'
        }
      ]
      dispatch({ type: 'USER_ORG_NAME ', data: datas })
    }
  }
}

export const genderList = [
  { value: 'M', label: 'M' },
  { value: 'F', label: 'F' }
]
export const orgList = [
  { value: 'FRUTEXSYD', label: 'FRUTEXSYD' },
  { value: 'ONEBYONE', label: 'ONEBYONE' },
  { value: 'CONTROLGLOBALLOGISTIC', label: 'CONTROLGLOBALLOGISTIC' }
]

export const assignDetail = ({ userDetail, createFrom, SetCreateFrom }) => {
  const od = { ...createFrom }
  od.portalLogin = userDetail?.portalLogin
  od.fullName = userDetail?.fullName
  od.portalEmail = userDetail?.email[0] ?? null
  od.roleid = 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51'
  SetCreateFrom(od)
}

const INITIAL_STATE = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  customDarkMode: true,
  user: null,
  loading: true,
  expired: false,
  lastChangedUser: null,
  navCollapsed: false,
  markedRow: []
}

// REDUCERS
export const rootReducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case 'LOGIN':
      return { ...state, user: data, expired: false }
    case 'LOGOUT':
      return { ...state, user: null, po_resources: null }
    case 'ASIDE':
      return { ...state, asideShow: data }
    case 'SIDEBAR':
      return { ...state, sidebarShow: data }
    case 'DARKMODE':
      return { ...state, darkModeMLS: data }
    case 'CONTENTNAV':
      return { ...state, contentNav: data }
    // Table Status
    case 'REORDER':
      return { ...state, reorder: data }
    // and Table Status

    // Available Filter
    case 'AVAILABLE_FILTER':
      return { ...state, availAbleFilter: data }
    // Shipment
    case 'GET_SP_SUMMARY_KEYWORD':
      return { ...state, spSummaryKeyword: data }
    case 'GET_SP_SUMMARY':
      return { ...state, spSummaryData: data }
    case 'GET_SP_SUMMARY_FILTERED':
      return { ...state, spSummaryDataFiltered: data }
    case 'GET_SP_SUMMARY_STATUS':
      return { ...state, spSummaryDataStatus: data }
    case 'GET_SP_SUMMARY_FLAGS_STATUS':
      return { ...state, spSummaryDataFlagsStatus: data }
    case 'DISPLAY_SP_SUMMARY_FILTERED':
      return { ...state, spSummaryFilterDisplay: data }
    case 'SP_SUMMARY_FILTER_Value':
      return { ...state, spSummaryFilterValue: data }
    case 'PAGING_SP':
      return { ...state, paginationSp: data }
    case 'SP_PAYMENT_SUMMARY':
      return { ...state, spInvoiceSummaryData: data }

    // Shipment Detail [New]
    case 'SP_SUMMARY_DETAIL_SHIPMENT':
      return { ...state, spSummaryDetailShipment: data }
    case 'SP_SUMMARY_DETAIL_SHIPMENT_CONTAINER':
      return { ...state, spSummaryDetailShipmentContainer: data }
    case 'SP_SUMMARY_DETAIL_SHIPMENT_LAST_MILESTONE':
      return { ...state, spSummaryDetailShipmentLastMilestone: data }
    case 'SP_SUMMARY_DETAIL_SHIPMENT_TRANSPORT':
      return { ...state, spSummaryDetailShipmentTransport: data }
    case 'SP_SUMMARY_DETAIL_SHIPMENT_GOODS_PACK':
      return { ...state, spSummaryDetailShipmentGoodsPack: data }
    case 'SP_SUMMARY_DETAIL_SHIPMENT_ORDERS':
      return { ...state, spSummaryDetailShipmentOrders: data }
    case 'SP_SUMMARY_SHIPMENT_DETAIL_REFERENCES':
      return { ...state, spSummaryDetailShipmentReferences: data }
    case 'SP_SUMMARY_SHIPMENT_DETAIL_EDOCS':
      return { ...state, spSummaryDetailShipmentEdocs: data }
    // --- END --- //

    case 'SP_SUMMARY_DETAIL_SHIPPER':
      return { ...state, spSummaryDetailShipper: data }
    case 'SP_SUMMARY_DETAIL_SHIPPER_STATUS':
      return { ...state, spSummaryDetailShipperStatus: data }
    case 'SP_SUMMARY_DETAIL_CONSIGNEE':
      return { ...state, spSummaryDetailConsignee: data }
    case 'SP_SUMMARY_DETAIL_CONSIGNEE_STATUS':
      return { ...state, spSummaryDetailConsigneeStatus: data }
    case 'SP_SUMMARY_DETAIL_AGENT':
      return { ...state, spSummaryDetailAgent: data }
    case 'SP_SUMMARY_DETAIL_AGENT_STATUS':
      return { ...state, spSummaryDetailAgentStatus: data }
    case 'SP_SUMMARY_DETAIL_GOODS':
      return { ...state, spSummaryDetailGoods: data }
    case 'SP_SUMMARY_DETAIL_GOODS_STATUS':
      return { ...state, spSummaryDetailGoodsStatus: data }
    case 'SP_SUMMARY_DETAIL_CONTAINERS':
      return { ...state, spSummaryDetailContainers: data }
    case 'SP_SUMMARY_DETAIL_CONTAINERS_STATUS':
      return { ...state, spSummaryDetailContainersStatus: data }
    case 'SP_SUMMARY_DETAIL_REFERENCE':
      return { ...state, spSummaryDetailReference: data }
    case 'SP_SUMMARY_DETAIL_REFERENCE_STATUS':
      return { ...state, spSummaryDetailReferenceStatus: data }
    case 'SP_SUMMARY_DETAIL_DOCUMENTS':
      return { ...state, spSummaryDetailDocuments: data }
    case 'SP_SUMMARY_DETAIL_DOCUMENTS_STATUS':
      return { ...state, spSummaryDetailDocumentsStatus: data }
    case 'SP_SUMMARY_DETAIL_RELATED':
      return { ...state, spSummaryDetailRelated: data }
    case 'SP_SUMMARY_DETAIL_RELATED_STATUS':
      return { ...state, spSummaryDetailRelatedStatus: data }
    case 'SP_SUMMARY_DETAIL_MILESTONES':
      return { ...state, spSummaryDetailMilestones: data }
    case 'SP_SUMMARY_DETAIL_MILESTONES_STATUS':
      return { ...state, spSummaryDetailMilestonesStatus: data }
    case 'SP_SUMMARY_DETAIL_ROUTE_DETAIL':
      return { ...state, spSummaryDetailRoute: data }
    case 'SP_SUMMARY_DETAIL_ROUTE_DETAIL_STATUS':
      return { ...state, spSummaryDetailRouteStatus: data }
    case 'SP_SUMMARY_DETAIL_EVENTS':
      return { ...state, spSummaryDetailEvents: data }
    case 'SP_SUMMARY_DETAIL_EVENTS_STATUS':
      return { ...state, spSummaryDetailEventsStatus: data }
    case 'SP_SUMMARY_DETAIL_ORDERS':
      return { ...state, spSummaryDetailOrders: data }
    case 'SP_SUMMARY_DETAIL_ORDERS_STATUS':
      return { ...state, spSummaryDetailOrdersStatus: data }
    case 'SP_SUMMARY_DETAIL_CUSTOMS':
      return { ...state, spSummaryDetailCustoms: data }
    case 'SP_SUMMARY_DETAIL_CUSTOMS_STATUS':
      return { ...state, spSummaryDetailCustomsStatus: data }
    case 'SP_SUMMARY_DETAIL_TRANSPORTS':
      return { ...state, spSummaryDetailTransports: data }
    case 'SP_SUMMARY_DETAIL_TRANSPORTS_STATUS':
      return { ...state, spSummaryDetailTransportsStatus: data }
    case 'SP_SUMMARY_SORTING_BY':
      return { ...state, spOrderBy: data }
    case 'SP_SUMMARY_SORTING_TYPE':
      return { ...state, spOrderType: data }
    // end Shipment

    //booking
    case 'CREATE_BOOKING':
      return { ...state, createBo: data }
    case 'GET_BO_SUMMARY':
      return { ...state, boSummaryData: data }
    case 'PAGING_BO':
      return { ...state, paginationBo: data }
    case 'BO_SUMMARY_DETAIL_BOOKING':
      return { ...state, boSummaryDetailBooking: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_STATUS':
      return { ...state, boSummaryDetailBookingStatus: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_ATTACHED':
      return { ...state, boSummaryDetailBookingAttached: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_ATTACHED_STATUS':
      return { ...state, boSummaryDetailBookingAttachedStatus: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_CONTAINER':
      return { ...state, boSummaryDetailBookingContainer: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_CONTAINER_STATUS':
      return { ...state, boSummaryDetailBookingContainerStatus: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_MILESTONES':
      return { ...state, boSummaryDetailBookingMilestones: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_MILESTONES_STATUS':
      return { ...state, boSummaryDetailBookingMilestonesStatus: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_GOODS':
      return { ...state, boSummaryDetailBookingGoods: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_GOODS_STATUS':
      return { ...state, boSummaryDetailBookingGoodsStatus: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_REFERENCE':
      return { ...state, boSummaryDetailBookingReference: data }
    case 'BO_SUMMARY_DETAIL_BOOKING_REFERENCE_STATUS':
      return { ...state, boSummaryDetailBookingReferenceStatus: data }
    // end booking

    // User data
    case 'PAGING_USER':
      return { ...state, paginationUser: data }
    case 'SP_USER_SUMMARY':
      return { ...state, spUserSummary: data }
    case 'SP_USER_SUMMARY_STATUS':
      return { ...state, spUserSummaryStatus: data }
    case 'SP_USER_DETAIL':
      return { ...state, spUserDetails: data }
    case 'SP_USER_DETAIL_STATUS':
      return { ...state, spUserDetailsStatus: data }
    case 'SP_CURRENT_USER_DETAIL':
      return { ...state, spCurrentUserDetails: data }
    case 'SP_CURRENT_USER_DETAIL_STATUS':
      return { ...state, spCurrentUserDetailsStatus: data }
    case 'SP_USER_CHANGE_PASSWORD_STATUS':
      return { ...state, spUserChangePasswordStatus: data }
    // end user data

    // Status data
    case 'STATUS_DATA':
      return { ...state, spStatusData: data }
    case 'STATUS_DATA_LOADING':
      return { ...state, spStatusDataLoading: data }
    // end status data

    // export data
    case 'SP_EXPORT_DATA':
      return { ...state, spExportData: data }
    case 'SP_EXPORT_LOADING':
      return { ...state, spExportStatus: data }
    case 'SCHEMA_CHANGED':
      return { ...state, schemaChanged: data }
    // end export data

    // dropdown filter
    case 'ORIGIN_LOCATION_FROM':
      return { ...state, originFrom: data }
    case 'ORIGIN_LOCATION_STATUS':
      return { ...state, originLocationStatus: data }
    case 'ORIGIN_LOCATION_CODES':
      return { ...state, originCodes: data }
    case 'ORIGIN_PAGING':
      return { ...state, originPaging: data }
    case 'DESTINATION_LOCATION_FROM':
      return { ...state, destinationFrom: data }
    case 'DESTINATION_LOCATION_STATUS':
      return { ...state, destinationLocationStatus: data }
    case 'DESTINATION_LOCATION_CODES':
      return { ...state, destinationCodes: data }
    case 'DESTINATION_PAGING':
      return { ...state, destinationPaging: data }
    case 'VESSEL_CODES':
      return { ...state, vesselCodes: data }
    case 'VESSEL_PAGING':
      return { ...state, vesselPaging: data }
    case 'VESSEL_STATUS':
      return { ...state, vesselStatus: data }
    case 'SHIPPER_NAME_CODES':
      return { ...state, shipperNameCode: data }
    case 'SHIPPER_NAME_PAGING':
      return { ...state, shipperNameCodePaging: data }
    case 'SHIPPER_NAME_STATUS':
      return { ...state, shipperNameCodeStatus: data }
    case 'USER_ORG_NAME':
      return { ...state, userOrgName: data }
    case 'USER_ORG_PAGIN':
      return { ...state, userOrgPaging: data }
    // end dropdown filter
    case 'CHANGE_HEADER':
      return { ...state, changeHeader: data }
    case 'GET_HEADER':
      return { ...state, getHeader: data }
    case 'GET_COLUMN':
      return { ...state, getColumn: data }

    // edocs
    case 'EDOCS':
      return { ...state, spEDocs: data }

    // edocs
    case 'NAV_COLLAPSED':
      return { ...state, navCollapsed: data }

    // Else
    default:
      return state
  }
}

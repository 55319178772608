import React from 'react'
import moment from 'moment'

// LIST OF METHOD //
export const selectedData = (title, data) => {
  switch (title) {
    case 'Milestone':
      return data[0]
    case 'Transport':
      return data.transport
    case 'Goods/Packs':
      return data[0].packLineDetail
    case 'Orders':
      return data[0].orders
    case 'Container':
      return data
    case 'Reference No.':
      return data[0].referenceNumbers
    case 'Relative Inv.':
      return data
  }
}

export const renderDocsVal = (
  accessor,
  fileName,
  saveDateUTC,
  code,
  description,
  awsDocument
) => {
  switch (accessor) {
    case 'fileName':
      return renderValue(accessor, fileName)
    case 'saveDateUTC':
      return renderValue(accessor, saveDateUTC)
    case 'description':
      return renderValue(accessor, description)
    case 'code':
      return renderValue(accessor, code)
    case 'awsDocument':
      return (
        <a href={awsDocument} className="theme-color-icon" download={fileName}>
          <i className="ri-file-download-fill" /> Download
        </a>
      )
    default:
      return '-'
  }
}

export const renderValue = (accessor, value) => {
  const timeAccessor = [
    'isoeta',
    'isoetd',
    'estimatedDelivery',
    'deliveryRequiredBy',
    'estimatedPickup',
    'pickuRequiredBy',
    'saveDateUTC',
    'estimatedDate',
    'transportDeparture',
    'transportArrival',
    'orderDate',
    'deliveryEst'
  ]

  if (value === '' || value === undefined || value === null) {
    return '-'
  } else if (timeAccessor.includes(accessor)) {
    return getTimeVal(value)
  } else {
    return value
  }
}

export const getDateVal = dateVal => {
  if (dateVal != null && dateVal != undefined) {
    if (dateVal === '') {
      return '-'
    } else {
      const date = moment(dateVal ?? '-')
        .format('L')
        .substring(3, 5)
      const month = moment(dateVal ?? '-')
        .format('L')
        .substring(0, 2)
      const year = moment(dateVal ?? '-')
        .format('L')
        .substring(6, 10)
      return year + '/' + month + '/' + date + ' ' + dateVal.substring(11, 17)
    }
  } else {
    return '-'
  }
}

export const getTimeVal = dateVal => {
  return getDateVal(moment(dateVal ?? '-').format('DD-MMM-YYYY'))
}

// --- LIST OF HEADERS --- //
export const headersTab = [
  { accessor: 'company', title: 'Company' },
  { accessor: 'milestone', title: 'Milestone' },
  { accessor: 'transport', title: 'Transport' },
  { accessor: 'goodpacks', title: 'Goods/Packs' },
  { accessor: 'orders', title: 'Orders' },
  { accessor: 'container', title: 'Container' },
  { accessor: 'referenceNo', title: 'Reference No.' },
  { accessor: 'documents', title: 'Documents' },
  { accessor: 'relativeInv', title: 'Relative Inv.' },
  { accessor: 'customEntries', title: 'Custom Entries' }
]

export const informationDetailHeader = [
  [
    { accessor: 'houseBill', header: 'Bill' },
    { accessor: 'ownerRef', header: `Owner's Ref No.` },
    { accessor: 'totalWeight', header: 'Weight' },
    { accessor: 'incoterm', header: 'Incoterm' },
    { accessor: 'onBoard', header: 'On Board' },
    { accessor: 'origin', header: 'Origin' },
    { accessor: 'isoetd', header: 'ETD' }
  ],
  [
    { accessor: 'shipperRef', header: `Shipper's Ref No.` },
    { accessor: 'orderNumber', header: 'Order Ref No.' },
    { accessor: 'totalVolume', header: 'Size' },
    { accessor: 'quantity', header: 'Quantity' },
    { accessor: 'serviceLevel', header: 'Service Level' },
    { accessor: 'destination', header: 'Destination' },
    { accessor: 'isoeta', header: 'ETA' }
  ],
  [
    { accessor: 'chargesApply', header: 'Charges Apply' },
    { accessor: 'goodsDescription', header: 'Goods Description' },
    { accessor: 'additionalTerm', header: 'Additional Terms' },
    { accessor: 'releaseType', header: 'Release Type' }
  ],
  [
    { accessor: 'marks', header: 'Marks & Numbers' },
    { accessor: 'instruction', header: 'Special Instruction' }
  ]
]

export const companyHeader = [
  [
    { accessor: 'shipperName', header: 'Shipper' },
    { accessor: 'consignor', header: 'Consignor Contract' },
    { accessor: 'estimatedPickup', header: 'Estimated Pickup' },
    { accessor: 'pickuRequiredBy', header: 'Pickup Required By' },
    { accessor: '', header: 'Pickup Equipment' },
    { accessor: 'pickupAgent', header: 'Pickup Agent' },
    { accessor: '', header: 'Pickup Address' }
  ],
  [
    { accessor: 'consignee', header: 'Consignee' },
    { accessor: 'consignor', header: 'Consignor Contract' },
    { accessor: 'estimatedDelivery', header: 'Estimated Delivery' },
    { accessor: 'deliveryRequiredBy', header: 'Delivery Required By' },
    { accessor: '', header: 'Cartage Drop Mode' },
    { accessor: 'deliveryAgent', header: 'Delivery Agent' },
    { accessor: '', header: 'Delivery Address' }
  ]
]

export const milestoneHeader = [
  { accessor: 'parentNo', header: 'Parent' },
  { accessor: 'description', header: 'Description' },
  { accessor: 'estimatedDate', header: 'Departure' },
  { accessor: 'status', header: 'Status' }
]

export const transportHeader = [
  { header: 'Leg', accessor: 'transportLeg' },
  { header: 'Mode', accessor: 'transportMode' },
  { header: 'Type', accessor: 'transportType' },
  { header: 'Parent', accessor: 'transportParent' },
  { header: 'Bill', accessor: 'transportBill' },
  { header: 'Vessel', accessor: 'transportVessel' },
  { header: 'Voyage', accessor: 'transportVoyage' },
  { header: 'Load', accessor: 'transportLoad' },
  { header: 'Discharge', accessor: 'transportDischarge' },
  { header: 'Departure', accessor: 'transportDeparture' },
  { header: 'Arrival', accessor: 'transportArrival' },
  { header: 'Status', accessor: 'transportStatus' },
  { header: 'Carrier', accessor: 'transportCarrier' }
]

export const goodsPacksHeader = [
  { header: 'Pieces', accessor: 'pieces' },
  { header: 'Pack Type', accessor: 'packType' },
  { header: 'Length', accessor: 'length' },
  { header: 'Width', accessor: 'width' },
  { header: 'Height', accessor: 'height' },
  { header: 'UD', accessor: 'ud' },
  { header: 'Weight', accessor: 'weight' },
  { header: 'UQ', accessor: 'uq' },
  { header: 'Volume', accessor: 'volume' },
  { header: 'Description', accessor: 'description' },
  { header: 'Marks and Number', accessor: 'marksAndNumbers' },
  { header: 'Line Price', accessor: 'linePrice' }
]

export const orderHeader = [
  { header: 'Order No.', accessor: 'orderNumber' },
  { header: 'Date', accessor: 'orderDate' },
  { header: 'Split No.', accessor: 'splitNumber' },
  { header: 'Transport Mode.', accessor: 'transportMode' },
  { header: 'Supplier', accessor: 'suppllier' },
  { header: 'Buyer', accessor: 'buyer' },
  { header: 'Controlling Customer', accessor: 'controllingCustomer' },
  { header: 'Status', accessor: 'status' },
  { header: 'Order Date', accessor: 'orderDate' },
  { header: 'Origin', accessor: 'origin' },
  { header: 'Destination', accessor: 'destination' },
  { header: 'Packs', accessor: 'packs' },
  { header: 'Volume', accessor: 'volume' },
  { header: 'Weight', accessor: 'weight' }
]

export const containerHeader = [
  { header: 'Container No.', accessor: 'containerNo' },
  { header: 'Shipment No.', accessor: 'shipmentNo' },
  { header: 'Seal No.', accessor: 'sealNo' },
  { header: 'Container Type', accessor: 'containerType' },
  { header: 'Container Mode', accessor: 'containerMode' },
  { header: 'Empty Returned On', accessor: 'emptyReturnedOn' },
  { header: 'Tare Weight', accessor: 'tareWeight' },
  { header: 'Weight', accessor: 'weight' },
  { header: 'Delivery Mode', accessor: 'deliveryMode' },
  { header: 'Est. Delivery', accessor: 'deliveryEst' },
  { header: 'Est. Return', accessor: 'returnEst' },
  { header: 'Act. Return', accessor: 'returnAct' }
]

export const referenceHeader = [
  { header: 'Country', accessor: 'country' },
  { header: 'Number Type', accessor: 'numberType' },
  { header: 'Number', accessor: 'number' },
  { header: 'Type Description', accessor: 'typeDescription' }
]

export const documentsHeader = [
  { header: 'Date', accessor: 'saveDateUTC' },
  { header: 'Description', accessor: 'fileName' },
  { header: 'Type', accessor: 'code' },
  { header: 'Link', accessor: 'awsDocument' }
]

export const invoiceHeader = [
  { header: 'Invoice', accessor: 'invoice' },
  { header: 'Issuer', accessor: 'issuer' },
  { header: 'Type', accessor: 'type' },
  { header: 'Terms', accessor: 'terms' },
  { header: 'Inv. Date', accessor: 'invoiceDate' },
  { header: 'Due Date', accessor: 'dueDate' },
  { header: 'Currency', accessor: 'currency' },
  { header: 'Amount', accessor: 'amount' },
  { header: 'Outstanding Amt', accessor: 'outstandingAmount' },
  { header: 'Paid Date', accessor: 'paidDate' }
]

export const customEntriesHeader = []

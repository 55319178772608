import React, { useEffect } from 'react'

import Table from 'react-bootstrap/Table'
import { addRowTable } from '../../page/Bookings/Create/service'
import useFormContext from 'hooks/useFormContext'
import InputTableHandling from './InputTableHandling'

const FormTable = ({ header, module, title, t }) => {
  const { formData, setFormData } = useFormContext()
  useEffect(() => {
    if (formData[module]?.length < 1) {
      addRowTable({
        state: formData,
        setState: setFormData,
        tableName: module
      })
    }
  }, [])

  return (
    <div className="table-of-form-container">
      <h3 className="formTitle pb-0 mb-0">{`ENTER NEW ${t(
        title.toUpperCase()
      )}`}</h3>
      <div className="container2">
        <Table className="table-of-form" borderless>
          <thead className="table-of-form-thead">
            <tr className="table-of-form-tr">
              {header.map((item, index) => {
                return (
                  <th className="table-of-form-th" key={index}>
                    {t(item.label)}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="table-of-form-tbody">
            {/* List Form Column */}
            {formData[module].map((item, index) => {
              return (
                <tr key={index} className="table-of-form-tr">
                  {header.map((itemHeader, indexHeader) => {
                    const { accessor, type, optionList, label } = itemHeader
                    const disabled = false
                    return (
                      <td key={indexHeader} className="table-of-form-td">
                        <InputTableHandling
                          accessor={accessor}
                          index={index}
                          type={type}
                          dropdownList={optionList}
                          state={formData}
                          setState={setFormData}
                          label={label}
                          module={module}
                          disabled={disabled}
                        />
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {/* Add New Form Column */}
            <tr className="table-of-form-tr">
              {header.map((itemHeader, indexHeader) => {
                const { type, label } = itemHeader
                const disabled = true
                return (
                  <td key={indexHeader} className="table-of-form-td">
                    <InputTableHandling
                      type={type}
                      state={formData}
                      setState={setFormData}
                      label={label}
                      module={module}
                      disabled={disabled}
                    />
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default FormTable

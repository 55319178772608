import React from 'react'
import RequiredMessage from '../RequiredMessage'
// translation
import { useTranslation } from 'react-i18next'

const Input = ({
  id,
  title,
  autoComplete = 'off',
  onChange,
  placeholder,
  maxLength = 99,
  className,
  name,
  style = null,
  value,
  rightText,
  required,
  readOnly,
  onKeyUp,
  alphaNumeric,
  messageRequired,
  parentDivClassName,
  titleClassName,
  type,
  disabled,
  min,
  max,
  messageParam = { messageShow: false, messageData: {}, customMessage: {} }
}) => {
  // translation
  const { t } = useTranslation()

  return (
    <div className={parentDivClassName}>
      {!title ? null : (
        <label
          className={`title-gray mb-0 ${titleClassName} ${
            required ? 'required' : ''
          }`}
        >
          {t(title)}
        </label>
      )}
      {rightText ? (
        <div className={`form-control-text ${className} `}>
          <input
            id={id}
            name={name}
            min={min}
            max={max}
            type={type ?? 'text'}
            autoComplete={autoComplete}
            onChange={e => onChange(e)}
            onKeyUp={e => (onKeyUp ? onKeyUp(e) : null)}
            placeholder={placeholder}
            maxLength={maxLength}
            readOnly={readOnly}
            value={value}
            style={style}
            disabled={disabled}
            onKeyPress={e => {
              if (alphaNumeric) {
                if (!/^[a-zA-Z0-9-_]+$/.test(e.key)) e.preventDefault()
              }
            }}
            multiple
          />
          {rightText}
        </div>
      ) : (
        <input
          id={id}
          name={name}
          min={min}
          max={max}
          type={type ?? 'text'}
          autoComplete={autoComplete}
          onChange={e => onChange(e)}
          onKeyUp={e => (onKeyUp ? onKeyUp(e) : null)}
          className={`form-control ${className}`}
          placeholder={placeholder}
          maxLength={maxLength}
          readOnly={readOnly}
          value={value}
          style={style}
          disabled={disabled}
          onKeyPress={e => {
            if (alphaNumeric) {
              if (!/^[a-zA-Z0-9-_]+$/.test(e.key)) e.preventDefault()
            }
          }}
          multiple
        />
      )}

      {messageRequired ? (
        <RequiredMessage
          column={name}
          messageShow={messageParam?.messageShow}
          columnText={title || placeholder}
          value={messageParam?.value}
          customMessage={messageParam?.customMessage}
        />
      ) : null}
    </div>
  )
}

export default Input

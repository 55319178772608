/*
- Static data for dropdown
- Please check file header.js

Two keys in every option:
- value : code from cargowise / unique ID
- label : displaying name for dropdown
*/

// --- LIST OF OPTIONS --- //

// --- COMPANY FORM --- //
export const companyList = [
  { value: 'ACEBAGKEL', label: 'ACE BAGS & LUGGAGE TAIWAN CO.LTD.' },
  { value: 'BAANBABKK', label: 'BAANBANCHI.COM CO.LTD.' },
  {
    value: 'CHEINFBOM1',
    label: 'CHEMITO INFOTECH PVT LTD'
  },
  { value: 'COSVILBKK', label: 'COSMO VILLA CO.LTD.' },
  { value: 'JINLOGMEL', label: 'JINDO LOGISTICS PTY LTD' },
  { value: 'ONEBYOTYO', label: 'ONEBYONE CO. LTD.' },
  { value: 'ONELOGMEL', label: 'OneByOne Logistical PTY LTD' },
  { value: 'TIAONETSN', label: 'TIANJIN ONETOUCH BUSINESS SERVICE CO.LTD.' }
]

export const contactList = [
  { value: 'contact1', label: 'Kojima' },
  { value: 'contact2', label: 'Pshahap' },
  { value: 'contact3', label: 'Sreejith' },
  { value: 'contact4', label: 'Amy' },
  { value: 'contact5', label: 'Mr. Jayesh' }
]

/* Additional Information */
export const serviceLevelList = [
  { value: 'serviceLevel1', label: 'Deferred' },
  { value: 'serviceLevel2', label: 'Direct' },
  { value: 'serviceLevel3', label: 'Door to Door' },
  { value: 'serviceLevel4', label: 'Standard' },
  { value: 'serviceLevel5', label: 'Transhipment' }
]

export const chargesApplyList = [
  { value: 'chargesApply1', label: 'No Charges Showing' },
  { value: 'chargesApply2', label: 'Show Collect Charges' },
  { value: 'chargesApply3', label: 'Show Prepaid Charges' },
  { value: 'chargesApply4', label: `Show "As Agreed" in the charges section` },
  { value: 'chargesApply5', label: 'Show Prepaid & Collect Charges' },
  {
    value: 'chargesApply6',
    label: `Show Original "As Agreed" & Copy with Collect Charges`
  },
  {
    value: 'chargesApply7',
    label: 'Show Original "As Agreed" & Copy with Prepaid Charges'
  },
  {
    value: 'chargesApply8',
    label: 'Show Original "As Agreed" & Copy with Prepaid & Collect Charges'
  }
]

/* Goods Description */
export const modeList = [
  { value: 'ULD', label: 'Sea Freight (FCL)' },
  { value: 'LCL', label: 'Air Freight (ULD)' },
  { value: 'FCL', label: 'Sea Freight (FCL)' },
  { value: 'LRO', label: 'Road Freight(LCL/LTL)' },
  { value: 'FRO', label: 'Road Freight(FCL)' },
  { value: 'FTL', label: 'Road Freight(FTL)' },
  { value: 'COU', label: 'Courier' },
  { value: 'FRA', label: 'Rail Freight (FCL)' },
  { value: 'LRA', label: 'Rail Freight (LCL)' }
]

/* PickUp Address */
export const serviceList = [
  {
    value: 'ARVNT Venando Tuerto (Santa FE)',
    label: 'ARVNT Venando Tuerto (Santa FE)'
  },
  {
    value: 'AEFAT Fateh Terminal',
    label: 'AEFAT Fateh Terminal'
  },
  {
    value: 'ARELB EI Bolson ( Catamarca )',
    label: 'ARELB EI Bolson ( Catamarca )'
  },
  {
    value: 'ALMIL Milot (Lezhe)',
    label: 'ALMIL Milot (Lezhe)'
  },
  {
    value: 'ARLES La Escondida (Choco)',
    label: 'ARLES La Escondida (Choco)'
  },
  {
    value: 'AOCAV Cazombo',
    label: 'AOCAV Cazombo'
  },
  {
    value: 'ARUSA Uspallata(Mendoza)',
    label: 'ARUSA Uspallata(Mendoza)'
  },
  {
    value: 'AODAL Dalia',
    label: 'AODAL Dalia'
  },
  {
    value: 'AFPEC Pol-e Charkhi',
    label: 'AFPEC Pol-e Charkhi'
  }
]
export const incotermList = [
  { value: 'CFR', label: 'Cost and Freight' },
  { value: 'CIF', label: 'Cost Insurance and freight' },
  {
    value: 'CIP',
    label: 'Carriage and Insurance Paid to'
  },
  { value: 'CPT', label: 'Carriage Paid To' },
  { value: 'DAP', label: 'Delivered At Place' },
  { value: 'DAT', label: 'Delivered At Terminal' },
  { value: 'DDP', label: 'Delivered Dity Paid' },
  { value: 'DPU', label: 'Delivered at Place Unloaded' },
  { value: 'EXW', label: 'Ex Works' },
  { value: 'FAS', label: 'Free Alongside Ship' },
  {
    value: 'FC1',
    label: 'FCA - Free Carrier (seller is responsible for origin and loading)'
  },
  {
    value: 'FC2',
    label: 'FCA - Free Carrier (buyer is responsible for origin and loading)'
  },
  {
    value: 'FCA',
    label:
      'FCA - Free Carrier (seller is responsible for origin, buyer for loading)'
  },
  { value: 'FOB', label: 'FCA - Free On Board' }
]

export const commodityList = [
  { value: 'val1', label: 'value 1' },
  { value: 'val2', label: 'value 2' },
  { value: 'val3', label: 'value 3' }
]

// Currency
export const currencyList = [
  { label: 'Albania Lek', value: 'ALL' },
  { label: 'Afghanistan Afghani', value: 'AFN' },
  { label: 'Argentina Peso', value: 'ARS' },
  { label: 'Aruba Guilder', value: 'AWG' },
  { label: 'Australia Dollar', value: 'AUD' },
  { label: 'Azerbaijan New Manat', value: 'AZN' },
  { label: 'Bahamas Dollar', value: 'BSD' },
  { label: 'Barbados Dollar', value: 'BBD' },
  { label: 'Bangladeshi taka', value: 'BDT' },
  { label: 'Belarus Ruble', value: 'BYR' },
  { label: 'Belize Dollar', value: 'BZD' },
  { label: 'Bermuda Dollar', value: 'BMD' },
  { label: 'Bolivia Boliviano', value: 'BOB' },
  { label: 'Bosnia and Herzegovina Convertible Marka', value: 'BAM' },
  { label: 'Botswana Pula', value: 'BWP' },
  { label: 'Bulgaria Lev', value: 'BGN' },
  { label: 'Brazil Real', value: 'BRL' },
  { label: 'Brunei Darussalam Dollar', value: 'BND' },
  { label: 'Cambodia Riel', value: 'KHR' },
  { label: 'Canada Dollar', value: 'CAD' },
  { label: 'Cayman Islands Dollar', value: 'KYD' },
  { label: 'Chile Peso', value: 'CLP' },
  { label: 'China Yuan Renminbi', value: 'CNY' },
  { label: 'Colombia Peso', value: 'COP' },
  { label: 'Costa Rica Colon', value: 'CRC' },
  { label: 'Croatia Kuna', value: 'HRK' },
  { label: 'Cuba Peso', value: 'CUP' },
  { label: 'Czech Republic Koruna', value: 'CZK' },
  { label: 'Denmark Krone', value: 'DKK' },
  { label: 'Dominican Republic Peso', value: 'DOP' },
  { label: 'East Caribbean Dollar', value: 'XCD' },
  { label: 'Egypt Pound', value: 'EGP' },
  { label: 'El Salvador Colon', value: 'SVC' },
  { label: 'Estonia Kroon', value: 'EEK' },
  { label: 'Euro Member Countries', value: 'EUR' },
  { label: 'Falkland Islands (Malvinas) Pound', value: 'FKP' },
  { label: 'Fiji Dollar', value: 'FJD' },
  { label: 'Ghana Cedis', value: 'GHC' },
  { label: 'Gibraltar Pound', value: 'GIP' },
  { label: 'Guatemala Quetzal', value: 'GTQ' },
  { label: 'Guernsey Pound', value: 'GGP' },
  { label: 'Guyana Dollar', value: 'GYD' },
  { label: 'Honduras Lempira', value: 'HNL' },
  { label: 'Hong Kong Dollar', value: 'HKD' },
  { label: 'Hungary Forint', value: 'HUF' },
  { label: 'Iceland Krona', value: 'ISK' },
  { label: 'India Rupee', value: 'INR' },
  { label: 'Indonesia Rupiah', value: 'IDR' },
  { label: 'Iran Rial', value: 'IRR' },
  { label: 'Isle of Man Pound', value: 'IMP' },
  { label: 'Israel Shekel', value: 'ILS' },
  { label: 'Jamaica Dollar', value: 'JMD' },
  { label: 'Japan Yen', value: 'JPY' },
  { label: 'Jersey Pound', value: 'JEP' },
  { label: 'Kazakhstan Tenge', value: 'KZT' },
  { label: 'Korea (North) Won', value: 'KPW' },
  { label: 'Korea (South) Won', value: 'KRW' },
  { label: 'Kyrgyzstan Som', value: 'KGS' },
  { label: 'Laos Kip', value: 'LAK' },
  { label: 'Latvia Lat', value: 'LVL' },
  { label: 'Lebanon Pound', value: 'LBP' },
  { label: 'Liberia Dollar', value: 'LRD' },
  { label: 'Lithuania Litas', value: 'LTL' },
  { label: 'Macedonia Denar', value: 'MKD' },
  { label: 'Malaysia Ringgit', value: 'MYR' },
  { label: 'Mauritius Rupee', value: 'MUR' },
  { label: 'Mexico Peso', value: 'MXN' },
  { label: 'Mongolia Tughrik', value: 'MNT' },
  { label: 'Mozambique Metical', value: 'MZN' },
  { label: 'Namibia Dollar', value: 'NAD' },
  { label: 'Nepal Rupee', value: 'NPR' },
  { label: 'Netherlands Antilles Guilder', value: 'ANG' },
  { label: 'New Zealand Dollar', value: 'NZD' },
  { label: 'Nicaragua Cordoba', value: 'NIO' },
  { label: 'Nigeria Naira', value: 'NGN' },
  { label: 'Norway Krone', value: 'NOK' },
  { label: 'Oman Rial', value: 'OMR' },
  { label: 'Pakistan Rupee', value: 'PKR' },
  { label: 'Panama Balboa', value: 'PAB' },
  { label: 'Paraguay Guarani', value: 'PYG' },
  { label: 'Peru Nuevo Sol', value: 'PEN' },
  { label: 'Philippines Peso', value: 'PHP' },
  { label: 'Poland Zloty', value: 'PLN' },
  { label: 'Qatar Riyal', value: 'QAR' },
  { label: 'Romania New Leu', value: 'RON' },
  { label: 'Russia Ruble', value: 'RUB' },
  { label: 'Saint Helena Pound', value: 'SHP' },
  { label: 'Saudi Arabia Riyal', value: 'SAR' },
  { label: 'Serbia Dinar', value: 'RSD' },
  { label: 'Seychelles Rupee', value: 'SCR' },
  { label: 'Singapore Dollar', value: 'SGD' },
  { label: 'Solomon Islands Dollar', value: 'SBD' },
  { label: 'Somalia Shilling', value: 'SOS' },
  { label: 'South Africa Rand', value: 'ZAR' },
  { label: 'Sri Lanka Rupee', value: 'LKR' },
  { label: 'Sweden Krona', value: 'SEK' },
  { label: 'Switzerland Franc', value: 'CHF' },
  { label: 'Suriname Dollar', value: 'SRD' },
  { label: 'Syria Pound', value: 'SYP' },
  { label: 'Taiwan New Dollar', value: 'TWD' },
  { label: 'Thailand Baht', value: 'THB' },
  { label: 'Trinidad and Tobago Dollar', value: 'TTD' },
  { label: 'Turkey Lira', value: 'TRL' },
  { label: 'Tuvalu Dollar', value: 'TVD' },
  { label: 'Ukraine Hryvna', value: 'UAH' },
  { label: 'United Kingdom Pound', value: 'GBP' },
  { label: 'United States Dollar', value: 'USD' },
  { label: 'Uruguay Peso', value: 'UYU' },
  { label: 'Uzbekistan Som', value: 'UZS' },
  { label: 'Venezuela Bolivar', value: 'VEF' },
  { label: 'Viet Nam Dong', value: 'VND' },
  { label: 'Yemen Rial', value: 'YER' },
  { label: 'Zimbabwe Dollar', value: 'ZWD' }
]

export const shipperCODAmountList = [
  { value: 'COC', label: 'Company Check' },
  { value: 'BAC', label: 'Bank Check' }
]

export const releaseTypeList = [
  { value: 'BRR', label: 'Letter of Credit (Bank Release)' },
  { value: 'BSD', label: 'Sight Draft (Bank Release)' },
  { value: 'BTD', label: 'Time Draft (Bank Release)' },
  { value: 'CSH', label: 'Company / Cashier Check' },
  { value: 'CAD', label: 'Cash Against Documents' },
  { value: 'EBL', label: 'Express of Bill Lading' },
  { value: 'LOI', label: 'Letter of Indemnity' },
  { value: 'NON', label: 'Not Negotionable unless consigned to Order' },
  { value: 'OBO', label: 'Original Bill - Surrendered at Origin' },
  { value: 'OBR', label: 'Original Bill Required at Destination' },
  { value: 'SWB', label: 'Sea Waybill' }
]

export const onBoardList = [
  { value: 'SHP', label: 'Shipped' },
  { value: 'CLN', label: 'Clean' },
  { value: 'LDN', label: 'Laden' },
  { value: 'RFS', label: 'Received For Shipment' }
]

export const containerTypeList = [
  { value: 'containerType1', label: '20FR' },
  { value: 'containerType2', label: '20GP' },
  { value: 'containerType3', label: '20HC' },
  { value: 'containerType4', label: '20NOR' },
  { value: 'containerType5', label: '20OT' },
  { value: 'containerType6', label: '20PL' },
  { value: 'containerType7', label: '20RE' },
  { value: 'containerType8', label: '40FR' },
  { value: 'containerType9', label: '40GP' },
  { value: 'containerType10', label: '40HC' },
  { value: 'containerType11', label: '40NOR' },
  { value: 'containerType12', label: '40OT' },
  { value: 'containerType13', label: '40PL' },
  { value: 'containerType14', label: '40RE' },
  { value: 'containerType15', label: '40REHC' },
  { value: 'containerType16', label: '40HC' }
]

export const packTypeList = [
  { value: 'BAG', label: 'Bag' },
  { value: 'BBG', label: 'Bale, Compressed' },
  { value: 'BBK', label: 'Bale, Uncompressed' },
  { value: 'BLC', label: 'Basket' },
  { value: 'BLU', label: 'Bottle' },
  { value: 'BND', label: 'Box' },
  { value: 'BOT', label: 'Break Bulk' },
  { value: 'BOX', label: 'Bundle' },
  { value: 'BSK', label: 'Carton' },
  { value: 'CAS', label: 'Case' },
  { value: 'COI', label: 'Coil' },
  { value: 'CRD', label: 'Cradle' },
  { value: 'CRT', label: 'Crate' },
  { value: 'CTN', label: 'Carton' },
  { value: 'CYL', label: 'Cylinder' },
  { value: 'DOZ', label: 'Dozen' },
  { value: 'DRM', label: 'Drum' },
  { value: 'ENV', label: 'Envelope' },
  { value: 'GRS', label: 'Gross' },
  { value: 'KEG', label: 'Keg' },
  { value: 'MIX', label: 'Mix' },
  { value: 'PLT', label: 'Pallet' },
  { value: 'PAI', label: 'Pail' },
  { value: 'PCE', label: 'Piece' },
  { value: 'PKG', label: 'Package' },
  { value: 'REL', label: 'Reel' },
  { value: 'RLL', label: 'Roll' },
  { value: 'SHT', label: 'Sheet' },
  { value: 'SKD', label: 'Skid' },
  { value: 'SPL', label: 'Spool' },
  { value: 'TOT', label: 'Tote' },
  { value: 'TUB', label: 'Tube' },
  { value: 'UNT', label: 'Unit' }
]
export const heightUnitList = [
  { value: 'CM', label: 'CM' },
  { value: 'FT', label: 'FT' },
  { value: 'IN', label: 'IN' },
  { value: 'KM', label: 'KM' },
  { value: 'M', label: 'M' },
  { value: 'MI', label: 'MI' },
  { value: 'MM', label: 'MM' },
  { value: 'YD', label: 'YD' }
]

export const weightUnitList = [
  { value: 'DT', label: 'DT' },
  { value: 'G', label: 'G' },
  { value: 'HG', label: 'HG' },
  { value: 'KG', label: 'KG' },
  { value: 'KT', label: 'KT' },
  { value: 'LB', label: 'LB' },
  { value: 'LT', label: 'LT' },
  { value: 'MC', label: 'MC' },
  { value: 'MG', label: 'MG' },
  { value: 'OT', label: 'OT' },
  { value: 'OZ', label: 'OZ' },
  { value: 'T', label: 'T' },
  { value: 'TL', label: 'TL' },
  { value: 'TN', label: 'TN' }
]

export const volumeUnitList = [
  { value: 'CC', label: 'CC' },
  { value: 'CF', label: 'CF' },
  { value: 'CI', label: 'CI' },
  { value: 'CY', label: 'CY' },
  { value: 'D3', label: 'D3' },
  { value: 'GA', label: 'GA' },
  { value: 'GI', label: 'GI' },
  { value: 'L', label: 'L' },
  { value: 'M3', label: 'M3' },
  { value: 'ML', label: 'ML' },
  { value: 'TE', label: 'TE' }
]

export const numberTypeList = [
  { value: 'COC', label: 'Custom Office Code (Override)' },
  { value: 'AMS', label: 'AMS Number' },
  { value: 'UBR', label: 'Under Bond Approval Reference Number' },
  { value: 'CON', label: 'Carrier Contract Number' },
  { value: 'CLC', label: 'Client Contract Number' },
  { value: 'BKG', label: 'Carrier Booking Reference' },
  { value: 'RLB', label: 'Railway Bill Number' },
  { value: 'UCR', label: 'External (3rd Party) Unique Consignment Reference' },
  { value: 'CAR', label: 'Customs Authorization Reference' },
  { value: 'LCR', label: 'Letter of Credit Number' },
  { value: 'ACA', label: 'Pentant Advance Cargo Advice Reference' },
  { value: 'CCN', label: 'Cargo Control Module' },
  { value: 'PCN', label: 'Previous Cargo Control Module' },
  { value: 'BAG', label: 'Courier Bag Reference' },
  { value: 'COU', label: 'Courier Consignment Reference' },
  { value: 'NAC', label: 'Contract Named Account' },
  { value: 'OAG', label: 'Other Agent Reference' },
  { value: 'CQN', label: 'Carrier Quote Number' },
  { value: 'TWR', label: 'Transit Warehouse Receive' },
  { value: 'HIR', label: 'eHub Interchange Reference' },
  { value: 'ACI', label: 'Advance Cargo Information Reference' },
  { value: 'ISF', label: 'US Import Security Filling (ISF) Reference' },
  { value: 'JDR', label: 'Declaration Reference' }
]

export const portName = [
  { value: 'ADALV', label: 'Andorra la Vella - ADALV' },
  { value: 'ADCAN', label: 'Canillo - ADCAN' },
  { value: 'ADEAC', label: 'Escas - ADEAC' },
  { value: 'ADENC', label: 'Encamp - ADENC' },
  { value: 'ADESC', label: 'Escaldes-Engordany - ADESC' },
  { value: 'ADFMO', label: 'La Farga de Moles - ADFMO' },
  { value: 'ADLMA', label: 'La Massana - ADLMA' },
  { value: 'ADORD', label: 'Ordino - ADORD' },
  { value: 'ADPAS', label: 'Pas de la Casa - ADPAS' },
  { value: 'ADSCO', label: 'Santa Coloma - ADSCO' },
  { value: 'ADSJL', label: 'Sant Julia de Loria - ADSJL' },
  { value: 'AEAAN', label: 'Al Ain - AEAAN' },
  { value: 'AEABU', label: 'Abu al Bukhoosh - AEABU' },
  { value: 'AEAJM', label: 'Ajman - AEAJM' },
  { value: 'AEAJP', label: 'Al Jeer Port - AEAJP' },
  { value: 'AEALB', label: 'Al Barsha - AEALB' },
  { value: 'AEALQ', label: 'Al Quoz - AEALQ' },
  { value: 'AEALS', label: 'Al Sufouh - AEALS' },
  { value: 'AEAMF', label: 'Mussafah - AEAMF' },
  { value: 'AEAMU', label: 'Abu Musa - AEAMU' },
  { value: 'AEARP', label: 'Ahmed Bin Rashid Port - AEARP' },
  { value: 'AEARZ', label: 'Arzanah Island - AEARZ' },
  { value: 'AEAUH', label: 'Abu Dhabi - AEAUH' },
  { value: 'AEDAS', label: 'Das Island - AEDAS' },
  { value: 'AEDBI', label: 'Al Mafraq - AEDBI' }
]

export const dropModeList = [
  { value: 'PE1', label: 'Any' },
  { value: 'PE2', label: 'Drop Container - Premise supplies Lift' },
  { value: 'PE3', label: 'Drop Container with Sideloader' },
  { value: 'PE4', label: 'Drop Trailer' },
  { value: 'PE5', label: 'Wait for Pack/Unpack' },
  { value: 'PE6', label: 'Ask Client' }
]

export const countryList = [
  { value: 'AD', label: 'Andora - AD' },
  { value: 'AE', label: 'United Arab Emirates - AE' },
  { value: 'AF', label: 'Afghanistan - AF' },
  { value: 'AG', label: 'Antigua and Barbuda - AG' },
  { value: 'AI', label: 'Anguilla - AI' },
  { value: 'AL', label: 'Albania - AL' },
  { value: 'AM', label: 'Armenia - AM' },
  { value: 'AN', label: 'Netherlands Antilles - AN' },
  { value: 'AO', label: 'Angolas - AO' },
  { value: 'AQ', label: 'Antarctica - AQ' },
  { value: 'AR', label: 'Argentina - AR' },
  { value: 'AS', label: 'American Samoa - AS' },
  { value: 'AT', label: 'Austria - AT' },
  { value: 'AU', label: 'Australia - AU' },
  { value: 'AW', label: 'Aruba - AW' },
  { value: 'AX', label: 'Aland Islands - AX' },
  { value: 'AZ', label: 'Azerbajian - AZ' },
  { value: 'BA', label: 'Bosnia and Herzegovina - BA' },
  { value: 'BB', label: 'Barbados - BB' },
  { value: 'BD', label: 'Bangladesh - BD' },
  { value: 'BE', label: 'Belgium - BE' },
  { value: 'BF', label: 'Burkina Faso - BF' },
  { value: 'BG', label: 'Bulgaria - BG' },
  { value: 'BH', label: 'Bahrain - BH' }
]

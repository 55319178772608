import React from 'react'
import { useSelector } from 'react-redux'
import HButton from 'components/Bottom'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { useTranslation } from 'react-i18next'

const NavbarTopButton = () => {
  // State & variable

  // Content Nav
  // moduleName         --> module name
  // linkModuleCreate   --> link to create page
  // filterButton       --> is using filter button ?
  // filterHandling     --> filter button handling
  // actionsButton      --> is using action button ?
  // actionsButtonMenu  --> list of button menu
  // layoutButton      --> is using layout button ?
  // layoutHandling    --> action button handline
  const contentNav = useSelector(state => state.contentNav)
  const { t } = useTranslation()

  if (!contentNav) return <></>
  return (
    <div className="d-flex justify-content-end">
      {NavbarDropdownButton(contentNav, {
        title: t('Actions'),
        isShow: contentNav.actionsButton,
        menuList: contentNav.actionsMenuList
      })}
      {NavbarButton(contentNav, {
        title: t('Layout'),
        isShow: contentNav.layoutButton,
        handling: () => contentNav.layoutHandling()
      })}
      {NavbarButton(contentNav, {
        title: t('Filter'),
        isShow: contentNav.filterButton,
        handling: () => contentNav.filterHandling()
      })}
    </div>
  )
}

const NavbarButton = (contentNav, { title, isShow, handling }) => {
  if (!isShow) return
  return (
    <HButton
      key={`button${title}`}
      text={title}
      useDefaultStyle={false}
      className={`custom-button custom-button2 me-2`}
      size="md"
      onClick={() => {
        handling()
      }}
      module={title}
    />
  )
}

const NavbarDropdownButton = (contentNav, { title, isShow, menuList }) => {
  // because we don't use typescript, i will add a note
  /* 
      menuList example  --> [
        {title: "Create", function: (){console.log();}}
      ]
  */

  if (!isShow) return
  return (
    <DropdownButton
      key={`button${title}`}
      title={title}
      size="md"
      variant={'custom-button'}
      className={`me-2 custom-dropdown`}
      onSelect={index => {
        const functions = menuList[index].function
        if (functions) functions()
      }}
    >
      {menuList.map((menu, index) => {
        return (
          <Dropdown.Item key={`${menu.title}-${index}`} eventKey={index}>
            {menu.title}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}

export default NavbarTopButton

import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { renderValue, renderDocsVal, uploadFile } from './service'
import LoadingAnimation from 'components/LoadingAnimation'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const TableOfInformation = ({
  header,
  data,
  title,
  loading,
  triggerLoadData
}) => {
  const { t } = useTranslation()

  // Upload File function
  const [uploadLoading, setUploadLoading] = useState(false)
  const inputRef = useRef(null)
  const openUploadDialog = () => {
    inputRef.current.click()
  }
  const handleFileChange = event => {
    const file = event.target.files[0]
    const { size } = file
    const maxSize = 100 * 1024 * 1024 // 100MB in bytes

    if (size > maxSize) {
      toast.error(`Please upload file less than 100MB`)
      return
    }

    uploadFile(data, file, setUploadLoading, triggerLoadData)
  }
  // End Upload File

  const selectedData = () => {
    switch (title) {
      case 'Milestone':
        return data[0]
      case 'Transport':
        return data.transport
      case 'Goods/Packs':
        return data[0].packLineDetail
      case 'Orders':
        return data[0].orders
      case 'Container':
        return data
      case 'Reference No.':
        return data[0]?.referenceNumbers
      case 'Relative Inv.':
        return data
    }
  }

  return (
    <div className="table-information">
      {loading ? (
        <LoadingAnimation />
      ) : data?.length === 0 ? (
        <div className="noData">{t('No Record Found')}</div>
      ) : (
        <Table borderless responsive="md">
          <thead>
            <tr>
              {header.map((id, index) => {
                const { header } = id
                return <th key={index}>{t(header)}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {selectedData()?.map((item, index1) => {
              return (
                <tr key={`header-${index1}`}>
                  {header.map((id, index2) => {
                    const { accessor } = id
                    return (
                      <td key={`value-${index2}`}>
                        {renderValue(accessor, item[accessor])}
                      </td>
                    )
                  })}
                </tr>
              )
            })}

            {title === 'Documents' &&
            data?.cargowiseUniversalDocumentRequest ? (
              data?.cargowiseUniversalDocumentRequest.map((doc, index1) => {
                let code, description
                const { awsDocument } = doc
                const { fileName, saveDateUTC } = doc.attachedDocument
                if (doc.attachedDocument.type) {
                  code = doc.attachedDocument.type.code
                  description = doc.attachedDocument.type.description
                }

                return (
                  <tr key={`header-${index1}`}>
                    {header.map((id, index2) => {
                      const { accessor } = id
                      return (
                        <td key={`value-${index2}`}>
                          {renderDocsVal(
                            accessor,
                            fileName,
                            saveDateUTC,
                            code,
                            description,
                            awsDocument,
                            t
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      )}
      {!loading && title == 'Documents' && (
        <div className="w-100 text-center">
          <Button
            type="button"
            className={
              'btn  custom-button2 m-2 ' + (uploadLoading ? 'disabled' : false)
            }
            htmlFor="fileUpload"
            onClick={() => {
              openUploadDialog()
            }}
          >
            <i className="ri-file-upload-fill" />{' '}
            {uploadLoading ? 'Uploading...' : 'Upload File'}
          </Button>
          <input
            onChange={e => {
              handleFileChange(e)
            }}
            ref={inputRef}
            style={{ display: 'none' }}
            type="file"
          />
        </div>
      )}
    </div>
  )
}

export default TableOfInformation

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup, FormControl, Row, Col } from 'react-bootstrap'
import Input from 'components/Input'
import Checkbox from 'components/CheckBox'
import { useDispatch } from 'react-redux'
import { validateForm } from 'ApiService'
import { Link } from 'react-router-dom'

const LoginForm = ({ navigate, content, setContent }) => {
  const baseUrl = process.env.REACT_APP_API_URL
  const dispatch = useDispatch()
  const dataRemember = JSON.parse(localStorage.getItem('loginRemember'))
  // State
  const [formData, setFormData] = useState({
    username: dataRemember ? dataRemember.username : '',
    password: dataRemember ? dataRemember.password : '',
    message: dataRemember ? dataRemember.message : '',
    companyName: '',
    confirmPassword: '',
    companyAddress: '',
    companyNumber: '',
    validateUsername: '',
    validatePassword: '',
    errorMessage: '',
    remember: false
  })

  const handleSubmit = e => {
    e.preventDefault()
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  let tittleLogin
  let tittleBtnSubmit
  let isDisable
  if (content === 'login') {
    tittleLogin = 'Account Login'
    tittleBtnSubmit = 'LOGIN'
    if (!formData.username || !formData.password) {
      isDisable = true
    } else isDisable = false
  } else if (content === 'forgot') {
    tittleLogin = 'Forgot Password'
    tittleBtnSubmit = 'REQUEST PASSWORD RESET'
    if (!formData.username) {
      isDisable = true
    } else isDisable = false
  } else if (content === 'createDemo') {
    tittleLogin = 'Create Demo Account'
    if (
      !formData.username ||
      !formData.password ||
      !formData.companyName ||
      !formData.confirmPassword ||
      !formData.companyAddress ||
      !formData.companyNumber
    ) {
      isDisable = true
    } else isDisable = false
  } else {
    tittleBtnSubmit = 'SEND MESSAGE'
    tittleLogin = 'Please contact us via email for support at '
    if (!formData.username || !formData.message) {
      isDisable = true
    } else isDisable = false
  }

  const contentLoginPage = (
    <>
      <FormGroup className={`email-${content}`}>
        <span>Username</span>
        <Input
          className={`form-control ${
            !formData.errorMessage === '' ? 'border-red' : ''
          }`}
          placeholder="Enter an Username"
          value={formData.username}
          type="text"
          name="username"
          onChange={e => handleFieldChange(e)}
        />
      </FormGroup>
      {content === 'login' && (
        <>
          <FormGroup className="pt-2">
            <span>Password</span>
            <FormControl
              className={`form-control ${
                !formData.errorMessage === '' ? 'border-red' : ''
              }`}
              placeholder="Enter your password"
              value={formData.password}
              name="password"
              onChange={e => handleFieldChange(e)}
              type="password"
            />
          </FormGroup>
          <div className="remember-forgot">
            <Checkbox
              name="Remember Me"
              value={formData.remember}
              onClick={() => {
                const newFormData = { ...formData }
                newFormData.remember = !formData.remember
                setFormData(newFormData)
              }}
            />
            <div className="text-forgot" onClick={() => setContent('forgot')}>
              Forgot Password
            </div>
          </div>
        </>
      )}
      {content === 'contact' && (
        <>
          <FormGroup className="pt-2">
            <span>Message</span>
            <FormControl
              className="form-control-area"
              placeholder="Enter your message"
              value={formData.message}
              name="message"
              onChange={e => handleFieldChange(e)}
              as="textarea"
              maxLength={200}
              rows={3}
            />
          </FormGroup>
          <div className="maximumText">Maximum 200 characters</div>
        </>
      )}
      {content === 'forgot' && (
        <>
          <FormGroup>
            <Button
              type="submit"
              size="lg"
              className="btn-back-page w-100"
              onClick={() => setContent('login')}
            >
              BACK TO LOGIN PAGE
            </Button>
          </FormGroup>
        </>
      )}

      <FormGroup>
        <Button
          type="submit"
          size="lg"
          className="w-100"
          disabled={isDisable}
          onClick={() => {
            if (content === 'login') {
              if (formData.username && formData.password) {
                validateForm({
                  formData,
                  setFormData,
                  navigate,
                  dispatch,
                  baseUrl
                })
              }
            } else if (content === 'forgot') {
              setContent('contact')
            }
          }}
        >
          {tittleBtnSubmit}
        </Button>
        <div className="terms-conditions">
          <p>
            Read our <Link to="#!">terms</Link> and{' '}
            <Link to="#!">conditions </Link>
          </p>
        </div>
      </FormGroup>
    </>
  )

  const contentLoginDemo = (
    <>
      <Row>
        <Col md={6}>
          <FormGroup className="pe-3">
            <span>Email Address</span>
            <Input
              className={`form-control ${
                formData.errorMessage === '' ||
                formData.errorMessage === 'Password is required'
                  ? ''
                  : 'border-red'
              }`}
              placeholder="Enter an Email Address"
              value={formData.username}
              type="text"
              name="username"
              onChange={e => handleFieldChange(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <span>Company's Name</span>
            <Input
              className="form-control"
              placeholder="Enter an Email Company'Name"
              value={formData.companyName}
              type="text"
              name="companyName"
              onChange={e => handleFieldChange(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="pe-3 pt-2">
            <span>Password</span>
            <FormControl
              className={`form-control ${
                formData.errorMessage === '' ||
                formData.errorMessage === 'Username is required'
                  ? ''
                  : 'border-red'
              }`}
              placeholder="Enter your password"
              value={formData.password}
              name="password"
              onChange={e => handleFieldChange(e)}
              type="password"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="pt-2">
            <span>Confirm Password</span>
            <FormControl
              className="form-control"
              placeholder="Enter your Confirm Password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={e => handleFieldChange(e)}
              type="password"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="pt-2 pe-3">
            <span>Company's Address</span>
            <Input
              className="form-control"
              placeholder="Enter an Company's Address"
              value={formData.companyAddress}
              type="text"
              name="companyAddress"
              onChange={e => handleFieldChange(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="pt-2">
            <span>Company's Contact Number</span>
            <Input
              className="form-control"
              placeholder="Enter an Company's Contact Number"
              value={formData.companyNumber}
              type="number"
              name="companyNumber"
              onChange={e => handleFieldChange(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="pe-3 pt-3">
            <Button
              type="submit"
              size="lg"
              disabled={isDisable}
              className="btn-demo-account"
              onClick={() => setContent('contact')}
            >
              SEND REQUEST DEMO ACCOUNT
            </Button>
            <div className="terms-conditions">
              <p>
                Read our <Link to="#!">terms</Link> and{' '}
                <Link to="#!">conditions </Link>
              </p>
            </div>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="pt-3">
            <Button
              type="submit"
              size="lg"
              className="btn-back-page"
              onClick={() => {
                setContent('login')
              }}
            >
              BACK TO LOGIN PAGE
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  )

  return (
    <Form onSubmit={handleSubmit} className={`content-${content}`}>
      <p className="form-title-login mb-1">
        {tittleLogin}
        {content === 'contact' ? <span>dev@onebyone.co</span> : ''}
      </p>
      {content === 'createDemo' ? contentLoginDemo : contentLoginPage}
    </Form>
  )
}

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
  navigate: PropTypes.any
}

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
}

export default LoginForm

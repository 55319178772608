import React from 'react'

export const SchemaHeader = t => [
  {
    Column: 'Organisation Code',
    Header: () => t('Organisation Code'),
    placeholder: () => t('Organisation Code'),
    accessor: 'code',
    list: 'code',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 200,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Company Name',
    Header: () => t('Company Name'),
    placeholder: () => t('Company Name'),
    accessor: 'name',
    list: 'name',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 200,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Main Admin User',
    Header: () => t('Main Admin User'),
    placeholder: () => t('Main Admin User'),
    accessor: 'mainAdminUser',
    list: 'mainAdminUser',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 200,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  },
  {
    Column: 'Company Address',
    Header: () => t('Company Address'),
    placeholder: () => t('Company Address'),
    accessor: 'address',
    list: 'address',
    style: { textAlign: 'left', paddingLeft: '15px' },
    sortable: true,
    width: 200,
    Cell: props => {
      return (
        <div>
          {props.value == '' || props.value == null || props.value == undefined
            ? '-'
            : props.value}
        </div>
      )
    }
  }
]

import React, { useState } from 'react'
import Select from 'react-select'
import cookies from 'js-cookie'

// translation
import i18next from 'i18next'

const DropdownLanguage = ({ dataLanguages, t }) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = dataLanguages.find(
    l => l.value === currentLanguageCode
  )
  const styles = {
    placeholder: provided => ({
      ...provided,
      color: '#efeef9',
      fontSize: '12px'
    }),
    option: provided => ({
      ...provided,
      textAlign: 'left'
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      display: 'flex'
    }),
    control: provided => ({
      ...provided,
      backgroundColor: 'white',
      pointerEvents: 'auto',
      border: 0,
      borderColor: '#D5D9E8 !important',
      height: 34,
      minHeight: 34,
      boxShadow: 'none'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: 14
    })
  }

  return (
    <>
      <Select
        options={dataLanguages}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        onChange={selected => {
          i18next.changeLanguage(selected.value)
        }}
        value={currentLanguage}
        placeholder={t('Select language')}
        isSearchable={false}
        className="create-input"
        maxMenuHeight={200}
        styles={styles}
        theme={theme => ({
          ...theme,
          borderRadius: 4
        })}
        components={{
          IndicatorSeparator: () => null
        }}
      />
    </>
  )
}

export default DropdownLanguage

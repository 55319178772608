import React from 'react'

const Checkbox = ({ value, onClick, name, className, classNameText }) => {
  return (
    <div className={className}>
      <div className="content-checkbox" onClick={onClick}>
        <input type="checkbox" checked={value} onChange={() => {}} />
        <span className="checkmark"></span>
        <div className={classNameText}>{name}</div>
        <br />
      </div>
    </div>
  )
}

export default Checkbox

export const invoicesData = [
  {
    invoice: 'S00001000/B',
    issuer: 'The Sumitomo Warehouse Co., Ltd.',
    type: 'INV',
    terms: 'COD',
    invoiceDate: '06/07/22',
    dueDate: '06/07/22',
    currency: 'JPY',
    amount: '4286',
    outstandingAmount: '4286',
    paidDate: '-'
  },
  {
    invoice: 'S00001000/D',
    issuer: 'The Sumitomo Warehouse Co., Ltd.',
    type: 'INV',
    terms: 'COD',
    invoiceDate: '16/08/22',
    dueDate: '16/08/22',
    currency: 'JPY',
    amount: '3400',
    outstandingAmount: '3400',
    paidDate: '-'
  }
]

export const customEntriesData = []

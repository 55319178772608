import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  dataShipmentReference,
  dataShipmentOrder,
  dataShipmentGoods,
  dataShipmentTransport,
  dataShipmentMilestone,
  dataContainer,
  dataShipmentDemo
} from 'helpers/dataDummyDemo'
import {
  getDetailShipment,
  getDetailShipmentContainer,
  getDetailShipmentLastMilestone,
  getDetailShipmentTransport,
  getDetailShipmentGoodsPack,
  getDetailShipmentOrders,
  getDetailShipmentReference
} from 'ApiService'

import {
  headersTab,
  informationDetailHeader,
  companyHeader,
  milestoneHeader,
  transportHeader,
  goodsPacksHeader,
  orderHeader,
  containerHeader,
  referenceHeader,
  documentsHeader,
  invoiceHeader,
  customEntriesHeader
} from './service'
import { invoicesData, customEntriesData } from './dummyData'
// translation
import { useTranslation } from 'react-i18next'
import ListOfInformation from 'components/ListOfInformation'
import TableOfInformation from 'components/TableOfInformation'

const ShipmentsDetail = () => {
  const user = useSelector(state => state.user)
  const userAccess = user?.role
  const [activeTab, setActiveTab] = useState('company')
  const [isLoadData, setIsLoadData] = useState(true)

  const dispatch = useDispatch()
  const { id } = useParams()
  const { t } = useTranslation()

  // --- get data --- //
  const shipmentData = useSelector(state => state.spSummaryDetailShipment)
  const shipmentContainerData = useSelector(
    state => state.spSummaryDetailShipmentContainer
  )
  const shipmentMilestone = useSelector(
    state => state.spSummaryDetailShipmentLastMilestone
  )
  const shipmentTransport = useSelector(
    state => state.spSummaryDetailShipmentTransport
  )
  const shipmentGood = useSelector(
    state => state.spSummaryDetailShipmentGoodsPack
  )
  const shipmentOrders = useSelector(
    state => state.spSummaryDetailShipmentOrders
  )
  const shipmentReference = useSelector(
    state => state.spSummaryDetailShipmentReferences
  )

  // --- get loading status --- //
  const [shipmentDataStatus, setShipmentDataStatus] = useState(true)
  const [shipmentContainerDataStatus, setShipmentContainerDataStatus] =
    useState(true)
  const [shipmentMilestoneStatus, setShipmentMilestoneStatus] = useState(true)
  const [shipmentTransportStatus, setShipmentTransportStatus] = useState(true)
  const [shipmentGoodStatus, setShipmentGoodStatus] = useState(true)
  const [shipmentOrdersStatus, setShipmentOrdersStatus] = useState(true)
  const [shipmentReferenceStatus, setShipmentReferenceStatus] = useState(true)

  // --- choose data based on user access --- //
  const dataGood = userAccess === 'User Demo' ? dataShipmentGoods : shipmentGood
  const dataReference =
    userAccess === 'User Demo' ? dataShipmentReference : shipmentReference
  const dataOrder =
    userAccess === 'User Demo' ? dataShipmentOrder : shipmentOrders
  const dataTransport =
    userAccess === 'User Demo' ? dataShipmentTransport : shipmentTransport
  const dataMilestone =
    userAccess === 'User Demo' ? dataShipmentMilestone : shipmentMilestone
  const dataContainerData =
    userAccess === 'User Demo' ? dataContainer : shipmentContainerData
  const dataShipmentData =
    userAccess === 'User Demo' ? dataShipmentDemo : shipmentData

  const abortController = new AbortController()
  useEffect(() => {
    if (isLoadData) {
      getDetailShipment({
        dispatch,
        url: 'api/SummaryShipments/' + id,
        loading: setShipmentDataStatus,
        dispatchData: 'SP_SUMMARY_DETAIL_SHIPMENT'
      })
      setIsLoadData(false)
    }

    return () => {
      abortController.abort()
      // stop the query by aborting on the AbortController on unmount
    }
  }, [isLoadData])

  useEffect(() => {
    switch (activeTab) {
      case 'milestone':
        getDetailShipmentLastMilestone({
          dispatch,
          url: 'api/LastMilestone/' + id,
          loading: setShipmentMilestoneStatus,
          dispatchData: 'SP_SUMMARY_DETAIL_SHIPMENT_LAST_MILESTONE',
          setShipmentMilestoneStatus
        })
        break
      case 'transport':
        getDetailShipmentTransport({
          dispatch,
          url: 'api/TransportDetail/' + id,
          loading: setShipmentTransportStatus,
          dispatchData: 'SP_SUMMARY_DETAIL_SHIPMENT_TRANSPORT'
        })
        break
      case 'goodpacks':
        getDetailShipmentGoodsPack({
          dispatch,
          url: 'api/GoodsPack/' + id,
          loading: setShipmentGoodStatus,
          dispatchData: 'SP_SUMMARY_DETAIL_SHIPMENT_GOODS_PACK'
        })
        break
      case 'orders':
        getDetailShipmentOrders({
          dispatch,
          url: 'api/Order/' + id,
          loading: setShipmentOrdersStatus,
          dispatchData: 'SP_SUMMARY_DETAIL_SHIPMENT_ORDERS'
        })
        break
      case 'container':
        getDetailShipmentContainer({
          dispatch,
          url: 'api/Container/' + id,
          loading: setShipmentContainerDataStatus,
          dispatchData: 'SP_SUMMARY_DETAIL_SHIPMENT_CONTAINER'
        })
        break
      case 'referenceNo':
        getDetailShipmentReference({
          dispatch,
          url: 'api/Reference/' + id,
          loading: setShipmentReferenceStatus,
          dispatchData: 'SP_SUMMARY_SHIPMENT_DETAIL_REFERENCES'
        })
        break
      default:
        return []
    }
    return () => {
      abortController.abort()
      // stop the query by aborting on the AbortController on unmount
    }
  }, [activeTab])

  useEffect(() => {
    // Top Navbar Button Setting
    const NavTopContentSettings = {
      moduleName: t('Shipment ') + id
    }
    dispatch({ type: 'CONTENTNAV', data: NavTopContentSettings })
  }, [])

  const setData = accessor => {
    switch (accessor) {
      case 'company':
        return [companyHeader, dataShipmentData]
      case 'milestone':
        return [milestoneHeader, dataMilestone]
      case 'transport':
        return [transportHeader, dataTransport]
      case 'goodpacks':
        return [goodsPacksHeader, dataGood]
      case 'orders':
        return [orderHeader, dataOrder]
      case 'container':
        return [containerHeader, dataContainerData]
      case 'referenceNo':
        return [referenceHeader, dataReference]
      case 'documents':
        return [documentsHeader, dataShipmentData]
      case 'relativeInv':
        return [invoiceHeader, invoicesData]
      case 'customEntries':
        return [customEntriesHeader, customEntriesData]
    }
    return ['no header', 'no data']
  }

  const setLoading = accessor => {
    switch (accessor) {
      case 'company':
        return shipmentDataStatus
      case 'milestone':
        return shipmentMilestoneStatus
      case 'transport':
        return shipmentTransportStatus
      case 'goodpacks':
        return shipmentGoodStatus
      case 'orders':
        return shipmentOrdersStatus
      case 'container':
        return shipmentContainerDataStatus
      case 'referenceNo':
        return shipmentReferenceStatus
      case 'documents':
        return shipmentDataStatus
      case 'relativeInv':
        return shipmentDataStatus
      case 'customEntries':
        return shipmentDataStatus
    }
    return shipmentDataStatus
  }

  return (
    <div className="container-shipment-booking">
      <div className="subcontainer">
        <div className="detail-card">
          <h1 className="header">{t('Information Detail')}</h1>
          <ListOfInformation
            title="Information Detail"
            header={informationDetailHeader}
            data={dataShipmentData}
            loading={shipmentDataStatus}
            list={'list1'}
          />
        </div>
      </div>
      <div className="subcontainer">
        <div className="detail-tab">
          <Tabs activeKey={activeTab} onSelect={k => setActiveTab(k)}>
            {headersTab.map((item, index) => {
              const { accessor, title } = item
              const header = setData(accessor)[0]
              const data = setData(accessor)[1]
              return (
                <Tab eventKey={accessor} key={index} title={t(title)}>
                  {accessor === 'company'
                    ? data && (
                        <ListOfInformation
                          title={title}
                          header={header}
                          data={data}
                          loading={setLoading(accessor)}
                        />
                      )
                    : data && (
                        <TableOfInformation
                          title={title}
                          header={header}
                          data={data}
                          loading={setLoading(accessor)}
                          triggerLoadData={value => {
                            setIsLoadData(value)
                          }}
                        />
                      )}
                </Tab>
              )
            })}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default ShipmentsDetail

import cookies from 'js-cookie'

export const version = '3.0.0-alpha2'
export const navbarBreakPoint = 'lg' // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg'
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: true, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  showSettingPanel: false,
  currentTimeZone: cookies.get('timezone') ?? ''
}

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings }

import HeaderCard from 'components/HeaderCard'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DashboardInfoBox from './DashboardInfoBox'

const DashBoardShipmentTracking = ({ upcomingData, delayedData }) => {
  const { t } = useTranslation()
  return (
    <>
      <HeaderCard title={t('Shipment tracking: Today')} />
      <Col className="dashboard-shipments-column">
        <Row className="mt-2">
          <Col>
            <div className="dashboard-shipments-subtitle">{t('Upcoming')}</div>
          </Col>
          <Col>
            <div className="dashboard-shipments-subtitle">{t('Delayed')}</div>
          </Col>
        </Row>
        <Row className="custom-height">
          <Col xs={6} className="fill-height-or-more">
            {upcomingData.map((item, index) => (
              <Row key={item.name + index} className="custom-stretch-item">
                <Col xs={8}>
                  <DashboardInfoBox
                    key={index}
                    borderWidth="1px"
                    borderColor="#EEEEF9"
                    color1="#B4EBFF"
                    colorWidth1={`${((31 - item.days) / 31) * 100}%`}
                    height="90%"
                  >
                    <div className="d-flex align-items-center justify-content-between dashboard-shipments-completed-value">
                      <span>{item.name}</span>
                    </div>
                  </DashboardInfoBox>
                </Col>
                <Col xs={4}>
                  <div className="dashboard-shipment-tracking-day me-2">
                    {item.days}
                    {t('d')} {t('remaining')}
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={6} className="fill-height-or-more">
            {delayedData.map((item, index) => (
              <Row key={item.name + index} className="custom-stretch-item">
                <Col xs={8}>
                  <DashboardInfoBox
                    key={index}
                    borderWidth="1px"
                    borderColor="#EEEEF9"
                    color1="#FF847B80"
                    color2="#FFDEE5"
                    colorWidth1={`${(item.days / 31) * 100}%`}
                    colorWidth2={`${(item.days / 31) * 100}%`}
                    height="90%"
                  >
                    <div className="d-flex align-items-center justify-content-between dashboard-shipments-completed-value">
                      <span>{item.name}</span>
                    </div>
                  </DashboardInfoBox>
                </Col>
                <Col xs={4}>
                  <div className="dashboard-shipment-tracking-day">
                    {item.days}
                    {t('d')} {t('delayed')}
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default DashBoardShipmentTracking

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  goodsValueHeader,
  additionalInformationHeader
} from './HeadersAndOptions/headers'
import InputTypesHandler from './Handler/InputTypesHandler'

const PickupAddressAndAdditionalInformation = ({ t }) => {
  const additionalHeaderPart1 = [...additionalInformationHeader].splice(0, 12)
  const additionalHeaderPart2 = [...additionalInformationHeader].splice(12)
  return (
    <Row className="p-3 gap-3">
      <h3 className="formTitle">Additional Information</h3>
      <Col lg={4} className="pickUpForm">
        <Row>
          {goodsValueHeader.map((header, index) => {
            const { lg, accessor } = header
            return (
              <Col key={`pickUp-${index}`} lg={lg} className={`${accessor}`}>
                <InputTypesHandler header={header} t={t} module="goods_value" />
              </Col>
            )
          })}
          {additionalHeaderPart2.map((header, index) => {
            const { lg, accessor } = header
            return (
              <Col lg={lg} key={`addInfo-${index}`} className={`${accessor}`}>
                <InputTypesHandler
                  header={header}
                  t={t}
                  module="additional_information"
                  className="gap-3"
                />
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col className="additionalForm">
        <Row>
          {additionalHeaderPart1.map((header, index) => {
            const { lg, accessor } = header
            return (
              <Col lg={lg} key={`addInfo-${index}`} className={`${accessor}`}>
                <InputTypesHandler
                  header={header}
                  t={t}
                  module="additional_information"
                  className="gap-3"
                />
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}

export default PickupAddressAndAdditionalInformation

export default {
  // Authentication
  login: 'api/Authentication/authenticate',
  // End Authentication

  // Get Summary
  getShipments: 'api/SummaryShipments',
  getBookings: 'api/BookingShipment',
  getUserAccess: 'api/Staff'
  // End Get Summary
}

// import library
import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import AppContext from 'context/Context'
import HButton from 'components/Bottom'

const HeaderUserDetail = ({ module, name, navigate, date, id, t }) => {
  const {
    config: { isNavbarVerticalCollapsed }
  } = useContext(AppContext)

  return (
    <div
      className={`pt-3 pt-md-0 ${
        isNavbarVerticalCollapsed == true ? 'search-burger' : ''
      }`}
    >
      <Row>
        <Col
          xl={2}
          lg={2}
          md={3}
          sm={6}
          xs={6}
          className="d-flex align-items-center"
          style={{ fontSize: '97%' }}
        >
          <div className="textBold">
            <div>{t(module)}</div>
            <div>{name}</div>
          </div>
        </Col>
        <Col
          xl={10}
          lg={10}
          md={9}
          sm={6}
          xs={6}
          className="d-flex justify-content-end"
        >
          <HButton
            icon="ri-edit-line"
            text={t('EDIT')}
            className="reverse-button, custom-button"
            onClick={() => {
              navigate(`/users-management-create/edit/${id}`)
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-2">
        {t('Last Reset')} : {date ?? '-'}
      </Row>
    </div>
  )
}

export default HeaderUserDetail

import axios from 'axios'
import endpoint from 'helpers/endpoint'
import moment from 'moment'
import { toast } from 'react-toastify'
// import { loadStripe } from "@stripe/stripe-js";

const getDateVal = dateVal => {
  if (dateVal != null && dateVal != undefined) {
    if (dateVal === '') {
      return null
    } else {
      return moment(dateVal ?? '-').format('DD-MMM-YYYY')
    }
  } else {
    return null
  }
}

const getBoolVal = stringval => {
  if (stringval != null && stringval != undefined) {
    if (stringval === '') {
      return null
    } else {
      if (stringval === 'true' || stringval === true) {
        return 'Y'
      }
      if (stringval === 'false' || stringval === false) {
        return 'N'
      }
      return stringval
    }
  } else {
    return null
  }
}

export async function getAllInvoice() {
  const results = await fetch(
    process.env.REACT_APP_STRIPE_API_URL + '/v1/invoices',
    {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY
      }
    }
  ).then(response => response.json())
  return results.data
}

export const getSummaryData = async ({
  dispatch,
  PageNumber,
  module,
  searchVal,
  sortBy,
  desc,
  navigate
}) => {
  let endpoints = ''
  let dispatchData = ''
  let dispatchPaging = ''
  let dispatchStatus = ''

  switch (module) {
    case 'Shipments':
      endpoints = endpoint.getShipments
      dispatchData = 'GET_SP_SUMMARY'
      dispatchPaging = 'PAGING_SP'
      dispatchStatus = 'GET_SP_SUMMARY_STATUS'
      break
    case 'Bookings':
      endpoints = endpoint.getBookings
      dispatchData = 'GET_BO_SUMMARY'
      dispatchPaging = 'PAGING_BO'
      dispatchStatus = 'GET_SP_SUMMARY_STATUS'
      break
    case 'User Access':
      endpoints = endpoint.getUserAccess
      dispatchData = 'SP_USER_SUMMARY'
      dispatchPaging = 'PAGING_USER'
      dispatchStatus = 'SP_USER_SUMMARY_STATUS'
      break
    case 'Payments':
      dispatchData = 'SP_PAYMENT_SUMMARY'
      break
  }

  let urls = [`PageNumber=${PageNumber ? PageNumber : '1'}`, `PageSize=50`]

  if (searchVal && searchVal !== '') urls.push(`search=${searchVal}`)
  if (desc && desc !== '') urls.push(`orderType=${desc}`)
  if (sortBy && sortBy !== '') urls.push(`orderBy=${sortBy}`)
  dispatch({ type: dispatchStatus, data: true })
  const initialPagination = {
    active: 1,
    lastPage: 1,
    perPage: 1,
    totalPage: 1
  }
  try {
    var getData = undefined
    if (module === 'Payments') {
      const res = await axios.get(
        `${process.env.REACT_APP_STRIPE_API_URL}/v1/invoices`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
          }
        }
      )
      getData = res
    } else getData = await axios.get(`${endpoints}?${urls.join('&')}`)

    const { meta, data } = getData?.data
    const dataPaging = meta
    let dataSummary = data
    if (!dataSummary) return
    var pagination = undefined
    let _dataSummary = dataSummary
    if (module !== 'Payments') {
      pagination = {
        active: dataPaging.current_page,
        lastPage: dataPaging.last_page,
        perPage: dataPaging.per_page,
        totalPage: dataPaging.total_page,
        totalItems: dataPaging.total_Items
      }
      _dataSummary = await dataSummary.map(function (data) {
        data.etd = `${data?.etd}.etd`
        data.eta = `${data?.eta}.eta`
        return data
      })
    }
    const flagsStatus = [...(_dataSummary || [])].map(() => false)

    dispatch({ type: dispatchData, data: _dataSummary })

    if (module !== 'Payments') {
      dispatch({ type: dispatchStatus, data: false })
      dispatch({
        type: dispatchPaging,
        data: !pagination?.active ? initialPagination : pagination
      })
    }
    if (module === 'Shipments' || module === 'Bookings') {
      dispatch({ type: 'GET_SP_SUMMARY_FLAGS_STATUS', data: flagsStatus })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: [] })
    dispatch({ type: dispatchPaging, data: initialPagination })
    dispatch({ type: dispatchStatus, data: false })
    if (error?.response?.data?.message == 'Unauthorized') {
      toast.error(`User session expired, login again to access`)
      localStorage.removeItem('persist:root')
      console.log(navigate)
      navigate('/login')
    }
  }
}

export const getExport = async ({
  dispatch,
  modules,
  searchVal,
  displayFilterData,
  pageNumber = 1,
  exportAll
}) => {
  let endpoints = ''
  let dispatchData = 'SP_EXPORT_DATA'
  let urls = []
  if (!exportAll) urls.push(`PageNumber=${pageNumber}`)
  if (!exportAll) urls.push(`PageSize=50`)

  // Check Module
  if (modules == 'Shipments') {
    endpoints = displayFilterData ? 'api/Filter/filter' : endpoint.getShipments
    if (!displayFilterData && searchVal && searchVal !== '')
      urls.push(`search=${searchVal}`)
  } else if (modules == 'User Management') {
    endpoints = `api/Staff`
    if (searchVal && searchVal !== '') urls.push(`search=${searchVal}`)
  }

  try {
    dispatch({ type: 'SP_EXPORT_LOADING', data: true })
    const response = await axios.get(`${endpoints}?${urls.join('&')}`)
    const { data } = response.data
    const dataSummary =
      modules == 'Shipments'
        ? await setExportShipment({ dataSummary: data })
        : await setExportUserManagement({ dataSummary: data })

    dispatch({ type: dispatchData, data: dataSummary })
    dispatch({ type: 'SP_EXPORT_LOADING', data: false })
  } catch (error) {
    dispatch({ type: dispatchData, data: [] })
    dispatch({ type: 'SP_EXPORT_LOADING', data: false })
    return
  }
}

const setExportShipment = async ({ dataSummary }) => {
  return dataSummary.map(function (data) {
    data.qp = getBoolVal(data?.qp ?? null)
    data.ifip = getBoolVal(data?.ifip ?? null)
    data.etd = getDateVal(data?.etd ?? null)
    data.eta = getDateVal(data?.eta ?? null)
    data.eidoCarrier = getDateVal(data?.eidoCarrier ?? null)
    data.etd = `${data?.etd}.etd`
    data.eta = `${data?.eta}.eta`
    return data
  })
}

const setExportUserManagement = async ({ dataSummary }) => {
  return dataSummary.reduce((prev, user) => {
    return [
      ...prev,
      {
        fullName: user?.staffPortalData?.fullName,
        jobTitle: user?.staffPortalData?.jobTitle ?? '-',
        lastLogin: user?.lastLogin,
        glbGroup: user?.staffPortalRole?.description,
        isActive: 'Y'
      }
    ]
  }, [])
}

export const validateForm = async ({
  formData,
  setFormData,
  dispatch,
  baseUrl
}) => {
  const newDataLogin = { ...formData }
  const username = newDataLogin.username
  const password = newDataLogin.password

  if (username && password) {
    const payload = { username: username, password: password }
    try {
      const { data } = await axios.post(baseUrl + endpoint.login, payload)

      if (data && data.isSuccess === true) {
        newDataLogin.errorMessage = null
        const dataUser = { ...data?.user }
        dataUser.token = data.data.token
        dataUser.all = data.data
        toast.success(`Logged in as ${username}`, {
          toastId: 'login-success-toast'
        })

        setTimeout(() => {
          dispatch({ type: 'LOGIN', data: dataUser })
        }, 1200)

        if (formData.remember) {
          const rememberData = {
            username: username,
            password: password
          }
          localStorage.setItem('loginRemember', JSON.stringify(rememberData))
        }
        newDataLogin.validateUsername = true
        newDataLogin.validatePassword = true
      } else {
        toast.error(`${data.message}`, { toastId: 'login-notSuccess-toast' })
      }
    } catch (error) {
      console.log(error)
      newDataLogin.errorMessage = 'Failed to process your request'
      if (error.response) {
        newDataLogin.errorMessage = error.response.data.message
      }
      toast.error(`${newDataLogin.errorMessage}`, {
        toastId: 'failed-login-toast'
      })
    }
  } else {
    // Username
    newDataLogin.validateUsername = validateForm_checkError(username)
    newDataLogin.validatePassword = validateForm_checkError(password)

    if (!username && !password) {
      newDataLogin.errorMessage = validateForm_setErrorMessage({
        value: username + password,
        title: 'Username & Password'
      })
    } else {
      newDataLogin.errorMessage = validateForm_setErrorMessage({
        value: username,
        title: 'Username'
      })
      newDataLogin.errorMessage = validateForm_setErrorMessage({
        value: password,
        title: 'Password'
      })
    }
  }
  setFormData(newDataLogin)
}

const validateForm_checkError = value => {
  return value.length ? true : false
}

const validateForm_setErrorMessage = ({ value, title }) => {
  if (!value) {
    const errorMessage = title + ' is required'
    toast.warn(`${errorMessage}`)
    return errorMessage
  }
}

export const getDetailShipment = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const response = await axios.get(`${endpoints}`)
    const { data } = response?.data
    let dataShipment = data
    if (dataShipment) {
      dispatch({ type: dispatchData, data: dataShipment })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentContainer = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const response = await axios.get(`${endpoints}`)
    const { data } = response?.data
    let dataShipmentContainer = data
    if (dataShipmentContainer) {
      dispatch({ type: dispatchData, data: dataShipmentContainer })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentLastMilestone = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    let dataShipmentLastMilestone = data
    if (dataShipmentLastMilestone) {
      dispatch({ type: dispatchData, data: dataShipmentLastMilestone })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentTransport = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    let dataShipmentTransport = data
    if (dataShipmentTransport) {
      dispatch({ type: dispatchData, data: dataShipmentTransport })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentGoodsPack = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    if (data) {
      dispatch({ type: dispatchData, data: data })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentOrders = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    if (data) {
      dispatch({ type: dispatchData, data: data })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentReference = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    if (data) {
      dispatch({ type: dispatchData, data: data })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getDetailShipmentEdocs = async ({
  dispatch,
  url,
  loading,
  dispatchData
}) => {
  loading(true)

  let endpoints = url
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    if (data) {
      dispatch({ type: dispatchData, data: data })
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
  }
  loading(false)
}

export const getListSummary = async ({ setDataShipmentList }) => {
  let endpoints = 'api/SummaryShipments/List'
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    setDataShipmentList(data)
    return
  } catch (error) {
    return
  }
}

export const getAvailableFilter = async () => {
  let endpoints = 'api/Filter/filter-list'
  try {
    const getData = await axios.get(`${endpoints}`)
    let dataShipment = getData.data
    if (dataShipment) {
      return dataShipment.map(data => {
        return data
      })
    }
  } catch (error) {
    return []
  }
}

export const getFilteredSummary = async (dispatch, formData, pageNumber) => {
  let endpoints = 'api/Filter/filter'
  let dispatchPaging = 'PAGING_SP'

  endpoints = `${endpoints}?PageNumber=${
    pageNumber ? pageNumber : '1'
  }&PageSize=50`
  dispatch({ type: 'GET_SP_SUMMARY_STATUS', data: true })
  dispatch({ type: 'DISPLAY_SP_SUMMARY_FILTERED', data: true })
  try {
    const data = await axios.post(`${endpoints}`, formData)
    const dataPaging = data.data.meta
    const pagination = {
      active: dataPaging.current_page,
      lastPage: dataPaging.last_page,
      perPage: dataPaging.per_page,
      totalPage: dataPaging.total_page
    }
    dispatch({ type: dispatchPaging, data: pagination })
    dispatch({ type: 'GET_SP_SUMMARY_STATUS', data: false })
    dispatch({ type: 'GET_SP_SUMMARY_FILTERED', data: data?.data?.data ?? [] })
    return data?.data?.data ?? []
  } catch (error) {
    const pagination = {
      active: '1',
      lastPage: '1',
      perPage: '1',
      totalPage: '1'
    }
    dispatch({ type: dispatchPaging, data: pagination })
    dispatch({ type: 'GET_SP_SUMMARY_FILTERED', data: [] })
    dispatch({ type: 'GET_SP_SUMMARY_STATUS', data: false })
    return []
  }
}

export const createShipment = async ({ formData }) => {
  let endpoints = 'api/UniversalShipment'
  let dataSent = {
    pCompany: formData.pickupCompany,
    pAddress: formData.pickupAddress,
    pPostalCode: formData.pickupPostalCode,
    pCity: formData.pickupCity,
    pCountry: formData.pickupCountry,
    pContact: formData.pickupContact,
    pPhone: formData.pickupPhone,
    pFax: formData.pickupFax,
    pEmail: formData.pickupEmail,
    pOrigin: formData.pickupOrigin,
    dCompany: formData.deliverCompany,
    dAddress: formData.deliverAddress,
    dPostalCode: formData.deliverPostalCode,
    dCity: formData.deliverCity,
    dCountry: formData.deliverCountry,
    dContact: formData.deliverContact,
    dPhone: formData.deliverPhone,
    dFax: formData.deliverFax,
    dEmail: formData.deliverEmail,
    dDestination: formData.deliverDestination,
    transportMode: formData.deliveryTransportMode,
    incoterm: formData.deliveryIncoterm,
    serviceLevel: formData.deliveryServiceLevel,
    weight: formData.goodsWeight,
    volume: formData.goodsVolume,
    commodity: formData.goodsCommodity,
    chargeable: formData.goodsChargeable,
    goodsValue: formData.goodsGoodsValue,
    insuranceValue: formData.goodsInsuranceValue
  }
  try {
    await axios.post(`${endpoints}`, dataSent)
    return true
  } catch (error) {
    return false
  }
}

export const createBooking = async ({ formData }) => {
  let endpoint = 'api/BookingShipment/create'
  const {
    pickup_from,
    delivery_to,
    detail_information,
    goods_value,
    additional_information,
    containerList,
    goodsPacksList,
    attachedOrderList,
    referenceList
  } = formData
  let dataSent = {
    pickupFrom: {
      companyName: {
        code: pickup_from.company_name.value || '',
        description: pickup_from.company_name.label || ''
      },
      companyAddress: pickup_from.company_address,
      companyPostalCode: pickup_from.company_postal_code,
      companyCity: pickup_from.company_city,
      companyState: pickup_from.company_state,
      companyCountry: pickup_from.company_country,
      contactName: {
        code: pickup_from.consignor_name.value || '',
        description: pickup_from.consignor_name.label || ''
      },
      contactPhone: pickup_from.consignor_phone,
      consignorFax: pickup_from.consignor_fax,
      consignorEmail: pickup_from.consignor_email,
      consignorOrigin: pickup_from.consignor_origin.value,
      consignorPort: '',
      savePickupAddress: pickup_from.save_pickup_address
    },
    deliveryTo: {
      companyName: {
        code: delivery_to.company_name.value || '',
        description: delivery_to.company_name.label || ''
      },
      companyAddress: delivery_to.company_address,
      companyPostalCode: delivery_to.company_postal_code,
      companyCity: delivery_to.company_city,
      companyState: delivery_to.company_state,
      companyCountry: delivery_to.company_country,
      consigneeName: {
        code: delivery_to.consignee_name.value || '',
        description: delivery_to.consignee_name.label || ''
      },
      consigneePhone: delivery_to.consignee_phone,
      consigneeFax: delivery_to.consignee_fax,
      consigneeEmail: delivery_to.consignee_email,
      consigneeDestination: delivery_to.consignee_destination,
      consigneePort: '',
      savePickupAddress: delivery_to.save_pickup_address
    },
    detailInformation: {
      mode: {
        code: detail_information.mode.value || '',
        description: detail_information.mode.label || ''
      },
      shipperRef: detail_information.shipperRef,
      orderNumber: detail_information.orderNumber,
      detailGoodsDescription: detail_information.detailGoodsDescription,
      goodDescription: detail_information.goodsDescription,
      message: detail_information.message
    },
    goodsValue: {
      goodsValues: goods_value.goodsValue,
      goodsValuesCurrency: {
        code: goods_value.goodsValue_currency.value || '',
        description: goods_value.goodsValue_currency.label || ''
      },
      insuranceValue: goods_value.insuranceValue,
      insuranceValueCurrency: {
        code: goods_value.insurance_currency.value || '',
        description: goods_value.insurance_currency.label || ''
      },
      shipperCODAmount: goods_value.shipperCODAmount,
      shipperCODAmountCurrency: {
        code: goods_value.shipperCODAmount_currency.value || '',
        description: goods_value.shipperCODAmount_currency.label || ''
      }
    },
    additionalInformation: {
      estimatedPickupDate: additional_information.estimated_pickup_date,
      estimatedPickupTime: additional_information.estimated_pickup_time,
      pickupRequiredByDate: additional_information.pickup_required_by_date,
      pickupRequiredByTime: additional_information.pickup_required_by_time,
      pickupRequiredBy: additional_information.pickup_equipment,
      estimatedDeliveryDate: additional_information.estimated_delivery_date,
      estimatedDeliveryTime: additional_information.estimated_delivery_time,
      deliveryRequiredByDate: additional_information.delivery_required_by_date,
      deliveryRequiredByTime: additional_information.delivery_required_by_time,
      deliveryRequiredBy: additional_information.cartage_drop_mode,
      marksAndNumbers: additional_information.marksAndNumbers,
      specialInstruction: additional_information.special_instruction,
      serviceLevel: {
        code: additional_information.serviceLevel.value || '',
        description: additional_information.serviceLevel.label || ''
      },
      incoterm: {
        code: additional_information.incoterm.value || '',
        description: additional_information.incoterm.label || ''
      },
      chargesApply: {
        code: additional_information.chargesApply.value || '',
        description: additional_information.chargesApply.label || ''
      },
      releasetType: {
        code: additional_information.releaseType.value || '',
        description: additional_information.releaseType.label || ''
      },
      onBoard: {
        code: additional_information.onBoard.value || '',
        description: additional_information.onBoard.label || ''
      }
    },
    containerList: containerList.map(item => {
      return {
        containerNo: item.containerNo,
        type: {
          code: item.type.value || '',
          description: item.type.label || ''
        },
        count: item.count
      }
    }),
    goodsPacksList: goodsPacksList.map(item => {
      console.log(item)
      return {
        pieces: item.pieces,
        packType: {
          code: item.packType.value || '',
          description: item.packType.label || ''
        },
        length: item.length,
        width: item.width,
        height: item.height,
        udHeight: {
          code: item.ud_height.value || '',
          description: item.ud_height.label || ''
        },
        weight: item.weight,
        uqWeight: {
          code: item.uq_volume.value || '',
          description: item.uq_volume.label || ''
        },
        volume: item.volume,
        uqVolume: {
          code: item.uq_volume.value || '',
          description: item.uq_volume.label || ''
        },
        description: item.description,
        marksAndNumbers: item.marksAndNumbers,
        product: item.product
      }
    }),
    attachedOrderList: attachedOrderList.map(item => {
      return {
        orderNumber: item.orderNumber,
        date: item.date,
        goodDesc: item.goodsDesc
      }
    }),
    refereceList: referenceList.map(item => {
      return {
        country: item.country,
        numberType: {
          code: item.numberType.value || '',
          description: item.numberType.label || ''
        },
        number: item.number,
        typeDescription: item.type_description,
        issueDate: item.issueDate,
        information: item.information
      }
    })
  }

  try {
    await axios.post(`${endpoint}`, dataSent)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const getUserSummary = async ({ dispatch, searchVal, navigate }) => {
  let endpoints = `api/Staff`
  let dispatchData = 'SP_USER_SUMMARY'

  let urls = []
  urls.push(`search=${searchVal ? searchVal : ''}`)
  dispatch({ type: 'SP_USER_SUMMARY_STATUS', data: true })
  try {
    const getData = await axios.get(`${endpoints}?${urls.join('&')}`)
    const { data } = getData.data
    if (data) {
      if (data?.length > 0) {
        dispatch({ type: dispatchData, data: data })
        dispatch({ type: 'SP_USER_SUMMARY_STATUS', data: false })
        return data
      } else {
        dispatch({ type: dispatchData, data: [] })
        dispatch({ type: 'SP_USER_SUMMARY_STATUS', data: false })
        return []
      }
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: [] })
    dispatch({ type: 'SP_USER_SUMMARY_STATUS', data: false })
    if (error?.response?.data?.message == 'Unauthorized') {
      toast.error(`User session expired, login again to access`)
      localStorage.removeItem('persist:root')
      navigate('/login')
    }
    return []
  }
}

export const getCurrentUserDetail = async ({ dispatch }) => {
  const endpoints = 'api/Staff/current-staff'
  const dispatchData = 'SP_CURRENT_USER_DETAIL'
  dispatch({ type: 'SP_CURRENT_USER_DETAIL_STATUS', data: true })
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    if (data) {
      dispatch({ type: dispatchData, data: data })
      dispatch({ type: 'SP_CURRENT_USER_DETAIL_STATUS' })
      return data
    } else {
      dispatch({ type: dispatchData, data: null })
      dispatch({ type: 'SP_CURRENT_USER_DETAIL_STATUS', data: false })
      return []
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
    dispatch({ type: 'SP_CURRENT_USER_DETAIL_STATUS', data: false })
    return []
  }
}

export const getUserDetail = async ({
  dispatch,
  // PageNumber,
  // module,
  id
}) => {
  let endpoints = 'api/Staff/' + id
  let dispatchData = 'SP_USER_DETAIL'
  dispatch({ type: 'SP_USER_DETAIL_STATUS', data: true })
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    if (data) {
      dispatch({ type: dispatchData, data: data })
      dispatch({ type: 'SP_USER_DETAIL_STATUS', data: false })
      return data
    } else {
      dispatch({ type: dispatchData, data: null })
      dispatch({ type: 'SP_USER_DETAIL_STATUS', data: false })
      return []
    }
  } catch (error) {
    dispatch({ type: dispatchData, data: null })
    dispatch({ type: 'SP_USER_DETAIL_STATUS', data: false })
    return []
  }
}

export const changeUserPassword = async ({
  dispatch,
  currentPassword,
  newPassword,
  newPasswordConfirmation
}) => {
  dispatch({ type: 'SP_USER_CHANGE_PASSWORD_STATUS', data: true })
  const passwordData = {
    currentPassword: currentPassword,
    newPassword: newPassword,
    newPasswordConfirmation: newPasswordConfirmation
  }
  const endpoints = 'api/Staff/ChangePassword'
  if (newPassword !== newPasswordConfirmation) {
    dispatch({ type: 'SP_USER_CHANGE_PASSWORD_STATUS', data: false })
    return false
  }
  try {
    await axios.put(`${endpoints}`, passwordData)
  } catch (error) {
    dispatch({ type: 'SP_USER_CHANGE_PASSWORD_STATUS', data: false })
    return false
  }
  dispatch({ type: 'SP_USER_CHANGE_PASSWORD_STATUS', data: false })
  return true
}

export const getStatusData = async ({ dispatch }) => {
  let endpoints = 'api/Status'
  let dispatchData = 'STATUS_DATA'
  dispatch({ type: 'STATUS_DATA_LOADING', data: true })
  try {
    const getData = await axios.get(`${endpoints}`)
    const { data } = getData.data
    dispatch({ type: dispatchData, data: data ?? [] })
    dispatch({ type: 'STATUS_DATA_LOADING', data: false })
    return
  } catch (error) {
    dispatch({ type: dispatchData, data: [] })
    dispatch({ type: 'STATUS_DATA_LOADING', data: false })
    return
  }
}

export const updateShipmentStatus = async ({ jobNo, id, module }) => {
  const formData = { statusId: id }
  let endPoints
  if (module === 'shipment') {
    endPoints = 'api/SummaryShipments/' + jobNo
  } else {
    endPoints = 'api/BookingShipment/' + jobNo
  }
  try {
    await axios.put(`${endPoints}`, formData)
    return true
  } catch (error) {
    return false
  }
}

export const getFilterDropdown = async ({
  dispatch,
  title,
  pageNumber,
  searchVal
}) => {
  let endpoints = 'api/'
  let dispatchPaging
  let dispatchLoading
  let dispatchData

  if (title == 'Origin') {
    dispatch({ type: 'ORIGIN_LOCATION_STATUS ', data: true })
    dispatchPaging = 'ORIGIN_PAGING'
    dispatchLoading = 'ORIGIN_LOCATION_STATUS'
    dispatchData = 'ORIGIN_LOCATION_CODES'
    endpoints = `${endpoints}Unloco`
  } else if (title == 'Destination') {
    dispatch({ type: 'DESTINATION_LOCATION_STATUS ', data: true })
    dispatchPaging = 'DESTINATION_PAGING'
    dispatchLoading = 'DESTINATION_LOCATION_STATUS'
    dispatchData = 'DESTINATION_LOCATION_CODES'
    endpoints = `${endpoints}Unloco`
  } else if (title == 'Vessel') {
    dispatch({ type: 'VESSEL_STATUS ', data: true })
    dispatchPaging = 'VESSEL_PAGING'
    dispatchLoading = 'VESSEL_STATUS'
    dispatchData = 'VESSEL_CODES'
    endpoints = `${endpoints}Vessel`
  } else if (title == 'Shipper Name') {
    dispatch({ type: 'SHIPPER_NAME_STATUS ', data: true })
    dispatchPaging = 'SHIPPER_NAME_PAGING'
    dispatchLoading = 'SHIPPER_NAME_STATUS'
    dispatchData = 'SHIPPER_NAME_CODES'
    endpoints = `${endpoints}Organization`
  }

  pageNumber = pageNumber ?? '1'
  searchVal = searchVal ?? ''
  endpoints = `${endpoints}?PageSize=50&PageNumber=${pageNumber}&search=${searchVal}`
  try {
    const data = await axios.get(`${endpoints}`)
    const dataPaging = data.data.meta
    const pagination = {
      active: dataPaging.current_page,
      lastPage: dataPaging.last_page,
      perPage: dataPaging.per_page,
      totalPage: dataPaging.total_page
    }
    let datas = []
    const summaryData = data?.data?.data
    const isDataExists = summaryData.length > 0
    if (summaryData && isDataExists) {
      datas = await getFilterDropdown_setSummaryData({ title, summaryData })
      dispatch({ type: dispatchPaging, data: pagination })
      dispatch({ type: dispatchLoading, data: false })
      dispatch({ type: dispatchData, data: datas })
      return data?.data?.data ?? []
    }
  } catch (error) {
    const pagination = {
      active: '1',
      lastPage: '1',
      perPage: '1',
      totalPage: '1'
    }
    dispatch({ type: dispatchPaging, data: pagination })
    dispatch({ type: dispatchData, data: [] })
    dispatch({ type: dispatchLoading, data: false })
    return []
  }
}

const getFilterDropdown_setSummaryData = async ({ title, summaryData }) => {
  let data = []
  if (title == 'Origin' || title == 'Destination') {
    data = summaryData.map(item => {
      return {
        value: item?.nativeUnlocoData?.code,
        label: `${item?.nativeUnlocoData?.code} (${item?.nativeUnlocoData?.portName})`
      }
    })
  } else if (title == 'Vessel') {
    data = summaryData.map(item => {
      return {
        value: item?.nativeVesselData?.code,
        label: `${item?.nativeVesselData?.code}`
      }
    })
  } else if (title == 'Shipper Name') {
    data = summaryData.map((item, i) => {
      return {
        value: item?.nativeOrganizationData?.code,
        label: `${item?.nativeOrganizationData?.code} (${
          data?.data?.data[i]?.nativeOrganizationData?.fullName ?? '-'
        })`
      }
    })
  }
  return data
}

export const createFlagStatus = async ({ dispatch, name, code, notify }) => {
  let formData = { hexcode: code, name: name }
  let endpoints = 'api/Status'
  try {
    let data = await axios.post(`${endpoints}`, formData)
    if (data) {
      await getStatusData({ dispatch: dispatch })
    }
    notify({ text: 'New status created', status: 'success' })
    return true
  } catch (error) {
    notify({ text: 'Error while creating status', status: 'error' })
    return false
  }
}

export const editFlagStatus = async ({ dispatch, name, code, id, notify }) => {
  let formData = { hexcode: code, name: name }
  let endpoints = `api/Status/${id}`
  try {
    let data = await axios.put(`${endpoints}`, formData)
    if (data) {
      await getStatusData({ dispatch: dispatch })
    }
    return notify({ text: 'Status edited', status: 'success' })
  } catch (error) {
    return notify({ text: 'Error while editing status', status: 'error' })
  }
}

export const deleteFlagStatus = async ({ dispatch, id, notify }) => {
  let endpoints = 'api/Status/'
  try {
    let data = await axios.delete(`${endpoints + id}`)
    if (data) {
      await getStatusData({ dispatch: dispatch })
    }
    notify({ text: 'Status deleted!', status: 'warn' })
  } catch (error) {
    return notify({ text: 'Error while deleting status', status: 'error' })
  }
}

export const getOrganizationList = async ({
  page,
  datasOrg,
  setUserOrgName,
  setUserOrgPage
}) => {
  let endpoints = 'api/Organization'
  try {
    let response = await axios.get(
      `${endpoints}?PageNumber=${page ?? 1}&PageSize=50`
    )
    const { data } = response.data
    if (data?.length > 0) {
      let newDatas = []
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.nativeOrganizationData?.code != null) {
          let a = {
            value: data[i]?.nativeOrganizationData?.code,
            label: data[i]?.nativeOrganizationData?.code
          }
          newDatas = [...newDatas, a]
        }
      }
      let datas = [...datasOrg, ...newDatas]
      setUserOrgName(datas)
      setUserOrgPage(data?.data?.meta)
    }
  } catch (error) {
    return
  }
}

export const getRoles = async ({ setRole }) => {
  let endpoints = 'api/Role'
  try {
    let response = await axios.get(`${endpoints}`)
    const { data } = response.data
    let newDatas = []
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.id != null) {
          let a = {
            value: data[i]?.id,
            label: data[i]?.name
          }
          newDatas = [...newDatas, a]
        }
      }
    }
    setRole(newDatas)
    return newDatas ?? []
  } catch (error) {
    return []
  }
}

export const createUser = async ({ formData, navigate }) => {
  let endpoints = 'api/Staff'
  let dataSent = formData
  try {
    await axios.post(`${endpoints}`, dataSent)
    navigate('/users-access')
    return true
  } catch (error) {
    return false
  }
}

export const updateUser = async ({ formData, navigate, id }) => {
  let endpoints = `api/Staff/${id}`
  let dataSent = formData
  try {
    await axios.put(`${endpoints}`, dataSent)
    navigate('/users-access')
    return true
  } catch (error) {
    return false
  }
}

export const postEdoc = async ({ fileList, jobNo }) => {
  let endpoints = 'api/UniversalDocument'
  const formData = new FormData()
  formData.set('id', '8f874b61-5311-4a72-a5bb-8beaec7abe3e')
  formData.set('AttachedDocument.IsPublished', true)
  formData.set('AttachedDocument.IsPublishedSpecified', true)
  formData.set('JobNo', jobNo)
  formData.set('File', fileList[0])
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  try {
    await axios.post(`${endpoints}`, formData, config)
    return true
  } catch (error) {
    return false
  }
}

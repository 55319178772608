import {
  chargesApplyList,
  companyList,
  contactList,
  containerTypeList,
  countryList,
  currencyList,
  dropModeList,
  heightUnitList,
  incotermList,
  modeList,
  numberTypeList,
  onBoardList,
  packTypeList,
  portName,
  releaseTypeList,
  serviceLevelList,
  shipperCODAmountList,
  volumeUnitList,
  weightUnitList
} from './options'
/* ----- List Of Field and All Information that Needed ----- */

/* 
// --------- HEADERS TAB ------------ //
  List of key:
  - accessor: 
    - to set active tab in tabs component (index.jsx)
    - to choose which form field that will render (FormsHandler.jsx)
  - title:
    - display Title in tabs (index.jsx)
*/

export const headersTab = [
  { accessor: 'company', title: 'Company' },
  { accessor: 'details', title: 'Details' },
  { accessor: 'containerList', title: 'Container' },
  { accessor: 'attachedOrderList', title: 'Attached Order' },
  { accessor: 'goodsPacksList', title: 'Goods/Packs' },
  { accessor: 'referenceList', title: 'Reference No.' }
  // { accessor: 'documentList', title: 'Documents' }
]

/* 
// --------- HEADER ------------ //
  List of key:
  Header to render each value in form

  Key:
  - accessor: same like id, do CRUD operation
  - title: display Title in tabs (index.jsx)
  - type: to decide which input (dropdown, checkbox, ) to render (InputTypesHandler.js)
  - optionList: set option from dropdown
  - lg: set width of each field
*/

export const pickupFromHeader = [
  {
    accessor: 'company_name',
    title: 'Company',
    type: 'dropdown',
    optionList: companyList,
    placeholder: 'Select a specific consignor name',
    lg: 12
  },
  {
    accessor: 'company_address',
    title: 'Address',
    type: 'textarea',
    placeholder: 'Enter consignor address',
    lg: 12
  },
  {
    accessor: 'company_postal_code',
    title: 'Postal Code',
    type: 'input',
    placeholder: 'Enter consignor postal code',
    lg: 6
  },
  {
    accessor: 'company_city',
    title: 'City',
    type: 'input',
    placeholder: 'Enter consignor postal code',
    lg: 6
  },
  {
    accessor: 'company_state',
    title: 'State',
    type: 'input',
    placeholder: 'Enter consignor state',
    lg: 6
  },
  {
    accessor: 'company_country',
    title: 'Country',
    type: 'input',
    placeholder: 'Enter consignor country',
    lg: 6
  },
  {
    accessor: 'consignor_name',
    title: 'Contact',
    type: 'dropdown',
    optionList: contactList,
    placeholder: 'Select consignor contact person',
    lg: 12
  },
  {
    accessor: 'consignor_phone',
    title: 'Phone',
    type: 'input',
    placeholder: 'Enter consignor phone number',
    lg: 6
  },
  {
    accessor: 'consignor_fax',
    title: 'Fax',
    type: 'input',
    placeholder: 'Enter consignor fax number',
    lg: 6
  },
  {
    accessor: 'consignor_email',
    title: 'Email Address',
    type: 'input',
    placeholder: 'Enter consignor email address',
    lg: 12
  },
  {
    accessor: 'consignor_origin',
    title: 'Origin',
    type: 'dropdown',
    optionList: portName,
    placeholder: 'Enter consignor country',
    lg: 12
  },
  {
    accessor: 'save_pickup_address',
    type: 'checkbox',
    placeholder: 'Save Pickup Address',
    lg: 12
  }
]

export const deliveryToHeader = [
  {
    accessor: 'company_name',
    title: 'Company',
    type: 'dropdown',
    optionList: companyList,
    placeholder: 'Select a specific consignee name',
    column: 'deliveryTransportMode',
    lg: 12
  },
  {
    accessor: 'company_address',
    title: 'Address',
    type: 'textarea',
    placeholder: 'Enter consignee address',
    className: 'create-input form-control-address',
    column: 'pickupAddress',
    lg: 12
  },

  {
    accessor: 'company_postal_code',
    title: 'Postal Code',
    type: 'input',
    placeholder: 'Enter consignee postal code',
    column: 'pickupPostalCode',
    lg: 6
  },
  {
    accessor: 'company_city',
    title: 'City',
    type: 'input',
    placeholder: 'Enter consignee postal code',
    column: 'pickupPostalCode',
    lg: 6
  },
  {
    accessor: 'company_state',
    title: 'State',
    type: 'input',
    placeholder: 'Enter consignee state',
    column: 'pickupOrigin',
    lg: 6
  },
  {
    accessor: 'company_country',
    title: 'Country',
    type: 'input',
    placeholder: 'Enter consignee country',
    column: 'pickupCountry',
    lg: 6
  },
  {
    accessor: 'consignee_name',
    title: 'Contact',
    type: 'dropdown',
    optionList: contactList,
    placeholder: 'Select consignee contact person',
    column: 'consignorName',
    lg: 12
  },

  {
    accessor: 'consignee_phone',
    title: 'Phone',
    type: 'input',
    placeholder: 'Enter consignee phone number',
    column: 'pickupPhone',
    lg: 6
  },
  {
    accessor: 'consignee_fax',
    title: 'Fax',
    type: 'input',
    placeholder: 'Enter consignee fax number',
    column: 'pickupFax',
    lg: 6
  },
  {
    accessor: 'consignee_email',
    title: 'Email Address',
    type: 'input',
    placeholder: 'Enter consignee email address',
    lg: 12
  },
  {
    accessor: 'consignee_destination',
    title: 'Destination',
    type: 'dropdown',
    optionList: portName,
    placeholder: 'Enter consignee destination',
    lg: 12
  },
  {
    accessor: 'save_delivery_address',
    type: 'checkbox',
    placeholder: 'Save Delivery Address',
    lg: 12
  }
]

export const detailInformationHeader = [
  {
    accessor: 'mode',
    title: 'Mode',
    type: 'dropdown',
    optionList: modeList,
    placeholder: 'Enter delivery mode'
  },
  {
    accessor: 'shipperRef',
    title: `Shipper's Ref No`,
    type: 'input',
    placeholder: `Enter shipper's ref number`
  },
  {
    accessor: 'orderNumber',
    title: `Order Ref No.`,
    type: 'input',
    placeholder: `Enter order ref number`
  },
  {
    accessor: 'detailGoodsDescription',
    title: `Detailed Good Description`,
    type: 'input',
    placeholder: `Enter detailed goods description`
  },
  {
    accessor: 'goodsDescription',
    title: `Good Description`,
    type: 'input',
    placeholder: `Enter goods description`
  },
  {
    accessor: 'message',
    title: `Message`,
    type: 'textarea',
    placeholder: `Enter your message`
  }
]

export const goodsValueHeader = [
  {
    accessor: 'goodsValue',
    title: 'Goods Value',
    type: 'input',
    placeholder: 'Enter goodsValue',
    lg: 6
  },
  {
    accessor: 'goodsValue_currency',
    title: '',
    type: 'dropdown',
    optionList: currencyList,
    placeholder: 'Select currency',
    lg: 6
  },
  {
    accessor: 'insuranceValue',
    title: 'Insurance Value',
    type: 'input',
    placeholder: 'Enter insurance value',
    selectedValue: '',
    lg: 6
  },
  {
    accessor: 'insurance_currency',
    title: '',
    type: 'dropdown',
    optionList: currencyList,
    placeholder: 'Select currency',
    selectedValue: '',
    lg: 6
  },
  {
    accessor: 'shipperCODAmount',
    title: 'Shipper COD Amount',
    type: 'input',
    placeholder: 'Enter amount',
    selectedValue: '',
    lg: 6
  },
  {
    accessor: 'shipperCODAmount_currency',
    title: '',
    type: 'dropdown',
    optionList: shipperCODAmountList,
    placeholder: 'Select type',
    selectedValue: '',
    lg: 6
  }
]

export const additionalInformationHeader = [
  {
    accessor: 'estimated_pickup_date',
    title: 'Estimated Pickup',
    type: 'date',
    placeholder: 'Select date',
    selectedValue: '',
    column: 'estimated_pickup_date',
    lg: 3
  },
  {
    accessor: 'estimated_pickup_time',
    type: 'time',
    column: 'estimated_pickup_time',
    lg: 3
  },
  {
    accessor: 'pickup_required_by_date',
    title: 'Pickup Required By',
    type: 'date',
    placeholder: 'Select date',
    selectedValue: '',
    column: 'pickup_required_by_date',
    lg: 3
  },
  {
    accessor: 'pickup_required_by_time',
    title: 'Pickup Required By',
    type: 'time',
    placeholder: 'Select time',
    selectedValue: '',
    column: 'pickup_required_by_time',
    lg: 3
  },
  {
    accessor: 'estimated_delivery_date',
    title: 'Estimated Delivery',
    type: 'date',
    placeholder: 'Select Date',
    selectedValue: '',
    column: 'estimated_delivery_date',
    lg: 3
  },
  {
    accessor: 'estimated_delivery_time',
    title: 'Estimated Delivery Time',
    type: 'time',
    placeholder: 'Select time',
    selectedValue: '',
    column: 'estimated_delivery_time',
    lg: 3
  },
  {
    accessor: 'delivery_required_by_date',
    title: 'Delivery Required By',
    type: 'date',
    placeholder: 'Select date',
    selectedValue: '',
    column: 'delivery_required_by_date',
    lg: 3
  },
  {
    accessor: 'delivery_required_by_time',
    title: 'Delivery Required By',
    type: 'time',
    placeholder: 'Select time',
    selectedValue: '',
    column: 'delivery_required_by_time',
    lg: 3
  },
  {
    accessor: 'pickup_equipment',
    title: 'Pickup Equipment',
    type: 'dropdown',
    optionList: dropModeList,
    placeholder: 'Select options',
    selectedValue: '',
    column: 'pickup_equipment',
    lg: 6
  },
  {
    accessor: 'cartage_drop_mode',
    title: 'Cartage Drop Mode',
    type: 'dropdown',
    optionList: dropModeList,
    placeholder: 'Select options',
    selectedValue: '',
    column: 'cartage_drop_mode',
    lg: 6
  },
  {
    accessor: 'marksAndNumbers',
    title: 'Marks & Numbers details',
    type: 'textarea',
    placeholder: 'Enter masks & numbers details',
    selectedValue: '',
    lg: 12
  },
  {
    accessor: 'special_instruction',
    title: 'Special Instructions',
    type: 'textarea',
    placeholder: 'Enter special instructions notes',
    selectedValue: '',
    column: '',
    lg: 12
  },
  {
    accessor: 'serviceLevel',
    title: 'Service Level',
    type: 'dropdown',
    optionList: serviceLevelList,
    placeholder: 'Select service level',
    selectedValue: '',
    lg: 12
  },
  {
    accessor: 'incoterm',
    title: 'Incoterm',
    type: 'dropdown',
    optionList: incotermList,
    placeholder: 'Select incoterm',
    selectedValue: '',
    lg: 12
  },
  {
    accessor: 'chargesApply',
    title: 'Charges Apply',
    type: 'dropdown',
    optionList: chargesApplyList,
    placeholder: 'Select charges apply',
    selectedValue: '',
    lg: 12
  },
  {
    accessor: 'releaseType',
    title: 'Release Type',
    type: 'dropdown',
    optionList: releaseTypeList,
    placeholder: 'Select release type',
    selectedValue: '',
    lg: 12
  },
  {
    accessor: 'onBoard',
    title: 'On Board',
    type: 'dropdown',
    optionList: onBoardList,
    placeholder: 'Select on board status',
    selectedValue: '',
    lg: 12
  }
]

export const goodsPackHeader = [
  { accessor: 'action', label: 'No', type: 'action' },
  { accessor: 'pieces', label: 'Pieces', type: 'number' },
  {
    accessor: 'packType',
    label: 'Pack Type',
    type: 'dropdown',
    optionList: packTypeList
  },
  {
    accessor: 'length',
    label: 'Length',
    type: 'number'
  },
  { accessor: 'width', label: 'Width', type: 'number' },
  { accessor: 'height', label: 'Height', type: 'number' },
  {
    accessor: 'ud_height',
    label: 'UD',
    type: 'dropdown',
    optionList: heightUnitList
  },
  { accessor: 'weight', label: 'Weight', type: 'number' },
  {
    accessor: 'uq_weight',
    label: 'UQ',
    type: 'dropdown',
    optionList: weightUnitList
  },
  { accessor: 'volume', label: 'Volume', type: 'number' },
  {
    accessor: 'uq_volume',
    label: 'UQ',
    type: 'dropdown',
    optionList: volumeUnitList
  },
  { accessor: 'description', label: 'Description', type: 'string' },
  { accessor: 'marksAndNumbers', label: 'Marks and Numbers', type: 'string' },
  { accessor: 'product', label: 'Product', type: 'string' }
]

export const containerHeader = [
  { accessor: 'action', label: 'No', type: 'action' },
  {
    accessor: 'containerNo',
    label: 'Container No.',
    type: 'number'
  },
  {
    accessor: 'type',
    label: 'Type',
    type: 'dropdown',
    optionList: containerTypeList
  },
  {
    accessor: 'count',
    label: 'Count',
    type: 'number'
  }
]

export const attachedHeader = [
  { accessor: 'action', label: 'No', type: 'action' },
  {
    accessor: 'orderNumber',
    label: 'Order Number',
    type: 'number'
  },
  { accessor: 'date', label: 'Date', type: 'date' },
  {
    accessor: 'goodsDesc',
    label: 'Goods Description',
    type: 'string'
  }
]

export const referenceHeader = [
  { accessor: 'action', label: 'No', type: 'action' },
  {
    accessor: 'country',
    label: 'Country',
    type: 'dropdown',
    optionList: countryList
  },
  {
    accessor: 'numberType',
    label: 'Number Type',
    type: 'dropdown',
    optionList: numberTypeList
  },
  { accessor: 'number', label: 'Number', type: 'number' },
  { accessor: 'type_description', label: 'Type Description', type: 'string' },
  { accessor: 'issueDate', label: 'Issue Date', type: 'date' },
  { accessor: 'information', label: 'Information', type: 'string' }
]

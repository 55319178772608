// ------ LIST OF FUNCTION --------- //
export const changeCreateFrom = ({
  column,
  value,
  createFrom,
  setCreateFrom
}) => {
  const od = { ...createFrom }
  od[column] = value
  od['validation_' + column] = !!value

  setCreateFrom(od)
}

export const changeCreateFromNew = ({
  module,
  column,
  value,
  formData,
  setFormData
}) => {
  const newFormData = { ...formData }
  newFormData[module][column] = value

  setFormData(newFormData)
}

export const addRowTable = ({ state, setState, tableName }) => {
  let targetTable
  if (tableName === 'containerList') targetTable = cleanContainer
  else if (tableName === 'attachedList') targetTable = cleanAttached
  else if (tableName === 'goodsPacksList') targetTable = cleanGoodsPack
  else targetTable = cleanReference

  const newContainerLines = Object.assign(
    JSON.parse(JSON.stringify(targetTable))
  )

  const newState = { ...state }
  newState[tableName].push(newContainerLines)
  setState(newState)
}

export const deleteLineTable = ({ state, setState, index, tableName }) => {
  const newState = { ...state }
  newState[tableName].splice(index, 1)
  setState(newState)
}

export const changeTableForm = ({
  val,
  column,
  index,
  state,
  setState,
  module
}) => {
  const newState = { ...state }
  newState[module][index][column] = val
  setState(newState)
}

// --- INITIATE EMPTY FORM --- //
export const cleanContainer = {
  containerNo: '',
  type: '',
  count: ''
}

export const cleanAttached = {
  orderNumber: '',
  dateGoods: '',
  goodsDesc: ''
}

export const cleanGoodsPack = {
  action: '',
  pieces: '',
  packType: '',
  length: '',
  width: '',
  height: '',
  ud: '',
  weight: '',
  uq: '',
  volume: '',
  description: '',
  marksAndNumbers: '',
  product: '',
  date: ''
}

export const cleanReference = {
  country: '',
  numberType: '',
  number: '',
  type_description: '',
  issueDate: '',
  information: ''
}

export const documentList = {
  description: '',
  type: '',
  link: ''
}

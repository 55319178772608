import React, { useState } from 'react'

const UserDetailSection = ({ userDetail, userDetailLoading, t }) => {
  const [userFullName, setUserFullName] = useState(userDetail?.fullName)
  let { organization, portalLogin, roleName, email } = userDetail

  return (
    <>
      {userDetailLoading && <div>loading</div>}
      <div className="position-relative mb-1">
        <span className="fieldTitle mb-0 text-capitalize">
          {t('organisation code')}
        </span>
        <div className="w-100 d-flex">
          <input
            className="setting-input mb-1"
            placeholder={t('Organisation code unavailable')}
            value={organization}
            disabled
            type="text"
          />
        </div>
      </div>
      <div className="position-relative mb-1">
        <span className="fieldTitle mb-0 text-capitalize">{t('fullname')}</span>
        <div className="w-100 d-flex">
          <input
            className="setting-input mb-1"
            placeholder={t('Enter fullname')}
            value={userFullName}
            onChange={e => setUserFullName(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <div className="position-relative mb-1">
        <span className="fieldTitle mb-0 text-capitalize">{t('username')}</span>
        <div className="w-100 d-flex">
          <input
            className="setting-input mb-1"
            placeholder={t('Username unavailable')}
            value={portalLogin}
            disabled
            type="text"
          />
        </div>
      </div>
      <div className="position-relative mb-1">
        <span className="fieldTitle mb-0 text-capitalize">{t('email')}</span>
        <div className="w-100 d-flex">
          <input
            className="setting-input mb-1"
            placeholder={t('Email unavailable')}
            value={email}
            disabled
            type="email"
          />
        </div>
      </div>
      <div className="position-relative mb-1">
        <span className="fieldTitle mb-0 text-capitalize">
          {t('user level')}
        </span>
        <div className="w-100 d-flex">
          <input
            className="setting-input mb-1"
            placeholder={t('User level unavailable')}
            value={roleName}
            disabled
            type="text"
          />
        </div>
      </div>
    </>
  )
}

export default UserDetailSection

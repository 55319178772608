import StatusLabel from 'page/dashboard/StatusLabel'
import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const HeaderCard = ({ title, isShowStatus, titleSize }) => {
  const { t } = useTranslation()
  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <Col style={{ fontSize: titleSize ?? 20, color: '#b1b4d6' }}>
        {t(title)}
      </Col>
      <div
        style={{
          textAlign: 'right',
          color: '#b1b4d6',
          fontWeight: 'bold',
          fontSize: 20,
          display: 'flex'
        }}
      >
        {isShowStatus && (
          <div
            className="me-4"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'normal'
            }}
          >
            <div>
              <StatusLabel bg="#828daf" label="ETD" />
            </div>
            <div className="mx-3">
              <StatusLabel bg="#259ca7" label="ETA" />
            </div>
          </div>
        )}
        <i className="ri-more-fill"></i>
      </div>
    </div>
  )
}

export default HeaderCard

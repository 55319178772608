export const dataShipmentSummary = [
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  },
  {
    consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
    consolNo: 'C00001018',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    customNo: 'S00001000',
    destination: 'JPOSA - Osaka',
    eta: '6/10/2022 12:00:00 AM.eta',
    etd: '2/21/2022 12:00:00 AM.etd',
    houseBill: 'S00000001',
    isoEta: '2022-06-10T00:00:00Z',
    isoEtd: '2022-02-21T00:00:00Z',
    declarationCountry: 'Japan',
    jobNo: 'S00001000',
    lastMilestoneDesc: 'Departure from First Load Port',
    orderNumber: 'FGR7988',
    origin: 'AUMEL - Melbourne',
    product: 'FOR CLEANING YOUR ASS\n - 800.000 KG',
    shipmentNo: 'S00001000',
    shipperName: 'ONEBYONE LOGISTICAL PTY LTD',
    shipperRef: '',
    statusData: {
      hexcode: null,
      id: '00000000-0000-0000-0000-000000000000',
      name: null
    },
    statusId: 'd48a07fa-f5f9-4d26-a588-34826ff670a4',
    vessel: 'EVER BASIS'
  }
]

export const dataShipmentReference = [
  {
    referenceNumbers: [
      {
        country: 'JP',
        number: '1241524532',
        numberType: 'Carrier Quote Number',
        typeDescription: 'CQN'
      }
    ],
    shipmentNo: 'S00001000'
  }
]

export const dataShipmentOrder = [
  {
    orders: [
      {
        buyer: 'NAGARA PAPER MGF. CO. LTD.',
        controllingCustomer: null,
        destination: 'CNGGZ',
        orderDate: '2022-07-06T19:15:00Z',
        orderNumber: 'P000002~0~NAGPAPGIF',
        origin: 'JPTYO',
        packs: '13 PLT',
        splitNumber: '0',
        status: 'Incomplete',
        supplier: 'WUHU TOKEN SCIENCES CO. LTD.',
        transportMode: 'SEA',
        volume: '252.000 M3',
        weight: '800.000 KG'
      },
      {
        buyer: 'NAGARA PAPER MGF. CO. LTD.',
        controllingCustomer: null,
        destination: 'CNGGZ',
        orderDate: '2022-07-06T19:15:00Z',
        orderNumber: 'P000002~0~NAGPAPGIF',
        origin: 'JPTYO',
        packs: '13 PLT',
        splitNumber: '0',
        status: 'Incomplete',
        supplier: 'WUHU TOKEN SCIENCES CO. LTD.',
        transportMode: 'SEA',
        volume: '252.000 M3',
        weight: '800.000 KG'
      },
      {
        buyer: 'NAGARA PAPER MGF. CO. LTD.',
        controllingCustomer: null,
        destination: 'CNGGZ',
        orderDate: '2022-07-06T19:15:00Z',
        orderNumber: 'P000002~0~NAGPAPGIF',
        origin: 'JPTYO',
        packs: '13 PLT',
        splitNumber: '0',
        status: 'Incomplete',
        supplier: 'WUHU TOKEN SCIENCES CO. LTD.',
        transportMode: 'SEA',
        volume: '252.000 M3',
        weight: '800.000 KG'
      },
      {
        buyer: 'NAGARA PAPER MGF. CO. LTD.',
        controllingCustomer: null,
        destination: 'CNGGZ',
        orderDate: '2022-07-06T19:15:00Z',
        orderNumber: 'P000002~0~NAGPAPGIF',
        origin: 'JPTYO',
        packs: '13 PLT',
        splitNumber: '0',
        status: 'Incomplete',
        supplier: 'WUHU TOKEN SCIENCES CO. LTD.',
        transportMode: 'SEA',
        volume: '252.000 M3',
        weight: '800.000 KG'
      },
      {
        buyer: 'NAGARA PAPER MGF. CO. LTD.',
        controllingCustomer: null,
        destination: 'CNGGZ',
        orderDate: '2022-07-06T19:15:00Z',
        orderNumber: 'P000002~0~NAGPAPGIF',
        origin: 'JPTYO',
        packs: '13 PLT',
        splitNumber: '0',
        status: 'Incomplete',
        supplier: 'WUHU TOKEN SCIENCES CO. LTD.',
        transportMode: 'SEA',
        volume: '252.000 M3',
        weight: '800.000 KG'
      },
      {
        buyer: 'NAGARA PAPER MGF. CO. LTD.',
        controllingCustomer: null,
        destination: 'CNGGZ',
        orderDate: '2022-07-06T19:15:00Z',
        orderNumber: 'P000002~0~NAGPAPGIF',
        origin: 'JPTYO',
        packs: '13 PLT',
        splitNumber: '0',
        status: 'Incomplete',
        supplier: 'WUHU TOKEN SCIENCES CO. LTD.',
        transportMode: 'SEA',
        volume: '252.000 M3',
        weight: '800.000 KG'
      }
    ],
    shipmentNo: 'S00001000'
  }
]

export const dataShipmentGoods = [
  {
    packLineDetail: [
      {
        container: 'WHLU5467895',
        currency: 'JPY',
        description: 'JUST A DESCRIPTION',
        height: '0.000',
        length: '0.000',
        linePrice: '0.0000',
        marksAndNumbers: '5234212\n',
        packType: 'Pallet',
        pieces: '12',
        tariffNumber: null,
        ud: 'M',
        volume: '152.000',
        volumeUQ: 'M3',
        weight: '500.000',
        weightUQ: 'KG',
        width: '0.000'
      },
      {
        container: 'WHLU5467895',
        currency: 'JPY',
        description: 'JUST A DESCRIPTION',
        height: '0.000',
        length: '0.000',
        linePrice: '0.0000',
        marksAndNumbers: '5234212\n',
        packType: 'Pallet',
        pieces: '12',
        tariffNumber: null,
        ud: 'M',
        volume: '152.000',
        volumeUQ: 'M3',
        weight: '500.000',
        weightUQ: 'KG',
        width: '0.000'
      },
      {
        container: 'WHLU5467895',
        currency: 'JPY',
        description: 'JUST A DESCRIPTION',
        height: '0.000',
        length: '0.000',
        linePrice: '0.0000',
        marksAndNumbers: '5234212\n',
        packType: 'Pallet',
        pieces: '12',
        tariffNumber: null,
        ud: 'M',
        volume: '152.000',
        volumeUQ: 'M3',
        weight: '500.000',
        weightUQ: 'KG',
        width: '0.000'
      }
    ],
    shipmentNo: 'S00001000'
  }
]

export const dataShipmentTransport = {
  createdAt: '2022-10-26T04:31:49.602Z',
  createdBy: null,
  deletedBy: null,
  id: '83e23839-75dc-484f-9fdf-fad78d5627eb',
  isDeleted: false,
  organization: 'ONEBYONE LOGISTICAL PTY LTD',
  shipmentNo: 'S00001000',
  univShipment_id: 'a8addabe-d3f8-48b4-bab7-f7b329f32233',
  updatedAt: '2022-10-26T04:31:49.602Z',
  updatedBy: null,
  transport: [
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    },
    {
      transportArrival: '2022-09-16T00:00:00',
      transportBill: '',
      transportCarrier: 'EVESHITYO',
      transportDeparture: '2022-09-03T00:00:00',
      transportDischarge: 'Melbourne',
      transportLeg: '2',
      transportLoad: 'Kaohsiung',
      transportMode: 'InlandWaterway',
      transportParent: 'C00001018',
      transportStatus: null,
      transportType: 'OnForwarding',
      transportVessel: 'SEASPAN EMPIRE',
      transportVoyage: '2229S'
    }
  ]
}

export const dataShipmentMilestone = [
  [
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    },
    {
      actualDate: '2022-05-29T15:39:00',
      description: 'Departure from First Load Port',
      estimatedDate: '2022-08-24T00:00:00',
      organization: 'ONEBYONE LOGISTICAL PTY LTD',
      parentNo: 'C00001018',
      shipmentNo: 'S00001000',
      status: 'Overdue'
    }
  ]
]

export const dataContainer = [
  {
    containerMode: 'FCL',
    containerNo: 'WHLU5467895',
    containerType: '40HC',
    count: '1',
    deliveryEst: '2022-09-16T02:03:00',
    deliveryMode: 'CY/CY',
    emptyReturnedOn: '',
    returnAct: '',
    returnEst: '',
    sealNo: 'WHL12345',
    shipmentNo: 'S00001000',
    tareWeight: '3980.000',
    weight: '4800'
  }
]

export const dataShipmentDemo = {
  additionalTerm: null,
  availability: '',
  billingBranch: null,
  billingDepartment: null,
  broker: null,
  brokerName: null,
  chargesApply: 'Show Collect Charges',
  consignee: 'ASIA PROJECTS ENGINEERING PTE. LTD.',
  container: null,
  containerMode: '40HC',
  containerNo: 'WHLU5467895',
  createdBy: null,
  createdTime: null,
  declarationBranch: null,
  deliveryAgent: 'SUMISO (TAIWAN) CO., LTD.',
  deliveryCartageAdvised: '2022-06-01T14:43:00',
  deliveryRequiredBy: '2022-09-17T14:32:00',
  destination: 'JPOSA - Osaka',
  eido: null,
  entryPoint: null,
  entryStatus: null,
  estimatedDelivery: '2022-09-16T02:03:00',
  estimatedPickup: '2022-07-01T01:55:00',
  eta: '6/10/2022 12:00:00 AM',
  etd: '2/21/2022 12:00:00 AM',
  generatedName: 'a8addabe-d3f8-48b4-bab7-f7b329f32233',
  goodsDelivered: '2022-09-16T16:14:00',
  goodsDescription: null,
  goodsPickup: '2022-07-01T01:55:00',
  houseBill: 'S00000001',
  ifip: null,
  incoterm: 'Free On Board',
  isoEstimatedDelivery: '2022-09-16T02:03:00Z',
  isoEstimatedPickup: '2022-07-01T01:55:00Z',
  isoeta: '2022-06-10T00:00:00Z',
  isoetd: '2022-02-21T00:00:00Z',
  jobNo: 'S00001000',
  lastEdit: null,
  lastEditedTime: null,
  lastMilestoneATD: null,
  lastMilestoneDesc: 'Departure from First Load Port',
  lastMilestoneEvent: null,
  messageStatus: null,
  nextMilestoneATD: null,
  nextMilestoneDesc: 'December',
  nextMilestoneEvent: null,
  onBoard: 'Shipped',
  orderNumber: 'FGR7988',
  origin: 'AUMEL - Melbourne',
  ownerRef: '849ASS',
  packType: 'PLT',
  pickuRequiredBy: '2022-06-03T14:44:00',
  pickupAgent: 'SUMITOMO WAREHOUSE (EUPOPE) GMBH',
  pickupCartageAdvised: '2022-06-02T14:44:00',
  pickupFrom: [
    'ACE BAGS & LUGGAGE TAIWAN CO.LTD.',
    '9F.NO.219 SEC.3',
    '219 NANJING EAST ROAD SECTION 3',
    'ZHONGSHAN DISTRICT',
    'TAIPEI CITY',
    'Taipei',
    '104'
  ],
  deliverTo: [
    'ASIA PROJECTS ENGINEERING PTE. LTD.',
    '20 TEMBUSU CRESCENT',
    'JURONG ISLAND',
    'SINGAPORE',
    '627610'
  ],
  availableAt: [
    'KOKUSAI EXPRESS CO.,LTD.',
    '4-12-6 HIGASHISHINAGAWA SHINAGAWA-KU TOKYO',
    'SHINAGAWA-KU',
    'Tokyo',
    '140-0002'
  ],
  cargowiseUniversalDocumentRequest: [
    {
      awsDocument:
        'https://controlglobal.s3.us-east-2.amazonaws.com/customDeclaration/S00001000/TTClub-Bill-Of-Lading---S2314511.pdf',
      createdAt: '2022-08-22T10:12:03.311Z',
      createdBy: 'System',
      dataSourceConsolKey: null,
      dataSourceCustomDeclarationKey: 'S00001000',
      dataSourceShipmentKey: null,
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: null,
      id: '6b84c0d6-7685-410f-9fb1-349fe4e8f0d0',
      isDeleted: false,
      isUploaded: true,
      updatedAt: '2022-10-05T07:11:58.605Z',
      updatedBy: 'System',
      uploadedTime: '2022-10-05T07:11:58.605Z',
      attachedDocument: {
        contextCollection: null,
        isPublished: true,
        isPublishedSpecified: true,
        savedBy: {
          code: 'OBO',
          name: 'OneByOne'
        },
        type: {
          code: 'HBL',
          description:
            'Sumitomo Warehouse (Hong Kong) Ltd. - SWHK HEAD OFFICE - Original'
        }
      }
    }
  ]
}

export const dataShipmentListDemo = {
  bill: ['S00000001', 'S00001036'],
  consignee: [
    'ASIA PROJECTS ENGINEERING PTE. LTD.',
    'The Sumitomo Warehouse Co., Ltd.',
    'BENESSE CORPORATION'
  ],
  declarationCountry: ['Japan', 'Indonesia', 'Australia'],
  destination: ['JPOSA - Osaka', 'JPOSA', 'HKHKG - Hong Kong', 'JPUKB - Kobe'],
  eta: ['2022-06-10T00:00:00'],
  etd: ['2022-07-16T00:00:00'],
  lastMilestone: [
    'Departure from First Load Port',
    'Send XML',
    'AMS not filed yet'
  ],
  origin: ['AUMEL - Melbourne', 'AUMEL', 'JPUKB - Kobe'],
  shipmentNo: ['S00001000', 'S00001110', 'S00001115'],
  shipper: [
    'ONEBYONE LOGISTICAL PTY LTD',
    'ACE BAGS & LUGGAGE TAIWAN CO.LTD.',
    'BENESSE CORPORATION'
  ],
  statusId: ['late', 'on time']
}

export const dataUserAccessDemo = [
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  },
  {
    createdAt: '0001-01-01T00:00:00',
    createdBy: null,
    customerId: null,
    deletedAt: '0001-01-01T00:00:00',
    deletedBy: null,
    expDate: '0001-01-01T00:00:00',
    id: '24bb0455-d30b-45d8-81e1-b24b6c06aea4',
    isActive: false,
    isDeleted: false,
    lastLogin: '2022-10-26T02:51:03.773Z',
    nativeStaffData: null,
    organization: 'FRUTEXSYD',
    paymentInfo: null,
    portalLogin: 'syd.frutex',
    priceId: null,
    roleId: null,
    status: null,
    subsType: null,
    updatedAt: '0001-01-01T00:00:00',
    updatedBy: null,
    userPreference: null,
    staffPortalRole: {
      createdAt: '2022-02-03T09:00:09.146Z',
      createdBy: '',
      deletedAt: '0001-01-01T00:00:00Z',
      deletedBy: null,
      description: 'Regular User',
      id: 'ebfe2986-a4bc-4c0b-83f8-f9edf4abae51',
      isActive: true,
      isDeleted: false,
      name: 'Regular User',
      updatedAt: '0001-01-01T00:00:00Z',
      updatedBy: null
    },
    staffPortalData: {
      fullName: 'SYD.FRUTEX',
      email: ['frutex@frutex.com'],
      jobTitle: 'Manager'
    }
  }
]

export const dataBookingSummary = [
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  },
  {
    destination: 'JPTYO - Tokyo',
    eta: '6/30/2022 12:00:00 AM.eta',
    etd: '6/30/2022 12:00:00 AM.etd',
    isoEta: '0001-01-01T00:00:00',
    isoEtd: '0001-01-01T00:00:00Z',
    jobNo: 'S00001049',
    lastMilestoneDesc: 'December',
    origin: 'AUMEL - Melbourne',
    pack: '12 BOX',
    product: 'Electronics',
    productCode: null,
    shipmentNo: 'S00001049',
    shipperRef: '266554',
    value: '120.0000 JPY',
    volume: '47420.000 M3',
    weight: '0.500 KG'
  }
]

export const dataBookingContainer = [
  {
    containerMode: '',
    containerNo: 'CSQU3054383',
    containerType: '20GP',
    count: '1',
    deliveryEst: null,
    deliveryMode: 'CFS/CFS',
    emptyReturnedOn: null,
    returnAct: null,
    returnEst: '',
    sealNo: null,
    shipmentNo: 'S00001049',
    tareWeight: '0',
    weight: '0'
  }
]

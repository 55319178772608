import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import AuthSimpleLayout from './AuthSimpleLayout'
import axios from 'axios'
import { useSelector } from 'react-redux'
import is from 'is_js'
import MainLayout from './MainLayout'
import AuthCardRoutes from 'components/authentication/card/AuthCardRoutes'
import AuthSplitRoutes from 'components/authentication/split/AuthSplitRoutes'

import ErrorLayout from './ErrorLayout'
import WizardAuth from 'components/authentication/wizard/WizardAuth'
import Login from 'page/Login'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Bookings from 'page/Bookings'
import BookingsDetail from 'page/Bookings/Detail'
import BookingsCreate from 'page/Bookings/Create'
import Shipments from 'page/Shipments'
import ShipmentsDetail from 'page/Shipments/Detail'
import UserManagement from 'page/UserManagement'
import UserCreate from 'page/UserManagement/Create'
import UserDetail from 'page/UserManagement/detail'
import AccountUpgrade from 'page/AccountUpgrade'
import Organisation from 'page/Organisation'
import Error404 from 'components/errors/Error404'
import Dashboard from 'page/dashboard'
import SettingPanel from 'components/SettingPanel'
import { FormProvider } from 'context/FormContext'
import Payment from 'page/Payment'

// Check Session
const RootComponent = () => {
  const user = useSelector(state => state.user)

  if (user && user?.token) {
    return <Navigate to={'/dashboard'} />
  } else {
    return <Navigate to={'/login'} />
  }
}

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  const navigate = useNavigate()
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
  }, [HTMLClassList])

  return (
    <>
      <Routes>
        <Route exact path="/" element={<RootComponent navigate={navigate} />} />
        <Route path="/login" element={<Login navigate={navigate} />} />
        <Route path="/errors" element={<ErrorLayout />} />
        <Route path="/authentication/simple" element={<AuthSimpleLayout />} />
        <Route path="/authentication/card" element={<AuthCardRoutes />} />
        <Route path="/authentication/split" element={<AuthSplitRoutes />} />
        <Route path="/authentication/wizard" element={<WizardAuth />} />

        {/* Protexted routes */}
        <Route exact element={<MainLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/bookings"
            element={<Bookings navigate={navigate} />}
          />
          <Route
            exact
            path="/bookings-detail/:id"
            element={<BookingsDetail />}
          />
          <Route
            exact
            path="/bookings/create/"
            element={
              <FormProvider>
                <BookingsCreate />
              </FormProvider>
            }
          />
          <Route
            exact
            path="/shipments"
            element={<Shipments navigate={navigate} />}
          />
          <Route
            exact
            path="/shipments-detail/:id"
            element={<ShipmentsDetail />}
          />
          <Route exact path="/users-access" element={<UserManagement />} />
          <Route exact path="/payment-demo" element={<Payment />} />
          <Route
            exact
            path="/users-access-create/:action/:id"
            element={UserCreate}
          />
          <Route exact path="/user-detail/:id" element={<UserDetail />} />
          <Route exact path="/account-upgrade" element={<AccountUpgrade />} />
          <Route exact path="/organisation" element={<Organisation />} />
        </Route>

        {/* Error 404 page not found */}
        <Route path="*" element={<Error404 />} />
      </Routes>
      {/* <SettingsToggle /> */}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        icon
      />
      <SettingPanel />
    </>
  )
}

export default Layout

import React, { useState } from 'react'
import Input from 'components/Input'
import Dropdown from 'components/Dropdown'
import DatePicker from 'components/DatePicker'

import {
  addRowTable,
  deleteLineTable,
  changeTableForm
} from '../../page/Bookings/Create/service'

function InputTableHandling({
  accessor,
  type,
  dropdownList,
  index,
  state,
  setState,
  label,
  module,
  disabled
}) {
  const [selectedDropdown, setSelectedDropdown] = useState()
  const [selectedDate, setSelectedDate] = useState('')
  const selectCellType = () => {
    if (disabled === false) {
      switch (type) {
        case 'action':
          return (
            <>
              <div
                className="icon-delete"
                onClick={() => {
                  if (state[module].length > 1) {
                    deleteLineTable({
                      state: state,
                      setState: setState,
                      index,
                      tableName: module
                    })
                  }
                }}
              >
                <span>{index + 1}</span>
                <i className="ri-close-line" />
              </div>
            </>
          )
        case 'number':
          return (
            <>
              <Input
                type="number"
                min={0}
                placeholder={`Enter ${label}`}
                value={state[module][index][accessor] ?? ''}
                onChange={e =>
                  changeTableForm({
                    val: e.target.value,
                    column: accessor,
                    index: index,
                    state: state,
                    setState: setState,
                    module: module
                  })
                }
              />
            </>
          )
        case 'dropdown':
          return (
            <>
              <Dropdown
                className="dropdown-val"
                placeholder={`Enter ${label}`}
                options={dropdownList}
                selectedValue={selectedDropdown}
                onChangeDropdown={selected => {
                  changeTableForm({
                    val: selected,
                    column: accessor,
                    index,
                    state: state,
                    setState: setState,
                    module: module
                  })
                  setSelectedDropdown(state[module][index][accessor] ?? '')
                }}
              />
            </>
          )
        case 'string':
          return (
            <>
              <Input
                placeholder={`Enter ${label}`}
                value={state[module][index][accessor] ?? ''}
                onChange={e =>
                  changeTableForm({
                    val: e.target.value,
                    column: accessor,
                    index,
                    state,
                    setState,
                    module: module
                  })
                }
              />
            </>
          )
        case 'date':
          return (
            <>
              <DatePicker
                placeholder={`Select ${label}`}
                defaultValues={selectedDate}
                onChange={selected => {
                  changeTableForm({
                    val: selected,
                    column: accessor,
                    index,
                    state: state,
                    setState: setState,
                    module: module
                  })
                  setSelectedDate(selected)
                }}
              />
            </>
          )
        case 'upload':
          return (
            <>
              <Input
                type="file"
                onChange={e =>
                  changeTableForm({
                    val: e.target.value,
                    column: accessor,
                    index,
                    state,
                    setState,
                    module: module
                  })
                }
              />
            </>
          )
        default:
          return (
            <>
              <Input
                placeholder={`Enter ${label}`}
                value={state[module][index][accessor] ?? ''}
                onChange={e =>
                  changeTableForm({
                    val: e.target.value,
                    column: accessor,
                    index,
                    state,
                    setState,
                    module: module
                  })
                }
              />
            </>
          )
      }
    } else {
      return (
        <>
          {type === 'action' ? (
            <i
              className="ri-add-line"
              onClick={() =>
                addRowTable({
                  state: state,
                  setState: setState,
                  tableName: module
                })
              }
            />
          ) : (
            <Input
              className="disabled-input"
              placeholder={`Add New ${label}`}
              disabled
            />
          )}
        </>
      )
    }
  }
  return <>{selectCellType()}</>
}

export default InputTableHandling

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from 'assets/img/logos/icon_blue.png'
import { appRoutes } from 'routes/routes'
import './_Navbar.scss'
import NavbarTopButton from './NavbarTopButton'

const NavbarTop = ({ navHandling, t }) => {
  // get the module label
  const location = useLocation()
  const [headerName, setHeaderName] = useState('')

  const getUrl = fullUrl => {
    var urlArray = fullUrl.split('/')
    return '/' + urlArray[1]
  }

  useEffect(() => {
    appRoutes.children.forEach(value => {
      const currentLocation = getUrl(location.pathname)

      if (!value.children) {
        if (value.to == currentLocation) setHeaderName(value.name)
        return
      }

      value.children.forEach(value => {
        if (value.to == currentLocation) setHeaderName(value.name)
        return
      })
    })
  }, [location.pathname])

  return (
    <div className="bd-highlight header">
      <div className="logo-section">
        <div className="menu-icon">
          <i
            className="ri-menu-line icon pointer"
            onClick={() => {
              navHandling()
            }}
          ></i>
        </div>
        <div className="col-7 justify-content-center mobile-logo">
          <img src={logo} className="logo" />
        </div>
        <div className="desktop-logo">
          <img src={logo} className="logo" />
        </div>
      </div>

      <div className="header-content">
        <div className="page-title">{t(headerName)}</div>
        <NavbarTopButton />
      </div>
    </div>
  )
}

export default NavbarTop

// import loading from '../../../assets/gif/LOADING-MLS-GRAY.gif'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail } from 'ApiService'
import { useParams } from 'react-router'
import HeaderUserDetail from 'components/HeadeUserDetail'
import {
  checkUserDetail,
  getDateVal,
  getDateVal2,
  getHeightDimension,
  getStringVal,
  getStringVal2
} from './service'

// translation
import { useTranslation } from 'react-i18next'
import LoadingAnimation from 'components/LoadingAnimation'

// const loadingMessage = (
//   <div style={{ background: 'transparent' }}>
//     <img src={loading} alt="" width="45" height="45" />
//   </div>
// )

const UserDetail = ({ navigate, user, preview, role }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const userDetail = useSelector(state => state.spUserDetails)
  const userDetailLoading = useSelector(state => state.spUserDetailsStatus)

  // translation
  const { t } = useTranslation()

  useEffect(async () => {
    if (preview === null || preview === undefined || preview === false) {
      await getUserDetail({ dispatch, id })
    }
  }, [])

  const [dimension, setDimension] = useState({
    height: getHeightDimension(preview, window.innerWidth, window.innerHeight),
    width: window.innerWidth
  })

  const { width, height } = dimension

  useEffect(() => {
    const handleResize = () => {
      setDimension({
        height: getHeightDimension(
          preview,
          window.innerWidth,
          window.innerHeight
        ),
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return (
    <>
      <Row className="mb-3">
        <Col className="sticky-header" xs={12} style={{ position: 'sticky' }}>
          <HeaderUserDetail
            module="User Details"
            email={userDetail?.loginEmail ?? '-'}
            name={preview ? user?.fullName ?? '' : userDetail?.fullName ?? '-'}
            navigate={navigate}
            id={id}
            t={t}
          />
        </Col>
        <div className="cardLayout mt-2">
          {userDetailLoading === true ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                minHeight: height,
                maxHeight: height,
                maxWidth: width
              }}
            >
              {<LoadingAnimation type={'user-detail'} />}
            </div>
          ) : (
            <Row className="formLayout p-3">
              <Col
                xs={12}
                md={10}
                className="pe-0 pe-md-3"
                style={{
                  minHeight: height,
                  maxHeight: height,
                  maxWidth: width
                }}
              >
                <Row>
                  <Col
                    lg={3}
                    sm={6}
                    xs={12}
                    className="d-flex align-items-center"
                  >
                    <i className="ri-map-pin-user-fill pe-2 detail-user-sub-icon" />
                    <div>
                      <div className="detail-user-subtitle">
                        {t('User Level')}
                      </div>
                      <div className="detail-user-value">
                        {getStringVal2(preview, role, null)}
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    sm={6}
                    xs={12}
                    className="d-flex align-items-center"
                  >
                    <i className="ri-line-chart-fill pe-2 detail-user-sub-icon" />
                    <div>
                      <div className="detail-user-subtitle">{t('Code')}</div>
                      <div className="detail-user-value">
                        {getStringVal2(
                          preview,
                          user?.code,
                          checkUserDetail(userDetail?.staffPortalData?.code)
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    sm={6}
                    xs={12}
                    className="d-flex align-items-center"
                  >
                    <i className="ri-mail-fill pe-2 detail-user-sub-icon" />
                    <div>
                      <div className="detail-user-subtitle">
                        {t('Email Address')}
                      </div>
                      <div className="detail-user-value">
                        {getStringVal(checkUserDetail(userDetail?.email))}
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    sm={6}
                    xs={12}
                    className={` ${
                      preview ? 'd-none' : 'd-flex'
                    } align-items-center`}
                  >
                    <i className="ri-navigate-line pe-2 detail-user-sub-icon" />
                    <div>
                      <div className="detail-user-subtitle">
                        {t('Last Login Date')}
                      </div>
                      <div className="detail-user-value">
                        {getDateVal(checkUserDetail(userDetail?.lastLogin))}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="pt-2">
                  <hr className="hori-line-2 mt-2 mb-2" />
                </div>
                <Row className="pt-2">
                  <div className="detail-user-title">
                    {t('Employee Details')}
                  </div>
                  <div className="pt-2">
                    <Row>
                      <Col xs={12} md={6} className="pe-2">
                        <Row>
                          <div className="detail-user-subtitle">
                            {t('Login Name')}
                          </div>
                          <div className="detail-user-value">
                            {getStringVal2(
                              preview,
                              user?.portalLogin,
                              checkUserDetail(userDetail?.portalLogin)
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} className="pt-2 pt-md-0">
                        <Row>
                          <Col xs={12} sm={12} md={8}>
                            <Row>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('Country')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal2(
                                      preview,
                                      user?.country,
                                      userDetail?.staffPortalData?.country ??
                                        null
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('State')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal2(
                                      preview,
                                      user?.state,
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.state
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('Nationality')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal2(
                                      preview,
                                      user?.nationality,
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.nationality
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={4} className="pt-2 pt-md-0">
                            <Row>
                              <div className="detail-user-subtitle">
                                {t('Job Title')}
                              </div>
                              <div className="detail-user-value">
                                {getStringVal2(
                                  preview,
                                  user?.jobTitle,
                                  checkUserDetail(
                                    userDetail?.staffPortalData?.jobTitle
                                  )
                                )}
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col xs={12} md={6} className="pe-2">
                        <Row>
                          <div className="detail-user-subtitle">
                            {t('Preferred Name')}
                          </div>
                          <div className="detail-user-value">
                            {getStringVal2(
                              preview,
                              user?.preferredName,
                              checkUserDetail(
                                userDetail?.staffPortalData?.preferredName
                              )
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} className="pt-2 pt-md-0">
                        <Row>
                          <Col xs={12} sm={12} md={8}>
                            <Row>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('City')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal2(
                                      preview,
                                      user?.city,
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.city
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    DoB
                                  </div>
                                  <div className="detail-user-value">
                                    {getDateVal2(
                                      preview,
                                      user?.dateOfBirth,
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.dateOfBirth
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('Employed')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getDateVal2(
                                      preview,
                                      user?.employed,
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.employed
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col xs={12} md={6} className="pe-2">
                        <Row>
                          <div className="detail-user-subtitle">
                            {t('Address')}
                          </div>
                          <div className="detail-user-value">
                            {getStringVal2(
                              preview,
                              user?.address,
                              checkUserDetail(
                                userDetail?.staffPortalData?.address
                              )
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} className="pt-2 pt-md-0">
                        <Row>
                          <Col xs={12} sm={12} md={8}>
                            <Row>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('Postcode')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal2(
                                      preview,
                                      user?.postCode,
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.postCode
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('Gender')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal(
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.gender
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col xs={4} className="pe-2">
                                <Row>
                                  <div className="detail-user-subtitle">
                                    {t('language')}
                                  </div>
                                  <div className="detail-user-value">
                                    {getStringVal(
                                      checkUserDetail(
                                        userDetail?.staffPortalData?.language
                                      )
                                    )}
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <div className="pt-2">
                  <hr className="hori-line-2 mt-2 mb-2" />
                </div>
                <Row>
                  <Col xs={12} md={6} className="pt-2">
                    <div className="detail-user-title">{t('Emails')}</div>
                    <div className="pt-2">
                      <div className="detail-user-subtitle">
                        {getStringVal(checkUserDetail(userDetail?.email))}
                        {userDetail?.email != null &&
                        userDetail?.email != undefined &&
                        userDetail?.email != ''
                          ? ' (Main)'
                          : ''}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <div className="pt-2 d-md-none">
                <hr className="hori-line-2 mt-2 mb-2" />
              </div>
            </Row>
          )}
        </div>
      </Row>
    </>
  )
}

export default UserDetail

import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import RequiredMessage from '../RequiredMessage'

const Dropdown = ({
  name,
  isEmpty,
  placeholder,
  options,
  selectedValue,
  entryListIdx,
  onChangeDropdown,
  poListIdx,
  isDisabled,
  className,
  onMenuOpen,
  onMenuClose,
  titleClassName,
  // showTitle,
  title = null,
  required,
  readOnly,
  messageRequired,
  messageParam = { messageShow: false, messageData: {}, messageCustom: {} },
  parentDivClassName,
  parentDivStyle
}) => {
  const [isOpen, setIsOpen] = useState()
  const onChangeHandler = (selected, action) => {
    setIsOpen(false)
    if (action === 'clear') {
      onChangeDropdown('')
    } else {
      onChangeDropdown(selected)
    }
  }

  useEffect(() => {
    if (selectedValue === 'empty') setIsOpen(true)
  }, [selectedValue, isEmpty])
  const elem = document?.getElementById(`dropdown${entryListIdx}${poListIdx}`)
  let position = elem?.getBoundingClientRect()

  useEffect(() => {
    position = elem?.getBoundingClientRect()

    if (onMenuOpen && isOpen === false) {
      onMenuClose()
    } else if (onMenuClose && isOpen === true) {
      onMenuOpen(messageParam.messageShow)
    }
  }, [isOpen])
  return (
    <div className={`${parentDivClassName}`} style={parentDivStyle}>
      {!title ? null : (
        <label
          className={`title-gray mb-0 ${titleClassName}  ${
            required ? 'required' : ''
          }`}
        >
          {title}
        </label>
      )}
      <Select
        className={`${className} form-dropdown  ${
          isDisabled ? ' disable-custom-button' : ''
        }`}
        isDisabled={isDisabled || false}
        isClearable={!readOnly}
        isSearchable={!readOnly}
        openMenuOnClick={!readOnly}
        value={selectedValue?.value ? selectedValue : false}
        menuIsOpen={isOpen}
        menuPortal
        menuPortalTarget={document.body}
        placeholder={placeholder}
        options={options}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        onChange={(val, { action }) => onChangeHandler(val, action)}
        maxMenuHeight={200}
        menuPlacement={`${position?.bottom > 600 ? 'top' : 'bottom'}`}
        filterOption={(option, inputVal) => {
          return (
            option.label.substr(0, inputVal.length).toUpperCase() ==
            inputVal.toUpperCase()
          )
        }}
        styles={{
          placeholder: provided => ({
            ...provided,
            color: '#efeef9',
            fontSize: '12px'
          }),
          option: provided => ({
            ...provided,
            textAlign: 'left'
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            display: readOnly || selectedValue ? 'none' : 'flex'
          }),
          control: provided => ({
            ...provided,
            backgroundColor: readOnly ? '#e4e7ea !important' : 'white',
            pointerEvents: readOnly ? 'none' : 'auto',
            border: 0,
            borderColor: '#D5D9E8 !important',
            height: 34,
            minHeight: 34,
            boxShadow: 'none'
          }),
          valueContainer: provided => ({
            ...provided,
            paddingLeft: 14
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 4
        })}
        components={{
          IndicatorSeparator: () => null
        }}
      />

      {!messageRequired ? null : (
        <RequiredMessage
          column={name}
          messageShow={messageParam?.messageShow}
          columnText={title || placeholder}
          value={messageParam?.value}
          customMessage={messageParam?.messageCustom}
        />
      )}
    </div>
  )
}

export default Dropdown
